import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AllBrands } from "./AllBrands";
import { ManageBrand } from "./ManageBrand";

export const Brands = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={AllBrands} />
      <Route exact path={`${path}/:brandId`} component={ManageBrand} />
    </Switch>
  );
};
