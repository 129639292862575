import React, { useContext, useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import { TableHeader, TableText } from "../../../../styles/StyledComponents";
import { AllBrandsContext } from ".";
import { Brand } from "../../types";
import { OpenFieldIcon } from "../../../../assets/icons/OpenFieldIcon";
import { EditFieldIcon } from "../../../../assets/icons/EditFieldIcon";
import { DeleteFieldIcon } from "../../../../assets/icons/DeleteFieldIcon";
import { useHistory } from "react-router";
import { BrandDialog } from "./BrandDialog";

export const BrandsTable = () => {
  const { brands, isLoading, deleteBrand } = useContext(AllBrandsContext);
  const [editBrandDialog, setEditBrandDialog] = useState(false);
  const [brandToEdit, setBrandToEdit] = useState<Brand>();
  const history = useHistory();

  const handleDelete = (brand: Brand) => {
    if (brand.modelsAssigned > 0) {
      Modal.confirm({
        title: "You can not delete this brand",
        icon: null,
        content: (
          <div>
            <span style={{ color: "#FF3E3E" }}>
              This brand has {brand.modelsAssigned}{" "}
              {brand.modelsAssigned > 1 ? "models " : "model "}
              assigned to it.
            </span>
            <br /> Before you do this action, make sure that
            {brand.modelsAssigned > 1 ? "all the models are" : "the model is"} assigned to another
            brand.
          </div>
        ),
        okButtonProps: { style: { display: "none" } },
        cancelText: "I understand",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#137AE9",
          },
        },
      });
    } else {
      Modal.confirm({
        title: "You are about to delete a brand",
        icon: null,
        content: "This action can not be reversed. Are you sure you want to delete this brand?",
        okText: "Yes, delete brand",
        okButtonProps: {
          style: {
            backgroundColor: "#FF3E3E",
            color: "white",
            borderRadius: "5px",
            border: "none",
          },
        },
        onOk() {
          return deleteBrand(brand.id);
        },
        cancelText: "Cancel action",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#A8A8A8",
          },
        },
      });
    }
  };

  return (
    <div>
      <Table dataSource={brands} rowKey="id" loading={isLoading} pagination={false}>
        <Table.Column
          dataIndex="name"
          title={<TableHeader>Brand name</TableHeader>}
          render={(name) => <TableText>{name}</TableText>}
        />
        <Table.Column
          dataIndex="modelsAssigned"
          title={<TableHeader>Models Assigned</TableHeader>}
          render={(modelsAssigned) => <TableText>{modelsAssigned}</TableText>}
        />
        <Table.Column
          dataIndex="itemsAssigned"
          title={<TableHeader>Items Assigned</TableHeader>}
          render={(itemsAssigned) => <TableText>{itemsAssigned}</TableText>}
        />
        <Table.Column<Brand>
          title={<TableHeader>Actions</TableHeader>}
          render={(brand: Brand) => (
            <div>
              <Tooltip title="Open">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/inventory/brands/${brand.id}`)}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <EditFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setBrandToEdit(brand);
                    setEditBrandDialog(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(brand)}
                />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      <BrandDialog
        isVisible={editBrandDialog}
        setIsVisible={setEditBrandDialog}
        brand={brandToEdit}
      />
    </div>
  );
};
