import React, {useEffect, useState} from "react";
import {Button, Skeleton, Typography} from "antd";
import {useTimeOffAdministration} from "../_core/hooks/time-off-administration.hook";
import {TimeOffAdministrationTable} from "./TimeOffAdministrationTable";
import {IncreaseAvailabilityForAllModal} from "./AdjustAvailabilityForAllModal";
import {useTimeOffListingDatasource} from "../_core/hooks/time-off-listing-datasource.hook";

export const TimeOffAdministration: React.FC = () => {

    const timeOffAdministration = useTimeOffAdministration();
    const {getEmployeesData, data, isLoading} = timeOffAdministration;

    const timeOffListingDatasource = useTimeOffListingDatasource();
    const {publicHolidaysSeed} = timeOffListingDatasource;

    const [displayAdjustAllModal, toggleAdjustAllModal] = useState(false);

    useEffect(() => {
        getEmployeesData();
    }, [])

    return (
        <>
            {
                isLoading && <Skeleton active />
            }
            {
                !isLoading &&
                <>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 25}}>
                        <Typography.Title style={{ display: "flex", justifyContent: "flex-start" }}>
                            Time Off Administration
                        </Typography.Title>

                        <Button
                            type="primary"
                            style={{borderRadius: 4}}
                            onClick={() => toggleAdjustAllModal(true)}
                        >Bulk Edit</Button>
                    </div>


                    <div style={{paddingBottom: 50}}>
                        <TimeOffAdministrationTable data={data} refreshData={() => getEmployeesData()} publicHolidaysSeed={publicHolidaysSeed}/>
                    </div>

                    <IncreaseAvailabilityForAllModal
                        data={data}
                        isVisible={displayAdjustAllModal}
                        onClose={() => toggleAdjustAllModal(false)}
                        onSuccess={() => {getEmployeesData(); toggleAdjustAllModal(false)}}
                    />
                </>
            }

        </>
    )
}
