import { Button, Card, Col, Row, Skeleton, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { ManageItemContext } from ".";
import { PrinterIcon } from "../../../../assets/icons/Inventory/PrinterIcon";
import { CardTitle } from "../../../../styles/StyledComponents";

export const ItemDetails = () => {
  const { item, isLoading } = useContext(ManageItemContext);
  const history = useHistory();
  return (
    <Card
      type="inner"
      title={<CardTitle>Item Details</CardTitle>}
      extra={
        <Tooltip title="Print label">
          <PrinterIcon style={{ cursor: "pointer" }} />
        </Tooltip>
      }
    >
      <Skeleton loading={isLoading}>
        <Row>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 130 }}>
                <Row>
                  <Typography.Text type="secondary">Item Number</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.inventoryNumber}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 130 }}>
                <Row>
                  <Typography.Text type="secondary">Model</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.modelName}</Typography.Text>
                </Row>
              </Col>
            </Row>
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 130 }}>
                <Row>
                  <Typography.Text type="secondary">Brand</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.brandName}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Owned by</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  {item?.ownedByFirstName ? (
                    <Button
                      style={{ margin: 0, padding: 0 }}
                      type="link"
                      onClick={() => history.push(`/employees/${item.ownedById}`)}
                    >{`${item.ownedByFirstName} ${item.ownedByLastName} `}</Button>
                  ) : (
                    <Typography.Text style={{ color: "#00AD0D" }}>Available</Typography.Text>
                  )}
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Office</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    type="link"
                    onClick={() => history.push(`/office/${item?.officeId}`)}
                  >
                    {item?.officeCity}
                  </Button>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Buy date</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>
                    {moment(item?.acquisitionDate).format("DD.MM.YYYY")}
                  </Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span="8">
            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Warranty</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.warranty} months</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Invoice Number</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.invoice}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Serial Number</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{item?.serialNumber}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 130 }}>
            <Row>
              <Typography.Text type="secondary">Item Description</Typography.Text>
            </Row>
          </Col>
          <Col flex="1">
            <Row>
              <Typography.Text>{item?.description}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};
