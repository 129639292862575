import moment from "moment";

import { useContext, useState } from "react";
import { Calendar, SlotInfo, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { TimeTrackingUserDataContext } from "../../../TimeTrackingUserDataProvider";
import { convertToLocal } from "../utils/helpers";
import { TrackerContext } from "../utils/types";
import EditSessionModal from "./EditSessionModal";

const localizer = momentLocalizer(moment);

const CalendarTimePicker: React.FC = () => {
  const [selectedSession, setSelectedSession] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const { createSession } = useContext(TrackerContext);
  const { sessionWeeks } = useContext(TimeTrackingUserDataContext);

  const events = sessionWeeks.flatMap((week) =>
    week.days.flatMap((day) =>
      day.tasks.flatMap((task) =>
        task.sessions.map((session, i) => ({
          id: session.id,
          title: task.taskName,
          start: convertToLocal(session.start).toDate(),
          end: convertToLocal(session.end).toDate(),
          color: task.color,
        }))
      )
    )
  );

  const handleSelect = async ({ start, end, box }: SlotInfo) => {
    const startTime = moment(start).format("HH:mm");
    const endTime = moment(end).format("HH:mm");
    await createSession(startTime, endTime, moment(start));
  };

  const handleEventSelect = (calendarEvent: any, e: any) => {
    setSelectedSession(calendarEvent);

    setModalVisible(true);
  };

  return (
    <>
      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day"]}
          selectable={true}
          formats={{
            timeGutterFormat: "HH:mm",
            eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
              localizer?.format(start, "HH:mm", culture) +
              " – " +
              localizer?.format(end, "HH:mm", culture),
          }}
          onDoubleClickEvent={(ev, e) => handleEventSelect(ev, e)}
          onSelectSlot={handleSelect}
          style={{ height: "70vh" }}
          onSelectEvent={handleEventSelect}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: event.color,
              color: "white",
              borderRadius: "10px",
              border: "1px solid #333",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            };

            return {
              className: "",
              style: newStyle,
            };
          }}
        />
        {selectedSession && (
          <EditSessionModal
            session={selectedSession}
            onUpdate={() => {
              /* Handle updating the session here, if necessary. */
              setModalVisible(false);
            }}
            isModalVisible={isModalVisible}
            onClose={() => setModalVisible(false)}
          />
        )}{" "}
      </div>
    </>
  );
};

export default CalendarTimePicker;
