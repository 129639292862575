export enum TimeOffRevisionType
{
    NotSpecified = 0,
    RequestApproval = 10,
    RequestUnapproval = 11,
    RequestDeletion = 12,
    YearlyRefill = 20,
    InitialSeed = 30,
    ErrorCorrection = 40,
    ManualIntervention = 50,
    Other = 100
}
