import { Button, Modal, Form, Input, Spin, Select } from "antd";
import { OfficesContext } from "./index";
import React, { useContext } from "react";
import { GreyText } from "./styled";
import { Timezone } from "../../_core/types";
import { CommonResourcesContext } from "../Routing/AppRouting";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import {countryTypesOptions} from "../../_core/hardcodedValues";
import {CountryTypes} from "../../_core/enums";

export const AddOfficeModal = () => {
  const {
    addOfficeModal,
    setAddOfficeModal,
    handleAddOffice,
    loading,
    managers,
  } = useContext(OfficesContext);

  const { timezones } = useContext(CommonResourcesContext);
  const [formInstance] = Form.useForm();

  const handleCreateCancel = () => {
    formInstance.resetFields();
    setAddOfficeModal(false);
  };

  return (
    <Modal
      visible={addOfficeModal}
      width="600px"
      onCancel={handleCreateCancel}
      footer={null}
      okButtonProps={{
        id: "button__edit-office",
        loading: loading,
      }}
      closeIcon={<RoundCloseIcon />}
    >
      <div style={{ width: "100%" }}>
        <h2 style={{ marginLeft: "auto", marginBottom: "15px" }}>
          Add a new Office
        </h2>
      </div>
      <Form
        form={formInstance}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
        // {...formItemLayout}
        onFinish={handleAddOffice}
      >
        <GreyText>City</GreyText>

        <Form.Item
          name="city"
          style={{ width: "100%" }}
          rules={[
            { required: true, message: "Please input a city!" },
            {
              max: 20,
              message: "The city name should not be longer than 20 characters!",
            },
          ]}
        >
          <Input
            id="input__city"
            size="large"
            style={{ borderRadius: 8 }}
            maxLength={20}
          />
        </Form.Item>

        <GreyText>Country</GreyText>

          <Form.Item
              name="countryTypes"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Please input a country!" }]}
          >
              <Select
                  id="input__country"
                  size="large"
                  style={{ borderRadius: 8 }}
                  loading={loading}
              >
                  {countryTypesOptions.map((country: { text: string, value: CountryTypes }) => {
                      return (
                          <Select.Option key={country.value} value={country.value}>
                              {country.text}
                          </Select.Option>
                      );
                  })}
              </Select>
          </Form.Item>

        <GreyText> Address </GreyText>

        <Form.Item
          name="address"
          style={{ width: "100%" }}
          rules={[
            { required: true, message: "Please input the Address!" },
            {
              max: 100,
              message: "The address should not be longer than 100 characters!",
            },
          ]}
        >
          <Input
            id="input__address"
            size="large"
            style={{ borderRadius: 8 }}
            maxLength={100}
          />
        </Form.Item>

        <GreyText> Manager </GreyText>

        <Form.Item
          name="manager"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input a manager!" }]}
        >
          <Select
            id="input__manager"
            size="large"
            style={{ borderRadius: 8 }}
            showSearch
            optionFilterProp="children"
            loading={loading}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
            }
          >
            {Object.keys(managers).map((managerId: string) => (
              <Select.Option key={managerId} value={managerId}>
                {managers[managerId]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <GreyText> Time zone </GreyText>

            <Form.Item
              name="timezone"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[{ required: true, message: "Please input a timezone!" }]}
            >
              <Select
                id="input__timezone"
                size="large"
                style={{ borderRadius: 8 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) !==
                  -1
                }
              >
                {timezones.map((timezone: Timezone, index) => (
                  <Select.Option key={index} value={timezone.name}>
                    <div style={{ float: "left" }}>{timezone.name}</div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <GreyText> Start Working Hour </GreyText>

            <Form.Item
              name="startWorkingHour"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input the start working hour!",
                },
                {
                  validator(_, value) {
                    if (value >= 0 && value < 24) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please input a valid hour! (0-23)");
                  },
                },
              ]}
            >
              <Input
                id="input__startWorkingHour"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={2}
              />
            </Form.Item>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <GreyText> Latitude </GreyText>

            <Form.Item
              name="latitude"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[
                { required: true, message: "Please input the latitude!" },
                {
                  validator(_, value) {
                    if (value >= 0 && value <= 90) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please input a valid latitude! (0-90)"
                    );
                  },
                },
              ]}
            >
              <Input
                id="input__latitude"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={8}
              />
            </Form.Item>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <GreyText> Longitude </GreyText>

            <Form.Item
              name="longitude"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[
                { required: true, message: "Please input the longitude!" },
                {
                  validator(_, value) {
                    if (value >= -180 && value <= 180) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Please input a valid longitude! (-180 - 180)"
                    );
                  },
                },
              ]}
            >
              <Input
                id="input__longitude"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={8}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item style={{ width: "100%" }}>
          <Button
            id="button__save"
            key="Add"
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              borderRadius: 6,
              backgroundColor: "#137AE9",
              height: 48,
              marginTop: 8,
              marginBottom: -8,
            }}
          >
            Add Office
          </Button>
        </Form.Item>

        {/* <Form.Item>
            <Button key="back" onClick={() => setAddOfficeModal(false)}>
              Cancel
            </Button>
          </Form.Item> */}
      </Form>

      {loading && <Spin tip="Loading..." style={{ marginTop: "24px" }} />}
    </Modal>
  );
};
