import { useContext, useMemo, useState } from "react";

import moment from "moment";
import TrackerModeSwitch from "../TrackerModeSwitch";
import { TrackerContext } from "../utils/types";
import TimeInput from "../../common/TimeInput";
import { DatePicker } from "antd";

export const ManualInsert = () => {
  const { day, setDay, isTracking, setStartTime, setEndTime } =
    useContext(TrackerContext);
  const [startTimeInternal, setStartTimeInternal] = useState<string>(
    moment().format("HH:mm")
  );
  const [endTimeInternal, setEndTimeInternal] = useState<string>(
    moment().add(30, "minutes").format("HH:mm")
  );
  const [intervalDuration, setIntervalDuration] = useState<string>("0:30");
  const showPlusOneBadge = useMemo<boolean>(() => {
    return moment(endTimeInternal, "HH:mm").isBefore(
      moment(startTimeInternal, "HH:mm")
    );
  }, [startTimeInternal, endTimeInternal]);

  const recalculateIntervalDuration = (start?: string, end?: string) => {
    //if either one of them is undefined then use the internal state
    //if we use only the internal state on blur then we will have the precendence of the previous value
    const [startHour, startMinute] = (start || startTimeInternal).split(":");
    const [endHour, endMinute] = (end || endTimeInternal).split(":");
    let hoursDuration = parseInt(endHour) - parseInt(startHour);
    let minutesDuration = parseInt(endMinute) - parseInt(startMinute);

    if (isNaN(minutesDuration)) minutesDuration = 0;
    if (isNaN(hoursDuration)) hoursDuration = 0;
    if (hoursDuration < 0) hoursDuration = 24 + hoursDuration;
    if (minutesDuration < 0) {
      minutesDuration = 60 + minutesDuration;
      if (hoursDuration === 0) hoursDuration = 23;
      else hoursDuration--;
    }

    setIntervalDuration(hoursDuration + ":" + minutesDuration);
  };
  const handleDurationBlur = (duration?: string) => {
    const [hours, minutes] = duration?.split(":") || ["0", "0"];
    const newEndTime = moment(startTimeInternal, "HH:mm")
      .add(parseInt(hours), "hours")
      .add(parseInt(minutes), "minutes")
      .format("HH:mm");
    setEndTimeInternal(newEndTime);
    setEndTime(newEndTime);
    // ONLY NEEDED TO ENSURE THAT THE DURATION TIME IS UPDATED
    // recalculateIntervalDuration();
  };
  const handleDurationChange = (value: string) => {
    setIntervalDuration(value);
  };

  const getDisabledDate = (currentDate: moment.Moment) => {
    const now = moment();
    return (
      currentDate &&
      (currentDate > now.endOf("day") || currentDate.month() !== now.month())
    );
  };

  const handleStartBlur = (time?: string) => {
    setStartTimeInternal(time || "");
    setStartTime(time || "");
    recalculateIntervalDuration(time);
  };

  const handleEndBlur = (time?: string) => {
    setEndTimeInternal(time || "");
    setEndTime(time || "");
    recalculateIntervalDuration(undefined, time);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <TrackerModeSwitch />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "calc(50% - 3px)",
          }}
        >
          <TimeInput
            placeholder="Start"
            style={{ width: "100%", textAlign: "center" }}
            onBlur={handleStartBlur}
            valueProps={startTimeInternal}
          />
        </div>
        <div
          style={{
            position: "relative",
            width: "calc(50% - 3px)",
          }}
        >
          <TimeInput
            style={{ width: "100%", textAlign: "center" }}
            placeholder="End"
            onBlur={handleEndBlur}
            valueProps={endTimeInternal}
          />
          {showPlusOneBadge && (
            <span
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "white",
                background: "#ff6464",
                padding: "2px 4px",
                fontSize: "10px",
                marginLeft: "-10px",
                marginTop: "-8px",
                height: "fit-content",
                zIndex: 10,
                borderRadius: "100%",
              }}
            >
              +1
            </span>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <DatePicker
          style={{
            width: "calc(50% - 3px)",
            marginRight: 6,
            borderRadius: 5,
          }}
          defaultPickerValue={moment()}
          value={day ? moment(day) : null}
          defaultValue={moment()}
          onSelect={(value) => {
            setDay(value);
          }}
          disabledDate={getDisabledDate}
        />

        <div
          style={{
            flex: 1,
            border: "1px solid #ffffff",
            borderRadius: "5px",
            height: "32px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "#ececec",
            paddingLeft: 10,
          }}
        >
          <span>Total: </span>
          <TimeInput
            onBlur={handleDurationBlur}
            onChange={handleDurationChange}
            valueProps={intervalDuration}
            style={{ flex: 1, paddingLeft: 20 }}
          />
        </div>
      </div>
    </div>
  );
};
