import { useContext } from "react";
import { UserContext } from "../../App";

export const useUserRoles = () => {
  const { user } = useContext(UserContext);

  const isAdmin = () => {
    return !!user.userRoles?.find((role: string) => role === "Admin");
  };

  const isNormalUser = () => {
    return !!user.userRoles?.find((role: string) => role === "NormalUser");
  };

  const isOperationalUser = () => {
    return !!user.userRoles?.find((role: string) => role === "Operational");
  };

  const isTeamLead = (projectId: string) => {
    return !!user.teamLeaderProjectIds.find((x) => x === projectId);
  };

  const isOfficeManager = (officeId: string) => {
    return !!user.managerOfficeIds.find((x) => x === officeId);
  };

  const isForecastUser = () => {
    return !!user.userRoles?.find((role: string) => role === "Forecast");
  };

  return {
    isAdmin,
    isNormalUser,
    isOperationalUser,
    isTeamLead,
    isOfficeManager,
    isForecastUser,
  };
};
