import React, { FC, useContext } from "react";
import useFilters from "./useFilters";
import { ReportsFiltersContextInterface } from "./types";

export const ReportsFiltersContext =
  React.createContext<ReportsFiltersContextInterface>(
    {} as ReportsFiltersContextInterface
  );

export const useReportsFilters = () => {
  const context = useContext(ReportsFiltersContext);
  if (!context) {
    throw new Error(
      "useReportsFilters must be used within a ReportsFiltersContextProvider"
    );
  }
  return context;
};

export const ReportsFiltersContextProvider: FC = ({ children }) => {
  const filtersHook = useFilters();
  return (
    <ReportsFiltersContext.Provider value={filtersHook}>
      {children}
    </ReportsFiltersContext.Provider>
  );
};
