import { Button, Col, Dropdown, Menu, Row } from "antd";
import React, { createContext, useContext, useState } from "react";
import { MenusIcon } from "../../../../../assets/icons/CustomIcons";
import { TimeTrackingUserDataContext } from "../../TimeTrackingUserDataProvider";
import { TimeTrackingSession } from "../../types";
import { Date } from "./EditInputs/Date";
import { SessionType } from "./EditInputs/SessionType";
import { StartEnd } from "./EditInputs/StartEnd";
import { TotalTime } from "./EditInputs/TotalTime";
import { IUseEditSession } from "./EditInputs/types";
import { useEditSession } from "./EditInputs/useEditSession";
import { isLoadingAnUpdate } from "./EditInputs/utils";

interface SessionProps {
  session: TimeTrackingSession;
  taskId: string;
}

export const EditSessionContext = createContext<IUseEditSession>(
  {} as IUseEditSession
);

export const Session: React.FC<SessionProps> = ({ session, taskId }) => {
  const [isHovered, setHovered] = useState(false);
  const hook = useEditSession(session);
  const {
    deleteSession,
    cloneSession,
    sessionToDelete,
    sessionToClone,
    setSessionToChangeTask,
  } = useContext(TimeTrackingUserDataContext);
  const menu = (
    <Menu
      style={{
        border: "none",
        borderRadius: "5px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        overflow: "hidden",
      }}
    >
      <Menu.Item
        key="1"
        style={{
          padding: "10px 20px",
          color: "#000",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          textAlign: "center",
        }}
        onClick={() => setSessionToChangeTask(session)}
      >
        Change Task
      </Menu.Item>
      <Menu.Item
        key="2"
        style={{
          padding: "10px 20px",
          color: "#000",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          textAlign: "center",
        }}
        onClick={() => cloneSession(session, taskId)}
      >
        Clone
      </Menu.Item>
      <Menu.Item
        key="3"
        style={{
          padding: "10px 20px",
          color: "red",
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: 500,
          textAlign: "center",
        }}
        onClick={() => deleteSession(session.id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <EditSessionContext.Provider value={hook}>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
          padding: "8px",
          paddingLeft: "2px",
          paddingRight: "2px",
          width: "100%",
          marginTop: "8px",
          marginBottom: "8px",
          fontWeight: 600,
          transition: "0.3s",
          boxShadow: isHovered
            ? "0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"
            : "none",
          backgroundColor: isLoadingAnUpdate(hook.loading)
            ? "#f6f6f9"
            : sessionToDelete === session.id
            ? "#feeeee"
            : sessionToClone === session.id
            ? "#eff9ef"
            : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: "36px",
          }}
        >
          <SessionType isHovered={isHovered} />

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <StartEnd isHovered={isHovered} />
            </div>

            <div
              style={{
                borderRight: "dotted",
                borderWidth: "2px",
                borderColor: "grey",
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "33px",
              }}
            >
              <Date />
            </div>

            <div
              style={{
                fontWeight: 700,
                fontSize: "16px",
                borderRight: "dotted",
                borderWidth: "2px",
                borderColor: "grey",
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
                marginRight: "16px",
                justifyContent: "center",
                maxWidth: "88px",
                paddingLeft: "4px",
                paddingRight: "4px",
              }}
            >
              <TotalTime isHovered={isHovered} />
            </div>

            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="link">
                <MenusIcon />
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </EditSessionContext.Provider>
  );
};
