import axios from "axios";

const useApiClient = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL || "http://dev.ia.cst.ro";
  const RECRUITMENT_APP_LINK = "http://dev.recruitment.ia.cst.ro";

  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  const apiClient = axios.create({
    baseURL: BASE_URL,
    responseType: "json",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json",
    },
  });
  apiClient.interceptors.response.use(
    (config) => {
      return config;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const { data } = await apiClient.get(
            `/api/account/new-access-token?refreshToken=${refreshToken}`
          );
          const { accessToken: newAccessToken, refreshToken: newRefreshToken } = data;
          localStorage.setItem("accessToken", newAccessToken);
          localStorage.setItem("refreshToken", newRefreshToken);
          axios.defaults.headers.common["Authorization"] = "Bearer " + newAccessToken;

          return apiClient(originalRequest);
        } catch (err) {
          return Promise.reject(error);
        }
      }
      return Promise.reject(error?.response);
    }
  );
  const setAuthorizationHeader = (token: string) => {
    apiClient.defaults.headers["Authorization"] = "Bearer " + token;
    apiClient.defaults.headers.common["Authorization"] = "Bearer " + token;
  };
  return {
    apiClient,
    BASE_URL,
    RECRUITMENT_APP_LINK,
    setAuthorizationHeader,
  };
};

export default useApiClient;
