import { Button, Card, Col, Row, Skeleton, Spin, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ProjectContext } from ".";
import { CardTitle, ProjectTag } from "../../../styles/StyledComponents";
import { ColorCircle } from "../ColorPicker";

export const ProjectDetails = () => {
  const rowStyle = {
    margin: 5,
  };

  const textStyle = {
    fontSize: "1.1em",
  };

  const { project, isLoading } = useContext(ProjectContext);

  const history = useHistory();
  return (
    <Card type="inner" title={<CardTitle>Project Details</CardTitle>}>
      <Skeleton loading={isLoading}>
        <Row justify="space-between">
          <Col style={{ textAlign: "left" }} xs={24} lg={10}>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Project Name
                </Typography.Text>
              </Col>
              <Col>
                <ProjectTag style={textStyle} color={project?.color}>
                  {project?.name}
                </ProjectTag>
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Client
                </Typography.Text>
              </Col>
              <Col>
                <Button
                  onClick={() => history.push(`/clients/${project?.client.id}`)}
                  type="link"
                  style={{ ...textStyle, padding: 0 }}
                >
                  {project?.client.name}
                </Button>
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Team Lead
                </Typography.Text>
              </Col>
              <Col>
                <Button
                  onClick={() => history.push(`/employees/${project?.teamLeader.userId}`)}
                  type="link"
                  style={{ ...textStyle, padding: 0 }}
                >
                  {project?.teamLeader
                    ? `${project?.teamLeader?.firstName} ${project?.teamLeader?.lastName}`
                    : ""}
                </Button>
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Worked time
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text style={textStyle}>{project?.workedTime}</Typography.Text>
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Forecasted time
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text style={textStyle}>{project?.forecastTime}</Typography.Text>
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Jira Project Key
                </Typography.Text>
              </Col>
              <Col>
                <Typography.Text style={textStyle}>{project?.jiraProjectKey}</Typography.Text>
              </Col>
            </Row>
          </Col>
          <Col xs={24} lg={10}>
            <Row>
              <Typography.Title level={5}>Important Links</Typography.Title>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Daily Meeting URL
                </Typography.Text>
              </Col>
              <Col>
                {project?.dailyUrl === null ? <Typography.Text type="secondary" style={{fontSize : '14px', color: 'grey'}}>
                  No existing link
                </Typography.Text> : <Button type="link" href={project?.dailyUrl} target="_blank" disabled={project?.dailyUrl === null}>
                  Open Link
                </Button>}
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Repo URL
                </Typography.Text>
              </Col>
              <Col>
              {project?.repoUrl === null ? <Typography.Text type="secondary" style={{fontSize : '14px', color: 'grey'}}>
                  No existing link
                </Typography.Text> : <Button type="link" href={project?.repoUrl} target="_blank" disabled={project?.repoUrl === null}>
                  Open Link
                </Button>}
              </Col>
            </Row>
            <Row justify="space-between" style={rowStyle}>
              <Col>
                <Typography.Text type="secondary" style={textStyle}>
                  Task Management URL
                </Typography.Text>
              </Col>
              <Col>
              {project?.taskManagementUrl === null ? <Typography.Text type="secondary" style={{fontSize : '14px', color: 'grey'}}>
                  No existing link
                </Typography.Text> : <Button type="link" href={project?.taskManagementUrl} target="_blank" disabled={project?.taskManagementUrl === null}>
                  Open Link
                </Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};
