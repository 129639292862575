import { createContext } from "react";
import { Task, Technology } from "../../../types";

export interface ITrackerContext {
  // State Values
  day: moment.Moment;
  technologies: Array<Technology>;
  tasks: Array<Task> | undefined;
  formData: TrackerForm;
  typeOfTracking: TrackingType;
  isTracking: boolean;
  isNewTask: boolean;
  trackerLoading: boolean;
  startTime: string;
  endTime: string;
  liveTime: number;
  newTaskTitle: string | undefined;

  // State Update Methods
  setDay: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setIsNewTask: React.Dispatch<React.SetStateAction<boolean>>;
  setFormData: React.Dispatch<React.SetStateAction<TrackerForm>>;
  setNewTaskTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;

  // Functions
  switchTracker: (type: TrackingType) => void;
  createSession: (
    startTimeProps?: string,
    endTimeProps?: string,
    dayProps?: moment.Moment
  ) => Promise<void>;
  startTracking: (projectId?: string, taskId?: string) => Promise<void>;
  stopTracking: () => Promise<void>;
  editCurrentSession: (start: string) => Promise<void>;
  showModal: () => void;
  handleClose: () => void;
  markTaskAsComplete: (taskId: string) => void;
}
export const TrackerContext = createContext<ITrackerContext>(
  {} as ITrackerContext
);
export enum SessionEnum {
  Other = 0,
  Coding = 10,
  ClientCall = 20,
  InternalCall = 25,
  Brainstorming = 30,
  Documentation = 40,
  Testing = 50,
  CodeReview = 60,
  Configuring = 70,
  Management = 80,
  InternalTraining = 90,
  ExternalTraining = 100,
  Design = 110,
}

export type TrackingType = "Auto" | "Manual" | "Calendar";
export interface TrackerForm {
  projectId: string;
  taskId?: string;
  technologyId: string;
  sessionType: number;
  description?: string;
  day?: string;
}
export const defaultTrackerForm: TrackerForm = {
  projectId: "",
  taskId: "",
  technologyId: "",
  sessionType: 10,
  description: "",
  day: "",
};
export type PreparedSession = {
  sessionType: number;
  start: string;
  taskId?: string;
  technologyId: string;
  task?: {
    projectId: string;
    title: string;
    description?: string;
  } | null;
};

export const SessionTypeLocalStorageKey = "internal-app-sessionType";
export const TechnologyLocalStorageKey = "internal-app-technology";
export const TypeOfTrackingLocalStorageKey = "internal-app-type-of-tracking";
