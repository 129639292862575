import { Row, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { ClientContactsContext } from ".";

export default function ClientInfo() {
  const { client } = useContext(ClientContactsContext);
  return (
    <div>
      <Row justify="center" style={{ margin: "5px" }}>
        <Typography.Title level={1}>{client?.name}</Typography.Title>
      </Row>
      <Row justify="space-between">
        <div>
          <Typography.Text type="secondary">Country</Typography.Text>
          <Typography.Title level={4}>{client?.country}</Typography.Title>
        </div>
        <div>
          <Typography.Text type="secondary">Town</Typography.Text>
          <Typography.Title level={4}>{client?.town}</Typography.Title>
        </div>
        <div>
          <Typography.Text type="secondary">Timezone Region</Typography.Text>
          <Typography.Title level={4}>{client?.timeZoneName}</Typography.Title>
        </div>
        <div>
          <Typography.Text type="secondary">Time Offset</Typography.Text>
          <Typography.Title level={4}>{client?.timeZone}</Typography.Title>
        </div>
      </Row>
    </div>
  );
}
