import React, { useContext, useState } from "react";
import { EditSessionContext } from "../Session";
import moment from "moment";
import TimeInput from "../../common/TimeInput";
import { Tooltip } from "antd";
import { isLoadingAnUpdate } from "./utils";

export interface StartEndProps {
  isHovered: boolean;
}
export const StartEnd: React.FC<StartEndProps> = ({ isHovered }) => {
  const { sessionState, setSessionState, loading } =
    useContext(EditSessionContext);

  const handleStartBlur = (value?: string) => {
    if (value) {
      const [hours, minutes] = value.split(":").map(Number);

      // Get a moment object for sessionState.start
      let adjustedStart = moment.utc(sessionState.start).local();

      // Adjust the hours and minutes of this object
      adjustedStart.set({ hour: hours, minute: minutes });

      // Convert back to UTC and format to ISO string
      const newStartDateTime = adjustedStart.utc().toISOString();

      // Update the sessionState with the new datetimes
      setSessionState({ ...sessionState, start: newStartDateTime });
    }
  };

  const handleEndBlur = (value?: string) => {
    if (value) {
      const [hours, minutes] = value.split(":").map(Number);
      let adjustedEnd = moment.utc(sessionState.end).local();
      adjustedEnd.set({ hour: hours, minute: minutes });
      const newEndDateTime = adjustedEnd.utc().toISOString();
      setSessionState({ ...sessionState, end: newEndDateTime });
    }
  };

  const startMoment = moment.utc(sessionState.start).local();
  const endMoment = moment.utc(sessionState.end).local();
  const startDay = startMoment.date();
  const endDay = endMoment.date();
  const daysAhead = endDay - startDay;

  return (
    <div style={{ display: "flex", gap: 6, alignItems: "center" }}>
      <TimeInput
        valueProps={moment.utc(sessionState.start).local().format("HH:mm")}
        onBlur={handleStartBlur}
        onEnter={handleStartBlur}
        style={{
          borderColor: isHovered ? "black" : "white",
          width: "80px",
          fontWeight: 500,
          fontSize: "16px",
          textAlign: "center",
        }}
        isDisabled={isLoadingAnUpdate(loading)}
      />
      <span style={{ fontSize: "20px" }}>-</span>
      <div style={{ display: "flex" }}>
        <TimeInput
          valueProps={moment.utc(sessionState.end).local().format("HH:mm")}
          onBlur={handleEndBlur} // Attach the new blur handler here
          onEnter={handleEndBlur} // Attach the new enter handler here
          style={{
            borderColor: isHovered ? "black" : "white",
            width: "80px",
            fontWeight: 500,
            fontSize: "16px",
            textAlign: "center",
          }}
          isDisabled={isLoadingAnUpdate(loading)}
        />
        {!isLoadingAnUpdate(loading) && daysAhead > 0 && (
          <Tooltip overlay={`Session spans ${daysAhead + 1} days`}>
            <div
              style={{
                background: "#ff6464",
                color: "white",
                borderRadius: "100%",
                padding: "2px 4px",
                fontSize: "10px",
                marginLeft: "-10px",
                marginTop: "-8px",
                height: "fit-content",
                zIndex: 10,
              }}
            >
              +{daysAhead}
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
