import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import BackgroundImg from "../../assets/images/authentication-background.png";
import { Register } from "../auth/Register";
import { useWindowSize } from "../../_core/hooks/useWindowSize";
import Logo from "../../assets/images/logo.png";
import styled from "styled-components";
import { Login } from "../auth/Login";
import { Layout } from "antd";
import { ForgotPassword } from "../auth/ForgotPassword";
import { ResetPassword } from "../auth/ResetPassword";

const SiderContent = styled.div`
  min-height: 100vh;
  background-image: url(${BackgroundImg});
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  flex-grow: 1;
`;

const { Content } = Layout;

export const AuthenticationRouting = () => {
  const { width } = useWindowSize();

  return (
    <BrowserRouter>
      <Layout
        style={{
          display: "flex",
          backgroundColor: "#FFF",
          flexWrap: "wrap",
          width: "100vw",
          flexDirection: "row",
        }}
      >
        <SiderContent style={{ paddingLeft: width > 400 ? 48 : 8 }}>
          <img style={{ maxWidth: 64 }} src={Logo} alt="company logo" />
          <div
            style={{
              marginTop: "30%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {window.location.pathname.substr(0, 6).toLowerCase() === "/login" ? (
              <>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 70,
                    color: "white",
                    marginBottom: -32,
                  }}
                >
                  Welcome
                </h1>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 70,
                    color: "white",
                    marginBottom: -8,
                  }}
                >
                  Back!
                </h1>

                <h2 style={{ color: "white", fontSize: 25 }}>Let's build great things!</h2>
              </>
            ) : (
              <>
                <h1
                  style={{
                    fontWeight: "bold",
                    fontSize: 70,
                    color: "white",
                    marginBottom: -2,
                  }}
                >
                  Welcome!
                </h1>

                <h2 style={{ color: "white", fontSize: 25, marginBottom: -8 }}>
                  You must be new to the team.
                </h2>
                <h2 style={{ color: "white", fontSize: 25 }}>Register and let's get started!</h2>
              </>
            )}
          </div>
          <div />
        </SiderContent>

        <Content
          style={{
            flexGrow: 2,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: 16,
            paddingTop: 128,
            paddingBottom: 64,
            overflowY: "scroll",
          }}
        >
          <Switch>
            <Route path="/Register" component={Register} />
            <Route path="/Login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Redirect to="/Login" />
          </Switch>
        </Content>
      </Layout>
    </BrowserRouter>
  );
};
