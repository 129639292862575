import { SelectOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Spin, Tooltip, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router";
import { DashboardContext } from ".";
import { OpenFieldIcon } from "../../assets/icons/OpenFieldIcon";
import { CardTitle } from "../../styles/StyledComponents";
import { Item } from "../Inventory/types";

export const Items = () => {
  const { items, loadingItems } = useContext(DashboardContext);
  const history = useHistory();
  return (
    <Card
      title={<CardTitle>Items Owned</CardTitle>}
      type="inner"
      style={{ width: "100%" }}
      bodyStyle={{
        height: "100%",
        maxHeight: 100,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Spin spinning={loadingItems}>
        {items.length ? (
          items.map((item: Item) => (
            <Row justify="space-between" align="middle" key={item.id}>
              <Col style={{ textAlign: "left" }}>
                <div style={{ fontSize: "1.1em", fontWeight: "bolder" }}>
                  {`${item.brandName} ${item.modelName}`}
                </div>

                <Typography.Text type="secondary">{item.inventoryNumber}</Typography.Text>
              </Col>
              <Tooltip title="Open">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/inventory/my-items/${item.id}`)}
                />
              </Tooltip>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
              No items yet!
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
