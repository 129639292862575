import React, { useState } from "react";
import { CloseOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import { Task } from "../../../types";
import { Button } from "antd";
import EventPopover from "../Calendar/EventPopover";

interface TaskOptionProps {
  task: Task;
  markTaskAsComplete: (taskId: string) => void;
  taskToComplete: string;
  setTaskToComplete: (taskId: string) => void;
}

const TaskOption: React.FC<TaskOptionProps> = ({
  task,
  markTaskAsComplete,
  taskToComplete,
  setTaskToComplete,
}) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTaskToComplete(task.taskId as string);
    setPosition({
      x: e.clientX,
      y: e.clientY,
    });
    setVisible(true);
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span>{task.title}</span>
      {!!task.taskId?.length && (
        <>
          <Button
            type="link"
            size="small"
            danger
            icon={<CloseOutlined />}
            loading={taskToComplete === task.taskId}
            onClick={handleButtonClick}
            style={{ fontSize: "16px" }}
          />

          {visible && (
            <EventPopover position={position} onClose={() => setVisible(false)}>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  border: "1px solid #000",
                  borderRadius: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <IssuesCloseOutlined
                    style={{
                      marginRight: "5px",
                    }}
                  />
                  Mark task as complete?
                </div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    markTaskAsComplete(task.taskId as string);
                    setVisible(false);
                  }}
                  style={{
                    color: "green",
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setTaskToComplete("");
                    setVisible(false);
                  }}
                  style={{
                    color: "red",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </EventPopover>
          )}
        </>
      )}
    </div>
  );
};

export default TaskOption;
