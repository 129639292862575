import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { Employee, Project, UpdateForecastValues } from "../types";

const useForecastTable = (workedTime: boolean) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [unfilteredProjects, setUnfilteredProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unfilteredEmployees, setUnfilteredEmployees] = useState<Employee[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [month, setMonth] = useState<number>(moment().get("M") + 1);
  const [year, setYear] = useState<number>(moment().get("y"));

  const { apiClient } = useContext(ApiClientContext);

  const [idProjectSelected, setIdProjectSelected] = useState<string>("");
  const [idSelectedEmployee, setIdSelectedEmployee] = useState<string>("");
  const fetchForecast = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(
        `/api/forecast?month=${month}&year=${year}&workedTime=${workedTime}`
      );

      const { projects: projectsData, employees: employeesData } = data;
      setProjects(projectsData);
      setUnfilteredProjects(projectsData);
      setUnfilteredEmployees(employeesData);
      setEmployees(employeesData);
      setIdProjectSelected("");
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
    }
  };

  const updateForecast = async (values: UpdateForecastValues) => {
    const putBody: UpdateForecastValues & {
      month: number;
      year: number;
      forecastTime?: number;
      workedTime?: number;
    } = {
      ...values,
      month,
      year,
    };
    if (workedTime) {
      putBody.workedTime = values.forecastTime;
      //@ts-ignore
      delete putBody.forecastTime;
    }

    try {
      const { data } = await apiClient.put("/api/forecast", putBody);

      const employee = employees.find((x) => x.id === values.userId);
      let userPosition: number;
      if (employee) {
        const project = employee?.projects.find((x) => x.id === values.projectId);
        if (project) {
          const technology = project?.employeeTechnologies.find(
            (x) => x.id === values.technologyId
          );
          if (technology) {
            if (workedTime) {
              technology.currentMonthWorked = values.forecastTime;
            } else {
              technology.currentMonthForecast = values.forecastTime;
            }
            userPosition = technology.userPosition;
          }
          let newTime = 0;
          employee.projects.forEach((project) => {
            project.employeeTechnologies.forEach((technology) => {
              newTime += workedTime
                ? technology.currentMonthWorked
                : technology.currentMonthForecast;
            });
          });
          if (workedTime) {
            employee.totalWorkedTime = newTime;
          } else {
            employee.totalForecastTime = newTime;
          }
        }
      }

      setEmployees([...employees]);
      let technologyTime = 0;
      employees.forEach((employee) => {
        const project = employee.projects.find((x) => x.id === values.projectId);
        if (project) {
          const technology = project.employeeTechnologies.find(
            (x) => x.id === values.technologyId && x.userPosition === userPosition
          );
          if (technology) {
            technologyTime += workedTime
              ? technology.currentMonthWorked
              : technology.currentMonthForecast;
          }
        }
      });

      const project = projects.find((x) => x.id === values.projectId);
      if (project) {
        project?.technologies.forEach((technology) => {
          if (technology.id === values.technologyId && technology.userPosition === userPosition) {
            if (workedTime) {
              technology.currentMonthWorked = technologyTime;
            } else {
              technology.currentMonthForecast = technologyTime;
            }
          }
        });
        let totalProjectTime = 0;
        project?.technologies.forEach((technology) => {
          totalProjectTime += workedTime
            ? technology.currentMonthWorked
            : technology.currentMonthForecast;
        });
        if (workedTime) {
          project.totalWorkedTime = totalProjectTime;
        } else {
          project.totalForecastTime = totalProjectTime;
        }
        setProjects([...projects]);
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const filterEmployees = () => {
    const filtered = unfilteredEmployees.filter((employee) =>
      employee.projects.some((project) => project.id === idProjectSelected)
    );
    setEmployees(filtered);
  };

  const filterProjects = () => {
    const employee = employees.find((x) => x.id === idSelectedEmployee);
    if (employee) {
      const filtered = unfilteredProjects.filter((project) =>
        employee?.projects.some((p) => p.id === project.id)
      );
      setProjects(filtered);
    } else {
      resetEmployeeFilter();
    }
  };

  const resetEmployeeFilter = () => {
    setIdProjectSelected("");

    setEmployees(unfilteredEmployees);
  };
  const resetProjectsFIlter = () => {
    setIdSelectedEmployee("");
    setProjects(unfilteredProjects);
  };

  useEffect(() => {
    if (idSelectedEmployee) {
      filterProjects();
    } else {
      resetProjectsFIlter();
    }
  }, [idSelectedEmployee]);

  useEffect(() => {
    if (idProjectSelected) {
      filterEmployees();
    } else {
      resetEmployeeFilter();
    }
  }, [idProjectSelected]);

  useEffect(() => {
    fetchForecast();
  }, [month, year]);

  return {
    idProjectSelected,
    setIdProjectSelected,
    projects,
    employees,
    isLoading,
    month,
    setMonth,
    year,
    setYear,
    updateForecast,
    idSelectedEmployee,
    setIdSelectedEmployee,
  };
};

export default useForecastTable;
