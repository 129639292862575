import React, { createContext } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AllProjects } from "./AllProjects";
import { useProjects } from "./AllProjects/useProjects";
import { Project } from "./Project";
import {
  Project as ProjectInterface,
  ProjectFormInputData,
  User,
} from "./types";
import useUsers from "./useUsers";
interface ProjectsContextInterface {
  projects: ProjectInterface[];
  isLoading: boolean;
  filteredProjects: ProjectInterface[];
  onSearch(input: string): void;
  createProject(values: ProjectFormInputData): void;
  updateProject(projectId: string, values: ProjectFormInputData): void;
  deleteProject(projectId: string): void;
  users: User[];
  isUsersLoading: boolean;
}

//@ts-ignore
export const ProjectsContext = createContext<ProjectsContextInterface>();
export const Projects = () => {
  let { path } = useRouteMatch();
  const ret = useProjects();
  const userRet = useUsers();
  return (
    <ProjectsContext.Provider value={{ ...ret, ...userRet }}>
      <Switch>
        <Route exact path={`${path}/`} component={AllProjects} />
        <Route exact path={`${path}/:projectId`} component={Project} />
      </Switch>
    </ProjectsContext.Provider>
  );
};
