import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Brand } from "../../types";

const useAllBrands = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);
  const [unfiltered, setUnfiltered] = useState<Brand[]>([]);
  const fetchBrands = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get("/api/items/get-all-brands");
      setBrands(data);
      setUnfiltered(data);
    } catch (err) {
      message.error("Couldn't fetch brands");
      console.warn(err);
    }
    setIsLoading(false);
  };

  const searchBrands = (searchVal: string) => {
    if (searchVal === "") {
      setBrands([...unfiltered]);
    } else {
      let filtered = unfiltered.filter((brand) =>
        brand.name.toLowerCase().trim().includes(searchVal.toLowerCase().trim())
      );
      setBrands([...filtered]);
    }
  };

  const addBrand = async (name: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post("/api/items/add-brand", { name });
      fetchBrands();
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  const editBrand = async (brandId: string, name: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.put(`/api/items/update-brand/${brandId}`, { name });
      fetchBrands();
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  const deleteBrand = async (brandId: string) => {
    try {
      setIsLoading(true);
      await apiClient.delete(`/api/items/delete-brand/${brandId}`);
      fetchBrands();
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);
  return {
    brands,
    isLoading,
    addBrand,
    deleteBrand,
    editBrand,
    searchBrands,
  };
};

export default useAllBrands;
