import { Tag } from "antd";
import styled from "styled-components";
import { useReportsFilters } from "./ReportsFiltersContext";

const StyledTagContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px; // Space between tags
  min-height: 64px; // Set a fixed height here
  overflow-y: auto; // Enable vertical scrolling
`;

const StyledTag = styled(Tag)`
  background-color: #1890ff; // Ant Design blue color
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  height: 32px;
  &:hover {
    background-color: #40a9ff; // Lighter blue on hover
  }
  .anticon-close {
    font-size: 16px; // Making the "X" bigger
  }
`;

const FilterTags = () => {
  const { selectedFilters, removeFilter } = useReportsFilters();
  return (
    <StyledTagContainer>
      {selectedFilters.map((filter, index) => (
        <StyledTag
          key={index + filter.filterName}
          closable
          onClose={() => removeFilter(filter.id, filter.filterType)}
        >
          {filter.filterName}
        </StyledTag>
      ))}
    </StyledTagContainer>
  );
};

export default FilterTags;
