import { Empty, Skeleton, Typography } from "antd";
import React, { useContext } from "react";
import { TimeTrackingUserDataContext } from "../../TimeTrackingUserDataProvider";
import { Week } from "./Week";
import styled from "styled-components";
import ChangeTaskModal from "./EditInputs/TaskModal";

export const HOURS_COLOR = "#137AE9";

const WeeksInput = styled.select`
  display: inline;
  border-color: white;
  border-radius: 8px;
  font-weight: bold;
  padding: 0;
  text-align: center;
  text-align-last: center; //for Firefox compatibility
  :hover {
    border-color: black;
  }
`;

const weeksOptions = Array.from({ length: 10 }, (_, i) => i + 1);

export const SessionsHistory: React.FC = () => {
  const {
    isGettingInitialData,
    sessionWeeks,
    weeksToFetch,
    setWeeksToFetch,
    sessionToChangeTask,
    setSessionToChangeTask,
  } = useContext(TimeTrackingUserDataContext);
  return (
    <>
      <Typography.Title
        style={{ textAlign: "start", fontWeight: "bold" }}
        level={2}
      >
        Sessions in the last{" "}
        <WeeksInput
          value={weeksToFetch ?? 4}
          onChange={(e) => setWeeksToFetch(parseInt(e.target.value))}
        >
          {weeksOptions.map((val) => (
            <option value={val} key={val}>
              {val}
            </option>
          ))}
        </WeeksInput>{" "}
        weeks
      </Typography.Title>
      <Skeleton loading={isGettingInitialData} active>
        {sessionWeeks.length ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {sessionWeeks.map((week, index) => (
              <Week key={`week-${index}`} week={week} />
            ))}
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_DEFAULT}
            description="No sessions to show."
            style={{
              boxShadow: "-1px -1px 19px -1px rgba(184,184,184,0.81)",
              padding: "16px",
            }}
          />
        )}
      </Skeleton>

      <ChangeTaskModal
        session={sessionToChangeTask}
        onUpdate={() => setSessionToChangeTask(null)}
        isModalVisible={!!sessionToChangeTask}
        onClose={() => setSessionToChangeTask(null)}
      />
    </>
  );
};
