import { Avatar, Image, Col, Row, Tooltip, Typography } from "antd";
import styled from "styled-components";
import avatar from "../../assets/images/avatar_dark.png";
import {Link} from "react-router-dom";
import React from "react";

const NameButton = styled.div`
  font-size: 1.2em;
  word-wrap: break-word;
  width: fit-content;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export interface ITableEmployee {
  employee: any,
  history: any,
  reverse?: boolean,
}

export const TableEmployee = ({ employee, history, reverse = false }: ITableEmployee) => {
  return (
      <div>
          <Row align="middle" wrap={false}>
              <Col style={{ marginRight: 7 }}>
                  <Avatar
                      size={40}
                      src={<Image preview={{ mask: <div /> }} src={employee.profilePicture ?? avatar} />}
                  />
              </Col>
              <TableEmployeeClickable employee={employee} reverse={reverse} />
          </Row>
          <Tooltip title="See employee time off" placement="top">
              <NameButton>
                  <Link to={`timeOffProfile?userId=${employee.id}`} style={{marginLeft: 45, fontSize: 15}}>
                      Time Off Profile
                  </Link>
              </NameButton>
          </Tooltip>
      </div>

  );
}

export const TableEmployeeClickable = ({ employee, reverse }: any) => {
  return (
    <a href={`/employees/${employee.id}`} style={{color:'black'}}>
      <Col flex="1">
        <Tooltip title="See employee details" placement="top">
          <NameButton>
              {
                  reverse
                      ? `${employee.lastName} ${employee.firstName}`
                      : `${employee.firstName} ${employee.lastName}`
              }
          </NameButton>
          <Typography.Text type="secondary">{employee.email}</Typography.Text>
        </Tooltip>
      </Col>
    </a>
  );
}
