import { Select } from "antd";
import React, { useContext } from "react";
import {
  SessionEnum,
  SessionTypeLocalStorageKey,
  TechnologyLocalStorageKey,
  TrackerForm,
} from "../../utils/types";
import { EditSessionModalContext } from "./types";
const { Option } = Select;
const CalendarSessionInput: React.FC = () => {
  const { formData, setIsNewTask, setFormData, technologies, tasks } =
    useContext(EditSessionModalContext);
  const setToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const handleTechnologyChange = (value: string) => {
    const matchingTask = tasks?.find((task) => task.taskId === formData.taskId);
    if (matchingTask?.technologyId !== value) {
      setIsNewTask(true);
      setFormData((prevState) => ({
        ...prevState,
        technologyId: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        taskId: matchingTask.taskId,
        technologyId: matchingTask.technologyId,
      }));
      setIsNewTask(false);
    }
    setToLocalStorage(TechnologyLocalStorageKey, value);
  };

  const handleSessionTypeChange = (value: string) => {
    setToLocalStorage(SessionTypeLocalStorageKey, value);
    setFormData((prevState) => ({
      ...prevState,
      sessionType: parseInt(value),
    }));
  };

  const handleSelectChange = (field: keyof TrackerForm, value: string) => {
    switch (field) {
      case "technologyId":
        handleTechnologyChange(value);
        break;
      case "sessionType":
        handleSessionTypeChange(value);
        break;
      default:
        setFormData((prevState) => ({
          ...prevState,
          [field]: value,
        }));
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
      <Select
        style={{ flex: 1, marginBottom: 10 }}
        value={formData.technologyId}
        onChange={(value) => handleSelectChange("technologyId", value)}
      >
        {technologies.map((technology) => (
          <Option key={technology.id} value={technology.id}>
            {technology.name}
          </Option>
        ))}
      </Select>

      <Select
        style={{ flex: 1, marginBottom: 10 }}
        value={SessionEnum[formData.sessionType]}
        onChange={(value) =>
          handleSelectChange(
            "sessionType",
            SessionEnum[value as keyof typeof SessionEnum].toString()
          )
        }
      >
        {Object.keys(SessionEnum)
          .filter((key) => isNaN(Number(key)))
          .map((key) => {
            return (
              <Option key={key} value={key}>
                {key}
              </Option>
            );
          })}
      </Select>
    </div>
  );
};
export default CalendarSessionInput;
