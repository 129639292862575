import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { Employee } from "../../Employees/types";

const useTransferItem = (itemId: string) => {
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [employeesLoading, setEmployeesLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchEmployee = async () => {
    try {
      setEmployeesLoading(true);
      const { data } = await apiClient.get("/api/users");
      setEmployees(data);
      setEmployeesLoading(false);
    } catch (err) {
      console.warn(err);
      setEmployeesLoading(false);
    }
  };
  useEffect(() => {
    fetchEmployee();
  }, []);

  const { apiClient } = useContext(ApiClientContext);
  const assignItem = async (toUserId: string) => {
    try {
      setActionLoading(true);
      const { data } = await apiClient.put(`/api/items/${itemId}/assign?toUserId=${toUserId}`);

      setActionLoading(false);
      return data;
    } catch (err) {
      setActionLoading(false);
      console.warn(err);
      throw err;
    }
  };

  const transferItem = async (toUserId: string) => {
    try {
      setActionLoading(true);
      const { data } = await apiClient.put(`/api/items/${itemId}/transfer?toUserId=${toUserId}`);
      setActionLoading(false);
      return data;
    } catch (err) {
      setActionLoading(false);
      console.warn(err);
      throw err;
    }
  };
  return {
    transferItem,
    assignItem,
    employees,
    employeesLoading,
    actionLoading,
  };
};

export default useTransferItem;
