import { Form, Input, Button, Spin, Alert } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ResetPassForm } from "./StyledComponents";
import { useAuthPasswordFlow } from "./useAuthPasswordFlow";

const styles = {
  title: {
    fontSize: 42,
    fontWeight: 600,
    marginBottom: 30,
    color: "#06062D"
  },
  textLine: { 
    color: "#A8A8A8", 
    fontSize: 20, 
    marginBottom: 10 
  },
  submitButton:{
    width: "100%",
    height: 64,
    borderRadius: 8,
    fontSize: 20,
  }
}

export const ResetPassword = () => {
  const { loading, response, handleResetPassword } = useAuthPasswordFlow();
  const [ token, setToken ] = useState<string>("");
  const [ email, setEmail ] = useState<string>("");

  useEffect(() => {
    var queryParams = new URLSearchParams(window.location.search);
    var token = queryParams.get("token");
    var email = queryParams.get("email");

    if(token && email){
      setToken(token);
      setEmail(email);
    }
  }, []);

  return (
    <ResetPassForm>
      <Form
        onFinish={(formData) => {handleResetPassword(formData.password, email, token)}}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h1 style={styles.title}>Reset Password</h1>

        <span style={styles.textLine}>Password</span>

        <Form.Item
          name="password"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input your new Password" }]}
        >
          <Input.Password size="large" style={{ borderRadius: 8, borderWidth: 2 }} maxLength={100} />
        </Form.Item>

        <span style={styles.textLine}>Password Confirm</span>

        <Form.Item
          name="confirm"
          style={{ width: "100%" }}
          dependencies={["password"]}
          hasFeedback
          rules={[
            { required: true, message: "Please confirm your password" },
            ( confirmPass ) => ({
              validator(rules, value) {
                if (!value || confirmPass.getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The passwords do not match");
              },
            }),
          ]}
        >
          <Input.Password
            size="large"
            style={{ borderRadius: 8, borderWidth: 2 }}
            maxLength={100}
          />
        </Form.Item>

        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={styles.submitButton}
            disabled={!token || !email}
          >
            Reset Password
          </Button>
        </Form.Item>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spin tip="Loading..." className="spin"></Spin>{" "}
          </div>
        ) : null}

        {!token || !email ? <Alert message={'The link you opened is invalid'} type={'error'} style={{ width: "100%" }} /> : null}

        {response.status == 'error' ? <Alert message={response.statusText} type={response.status} style={{ width: "100%" }} /> : null}
       
        {response.status == 'success' ? 
          <div style={{ backgroundColor:'#f6ffed', border: '1px solid #b7eb8f', padding:8, width:'100%' }}> {response.statusText} <br/> <Link to="/login"> Log In </Link> with your new password!</div> 
        : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span style={{ color: "#06062D", fontSize: 16 }}>Got here by mistake?</span>

          <span style={{ color: "#A8A8A8" }}>
            <Link to="/login">Sign in</Link>
          </span>
        </div>

      </Form>
    </ResetPassForm>
  );
};
