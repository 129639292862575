import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../App";
import { ChangePasswordFormValues, Profile, UpdateProfileFormValues } from "./types";

const useProfile = () => {
  const [profile, setProfile] = useState<Profile>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);
  const [error, setError] = useState<string>("");

  const { apiClient } = useContext(ApiClientContext);

  const fetchProfile = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get("/api/users/get-profile");
      setProfile(data);
      setIsLoading(false);
    } catch (err: any) {
      console.warn(err);
      setError(err);
      setIsLoading(false);
    }
  };

  const updateProfile = async (values: UpdateProfileFormValues) => {
    try {
      setIsLoading(true);
      await apiClient.put("/api/users/update-profile", values);
      fetchProfile();
    } catch (err) {
      setError("An error occurred");
      setIsLoading(false);
    }
  };

  const changePassword = async (values: ChangePasswordFormValues) => {
    try {
      const { data } = await apiClient.post("/api/users/change-password", {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      });
      return data.message;
    } catch (err: any) {
      throw err.response.data.message;
    }
  };

  const changeProfilePicture = async (photo: File) => {
    try {
      setIsLoadingProfilePicture(true);
      const formData = new FormData();
      formData.append("file", photo);
      const { data } = await apiClient.post("/api/users/upload-picture", formData);
      setIsLoadingProfilePicture(false);
      return data;
    } catch (err) {
      throw err;
    }
  };

  const addInterestTechnology = async (formValues: any) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post("/api/users/other-technology", formValues);
      fetchProfile();
      return data;
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      throw err;
    }
  };
  const deleteInterestTechnology = async (otherTechnologyId: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.delete(`/api/users/other-technology/${otherTechnologyId}`);
      fetchProfile();
      return data;
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      throw err;
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  return {
    profile,
    isLoading,
    error,
    updateProfile,
    changePassword,
    changeProfilePicture,
    isLoadingProfilePicture,
    addInterestTechnology,
    deleteInterestTechnology,
  };
};

export default useProfile;
