import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Tooltip, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from ".";
import { ApiClientContext } from "../../../App";
import { getUserPosition, positions } from "../../../_core/hardcodedValues";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../styles/StyledComponents";
import { Employee as RequestEmployee } from "../../Employees/ManageEmployee/types";
import { Employee } from "../types";
interface EditMemberTechnologiesProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
  member?: Employee;
}
export const EditMemberTechnologies = (props: EditMemberTechnologiesProps) => {
  const { isVisible, setIsVisible, member } = props;
  const { projectId, fetchProject } = useContext(ProjectContext);
  const [employee, setEmployee] = useState<RequestEmployee>();
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);
  const [form] = useForm();
  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const fetchEmployee = async () => {
    try {
      setEmployeeLoading(true);
      const { data } = await apiClient.get(
        `/api/users/get-user-by/${member?.userId}`
      );
      setEmployee(data);
      setEmployeeLoading(false);
    } catch (err) {
      setEmployeeLoading(false);
      console.warn(err);
    }
  };

  useEffect(() => {
    if (member && isVisible) {
      fetchEmployee();
      const technologies = member?.technologies.map((technology) => {
        return {
          technologyId: technology.technologyId,
          userPosition: technology.userPosition,
        };
      });

      form.setFieldsValue({ technologies });
    }
  }, [isVisible]);
  const handleFinish = async (values: any) => {
    try {
      const valuesFilteres = values.technologies.filter(
        (tech: any) => tech.technologyId
      );
      const { data } = await apiClient.put(
        `/api/projects/${projectId}/members/${member?.userId}`,
        { technologies: valuesFilteres }
      );
      fetchProject();
      handleCancel();
    } catch (err) {
      console.warn(err);
    }
  };
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={handleCancel}
      closeIcon={<RoundCloseIcon />}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        Edit {`${member?.firstName}`}'s technologies
      </div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.List name="technologies">
          {(fields, { add, remove }) => {
            //so we only have a form tehcnology input opened
            if (fields?.length === 0) add();
            return (
              <>
                {fields.map((field) => (
                  <Form.Item key={field.key}>
                    <Row style={{ alignItems: "flex-end" }}>
                      <Form.Item
                        label={<FormLabel>Technology</FormLabel>}
                        name={[field.name, "technologyId"]}
                        fieldKey={[field.fieldKey, "technology"]}
                        style={{ flex: 1, margin: "2px" }}
                      >
                        <Select loading={employeeLoading}>
                          {employee?.userTechnologies.map((technology) => (
                            <Select.Option
                              value={technology.technologyId}
                              key={technology.technologyId}
                            >
                              {technology.technologyName}/
                              {getUserPosition(technology.userPosition)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Tooltip title="Remove technology">
                        <MinusCircleOutlined
                          style={{
                            color: "red",
                            margin: "2px",
                            fontSize: "1.2em",
                            marginBottom: "10px",
                          }}
                          onClick={() => remove(field.name)}
                        />
                      </Tooltip>
                    </Row>
                  </Form.Item>
                ))}

                <Form.Item>
                  {employee &&
                    fields.length < employee?.userTechnologies?.length && (
                      <Button
                        size="small"
                        shape="round"
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => add()}
                      >
                        Add technology
                      </Button>
                    )}
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.Item>
          <Button
            htmlType="submit"
            block
            type="primary"
            style={{
              borderRadius: 5,
              border: "none",
              backgroundColor: "#00AD0D",
            }}
            size="large"
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
