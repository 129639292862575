import { Checkbox, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useReportsFilters } from "../ReportsFiltersContext";
import { useReportsData } from "../Table/ReportsDataContext";
import { FilterInterface } from "../types";
import FilterSearch from "./FilterSearch";

const CheckboxWrapper = styled.div`
  padding-left: 16px;
`;

const RowFilter: React.FC = () => {
  const { selectedRows, setSelectedRows, handleRowSelection, initialRows } =
    useReportsFilters();

  const [loading, setLoading] = useState<boolean>(true);
  const [filteredRows, setFilteredRows] =
    useState<FilterInterface[]>(initialRows);

  const handleCheckboxChange = (rowId: string) => {
    handleRowSelection(rowId);
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleSearch = (value: string) => {
    setFilteredRows(
      initialRows.filter((row) =>
        `${row.filterName}`.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  useEffect(() => {
    if (initialRows.length > 0) {
      setFilteredRows(initialRows);
      setLoading(false);
    }
  }, [initialRows]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <FilterSearch onSearch={handleSearch} />
          {filteredRows.map((row) => (
            <CheckboxWrapper key={row.id}>
              <Checkbox
                checked={selectedRows.includes(row.id)}
                onChange={() => handleCheckboxChange(row.id)}
              >
                {row.filterName}
              </Checkbox>
            </CheckboxWrapper>
          ))}{" "}
        </>
      )}
    </div>
  );
};

export default RowFilter;
