import { Button, Card, Col, Divider, Row, Typography, Progress, Tabs, Spin, Tooltip } from "antd";
import moment from "moment";
import { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { DashboardContext } from ".";
import { getProjectDocType } from "../../_core/hardcodedValues";
import useDownloadDocument from "../../_core/hooks/useDownloadDocument";
import { OpenFieldIcon } from "../../assets/icons/OpenFieldIcon";
import { CardTitle } from "../../styles/StyledComponents";
import { TableEmployeeClickable } from "../Utils/TableEmployee";
import { Member, Project, Stat } from "./types";

const ColorBar = styled.div`
  width: 10px;
  height: 100%;
  border-radius: 2px 25px 25px 2px;
  background-color: ${(props) => props.color || "blue"};
  position: relative;
  left: 0;
  margin-right: 10px;
`;

const ProjectTitle = styled.a`
  font-weight: 600;
  font-size: 20px;
  color: black;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
`;

const DocumentName = styled.div`
  font-weight: 600;
  font-size: 1.2em;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
  line-height: 1;
`;

export const Projects = () => {
  const { projects, loadingProjects } = useContext(DashboardContext);
  const downloadDocument = useDownloadDocument();

  const todaysMeetings = (project: Project) => {
    return project.meetings.filter((meeting) => moment(meeting.start).isSame(moment(), "day"));
  };
  const upcomingMeetings = (project: Project) => {
    return project.meetings.filter((meeting) => moment(meeting.start).isAfter(moment(), "day")).slice(0, 3);
  };
  const history = useHistory();
  return (
    <Card
      style={{ height: "100%" }}
      bodyStyle={{ paddingTop: "0px" }}
      title={projects.length ? null : <CardTitle>Projects</CardTitle>}
    >
      <Spin spinning={loadingProjects}>
        {projects.length ? (
          <Tabs defaultActiveKey={projects[0]?.id} size="large">
            {projects.map((project: Project) => (
              <Tabs.TabPane tab={project.name} key={project.id}>
                <Row wrap={false} justify="space-around">
                  <Col>
                    <ColorBar color={project.color ? project.color : "purple"} />
                  </Col>
                  <Col span={23}>
                    <Row>
                      <Col style={{ textAlign: "left" }}>
                        <ProjectTitle href={`/projects/${project.id}`}>
                          {project.name}
                        </ProjectTitle>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "5px" }}>
                      <Col flex="1">
                        <Row justify="space-between">
                          <Typography.Title level={5} style={{ marginBottom: 0 }}>
                            Time Tracker
                          </Typography.Title>
                          <Tooltip title="Start Working">
                            <OpenFieldIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => history.push({pathname: "/trackYourTime", state: {defaultProjectId: project.id}})}
                            />
                          </Tooltip>
                        </Row>
                        <Row>
                          <Typography.Text style={{ color: "#1890ff", fontSize: "12px" }} strong>
                            {project.tasks} Tasks assigned
                          </Typography.Text>
                        </Row>
                        <div
                          style={{
                            height: "100%",
                            maxHeight: 170,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {project.stats.map((stat: Stat) => (
                            <div key={stat.name}>
                              <Row>
                                <Col
                                  style={{
                                    width: "100%",
                                    textAlign: "left",
                                  }}
                                >
                                  <Typography.Text
                                    type="secondary"
                                    style={{ textTransform: "uppercase" }}
                                    strong
                                  >
                                    {stat.name}
                                  </Typography.Text>
                                  <div style={{ fontWeight: "bold" }}>
                                    {stat.workedTime}/{stat.forecastTime}
                                  </div>
                                  <Progress
                                    percent={
                                      stat.workedTime > 0 ? stat.forecastTime / stat.workedTime : 0
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </Col>
                      <Divider type="vertical" style={{ height: "inherit" }} />

                      <Col flex="1">
                        <Row justify="space-between">
                          <Typography.Title level={5}>Meetings</Typography.Title>
                        </Row>
                        <div
                          style={{
                            height: "30%",
                            maxHeight: 350,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}>
                        <Row>
                          <Col style={{ textAlign: "left" }}>
                            {project.meetings.length ? (
                              <>
                                <Typography.Text
                                  type="secondary"
                                  style={{ textTransform: "uppercase" }}
                                  strong
                                >
                                  today's meetings
                                </Typography.Text>
                                {todaysMeetings(project).length ? (
                                  todaysMeetings(project).map((meeting) => (
                                    <div>
                                      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                                        {meeting.name}
                                      </div>
                                      <div>
                                        {moment(meeting.start).local().format("ddd Do MMM HH:mm")} lasts
                                        {` ${moment(meeting.end).diff(
                                          moment(meeting.start),
                                          "minutes"
                                        )} min`}
                                      </div>
                                      <Button
                                        style={{ padding: 0 }}
                                        type="link"
                                        target="_blank"
                                        href={meeting.url}
                                      >
                                        Connect to the meeting
                                      </Button>
                                    </div>
                                  ))
                               ) : (
                                  <div>No meetings today</div>
                                )}
                                <br />
                                <Typography.Text
                                  type="secondary"
                                  style={{ textTransform: "uppercase" }}
                                  strong
                                >
                                  upcoming meeting
                                </Typography.Text>
                                {upcomingMeetings(project).length ? (
                                  upcomingMeetings(project).map((meeting) => (
                                    <div>
                                      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>
                                        {meeting.name}
                                      </div>
                                      <div>
                                        {moment(meeting.start).local().format("ddd Do MMM HH:mm")} lasts
                                        {` ${moment(meeting.end).diff(
                                          moment(meeting.start),
                                          "minutes"
                                        )} min`}
                                      </div>
                                      <Button
                                        style={{ padding: 0 }}
                                        type="link"
                                        target="_blank"
                                        href={meeting.url}
                                      >
                                        Connect to the meeting
                                      </Button>
                                    </div>
                                  ))
                                ) : (
                                  <div>No upcoming meetings</div>
                                )}
                          </>
                            ) : (
                              <Typography.Text
                                type="secondary"
                                style={{ textTransform: "uppercase" }}
                                strong
                              >
                                No meetings
                              </Typography.Text>
                            )}
                          </Col>
                        </Row>
                        </div>
                        <Divider type="horizontal" style={{ height: "inherit" }} />

                        <Row>
                          <Typography.Title level={5}>Documents</Typography.Title>
                        </Row>
                        <div
                          style={{
                            height: "50%",
                            maxHeight: 350,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {project.documents.length ? (project.documents.map((document) => (
                            <div key={document.id}>
                              <Row>
                                <DocumentName
                                  onClick={() =>
                                    document.isFile
                                      ? downloadDocument(document.url, document.name)
                                      : window.open(document.url, "_blank")
                                  }
                                >
                                  {document.name}
                                </DocumentName>
                              </Row>
                              <Row>
                                <Typography.Text type="secondary">
                                  {getProjectDocType(document.docType)}
                                </Typography.Text>
                              </Row>
                            </div>
                          ))) : (
                            <div style={{ color: 'grey' }}>No documents!</div>
                          )}
                        </div>
                      </Col>
                      <Divider type="vertical" style={{ height: "inherit" }} />

                      <Col flex="1">
                        <Row>
                          <Typography.Title level={5}>Members</Typography.Title>
                        </Row>
                        <div
                          style={{
                            height: "100%",
                            maxHeight: 350,
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {project.members.map((member: Member) => (
                            <a key={member.email} href={`/employees/${member.id}`} style={{ color: 'black' }}>
                              <Row>
                                <Col style={{ textAlign: "left" }}>
                                  <Row
                                    wrap={false}
                                    style={{ alignItems: "baseline", width: "100%" }}
                                  >
                                    <Typography.Text
                                      strong
                                      style={{
                                        fontSize: "1.2em",
                                        whiteSpace: "pre",
                                      }}
                                    >
                                      {member.name}
                                    </Typography.Text>
                                    <Typography.Text
                                      ellipsis={true}
                                      style={{
                                        width: "fit-context",
                                        color: "#1890ff",
                                        fontSize: "1.1em",
                                        marginLeft: 5,
                                        fontWeight: "bolder",
                                      }}
                                    >
                                      {member.technologies.join(", ")}
                                    </Typography.Text>
                                  </Row>
                                  <Typography.Text type="secondary">{member.email}</Typography.Text>
                                </Col>
                              </Row>
                            </a>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
            ))}
          </Tabs>
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ fontSize: "1.5em", marginTop: 20 }}>
              No Projects assigned for you yet!
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
