import moment from "moment";
import {KeyValue} from "../../../../_core/types";
import {TimeOffRevisionType} from "../enums/time-off-revision-type.enum";
import {TimeOffStatus} from "../enums/time-off-status.enum";
import {TimeOffRequestType} from "../enums/time-off-request-type.enum";
import {CountryTypes} from "../../../../_core/enums";

export const prepareDates = (startDate: string, endDate: string) => {
  const year = moment(startDate).format("YYYY");
  const start = moment(startDate).format("DD MMM");
  const end = moment(endDate).format("DD MMM");

  return (
      <>
        {
          start === end ? <span>{start}, {year}</span> : <span>{start} - {end}, {year}</span>
        }
      </>
  );
};

export const getTimeOffStatusColor = (status: TimeOffStatus) => {
  switch (status) {
    case TimeOffStatus.Pending: {
      return "#F1C757";
    }
    case TimeOffStatus.Accepted: {
      return "#00AD0D";
    }
    case TimeOffStatus.Declined: {
      return "#FF3E3E";
    }
    default: {
      return "black";
    }
  }
};

export const getTimeOffStatusColorForCalendar = (status: TimeOffStatus) => {
  switch (status) {
    case TimeOffStatus.Pending: {
      return "#F1C757";
    }
    case TimeOffStatus.Accepted: {
      return "#53a859";
    }
    case TimeOffStatus.Declined: {
      return "#ec6565";
    }
    default: {
      return "black";
    }
  }
};

export const getTimeOffStatusText = (status: TimeOffStatus) => {
  switch (status) {
    case TimeOffStatus.Pending: {
      return "Pending";
    }
    case TimeOffStatus.Accepted: {
      return "Approved";
    }
    case TimeOffStatus.Declined: {
      return "Declined";
    }
    default: {
      return "Unknown";
    }
  }
};

export const getTimeOffTypeText = (type: TimeOffRequestType): string => {
  switch (type) {
    case TimeOffRequestType.Holiday: {
      return "Holiday";
    }
    case TimeOffRequestType.Medical: {
      return "Medical";
    }
    case TimeOffRequestType.Maternal: {
      return "Maternal";
    }
    case TimeOffRequestType.Study: {
      return "Study";
    }
    case TimeOffRequestType.Other: {
      return "Other";
    }
    default: {
      return "unknown";
    }
  }
}

export const getTimeOffStatusesList = ():KeyValue[] => {
  return [
    {
      key: TimeOffStatus.Pending,
      value: 'Pending'
    },
    {
      key: TimeOffStatus.Accepted,
      value: 'Approved'
    },
    {
      key: TimeOffStatus.Declined,
      value: 'Declined'
    }
  ]
}

export const computeTimeOffRevisionTypeName = (type: TimeOffRevisionType): string => {
  switch (type){
    case TimeOffRevisionType.ErrorCorrection:
      return 'Error Correction';
    case TimeOffRevisionType.InitialSeed:
      return 'Initial Seed';
    case TimeOffRevisionType.NotSpecified:
      return 'Not Specified';
    case TimeOffRevisionType.Other:
      return 'Other';
    case TimeOffRevisionType.RequestDeletion:
      return 'Request Deleted';
    case TimeOffRevisionType.RequestApproval:
      return 'Request Approved';
    case TimeOffRevisionType.YearlyRefill:
      return 'Yearly Refill';
    case TimeOffRevisionType.RequestUnapproval:
      return 'Request Unapproved';
    case TimeOffRevisionType.ManualIntervention:
      return 'Manual Intervention';
  }
}

export const timeOffHistoryLogHasLink = (type: TimeOffRevisionType): boolean => {
  switch (type){
    case TimeOffRevisionType.ErrorCorrection:
      return false;
    case TimeOffRevisionType.InitialSeed:
      return false;
    case TimeOffRevisionType.NotSpecified:
      return false;
    case TimeOffRevisionType.Other:
      return false;
    case TimeOffRevisionType.RequestDeletion:
      return false;
    case TimeOffRevisionType.RequestApproval:
      return true;
    case TimeOffRevisionType.YearlyRefill:
      return false;
    case TimeOffRevisionType.RequestUnapproval:
      return true;
    case TimeOffRevisionType.ManualIntervention:
      return false;
  }
}

export function saveFile(fileName: string, bytes: any) {
  var blob = new Blob([bytes], { type: "application/octet-stream" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = fileName;
  link.download = fileName;
  link.click();
}
