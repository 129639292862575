import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Item } from "../../types";

const useManageMyItem = (itemId: string) => {
  const [item, setItem] = useState<Item>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);
  const fetchItem = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`api/items/${itemId}`);
      setItem(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      message.error("Could not get item");
    }
  };
  useEffect(() => {
    fetchItem();
  }, []);
  return {
    item,
    isLoading,
  };
};

export default useManageMyItem;
