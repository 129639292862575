import { Modal, Form, Input, Select, Button } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AllModelsContext } from ".";
import { itemTypes } from "../../../../_core/hardcodedValues";
import { RoundCloseIcon } from "../../../../assets/icons/RoundCloseIcon";
import { FormLabel, StyledTag } from "../../../../styles/StyledComponents";
import useAllBrands from "../../Brands/AllBrands/useAllBrands";
import { AddModelFormValues, Model } from "../../types";

interface ModelDialogProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
  setAddToBrand?(value: boolean): void;
  model?: Model;
  brandId?: number;
}
export const ModelDialog = (props: ModelDialogProps) => {
  const { isVisible, setIsVisible, setAddToBrand, model, brandId } = props;
  const { brands, isLoading: brandLoading } = useAllBrands();
  const { addModel, isLoading, updateModel, tags } = useContext(AllModelsContext);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    if (model) {
      form.setFieldsValue({ name: model.name });
      form.setFieldsValue({ itemType: model.itemType });
      form.setFieldsValue({ brandId: model.brandId });
      const tags = model.modelTags.map((tag) => tag.name);
      form.setFieldsValue({ modelTags: tags });
    }

    if(brandId)
    {
      form.setFieldsValue({ brandId: brandId });
    }
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    setAddToBrand ? setAddToBrand(false) : null;
    history.replace({pathname: `/inventory/models`, state: {}});
    form.resetFields();
  };
  const TagRender = (props: any) => {
    return (
      <StyledTag style={{ marginTop: 4 }} {...props}>
        {props.value}
      </StyledTag>
    );
  };
  const handleFinish = async (values: AddModelFormValues) => {
    if (model) {
      await updateModel(values, model.id);
      handleCancel();
    } else {
      await addModel(values);
      handleCancel();
    }
  };
  return (
    <Modal visible={isVisible} onCancel={handleCancel} footer={null} closeIcon={<RoundCloseIcon />}>
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        {model ? "Edit model" : "Add a new model"}
      </div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="brandId"
          label={<FormLabel>Assign brand </FormLabel>}
          rules={[{ required: true, message: "Please select a brand!" }]}
        >
          <Select disabled= {brandId ? true : false} style={{ textAlign: "left" }} loading={brandLoading}>
            {brands.map((brand) => (
              <Select.Option key={brand.id} value={brand.id}>
                {brand.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="itemType"
          label={<FormLabel>Item type </FormLabel>}
          rules={[{ required: true, message: "Please select a type!" }]}
        >
          <Select style={{ textAlign: "left" }}>
            {itemTypes.map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label={<FormLabel>Model name</FormLabel>}
          rules={[{ required: true, message: "Please input a model name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="modelTags" label={<FormLabel>Features</FormLabel>}>
          <Select mode="tags" tokenSeparators={[","]} tagRender={TagRender}>
            {tags.map((tag) => (
              <Select.Option key={tag.id} value={tag.name}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            block
            loading={isLoading}
            type="primary"
            style={{ borderRadius: 5, border: "none", backgroundColor: model ? "#00AD0D" : "" }}
            size="large"
          >
            {model ? "Save Changes" : "Add Model"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
