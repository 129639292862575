import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Client } from "../types";
import ClientInfo from "./ClientInfo";
import ContactDialog from "./ContactDialog";
import ContactsTable from "./ContactsTable";
import { ContactFormData } from "./types";
import useContacts from "./useContacts";

type ClientRouteParams = {
  clientId: string;
};
interface ClientContactsInterface {
  client?: Client;
  isLoading: boolean;
  error: string;
  addContact(formData: ContactFormData): Promise<any>;
  removeContact(contactId: string): Promise<any>;
  editContact(formData: ContactFormData, contactId: string): Promise<any>;
}

export const ClientContactsContext =
  //@ts-ignore
  React.createContext<ClientContactsInterface>();

export default function index() {
  const { clientId } = useParams<ClientRouteParams>();
  const ret = useContacts(clientId);
  const [addDialog, setAddDialog] = useState(false);
  return (
    <ClientContactsContext.Provider value={ret}>
      <ClientInfo />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
          marginBottom: "15px",
        }}
      >
        <Button
          style={{
            backgroundColor: "#00AD0D",
            color: "white",
            borderColor: "white",
            borderRadius: 4,
          }}
          icon={<PlusOutlined />}
          onClick={() => setAddDialog(true)}
        >
          Add Contact
        </Button>
      </div>
      <ContactDialog isVisible={addDialog} setIsVisible={setAddDialog} />
      <ContactsTable />
    </ClientContactsContext.Provider>
  );
}
