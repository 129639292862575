import { ColumnType } from "antd/lib/table";
import { useEffect, useRef, useState } from "react";
import useApiClient from "../../../_core/hooks/useApiClient";
import { useReportsFilters } from "../ReportsFiltersContext";
import { FilterInterface, NewEmployee, ProjectReport } from "../types";
import EmployeeCell from "./EmployeeCell";
import ProjectHeaderCell from "./ProjectHeaderCell";
import TotalHoursHeaderCell from "./TotalHoursHeaderCell";
export interface IReportsContextInterface {
  tableColumns: ColumnType<ProjectReport>[];
  loading: boolean;
  fetchData: () => Promise<void>;
  allProjectsTotalHours: number;
}

const useReports = (): IReportsContextInterface => {
  const { apiClient } = useApiClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [allProjectsTotalHours, setAllProjectsTotalHours] = useState<number>(0);
  const {
    date,
    cols,
    selectedCols,
    handleColSelection,
    selectedRows,
    setSelectedCols,
    selectedFilters,
    setSelectedFilters,
    setCols,
    sortOptions,
    hideProjectsWithNoHours,
    projectType,
    setData,
    setInitialCols,
    setInitialRows,
    initialCols,
    initialRows,
  } = useReportsFilters();

  const [tableColumns, setTableColumns] = useState<
    ColumnType<ProjectReport | any>[]
  >([]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fetchData = async () => {
    setLoading(true);
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const reportOptions = {
      filters: {
        hideProjectsWithNoHours: hideProjectsWithNoHours,
        employeeIds: selectedRows,
        projectIds: selectedCols,
        projectType: projectType,
      },
      sorts: {
        rowName: sortOptions.sortBy,
        direction: sortOptions.order,
      },
    };
    await apiClient
      .post(
        `/api/time-tracking/reports/company-overview?month=${month}&year=${year}`,
        reportOptions
      )
      .then((res) => {
        const tableData: NewEmployee[] = res.data.content;
        setAllProjectsTotalHours(res.data.allProjectsTotalHours);
        const projectsOverview = res.data.projectsOverviewReport;
        const newCols: ProjectReport[] = Object.keys(projectsOverview).map(
          (key, index) => {
            return {
              projectId: projectsOverview[key]?.id,
              projectName: key,
              color: projectsOverview[key].color,
              workedHours: projectsOverview[key].workedHours,
            };
          }
        );
        if (initialCols.length === 0) {
          setInitialCols(
            newCols.map((c) => {
              return { id: c.projectId, filterName: c.projectName };
            })
          );
        }
        if (initialRows.length === 0) {
          setInitialRows(
            tableData.map((td) => {
              return {
                id: td.userId,
                filterName: td.fullName,
              };
            })
          );
        }
        setCols(newCols);
        setData(tableData);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchData();
    }, 2000); // 2s

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [
    date,
    sortOptions,
    selectedFilters,
    projectType,
    hideProjectsWithNoHours,
  ]);

  useEffect(() => {
    // !!!!!!!!!!!!!!!!!!!!!!!!! SORT COLS LOGIC IN CASE ANYTHING CHANGES !!!!!!!!!!!!!!!!!!!!!!!!!!!
    // const sortedCols = cols.sort((a, b) =>
    //   a.projectName.localeCompare(b.projectName)
    // );

    //Sort colums logic
    // const orderedCols = sortedCols.sort((a, b) => {
    //   // Sort by user options next
    //   // switch (sortOptions.cols.sortBy) {
    //   //   case "ProjectName":
    //   //     return sortOptions.cols.order === "Ascending"
    //   //       ? a.projectName.localeCompare(b.projectName)
    //   //       : b.projectName.localeCompare(a.projectName);
    //   //   case "WorkedHours":
    //   //     return sortOptions.cols.order === "Ascending"
    //   //       ? a.workedHours - b.workedHours
    //   //       : b.workedHours - a.workedHours;
    //   //   default:
    //   //     return 0;
    //   // }
    //   return 0;
    // });

    // Create columns based on selectedCols and general columns like 'Employee'
    const columns = [
      {
        title: "Employee",
        dataIndex: "employee",
        key: "employee",
        // fixed: "left" as FixedType,
        fixed: "left" as any,
        render: (employee: NewEmployee) => {
          return <EmployeeCell employee={employee} />;
        },
      },
      ...cols.map((col) => {
        return {
          title: col?.projectName || "",
          dataIndex: col.projectId,
          key: col.projectId,
          color: col.color,
          workedHours: col.workedHours,
          render: (project: ProjectReport, cell: any) => {
            const isColSelected = selectedCols.includes(col.projectId);
            const isRowSelected = selectedRows.includes(cell.employee.userId);

            const shouldHighlightOpacity =
              (selectedCols.length === 0 && selectedRows.length === 0) ||
              (isColSelected && selectedRows.length === 0) ||
              (isRowSelected && selectedCols.length === 0) ||
              (isColSelected && isRowSelected);
            const shouldHighlightColor =
              (isColSelected && selectedRows.length === 0) ||
              (isRowSelected && selectedCols.length === 0) ||
              (isColSelected && isRowSelected);
            return (
              <ProjectHeaderCell
                project={project}
                shouldHighlightOpacity={shouldHighlightOpacity}
                shouldHighlightColor={shouldHighlightColor}
              />
            );
          },
          onHeaderCell: (column: any) => {
            return {
              style: { textAlign: "center" as any, color: column?.color },
              onClick: (e: any) => {
                const isCtrlPressed = e.ctrlKey;
                if (!isCtrlPressed) {
                  handleColSelection(column.dataIndex);
                  if (!selectedCols.includes(column.dataIndex)) {
                    setSelectedFilters([
                      ...selectedFilters,
                      {
                        id: column.dataIndex,
                        filterName: column.title,
                        filterType: "col",
                      },
                    ]);
                  }
                } else {
                  setSelectedCols([column.dataIndex]);
                  setSelectedFilters([
                    ...selectedFilters.filter((sf) => sf.filterType === "row"),
                    {
                      id: column.dataIndex,
                      filterName: column.title,
                      filterType: "col",
                    },
                  ]);
                }
              },
            };
          },
        };
      }),
      {
        title: "Total Hours",
        dataIndex: "totalHours",
        key: "totalHours",
        // fixed: "right" as FixedType ,
        fixed: "right" as any,
        render: (data: any) => {
          return (
            <TotalHoursHeaderCell
              totalHoursWorked={data.totalHoursWorked}
              timeOffHours={data.timeOffHours}
            />
          );
        },
      },
    ];
    setTableColumns(columns);
  }, [selectedCols, cols, selectedRows, sortOptions]);

  return {
    tableColumns,
    fetchData,
    loading,
    allProjectsTotalHours,
  };
};

export default useReports;
