import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";
import { UserContext } from "../../../App";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { useOffices } from "../../Offices/hooks";
import { InviteModal } from "../InviteModal/InviteModal";
import { Employee } from "../types";
import { EmployeesTable } from "./EmployeesTable";
import useEmployees from "./useEmployees";
import { userStatuses, userStatusesEnum } from "../../../_core/hardcodedValues";

interface AllEmployeesContextInterface {
  offices: any;
  employees: Employee[];
  isLoading: boolean;
}

export const AllEmployeesContext =
  //@ts-ignore
  createContext<AllEmployeesContextInterface>();
export const AllEmployees = () => {
  const { offices } = useOffices();
  const { employees, isLoading, fetchEmployees } = useEmployees();
  const { isAdmin, isOperationalUser } = useUserRoles();

  const [userStatus, setUserStatus] = useState(userStatusesEnum.Active)

  const [inviteModal, setInviteModal] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(ev.target.value);

  const handleFilterEmployees = (userStatus: number) => {
    setUserStatus(userStatus);
    fetchEmployees(userStatus);
  }

  return (
    <AllEmployeesContext.Provider value={{ offices, employees, isLoading }}>
      <div>
        <Row>
          <Col flex="3" style={(isAdmin() || isOperationalUser()) ? { paddingInlineEnd: 32 } : { paddingInlineEnd: 0 }}>
            <Input
              placeholder="Search "
              className="radius-5"
              prefix={<SearchOutlined />}
              allowClear
              value={searchInputValue}
              onChange={handleSearchInputChange}
              style={{ float: 'left' }}
            />
          </Col>

          {(isAdmin() || isOperationalUser()) && <Col flex="1"><Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Select status"
            value={userStatus ? userStatus : undefined}
            onSelect={(value: number) => handleFilterEmployees(value)}
            onClear={() => handleFilterEmployees(0)}
          >
            {userStatuses.map((x: any) => (
              <Select.Option key={x.value} value={x.value}>
                {`${x.name}`}
              </Select.Option>
            ))}
          </Select></Col>}

          {isAdmin() && (
            <Col flex="1" style={{ paddingInlineStart: 32 }}>
              <Button
                type="primary"
                className="radius-5"
                block
                style={{ float: 'right' }}
                onClick={() => setInviteModal(true)}
              >
                Invite employee
              </Button>
            </Col>
          )}
        </Row>
        <EmployeesTable
          employees={
            searchInputValue
              ? employees.filter(
                (e: any) =>
                  e.lastName.toLowerCase().includes(searchInputValue.toLowerCase()) ||
                  e.firstName.toLowerCase().includes(searchInputValue.toLowerCase()) ||
                  e.email.toLowerCase().includes(searchInputValue.toLowerCase())
              )
              : employees
          }
        />
      </div>

      <InviteModal isVisible={inviteModal} setIsVisible={setInviteModal} />
    </AllEmployeesContext.Provider>
  );
};
