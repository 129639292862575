import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Skeleton, Select } from "antd";
import React, { useState } from "react";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { TechnologiesTable } from "./TechnologiesTable";
import { TechnologyModel } from "./TechnologyModel";
import { typesDict, useTechnologies } from "./useTechnologies";

export const TechnologiesContext = React.createContext<any>(null);

export const Technologies = () => {
  const hook = useTechnologies();
  // const actionSuccess = hook.actionSuccess;
  const [formInstance] = Form.useForm();

  const [searchInputValue, setSearchInputValue] = useState("");

  const handleAdd: React.MouseEventHandler<HTMLElement> = (e) => {
    hook.setCreateModalVisible(true);
  };

  const handleCreateOk = async () => {
    try {
      await formInstance.validateFields();
      const name = formInstance.getFieldValue("name");
      const type = formInstance.getFieldValue("type");
      await hook.addTechnology(name, type);
      hook.setCreateModalVisible(false);
      formInstance.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateCancel = () => {
    formInstance.resetFields();
    hook.setCreateModalVisible(false);
  };

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(ev.target.value);

  return hook.listLoading ? (
    <Skeleton active />
  ) : (
    <TechnologiesContext.Provider value={hook}>
      <div
        style={{
          justifyContent: "space-between",
          flexFlow: "row wrap",
          minWidth: "200px",
          display: "flex",
        }}
      >
        <Input
          style={{
            flexGrow: 1,
            marginBottom: 8,
          }}
          value={searchInputValue}
          onChange={handleSearchInputChange}
          placeholder="Search here..."
          prefix={<SearchOutlined />}
          maxLength={100}
        />
        <Button
          id="button__new-technology"
          onClick={handleAdd}
          type="primary"
          style={{
            width: "200px",
            borderColor: "white",
            borderRadius: 4,
            backgroundColor: "#00AD0D",
            color: "#FFF",
            // display: "flex",
            // alignSelf: "left",
            marginBottom: "20px",
          }}
        >
          New technology
        </Button>
      </div>

      <TechnologiesTable
        technologies={
          searchInputValue
            ? hook.technologies.filter((t: TechnologyModel) =>
                t.name.toLowerCase().includes(searchInputValue)
              )
            : hook.technologies
        }
      />

      {/* Create technology modal */}

      <Modal
        visible={hook.createModalVisible}
        closeIcon={<RoundCloseIcon />}
        onOk={handleCreateOk}
        onCancel={handleCreateCancel}
        okButtonProps={{
          id: "button__save-new-technology",
          loading: hook.addLoading,
        }}
        okText="Save"
      >
        <h1>Create a new technology:</h1>
        <Form form={formInstance}>
          <Form.Item
            label="technology name"
            name="name"
            rules={[
              { required: true, message: "The name is required!" },
              {
                max: 20,
                message: "The name should not be longer than 20 characters!",
              },
            ]}
          >
            <Input id="input__new-technology" style={{ width: "200px" }} />
          </Form.Item>

          <Form.Item
            label="type"
            name="type"
            rules={[{ required: true, message: "The type is required!" }]}
          >
            <Select id="dropdown__create-technology-type" style={{ width: "200px" }}>
              {Object.keys(typesDict).map((key) => {
                return (
                  <Select.Option value={key}>
                    {
                      //@ts-ignore
                      typesDict[key]
                    }
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </TechnologiesContext.Provider>
  );
};
