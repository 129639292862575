import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { SearchIcon } from "../../../../assets/icons/SearchIcon";
import { Item } from "../../types";
import { MyItemsTable } from "./MyItemsTable";
import { RequestItemDialog } from "./RequestItemDialog";
import useAllMyItems from "./useAllMyItems";
interface AllMyItemsContextInterface {
  items: Item[];
  isLoading: boolean;
  requestItem(description: string): void;
  fetchMyItems(): void;
}
//@ts-ignore
export const AllMyItemsContext = React.createContext<AllMyItemsContextInterface>();
export const AllItems = () => {
  const ret = useAllMyItems();
  const [requestDialog, setRequestDialog] = useState(false);
  return (
    <AllMyItemsContext.Provider value={ret}>
      <Row style={{ marginBottom: 10 }}>
        <Col flex="5">
          <Input placeholder="Search here" prefix={<SearchIcon />} style={{ borderRadius: 5 }} />
        </Col>
        <Col flex="1" style={{ marginLeft: 3 }}>
          <Button
            style={{ borderRadius: 5 }}
            block
            type="primary"
            onClick={() => setRequestDialog(true)}
          >
            Request Item
          </Button>
        </Col>
      </Row>
      <MyItemsTable />
      <RequestItemDialog isVisible={requestDialog} setIsVisible={setRequestDialog} />
    </AllMyItemsContext.Provider>
  );
};
