import React, {useContext, useEffect, useState} from "react";
import {Button, Input, InputNumber, message, Modal, Radio} from "antd";
import {HourglassTwoTone} from "@ant-design/icons";
import {TimeOffUserAdministration} from "../_core/interfaces/time-off-user-administration.interface";
import {TimeOffRevisionType} from "../_core/enums/time-off-revision-type.enum";
import {ApiClientContext} from "../../../App";

interface OverrideUserAvailableDaysModalProps {
    isVisible: boolean;
    data: TimeOffUserAdministration | null;
    onClose: () => void;
    onSuccess: () => void;
}

export const OverrideUserAvailableDaysModal: React.FC<OverrideUserAvailableDaysModalProps> = ({isVisible, data, onClose, onSuccess}) => {
    const {apiClient} = useContext(ApiClientContext);

    const [selectedRevisionType, setSelectedRevisionType] = useState<TimeOffRevisionType>(TimeOffRevisionType.ManualIntervention);
    const [availableDaysCount, setAvailableDaysCount] = useState(data?.availabilityStats?.daysOffAvailableCount || 0);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setAvailableDaysCount(data?.availabilityStats?.daysOffAvailableCount || 0);
    }, [data])

    const computeHelpTextForType = () => {
        switch (selectedRevisionType) {
            case TimeOffRevisionType.ManualIntervention:
                return 'Use this if you want to manually adjust the available days off';
            case TimeOffRevisionType.ErrorCorrection:
                return 'Use this if there was an error on our side';
            case TimeOffRevisionType.InitialSeed:
                return 'Use this to set the initial days off available'
            case TimeOffRevisionType.YearlyRefill:
                return 'Use this to add the yearly available days'
            case TimeOffRevisionType.Other:
                return "For any other reasons"
        }
    }

    const submit = async () => {
        setIsLoading(true);
        const payload = {
            userId: data?.user.id,
            newTimeOffAvailableDaysCount: availableDaysCount,
            revisionType: selectedRevisionType,
            revisionMessage: description
        }

        try {
            await apiClient.post("/api/time-off-management/override-user-available-time-off", payload);
            message.success("Success!");
            setIsLoading(false);
            onSuccess();
        } catch(error: any) {
            message.error("Sorry, there was an error");
            setIsLoading(false);
        }

    }

    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            width={700}
        >
            <div>
                <h4 style={headerStyle}>
                    <HourglassTwoTone style={{marginRight: 7}}/>
                    {data?.user.userName + '\'s Time Off Management'}
                </h4>
            </div>

            <div style={statisticsStyle}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{textAlign: 'left', paddingRight: 20, borderRight: '1px solid #efefef' }}>
                        <p style={{marginBottom: 0, fontSize: 30, fontWeight: 700, color: 'green'}}>
                            {data?.availabilityStats?.daysOffAvailableCount}
                        </p>
                        <label style={{color: 'gray'}}>Available days</label>
                    </div>
                    <div style={{textAlign: 'left', padding: '0 20px', borderRight: '1px solid #efefef'}}>
                        <p style={{ marginBottom: 0, fontSize: 30, fontWeight: 700, color: '#F1C757' }}>{data?.availabilityStats?.daysOffPendingApprovalCount}</p>
                        <label style={{color: 'gray'}}>Waiting for approval</label>
                    </div>

                    <div style={{textAlign: 'left', paddingLeft: 20}}>
                        <p style={{ marginBottom: 0, fontSize: 30, fontWeight: 700, color: (data?.availabilityStats?.daysOffAvailableUpcomingCount || 0) <= 0 ? 'red' : 'green' }}>{data?.availabilityStats?.daysOffAvailableUpcomingCount}</p>
                        <label style={{color: 'gray'}}>Remaining days</label>
                    </div>
                </div>
            </div>

            <div style={stepStyleOdd}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle, backgroundColor: 'white'}}>1</span> Choose Type...
                </p>
                <Radio.Group value={selectedRevisionType} onChange={(e) => setSelectedRevisionType(e.target.value)} style={typeBtnContainerStyle}>
                    <Radio.Button style={selectedRevisionType === TimeOffRevisionType.ManualIntervention ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRevisionType.ManualIntervention}>Manual Intervention</Radio.Button>
                    <Radio.Button style={selectedRevisionType === TimeOffRevisionType.ErrorCorrection ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRevisionType.ErrorCorrection}>Error Correction</Radio.Button>
                    <Radio.Button style={selectedRevisionType === TimeOffRevisionType.InitialSeed ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRevisionType.InitialSeed}>Initial Seed</Radio.Button>
                    <Radio.Button style={selectedRevisionType === TimeOffRevisionType.YearlyRefill ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRevisionType.YearlyRefill}>Yearly Refill</Radio.Button>
                    <Radio.Button style={selectedRevisionType === TimeOffRevisionType.Other ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRevisionType.Other}>Other</Radio.Button>
                </Radio.Group>

                <p style={typeAltTextStyle}>{computeHelpTextForType()}</p>
            </div>

            <div style={stepStyle}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle}}>2</span> Set Available Days Off
                </p>
                <InputNumber min={-100} max={100} size={'large'} value={availableDaysCount} onChange={setAvailableDaysCount} />
            </div>

            <div style={stepStyleOdd}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle, backgroundColor: 'white'}}>3</span> Description
                </p>
                <Input.TextArea value={description} onChange={(e: any) => setDescription(e.target.value)} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1000} style={{borderRadius: 0, fontSize: 15}} placeholder={'This is required, just so you know'}/>
            </div>

            <div style={{marginTop: 20}}>
                <Button
                    type="primary"
                    style={{borderRadius: 4, marginLeft: 20}}
                    onClick={() => submit()}
                    loading={isLoading}
                    disabled={!description || availableDaysCount === data?.availabilityStats?.daysOffAvailableCount}
                >All Good</Button>
            </div>
        </Modal>
    )
}

const headerStyle = {
    color: '#1a8bcd',
    fontSize: 19,
    fontWeight: 600
}

const statisticsStyle = {
    border: "1px solid #dfdfdf",
    borderRadius: "6px",
    width: "fit-content",
    margin: "auto",
    padding: "20px",
    textAlign: "left" as "left",
    marginTop: "20px",
    marginBottom: "20px"
}

const typeBtnContainerStyle = {
    width: '100%',
}

const btnTypeStyle = {
    width: 'fit-content',
    textAlign: 'center' as 'center',
}

const btnTypeSelectedStyle = {
    fontWeight: 500,
   // width: '20%',
    textAlign: 'center' as 'center',
    backgroundColor: '#cee6f4',
}

const stepTitleStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500
}

const typeAltTextStyle = {
    marginTop: 10,
    marginBottom: 0,
    fontStyle: 'italic',
    fontSize: 15
}

const stepNumberStyle = {
    fontSize: 21,
    fontWeight: 700,
    backgroundColor: '#cee6f4',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    border: '2px solid #1a8bcd',
    color: '#1a8bcd',
    marginRight: 10
}

const stepStyleOdd = {
    backgroundColor: '#d2e0e9',
    marginLeft: -24,
    marginRight: -24,
    marginBottom: 24,
    padding: 24,
    marginTop: 30,
    textAlign: 'left' as 'left'
}

const stepStyle = {
    marginTop: 30,
    textAlign: 'left' as 'left'
}
