export interface Employee {
  id: string;
  lastName: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  legalPosition: UserLegalPositionEnum;
  function: number;
  birthday: string;
  office: Office;
  vehiclePlates: string;
  vehicleDescription: string;
  profilePicture: string;
  isDeleted: boolean;
  emailConfirmed: boolean;
  userTechnologies: Technology[];
  currentMonthDaysOff: number;
  nextMonthDaysOff: number;
  daysLeft: number;
  userStatus: number;
}

export interface Office {
  id: string;
  city: string;
}
export interface Technology {
  technologyId: string;
  technologyName: string;
  userPosition: number;
}
export enum UserLegalPositionEnum {
  Angajat = 0,
  Programator = 10,
  "Programator ajutor" = 20,
  Analist = 30,
  "Analist ajutor" = 40,
  "Resurse umane" = 50,
  "Manager de proiect informatic" = 60,
}
export const legalPositions = [
  {
    name: "Angajat",
    value: 0,
  },
  {
    name: "Programator",
    value: 10,
  },
  {
    name: "Programator ajutor",
    value: 20,
  },
  {
    name: "Analist",
    value: 30,
  },
  {
    name: "Analist ajutor",
    value: 40,
  },
  {
    name: "Resurse umane",
    value: 50,
  },
  {
    name: "Manager de proiect informatic",
    value: 60,
  },
];
