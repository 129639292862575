import { Modal } from "antd";
import CalendarSessionInput from "./CalendarSessionInput";
import CalendarTaskInput from "./CalendarTaskInput";
import CalendarTimeInput from "./CalendarTimeInput";
import ModalFooter from "./ModalFooter";
import { EditSessionModalContext } from "./types";
import { useEditSessionModal } from "./useEditSessionModal";
const EditSessionModal = ({
  session,
  onUpdate,
  isModalVisible,
  onClose,
}: {
  session: any;
  onUpdate: () => void;
  isModalVisible: boolean;
  onClose: () => void;
}) => {
  const handleCancel = () => {
    onClose();
  };
  const hook = useEditSessionModal(session, onUpdate);
  return (
    <Modal
      visible={isModalVisible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      width="50%"
    >
      <EditSessionModalContext.Provider value={hook}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CalendarTaskInput />
          <div style={{ display: "flex" }}>
            <CalendarSessionInput />
            <CalendarTimeInput />
          </div>
        </div>
        <ModalFooter sessionId={session.id} onClose={onClose} />
      </EditSessionModalContext.Provider>
    </Modal>
  );
};

export default EditSessionModal;
