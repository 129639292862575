import {Table, Tooltip, Typography} from "antd";
import React from "react";
import {ArrowRightOutlined, LinkOutlined, SnippetsOutlined} from "@ant-design/icons";
import {computeTimeOffRevisionTypeName, timeOffHistoryLogHasLink} from "../_core/helpers/time-off-general.helper";
import {Link} from "react-router-dom";
import moment from "moment";
import {TimeOffHistoryLog} from "../_core/interfaces/history-log.interface";

interface TimeOffHistoryLogsProps {
    historyLogs: Array<TimeOffHistoryLog>
}

export const TimeOffHistoryLogs: React.FC<TimeOffHistoryLogsProps> = ({historyLogs}) => {

    const tableColumns= [
        {
            title: 'Change',
            width: '25%',
            render: (record: TimeOffHistoryLog) => {
                return (
                    <p style={{marginBottom: 0, display: 'flex', alignItems: 'center'}}>
                        <span style={{fontWeight: 500}}>{record.daysBefore} days</span>
                        <ArrowRightOutlined style={{margin: '0 5px'}}/>
                        <span style={{fontWeight: 500}}>{record.daysAfter} days</span>
                        {
                            timeOffHistoryLogHasLink(record.revisionType) &&
                            <Link to={`time-off-request/${record.daysOffId}`} style={{fontSize: 15, marginLeft: 7}}>
                                <LinkOutlined />
                            </Link>
                        }

                        {
                            record.revisionMessage &&
                            <Tooltip title={record.revisionMessage} placement="top">
                                <SnippetsOutlined style={{marginLeft: 7, fontSize: 15, color: '#108930', cursor: 'pointer'}}  />
                            </Tooltip>
                        }
                    </p>
                )
            }
        },
        {
            title: 'Revision Type',
            width: '25%',
            render: (record: TimeOffHistoryLog) => {
                return (
                    <p style={{marginBottom: 0}}>
                        {computeTimeOffRevisionTypeName(record.revisionType)}
                    </p>
                )
            }
        },
        {
            title: 'Altered By',
            width: '25%',
            render: (record: TimeOffHistoryLog) => {
                return (
                    <p style={{marginBottom: 0}}>
                        {record.alteredBy.lastName} {record.alteredBy.firstName} on {moment(record.createdAt).local().format('D MMM, YY')} @ {moment.utc(record.createdAt).local().format('hh:mm a')}
                    </p>
                )
            }
        }
    ];

     return (
         <div>
             <Typography.Text style={{ display: "flex", justifyContent: "flex-start", fontSize: 24, marginBottom: 20 }}>
                 History Logs
             </Typography.Text>

             <Table
                 dataSource={historyLogs?.map((val: any) => ({
                     ...val,
                     key: val.id,
                 }))}
                 columns={[...tableColumns]}
             />
         </div>
     )
}
