import { message } from "antd";
import moment from "moment";
import React from "react";
import { ApiClientContext } from "../../../../../App";
import useInterval from "../../../../../_core/hooks/useInterval";
import { TimeTrackingUserDataContext } from "../../TimeTrackingUserDataProvider";
import { Project, Task, Technology } from "../../types";

import {
  convertToLocal,
  getDates,
  getPassedSeconds,
  prepareSessionForTracking,
  validateTrackerForm,
} from "./utils/helpers";
import {
  ITrackerContext,
  SessionTypeLocalStorageKey,
  TechnologyLocalStorageKey,
  TrackerForm,
  TrackingType,
  TypeOfTrackingLocalStorageKey,
  defaultTrackerForm,
} from "./utils/types";

export const useTracker = (defaultProjectId?: string): ITrackerContext => {
  // or useApiClient()
  const { apiClient } = React.useContext(ApiClientContext);
  const { projects, updateProjectTasks, updateSessionWeeks } = React.useContext(
    TimeTrackingUserDataContext
  );

  const [tasks, setTasks] = React.useState<Array<Task> | undefined>([]);
  const [technologies, setTechnologies] = React.useState<Array<Technology>>([]);

  const [startTime, setStartTime] = React.useState<string>(
    moment().format("HH:mm")
  );
  const [endTime, setEndTime] = React.useState<string>(
    moment().add(30, "minutes").format("HH:mm")
  );

  const [typeOfTracking, setTypeOfTracking] = React.useState<TrackingType>(
    (localStorage.getItem(TypeOfTrackingLocalStorageKey) ||
      "Auto") as TrackingType
  );
  const [trackerLoading, setTrackerLoading] = React.useState<boolean>(false);
  const [isTracking, setIsTracking] = React.useState<boolean>(false);
  const [delay, setDelay] = React.useState<number | null>(null);
  const [liveTime, setLiveTime] = React.useState<number>(0);
  const [day, setDay] = React.useState(moment());
  const [formData, setFormData] =
    React.useState<TrackerForm>(defaultTrackerForm);
  const [isNewTask, setIsNewTask] = React.useState<boolean>(false);
  const [newTaskTitle, setNewTaskTitle] = React.useState<string | undefined>(
    undefined
  );

  /////////////////////////////////////////////////////////////////////////////////////////////////

  const validateSessionCreation = () => {
    const validationResult = validateTrackerForm(
      formData,
      isNewTask,
      typeOfTracking,
      endTime
    );
    if (!validationResult.isValid) {
      return validationResult.errorMessage;
    }
    return null;
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  const updateTasks = async () => {
    //TODO FOR BACKEND TO RETURN A DTO TASK, NOT THE FULL OBJECT FOR THIS ENDPOINT
    await apiClient
      .get(`/api/time-tracking/tasks/project/${formData.projectId}`)
      .then((res) => {
        updateProjectTasks(formData.projectId, res.data);
        //TODO AFTER BACKEND IS FIDEX SEARCH FOR THE TITLE IN RES.DATA AND SET THAT TASK AS CURRENT
        setFormData({
          ...formData,
          taskId: res.data[0]?.id,
        });
        setIsNewTask(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const createSession = async (
    startTimeProps?: string,
    endTimeProps?: string,
    dayProps?: moment.Moment
  ) => {
    const validationError = validateSessionCreation();
    if (validationError) {
      message.error(validationError);
      return;
    }
    let dates = {};
    if (
      dayProps &&
      (dayProps?.isAfter(moment()) ||
        dayProps?.month() !== moment().month() ||
        dayProps?.year() !== moment().year())
    ) {
      message.error(
        "You can't create sessions in the past month or in the future"
      );
      return;
    } else if (
      day.month() !== moment().month() ||
      day.year() !== moment().year() ||
      day.isAfter(moment())
    ) {
      message.error(
        "You can't create sessions in the past month or in the future"
      );
      return;
    }

    if (startTimeProps && endTimeProps) {
      dates = getDates(startTimeProps, endTimeProps, dayProps);
    } else dates = getDates(startTime, endTime, day);
    const basicSession = prepareSessionForTracking(
      formData,
      isNewTask,
      newTaskTitle,
      tasks
    );

    const preparedSession = {
      ...basicSession,
      ...dates,
      task: isNewTask ? basicSession.task : undefined,
      taskId: !isNewTask ? formData.taskId : undefined,
    };

    try {
      await apiClient
        .post("/api/time-tracking/sessions/create", preparedSession)
        .then((res) => {
          message.success("Session recorded.");
        })
        .catch((err) => {
          message.error("Could not record session. " + err);
        });

      if (isNewTask) await updateTasks();
      await updateSessionWeeks();
    } catch (error) {
      message.error("Could not record session. " + error);
    }

    setIsTracking(false);
  };

  const switchTracker = (type: TrackingType) => {
    //cant switch the tracker if it is tracking
    if (!isTracking) {
      setTypeOfTracking(type);
      localStorage.setItem(TypeOfTrackingLocalStorageKey, type);
    }
  };

  const runTimer = (isTracking: boolean) => {
    if (isTracking) {
      setDelay(1000);
    } else {
      setDelay(null);
    }
  };
  //////////////////////////////////////////////////////////////////////////
  const startTracking = async (projectId?: string, taskId?: string) => {
    let formDataCurrent = formData;
    setTypeOfTracking("Auto");
    if (taskId && projectId) {
      let project = projects.find((p: Project) => p.projectId === projectId);
      let task = project?.tasks.find((t: Task) => t.taskId === taskId);
      formDataCurrent = {
        ...formData,
        projectId: projectId,
        taskId: task?.taskId,
        description: task?.description,
        technologyId: task?.technologyId || "",
      };
    }
    const validationResult = validateTrackerForm(
      formDataCurrent,
      taskId ? false : isNewTask,
      typeOfTracking,
      endTime
    );
    if (!validationResult.isValid) {
      message.error(validationResult.errorMessage || "Validation errors!");
      return;
    }

    const preparedSession = prepareSessionForTracking(
      formDataCurrent,
      taskId ? false : isNewTask,
      newTaskTitle,
      tasks
    );

    try {
      await apiClient
        .post("/api/time-tracking/sessions/start", preparedSession)
        .then((res) => {
          if (taskId) {
            setFormData(formDataCurrent);
          }
          setIsTracking(true);
          runTimer(true);
          setStartTime(moment().format());
        });
    } catch (error: any) {
      message.error(
        "Could not start a new session. " + error?.response?.data?.message
      );
    }
  };

  const editCurrentSession = async (start: any) => {
    //TODO HANDLE ERROR

    await apiClient
      .put(`/api/time-tracking/sessions/update-current-session-start`, {
        start: start,
      })
      .then((res) => {
        setStartTime(start);
        message.success("Session edited.");
      })
      .catch((err) => {
        console.log(err);
        message.error("Could not edit session. " + err);
      });
  };

  //////////////////////////////////////////////////////////////
  const stopTracking = async () => {
    const err = validateSessionCreation();
    if (err) {
      console.error("error: ", err);
      message.error("Form validation errors");
      return;
    }

    setTrackerLoading(true);
    const end = moment().toISOString();
    await apiClient
      .put("/api/time-tracking/sessions/end", {
        end: end,
      })
      .then(async (res) => {
        await updateSessionWeeks();
        if (isNewTask) await updateTasks();
        setStartTime(moment().format("HH:mm"));
      })
      .catch(() => {
        message.error("Error ending task");
      });

    runTimer(false);
    setIsTracking(false);
    setLiveTime(0);

    message.success("Session saved.");
    setTrackerLoading(false);
  };

  const fetchData = async (
    endpoint: string,
    onSuccess: Function,
    onError: Function
  ) => {
    try {
      const response = await apiClient.get(endpoint);
      onSuccess(response);
    } catch (error) {
      onError(error);
    }
  };

  const initializeFormData = async () => {
    const onSuccessActiveSession = (response: any) => {
      const activeSession = response.data;
      if (activeSession && Object.keys(activeSession).length !== 0) {
        const project = projects.find(
          (p: Project) => p.projectId === activeSession.projectId
        );
        setTasks(project?.tasks || []);
        const task = project?.tasks.find(
          (t: Task) => t.taskId === activeSession.taskId
        );
        setFormData({
          technologyId: activeSession.technologyId,
          taskId: activeSession.taskId,
          description: task?.description ?? "",
          projectId: activeSession.projectId,
          sessionType: activeSession.sessionType,
        });

        const startTime = activeSession.start;
        setStartTime(convertToLocal(startTime).format());

        setIsTracking(true);
        setTypeOfTracking("Auto");

        setLiveTime(getPassedSeconds(startTime));
        runTimer(true);
      } else {
        // If there is no active session, we set the form data to default values or values from local storage
        const defaultProject = projects[0];
        const tasks =
          defaultProject?.tasks?.reduce((acc: Task[], task: Task) => {
            if (!acc.find((t) => t.title === task.title)) acc.push(task);
            return acc;
          }, []) || [];
        const technologies = tasks.map((task: Task) => task.technologyId);
        setTasks(tasks);
        setTechnologies(defaultProject?.technologies || []);
        const defaultTask = defaultProject?.tasks[0];
        const defaultTechnologyId =
          technologies?.find(
            (tech: string) =>
              tech === localStorage.getItem(TechnologyLocalStorageKey)
          ) ||
          defaultProject?.tasks[0]?.technologyId ||
          defaultProject?.technologies[0]?.id ||
          "";

        setFormData({
          projectId: defaultProject?.projectId || "",
          taskId: defaultTask?.taskId || "",
          technologyId: defaultTechnologyId,
          sessionType: parseInt(
            localStorage.getItem(SessionTypeLocalStorageKey) || "10"
          ),
          description: defaultTask?.description || "",
        });
      }
    };

    const onErrorActiveSession = (error: any) => {
      console.error("Failed to fetch active session", error);
      // Handle additional error logic here if needed
    };

    await fetchData(
      "/api/time-tracking/sessions/user/active",
      onSuccessActiveSession,
      onErrorActiveSession
    );
  };
  const showModal = () => {
    setTypeOfTracking("Calendar");
  };

  const handleClose = () => {
    setTypeOfTracking("Auto");
  };
  const markTaskAsComplete = async (taskId: string) => {
    await apiClient
      .post(`/api/tasks/done/${taskId}`)
      .then(() => {
        setTasks(tasks?.filter((t) => t.taskId !== taskId));
        message.success("Task marked as complete.");
      })
      .catch((e: any) => {
        message.error("Could not mark task as complete." + e);
      });
  };

  React.useEffect(() => {
    setTasks(
      projects.find((p: Project) => p.projectId === formData.projectId)
        ?.tasks || []
    );
    setTechnologies(
      projects.find((p: Project) => p.projectId === formData.projectId)
        ?.technologies || []
    );
    const firstProject = projects.find(
      (p: Project) => p.projectId === formData.projectId
    );
    setFormData({
      ...formData,
      technologyId:
        firstProject?.tasks[0]?.technologyId ||
        firstProject?.technologies[0]?.id ||
        tasks?.[0]?.technologyId ||
        "",
      taskId: firstProject?.tasks[0]?.taskId || tasks?.[0]?.taskId || "",
    });
  }, [formData.projectId]);

  React.useEffect(() => {
    initializeFormData();
  }, [projects]);

  /////////////////////////////////////////////////////////
  useInterval(() => {
    setLiveTime(getPassedSeconds(startTime));
  }, delay);
  return {
    // State Values
    day,
    technologies,
    tasks,
    formData,
    typeOfTracking,
    isTracking,
    isNewTask,
    trackerLoading,
    startTime,
    endTime,
    liveTime,
    newTaskTitle,

    // State Update Methods
    setDay,
    setIsNewTask,
    setFormData,
    setNewTaskTitle,
    setStartTime,
    setEndTime,

    // Functions

    switchTracker,
    createSession,
    startTracking,
    stopTracking,
    editCurrentSession,
    showModal,
    handleClose,
    markTaskAsComplete,
  };
};
