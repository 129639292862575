import { Select } from "antd";
import { typesDict } from "../../../types";
import { useContext } from "react";
import { EditSessionContext } from "../Session";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";
import { isLoadingAnUpdate } from "./utils";

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-arrow {
    color: black;
  }
`;
interface TypesSelectProps {
  isHovered: boolean;
}

export const SessionType: React.FC<TypesSelectProps> = ({ isHovered }) => {
  const { sessionState, setSessionState, loading } =
    useContext(EditSessionContext);
  const handleChange = (value: SelectValue) => {
    setSessionState({ ...sessionState, type: value as keyof typeof typesDict });
  };

  return (
    <StyledSelect
      value={sessionState.type}
      style={{
        width: "180px",
        fontSize: "16px",
        border: "1px solid",
        borderRadius: "5px",
        borderColor: isHovered ? "black" : "white",
        marginLeft: "4px",
      }}
      onChange={handleChange}
      showArrow={isHovered}
      bordered={isHovered}
      disabled={isLoadingAnUpdate(loading)}
      size="small"
    >
      {Object.entries(typesDict).map(([value, label]) => (
        <Option key={value} value={Number(value)}>
          {label}
        </Option>
      ))}
    </StyledSelect>
  );
};
