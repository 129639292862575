import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ApiClientContext } from "../../../App";
import {TimeOffRequestDetails} from "../_core/interfaces/time-off-record.interface";

export const useTimeOffRequest = (requestId: string) => {
  const { apiClient } = useContext(ApiClientContext);
  const [timeOffRequest, setTimeOffRequest] = useState<TimeOffRequestDetails | null>(null);
  const [requestLoading, setRequestLoading] = useState<Boolean>(true);
  const history = useHistory();

  const approveRequest = async (requestId: string) => {
    try {
      await apiClient.put(`/api/time-off/approve/${requestId}`);
      history.goBack();
      message.success("Request approved.");
    } catch (error) {
      message.error("Could not approve request.");
    }
  };

  const declineRequest = async (requestId: string) => {
    try {
      await apiClient.put(`/api/time-off/decline/${requestId}`);
      message.success("Request declined.");
      history.goBack();
    } catch (error) {
      message.error("Could not decline request.");
    }
  };

  const deleteRequest = async (requestId: string) => {
    try {
      await apiClient.delete(`/api/time-off/delete/${requestId}`);
      message.success("Request deleted.");
      history.goBack();
    } catch (error) {
      message.error("Could not delete request.");
    }
  };

  const getRequestDetails = async () => {
    try {
      const timeOffResponse = await apiClient.get(`/api/time-off/${requestId}`);
      setTimeOffRequest(timeOffResponse.data);
    } catch (error: any) {
      console.error(error.response);
      message.error("Could not retrieve days off request. ");
    }
    setRequestLoading(false);
  };

  const getTimeOffDoc = async (timeOffId: string, documentId: string, fileName: string) => {
    try {
      const response = await apiClient.get(
        `/api/time-off-docs/${timeOffId}/download-doc/${documentId}`, {responseType: 'blob'}
      );

      saveFile(fileName, response.data);

    } catch (error) {
      message.error("Failed to upload associated docs to time off request.");
    }
  };

  function saveFile(fileName: string, bytes: any) {
    var blob = new Blob([bytes], { type: "application/pdf" });
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    var fileName = fileName;
    link.download = fileName;
    link.click();
  }

  useEffect(() => {
    if(!requestId) {
      return;
    }
    getRequestDetails();
  }, []);

  return {
    timeOffRequest,
    requestLoading,
    approveRequest,
    declineRequest,
    deleteRequest,
    getTimeOffDoc,
  };
};;
