export enum CountryTypes {
    Unknown = 0,
    Romania = 1,
    Georgia = 2
}

export const getCountryTypeValue = (countryType: CountryTypes): string => {
    if(countryType === CountryTypes.Georgia) {
        return 'Georgia';
    }
    if(countryType === CountryTypes.Romania) {
        return 'Romania';
    }
    return 'unknown'
}
