import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, message, Table, Tooltip, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { ProjectContext } from ".";
import { ApiClientContext } from "../../../App";
import { getProjectDocType } from "../../../_core/hardcodedValues";
import useDownloadDocument from "../../../_core/hooks/useDownloadDocument";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { TableHeader } from "../../../styles/StyledComponents";
import { ProjectDocument } from "../types";

export const DocumentsTable = () => {
  const { documents, documentsLoading, deleteDocument, projectId } = useContext(ProjectContext);
  const downloadDocument = useDownloadDocument();
  const { isAdmin, isTeamLead } = useUserRoles();

  return (
    <Table dataSource={documents} rowKey="id" loading={documentsLoading} pagination={false}>
      <Table.Column<ProjectDocument>
        title={<TableHeader>Document</TableHeader>}
        dataIndex="name"
        render={(val, row) => (
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {row.name}
            </Typography.Title>
            <Typography.Text type="secondary">{getProjectDocType(row.docType)}</Typography.Text>
          </Col>
        )}
      />
      <Table.Column<ProjectDocument>
        title={<TableHeader>Description</TableHeader>}
        dataIndex="description"
      />
      <Table.Column<ProjectDocument>
        title={<TableHeader>Get Document</TableHeader>}
        dataIndex="url"
        render={(url: string, row) => {
          if (row.isFile) {
            return (
              <Button type="link" onClick={() => downloadDocument(row.url, row.name)}>
                Download
              </Button>
            );
          } else {
            return (
              <Button type="link" href={url} target="_blank">
                URL
              </Button>
            );
          }
        }}
      />
      <Table.Column<ProjectDocument>
        title={<TableHeader>Actions</TableHeader>}
        render={(val, row) => {
          if (isAdmin() || isTeamLead(projectId)) {
            return (
              <Tooltip title="Delete">
                <DeleteFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteDocument(row.id)}
                />
              </Tooltip>
            );
          }
        }}
      />
    </Table>
  );
};
