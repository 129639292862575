import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { AddMemberFormValues, Project, ProjectDocument } from "../types";
import { useLocation } from "react-router-dom";

const useProject = (projectId: string) => {
  const location = useLocation();
  const [project, setProject] = useState<Project>();
  const [documents, setDocuments] = useState<ProjectDocument[]>([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const { apiClient } = useContext(ApiClientContext);

  const fetchProject = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/projects/${projectId}`);
      setProject(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
      setError("An error ocurred");
    }
  };

  const fetchDocuments = async () => {
    try {
      setDocumentsLoading(true);
      const { data } = await apiClient.get(
        `/api/projects/${projectId}/documents`
      );
      setDocuments(data);
      setDocumentsLoading(false);
    } catch (err) {
      console.warn(err);
      setDocumentsLoading(false);
    }
  };

  useEffect(() => {
    fetchProject();
    fetchDocuments();
  }, [location.pathname]);

  const addMember = async (addMemberFormValues: AddMemberFormValues) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post(
        `/api/projects/${projectId}/members`,
        addMemberFormValues
      );
      fetchProject();
    } catch (err: any) {
      setIsLoading(false);
      throw err.response.data;
    }
  };

  const removeMember = async (memberId: string) => {
    try {
      const { data } = await apiClient.delete(
        `/api/projects/${projectId}/members/${memberId}`
      );
      fetchProject();
      return data;
    } catch (err: any) {
      throw err;
    }
  };

  const addClientContact = async (contactId: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post(
        `/api/projects/${projectId}/contacts`,
        { contactId }
      );
      fetchProject();
    } catch (err: any) {
      setIsLoading(false);
      throw err.response.data;
    }
  };
  const removeClientContact = async (contactId: String) => {
    const { data } = await apiClient.delete(
      `/api/projects/${projectId}/contacts/${contactId}`
    );
    fetchProject();
    return data;
  };

  const addDocument = async (formValues: any) => {
    try {
      setDocumentsLoading(true);
      const { data } = await apiClient.post("/api/projects/documents", {
        ...formValues,
        projectId,
      });
      fetchDocuments();
      setDocumentsLoading(false);
      return data;
    } catch (error) {
      throw error;
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      setDocumentsLoading(true);
      const { data } = await apiClient.delete(
        `/api/projects/documents/${documentId}`
      );
      fetchDocuments();
      return data;
    } catch (err) {
      console.warn(err);
    }
  };

  return {
    fetchProject,
    project,
    isLoading,
    error,
    addMember,
    removeMember,
    addClientContact,
    removeClientContact,
    documents,
    documentsLoading,
    addDocument,
    deleteDocument,
  };
};

export default useProject;
