import React, {useContext, useState} from "react";
import {Button, Input, InputNumber, message, Modal, Radio, Select} from "antd";
import {HourglassTwoTone} from "@ant-design/icons";
import {ApiClientContext} from "../../../App";
import {TimeOffUserAdministration} from "../_core/interfaces/time-off-user-administration.interface";
import {KeyValue} from "../../../_core/types";

interface IncreaseAvailabilityForAllModalProps {
    data: TimeOffUserAdministration[];
    isVisible: boolean;
    onClose: () => void;
    onSuccess: () => void;
}

export const IncreaseAvailabilityForAllModal: React.FC<IncreaseAvailabilityForAllModalProps> = ({data, isVisible, onClose, onSuccess}) => {
    const {apiClient} = useContext(ApiClientContext);

    const [availableDaysCount, setAvailableDaysCount] = useState(1);
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const[selectedEmployees, setSelectedEmployees] = useState<string[]>(data.map(d => d.user.id));

    const submit = async () => {
        setIsLoading(true);
        const payload = {
            incrementAmount: availableDaysCount,
            userIds: selectedEmployees,
            refillMessage: description,
        }

        try {
            await apiClient.post("/api/time-off-management/refill-users-available-time-off", payload);
            message.success("Success!");
            setIsLoading(false);
            onSuccess();
        } catch(error: any) {
            message.error("Sorry, there was an error");
            setIsLoading(false);
        }
    }

    const selectAll = () => {
        setSelectedEmployees(data.map(d => d.user.id));
    }

    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            footer={null}
            width={700}
        >
            <div>
                <h4 style={headerStyle}>
                    <HourglassTwoTone style={{marginRight: 7}}/>
                    Edit available days for for <b>all selected employees</b>
                </h4>
            </div>

            <div  style={stepStyle}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle}}>1</span> Adjust for...
                </p>
                <Select
                    placeholder="Select employees"
                    mode="multiple"
                    maxTagCount={3}
                    onChange={(value: string[]) => setSelectedEmployees(value)}
                    style={{ width: "100%" }}
                    showSearch={true}
                    value={selectedEmployees}
                    allowClear={true}
                    filterOption={(input, option) =>
                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {data?.map((d: TimeOffUserAdministration) => (
                        <Select.Option key={d.user.id} value={d.user.id} title={d.user.userName}>
                            {d.user.userName}
                        </Select.Option>
                    ))}
                </Select>
                <p style={{cursor: "pointer", marginTop: 5}} onClick={() => selectAll()}>Select all</p>
            </div>

            <div style={stepStyleOdd}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle, backgroundColor: 'white'}}>2</span> Increase available days off by...
                </p>
                <InputNumber min={-100} max={100} size={'large'} value={availableDaysCount} onChange={setAvailableDaysCount} />
            </div>

            <div style={stepStyle}>
                <p style={stepTitleStyle}>
                    <span style={{...stepNumberStyle}}>3</span> Description
                </p>
                <Input.TextArea value={description} onChange={(e: any) => setDescription(e.target.value)} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1000} style={{borderRadius: 0, fontSize: 15}} placeholder={'This is required, just so you know'}/>
            </div>

            <div style={{marginTop: 20}}>
                <Button
                    type="primary"
                    style={{borderRadius: 4, marginLeft: 20}}
                    onClick={() => submit()}
                    loading={isLoading}
                    disabled={!description || availableDaysCount === 0 || selectedEmployees.length === 0}
                >All Good</Button>
            </div>
        </Modal>
    )
}

const headerStyle = {
    color: '#1a8bcd',
    fontSize: 19,
    fontWeight: 600
}

const statisticsStyle = {
    border: "1px solid #dfdfdf",
    borderRadius: "6px",
    width: "fit-content",
    margin: "auto",
    padding: "20px",
    textAlign: "left" as "left",
    marginTop: "20px",
    marginBottom: "20px"
}

const typeBtnContainerStyle = {
    width: '100%',
}

const btnTypeStyle = {
    width: 'fit-content',
    textAlign: 'center' as 'center',
}

const btnTypeSelectedStyle = {
    fontWeight: 500,
    // width: '20%',
    textAlign: 'center' as 'center',
    backgroundColor: '#cee6f4',
}

const stepTitleStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500
}

const typeAltTextStyle = {
    marginTop: 10,
    marginBottom: 0,
    fontStyle: 'italic',
    fontSize: 15
}

const stepNumberStyle = {
    fontSize: 21,
    fontWeight: 700,
    backgroundColor: '#cee6f4',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    border: '2px solid #1a8bcd',
    color: '#1a8bcd',
    marginRight: 10
}

const stepStyleOdd = {
    backgroundColor: '#d2e0e9',
    marginLeft: -24,
    marginRight: -24,
    marginBottom: 24,
    padding: 24,
    marginTop: 30,
    textAlign: 'left' as 'left'
}

const stepStyle = {
    marginTop: 30,
    textAlign: 'left' as 'left'
}
