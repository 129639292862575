import { Button, Form, Input, Modal, Select } from "antd";
import { FormButtons, ModalStyle } from "../../../styles/StyledComponents";
import FormItem from "antd/lib/form/FormItem";
import React, { useContext } from "react";
import { OfficePageContext } from ".";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";

export const ManagerModal = () => {
  const officePageHooks = useContext(OfficePageContext);
  const { managerModal, setManagerModal, handleSetManager, officeData, managers } = officePageHooks;

  return (
    <Modal
      visible={officePageHooks.managerModal.visible}
      width="380px"
      style={{ top: "25%" }}
      onCancel={() => {
        setManagerModal({ visible: false });
      }}
      footer={null}
      closeIcon={<RoundCloseIcon />}
    >
      <ModalStyle>
        <h2>Set a manager</h2>
        <Form style={{ marginBottom: "-20px", width: "100%" }} onFinish={handleSetManager}>
          <FormItem style={{ width: "100%" }} name="manager">
            <Select
              showSearch
              showArrow={false}
              placeholder="Select employee"
              style={{ marginRight: 32, width: "100%" }}
            >
              {Object.keys(managers).map((managerId: string) => {
                return (
                  <Select.Option key={managerId} value={managerId}>
                    {managers[managerId]}
                  </Select.Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem name="office" initialValue={managerModal.officeId} hidden>
            <Input defaultValue={officeData?.name} maxLength={100} />
          </FormItem>
          <FormButtons>
            <Form.Item>
              <Button key="back" onClick={() => setManagerModal(false)}>
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button style={{ marginLeft: "15px" }} htmlType="submit" type="primary" key="Add">
                Set Manager
              </Button>
            </Form.Item>
          </FormButtons>
        </Form>
      </ModalStyle>
    </Modal>
  );
};
