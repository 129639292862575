import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { AllEmployees } from "./AllEmployees";
import { ManageEmployee } from "./ManageEmployee";

export const Employees = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={AllEmployees} />
      <Route exact path={`${path}/:employeeId`} component={ManageEmployee} />
    </Switch>
  );
};
