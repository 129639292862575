import {Button, Col, Input, Row} from "antd";
import React, {useState} from "react";
import {SearchIcon} from "../../../../assets/icons/SearchIcon";
import {AddModelFormValues, Model, Tag} from "../../types";
import {ModelDialog} from "./ModelDialog";
import {ModelsTable} from "./ModelsTable";
import useAllModels from "./useAllModels";

interface AllModelsContextInterface {
  models: Model[];
  isLoading: boolean;
  addModel(formValues: AddModelFormValues): void;
  deleteModel(modelId: string): void;
  updateModel(formValues: AddModelFormValues, modelId: string): void;
  tags: Tag[];
  searchInputValue: string;
}

//@ts-ignore
export const AllModelsContext = React.createContext<AllModelsContextInterface>();
export const AllModels = (props : any) => {
  const ret = useAllModels();
  const [addModelDialog, setAddModelDialog] = useState(false);
  const [addToBrand, setAddToBrand] = useState(props.location.state?.addToBrand);
  const { brandId } = (props.location && props.location.state) || {};

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ret.setSearchInputValue(ev.target.value);
  };

  return (
    <AllModelsContext.Provider value={ret}>
      <Row style={{marginBottom: 10}}>
        <Col span="20" style={{paddingRight: 5}}>
          <Input
            placeholder="Search here"
            prefix={<SearchIcon />}
            style={{borderRadius: 5}}
            value={ret.searchInputValue}
            onChange={handleSearchInputChange}
          />
        </Col>
        <Col span="4">
          <Button
            block
            type="primary"
            style={{borderRadius: 5}}
            onClick={() => setAddModelDialog(true)}>
            Add new model
          </Button>
        </Col>
      </Row>
      <ModelsTable />
      <ModelDialog isVisible={addModelDialog || addToBrand} setAddToBrand={setAddToBrand} setIsVisible={setAddModelDialog} brandId={brandId} />
    </AllModelsContext.Provider>
  );
};
