import React, {
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import { ITimeTrackingUserData } from "./types";
import { useTimeTrackingUserData } from "./useTimeTrackingUserData";

export const TimeTrackingUserDataContext = createContext<ITimeTrackingUserData>(
  {} as ITimeTrackingUserData
);

export const TimeTrackingUserDataProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [didMount, setDidMount] = useState(false);
  const hook = useTimeTrackingUserData();
  useEffect(() => {
    setDidMount(true);
  }, []);
  return (
    <>
      {didMount && (
        <TimeTrackingUserDataContext.Provider value={hook}>
          {children}
        </TimeTrackingUserDataContext.Provider>
      )}
    </>
  );
};
