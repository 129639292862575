import React from "react";
import ReportsWrapper from "./ReportsWrapper";
import Table from "./Table";
import Drawer from "./Drawer";
import { ReportsDataContextProvider } from "./Table/ReportsDataContext";
import { ReportsFiltersContextProvider } from "./ReportsFiltersContext";

const ReportsPage: React.FC = () => {
  return (
    <ReportsFiltersContextProvider>
      <ReportsDataContextProvider>
        <ReportsWrapper>
          <Table />
          <Drawer />
        </ReportsWrapper>
      </ReportsDataContextProvider>
    </ReportsFiltersContextProvider>
  );
};

export default ReportsPage;
