import { Typography } from "antd";
import React from "react";
import { getTotal, getWeekName } from "../../../utils";
import { SessionsWeek } from "../../types";
import { HOURS_COLOR } from "./SessionsHistory";
import { Day } from "./Day";

interface WeekProps {
  week: SessionsWeek;
}

export const Week: React.FC<WeekProps> = ({ week }) => {
  return (
    <div
      style={{
        boxShadow: "-1px -1px 19px -1px rgba(184,184,184,0.81)",
        marginTop: "8px",
        marginBottom: "8px",
        padding: "8px",
        borderRadius: "8px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <Typography.Title
          style={{
            margin: 0,
          }}
          level={3}
        >
          {getWeekName(week.start.slice(0, 10), week.end.slice(0, 10))}
        </Typography.Title>

        <Typography.Title
          level={3}
          style={{
            margin: 0,
            paddingRight: "4px",
          }}
        >
          Week Total:
          <span
            style={{
              color: HOURS_COLOR,
              fontWeight: "bold",
              paddingLeft: "8px",
            }}
          >
            {getTotal(week.total)}
          </span>
        </Typography.Title>
      </div>

      {week.days.map((day, index) => (
        <Day day={day} key={`day-${index}`} />
      ))}
    </div>
  );
};
