import React from "react";
import styled from "styled-components";
import { Tooltip, Typography } from "antd";
import { TimeOffHours } from "../types";

const { Text } = Typography;

const TotalHoursHeaderCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
`;

const BoldText = styled(Text)`
  font-weight: bold;
`;

const TimeOffText = styled.span`
  color: ${(props) => props.color || "black"};
  margin-right: 5px;
`;
const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
  font-size: 1em;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

const TooltipItem = styled.div`
  margin-bottom: 4px;
  color: ${(props) => props.color || "#333"};
`;
interface TotalHoursHeaderCellProps {
  totalHoursWorked: number;
  timeOffHours: TimeOffHours;
}

const TotalHoursHeaderCell: React.FC<TotalHoursHeaderCellProps> = ({
  totalHoursWorked,
  timeOffHours,
}) => {
  const TimeOffTooltipContent = (
    <TooltipContent>
      <TooltipItem color="red">
        Holiday Hours: {timeOffHours?.holidayHours} hrs
      </TooltipItem>
      <TooltipItem color="green">
        Maternity Hours: {timeOffHours?.maternityHours} hrs
      </TooltipItem>
      <TooltipItem color="blue">
        Other Hours: {timeOffHours?.otherHours} hrs
      </TooltipItem>
    </TooltipContent>
  );

  return (
    <TotalHoursHeaderCellContainer>
      <BoldText>Total Worked: {totalHoursWorked} hrs</BoldText>
      {timeOffHours?.totalHours !== 0 && (
        <Tooltip title={TimeOffTooltipContent}>
          <Text style={{ cursor: "pointer" }}>
            Time Off: {timeOffHours?.totalHours} hrs
          </Text>
        </Tooltip>
      )}
    </TotalHoursHeaderCellContainer>
  );
};

export default TotalHoursHeaderCell;
