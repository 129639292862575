import { Typography } from "antd";
import React from "react";
import { getDayName, getTotal } from "../../../utils";
import { SessionsDay } from "../../types";
import { HOURS_COLOR } from "./SessionsHistory";
import { Task } from "./Task";

//fafafa

interface DayProps {
  day: SessionsDay;
}

export const Day: React.FC<DayProps> = ({ day }) => {
  return (
    <div
      style={{
        margin: "8px",
        borderRadius: "12px",
        border: "2px solid",
        borderColor: "#cbd2f49f",
      }}
    >
      <div
        style={{
          backgroundColor: "#fafafa",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderRadius: "12px 12px 0px 0px",
          fontWeight: "bold",
          fontSize: "20px",
          padding: "8px",
        }}
      >
        <div>{getDayName(day.day.slice(0, 10))}</div>
        <div style={{ color: HOURS_COLOR }}>{getTotal(day.total)}</div>
      </div>
      <div>
        {day.tasks.map((task, index) => (
          <Task
            task={task}
            key={`task-${task.taskId}`}
            isFirst={index === 0}
            isLast={index === day.tasks.length - 1}
          />
        ))}
      </div>
    </div>
  );
};
