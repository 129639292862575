import { Empty, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { ProjectReport } from "../types";

const ProjectHeaderCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  //i did try to use a relative height but it does not work bcs of the parent, so I put a fix height for all of them
  height: 120px;
`;

const ProjectName = styled.span<{ color?: string }>`
  font-weight: bold;
  color: ${(props) => props.color || "#000"};
`;

const TimeInfo = styled.span`
  font-size: 0.8em;
  color: #000;
  font-weight: bold;
`;

interface ProjectHeaderCellProps {
  project: ProjectReport;
  shouldHighlightOpacity: boolean;
  shouldHighlightColor: boolean;
}

const ProjectHeaderCell: React.FC<ProjectHeaderCellProps> = ({
  project,
  shouldHighlightOpacity,
  shouldHighlightColor,
}) => {
  return (
    <ProjectHeaderCellContainer
      style={{
        opacity: shouldHighlightOpacity ? 1 : 0.5,
        backgroundColor: shouldHighlightColor ? "#e6f7ff" : "transparent",
      }}
    >
      <Tooltip title={project?.projectName}>
        <ProjectName color={project?.color}>{project?.projectName}</ProjectName>
      </Tooltip>
      <TimeInfo>
        <>
          {project?.workedHours ? (
            <>{`Worked: ${project?.workedHours}h`}</>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Data"
              style={{ fontSize: "0.8em" }}
            />
          )}
        </>
      </TimeInfo>
    </ProjectHeaderCellContainer>
  );
};

export default ProjectHeaderCell;
