import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useContext } from "react";
import { OfficesContext } from ".";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { Timezone } from "../../_core/types";
import { CommonResourcesContext } from "../Routing/AppRouting";
import { GreyText } from "./styled";
import {countryTypesOptions} from "../../_core/hardcodedValues";
import {CountryTypes} from "../../_core/enums";

export const EditOfficeModal = () => {
  const {
    editOfficeModal,
    setEditOfficeModal,
    handleEditOffice,
    loading,
    officeId,
    currentOffice,
    managers,
  } = useContext(OfficesContext);
  const { timezones } = useContext(CommonResourcesContext);
  const [formInstance] = Form.useForm();

  return (
    <Modal
      visible={editOfficeModal}
      width="600px"
      onCancel={() => setEditOfficeModal(false)}
      footer={null}
      okButtonProps={{
        id: "button__edit-office",
        loading: loading,
      }}
      closeIcon={<RoundCloseIcon />}
    >
      <div style={{ width: "100%" }}>
        <h2 style={{ marginLeft: "auto", marginBottom: "15px" }}>
          Edit {currentOffice.city} Office
        </h2>
      </div>

      <Form
        form={formInstance}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
        // {...formItemLayout}
        onFinish={handleEditOffice}
      >
        <GreyText>City</GreyText>

        <Form.Item
          initialValue={currentOffice.city}
          name="city"
          style={{ width: "100%" }}
          rules={[
            { required: true, message: "Please input a city!" },
            {
              max: 20,
              message: "The city name should not be longer than 20 characters!",
            },
          ]}
        >
          <Input
            id="input__city"
            size="large"
            style={{ borderRadius: 8 }}
            maxLength={20}
            // defaultValue={currentOffice.city}
          />
        </Form.Item>

        <GreyText>Country</GreyText>
          <Form.Item
              initialValue={currentOffice.countryTypes}
              name="countryTypes"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Please input a country!" }]}
          >
              <Select
                  id="input__country"
                  defaultValue={currentOffice.countryTypes}
                  size="large"
                  style={{ borderRadius: 8 }}
                  loading={loading}
              >
                  {countryTypesOptions.map((country: { text: string, value: CountryTypes }) => {
                      return (
                          <Select.Option key={country.value} value={country.value}>
                              {country.text}
                          </Select.Option>
                      );
                  })}
              </Select>
          </Form.Item>

        <GreyText> Address </GreyText>

        <Form.Item
          initialValue={currentOffice.address}
          name="address"
          style={{ width: "100%" }}
          rules={[
            { required: true, message: "Please input the Address!" },
            {
              max: 100,
              message: "The address should not be longer than 100 characters!",
            },
          ]}
        >
          <Input
            id="input__address"
            size="large"
            style={{ borderRadius: 8 }}
            maxLength={100}
            // defaultValue={currentOffice.address}
          />
        </Form.Item>

        <GreyText> Manager </GreyText>

        <Form.Item
          initialValue={currentOffice.managerId}
          name="manager"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input a manager!" }]}
        >
          <Select
            id="input__manager"
            defaultValue={currentOffice.managerId}
            size="large"
            style={{ borderRadius: 8 }}
            showSearch
            optionFilterProp="children"
            loading={loading}
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
            }
          >
            {Object.keys(managers).map((managerId: string) => {
              return (
                <Select.Option key={managerId} value={managerId}>
                  {managers[managerId]}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <GreyText> Time zone </GreyText>

            <Form.Item
              initialValue={currentOffice.timezone}
              name="timezone"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[{ required: true, message: "Please input a timezone!" }]}
            >
              <Select
                id="input__timezone"
                // defaultValue={currentOffice.timezone}
                size="large"
                style={{ borderRadius: 8 }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
                }
              >
                {timezones.map((timezone: Timezone, index) => (
                  <Select.Option key={index} value={timezone.name}>
                    <div style={{ float: "left" }}>{timezone.name}</div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <GreyText> Start Working Hour </GreyText>

            <Form.Item
              initialValue={currentOffice.startWorkingHour}
              name="startWorkingHour"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input the start working hour!",
                },
                {
                  validator(_, value) {
                    if (value > 0 && value < 24) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please input a valid hour! (0-23)");
                  },
                },
              ]}
            >
              <Input
                id="input__startWorkingHour"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={2}
                // defaultValue={currentOffice.startWorkingHour}
              />
            </Form.Item>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <GreyText> Latitude </GreyText>

            <Form.Item
              initialValue={currentOffice.latitude}
              name="latitude"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[
                { required: true, message: "Please input the latitude!" },
                {
                  validator(_, value) {
                    if (value >= 0 && value <= 90) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please input a valid latitude! (0-90)");
                  },
                },
              ]}
            >
              <Input
                id="input__latitude"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={8}
                // defaultValue={currentOffice.latitude}
              />
            </Form.Item>
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "50%" }}>
            <GreyText> Longitude </GreyText>

            <Form.Item
              initialValue={currentOffice.longitude}
              name="longitude"
              style={{ width: "100%", paddingRight: "8px" }}
              rules={[
                { required: true, message: "Please input the longitude!" },
                {
                  validator(_, value) {
                    if (value >= -180 && value <= 180) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please input a valid longitude! (-180 - 180)");
                  },
                },
              ]}
            >
              <Input
                id="input__longitude"
                size="large"
                style={{ borderRadius: 8 }}
                maxLength={8}
                // defaultValue={currentOffice.longitude}
              />
            </Form.Item>
          </div>
        </div>

        <Form.Item style={{ width: "100%" }}>
          <Button
            id="button__save"
            key="Add"
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              borderRadius: 6,
              backgroundColor: "#137AE9",
              height: 48,
              marginTop: 8,
              marginBottom: -8,
            }}
          >
            Save
          </Button>
        </Form.Item>

        {/* <Form.Item>
            <Button key="back" onClick={() => setAddOfficeModal(false)}>
              Cancel
            </Button>
          </Form.Item> */}
      </Form>

      {loading && <Spin tip="Loading..." style={{ marginTop: "24px" }} />}
    </Modal>
  );
};
