import { Avatar, Col, Image, Row } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const NameButton = styled.div`
  font-size: 1.4em;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

interface ISidebarProfileAvatarProps {
  isCollapsed: boolean;
  user: any;
}

export const SidebarProfileAvatar = ({
  isCollapsed,
  user,
}: ISidebarProfileAvatarProps) => {
  return (
    <Link to="/user-profile">
      <Row
        justify="center"
        align="middle"
        style={{
          marginTop: 20,
          marginBottom: 40,
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: window.location.pathname.includes("/user-profile")
            ? "rgba(255, 255, 255, 0.2)"
            : "",
        }}
      >
        <Col
          flex="1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isCollapsed ? (
            <Image
              height="100%"
              preview={false}
              src={user.profilePicturePath}
              style={{
                borderRadius: "100%",
                width: 60,
                height: 60,
                objectFit: "cover",
                cursor: "pointer",
                verticalAlign: "top",
              }}
            />
          ) : (
            <Avatar
              src={
                <Image
                  height="100%"
                  src={user.profilePicturePath}
                  preview={{ mask: <div /> }}
                  style={{
                    borderRadius: "100%",
                    width: 60,
                    height: 60,
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                />
              }
              size={{ xs: 24, sm: 32, md: 40, lg: 60, xl: 60, xxl: 60 }}
            />
          )}
        </Col>
        <Col
          flex="2"
          style={{ display: isCollapsed ? "none" : "block", textAlign: "left" }}
        >
          <NameButton>{`${user.firstName} ${user.lastName}`}</NameButton>
        </Col>
      </Row>
    </Link>
  );
};
