import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserRoles } from "../../_core/hooks/useUserRoles";

export default function AdminRoute({ component: Component, ...rest }: any) {
  const { isAdmin } = useUserRoles();
  return (
    <Route
      {...rest}
      render={(props) => (isAdmin() ? <Component {...props} /> : <Redirect to="/" />)}
    />
  );
}
