import { Col, Row } from "antd";
import React from "react";
import { createContext } from "react";
import { useContext } from "react";
import { UserContext } from "../../App";
import { Item } from "../Inventory/types";
import { DaysOff } from "./DaysOff";
import { Items } from "./Items";
import { Notifications } from "./Notifications";
import { Projects } from "./Projects";
import { GreetingMessage } from "./styledComponents";
import { Tasks } from "./Tasks";
import { DayOff, Notification, Project, Task } from "./types";
import useDashboard from "./useDashboard";

interface DashboardContextInterface {
  projects: Project[];
  loadingProjects: boolean;
  notifications: Notification[];
  loadingNotifications: boolean;
  tasks: Task[];
  loadingTasks: boolean;
  items: Item[];
  loadingItems: boolean;
  daysOff: DayOff[];
  loadingDaysOff: boolean;
  markTaskDone(taskId: string): void;
}
//@ts-ignore
export const DashboardContext = createContext<DashboardContextInterface>();

export const Dashboard = () => {
  const { user } = useContext(UserContext);

  const ret = useDashboard();

  return (
    <DashboardContext.Provider value={ret}>
      <div>
        <GreetingMessage>Hello {user.firstName} 👋🏻. What are you working on today?</GreetingMessage>
        <div>
          <Row>
            <Col span={20}>
              <Row>
                <Col span={24} style={{ padding: "5px", minHeight: "350px" }}>
                  <Projects />
                </Col>
              </Row>
              <Row style={{ minHeight: "50%" }}>
                <Col span={24} style={{ padding: "5px" }}>
                  <Tasks />
                </Col>
              </Row>
            </Col>

            <Col span={4}>
              <Row style={{ padding: "5px", minHeight: "33%" }}>
                <Notifications />
              </Row>
              <Row style={{ padding: "5px", minHeight: "33%" }}>
                <DaysOff />
              </Row>
              <Row style={{ padding: "5px", minHeight: "33%" }}>
                <Items />
              </Row>
            </Col>

          </Row>
        </div>
      </div>
    </DashboardContext.Provider>
  );
};
