import { Modal, Form, Input, Button, message } from "antd";
import React, { useContext, useEffect } from "react";
import { AllBrandsContext } from ".";
import { RoundCloseIcon } from "../../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../../styles/StyledComponents";
import { Brand } from "../../types";
interface BrandDialogProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
  brand?: Brand;
}
export const BrandDialog = (props: BrandDialogProps) => {
  const { isVisible, setIsVisible, brand } = props;
  const [form] = Form.useForm();
  const { addBrand, isLoading, editBrand } = useContext(AllBrandsContext);
  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };
  useEffect(() => {
    if (brand) {
      form.setFieldsValue({ name: brand.name });
    }
  }, [brand]);
  const handleFinish = async (values: { name: string }) => {
    try {
      if (brand) {
        await editBrand(brand.id, values.name);
      } else {
        await addBrand(values.name);
        message.success("New brand added");
      }
      handleCancel();
    } catch (err) {
      console.warn(err);
    }
  };
  return (
    <Modal visible={isVisible} onCancel={handleCancel} footer={null} closeIcon={<RoundCloseIcon />}>
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        {brand ? "Edit brand" : "Add a new Brand"}
      </div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="name"
          label={<FormLabel>Brand name</FormLabel>}
          rules={[{ required: true, message: "Please input a brand!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            htmlType="submit"
            block
            type="primary"
            style={{ borderRadius: 5, border: "none", backgroundColor: brand ? "#00AD0D" : "" }}
            size="large"
          >
            {brand ? "Save Changes" : "Add Brand"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
