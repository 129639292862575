import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserRoles } from "../../_core/hooks/useUserRoles";

export default function OperationalRoute({ component: Component, ...rest }: any) {
    const { isAdmin, isOperationalUser } = useUserRoles();
    return (
        <Route
            {...rest}
            render={(props) => (isAdmin() || isOperationalUser() ? <Component {...props} /> : <Redirect to="/" />)}
        />
    );
}
