import React, { useContext } from "react";
import TimeInput from "../../common/TimeInput";
import { EditSessionContext } from "../Session";
import {
  isLoadingAnUpdate,
  minutesToTimeString,
  timeStringToMinutes,
} from "./utils";

interface TotalTimeProps {
  isHovered: boolean;
}

export const TotalTime: React.FC<TotalTimeProps> = ({ isHovered }) => {
  const { sessionState, setSessionState, loading } =
    useContext(EditSessionContext);

  const handleBlur = (val?: string) => {
    if (val) {
      const minutes = timeStringToMinutes(val);
      setSessionState({
        ...sessionState,
        time: minutes,
      });
    }
  };

  return (
    <TimeInput
      valueProps={minutesToTimeString(sessionState.time)}
      style={{
        borderColor: isHovered ? "black" : "white",
        width: "100%",
        fontWeight: 700,
        fontSize: "18px",
        textAlign: "center",
      }}
      onBlur={handleBlur}
      onEnter={handleBlur}
      isDisabled={isLoadingAnUpdate(loading)}
      disableHoursConstraint
    />
  );
};
