import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { userStatusesEnum } from "../../../_core/hardcodedValues";
import { Employee } from "../types";

const useEmployees = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { apiClient } = useContext(ApiClientContext);

  const fetchEmployees = async (userStatus: number) => {
    try {
      setIsLoading(true);
      let { data } = await apiClient.get(`/api/users?status=${userStatus}`);
      setEmployees(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      if (err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Could not get employees");
      }
    }
  };

  const resendInvitation = async (row: Employee) => {
    try {
      const { data } = await apiClient.get(`/api/account/resend-invite/${row.email}`);
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  useEffect(() => {
    fetchEmployees(userStatusesEnum.Active);
  }, []);

  return {
    employees,
    isLoading,
    fetchEmployees
  };
};

export default useEmployees;
