import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Item } from "../../types";

const useAllMyItems = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { apiClient } = useContext(ApiClientContext);

  const fetchMyItems = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/items/my-items`);
      setItems(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      message.error("Could not fetch items");
    }
  };

  const requestItem = async (description: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post(`/api/items/request-item`, { description });
      setIsLoading(false);
      return data;
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      throw err;
    }
  };
  useEffect(() => {
    fetchMyItems();
  }, []);

  return {
    items,
    isLoading,
    requestItem,
    fetchMyItems,
  };
};

export default useAllMyItems;
