import { Button, Modal } from "antd";
import React, { useContext, useState } from "react";
import { EditSessionModalContext } from "./types";
import { DeleteOutlined } from "@ant-design/icons";
import { TimeTrackingUserDataContext } from "../../../../TimeTrackingUserDataProvider";
interface Props {
  sessionId: string;
  onClose: () => void;
}
const ModalFooter: React.FC<Props> = ({ sessionId, onClose }) => {
  const [isDeleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
  const { onSubmit } = useContext(EditSessionModalContext);
  const { deleteSession } = useContext(TimeTrackingUserDataContext);
  const handleDeleteEvent = async (id: any) => {
    deleteSession(id);
    setDeleteConfirmVisible(false);
    onClose();
  };

  const showDeleteConfirmation = () => {
    setDeleteConfirmVisible(true);
  };
  return (
    <>
      <div
        style={{
          marginTop: 20,
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button
          onClick={showDeleteConfirmation}
          style={{
            color: "red",
            backgroundColor: "red",
          }}
        >
          <DeleteOutlined
            style={{
              color: "white",
            }}
          />
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Button
          style={{
            width: "10%",
            minWidth: "100px",
            marginRight: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 8,
            backgroundColor: "white",
            fontSize: 20,
            color: "black",
            textAlign: "center",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit()}
          style={{
            width: "10%",
            minWidth: "100px",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#137AE9",
            fontSize: 20,
            color: "white",
          }}
        >
          Save
        </Button>
      </div>
      <Modal
        title="Confirm Deletion"
        visible={isDeleteConfirmVisible}
        onOk={() => handleDeleteEvent(sessionId)}
        onCancel={() => setDeleteConfirmVisible(false)}
        okText="Delete"
        okType="danger"
        cancelButtonProps={{ style: { marginRight: "10px" } }}
        okButtonProps={{
          style: { background: "red", borderColor: "red", color: "white" },
        }}
        centered
      >
        <p style={{ fontSize: "16px", color: "#9e9e9e" }}>
          Are you sure you want to delete this session? This action cannot be
          undone.
        </p>
      </Modal>
    </>
  );
};
export default ModalFooter;
function deleteSession(id: any) {
  throw new Error("Function not implemented.");
}

function setDeleteConfirmVisible(arg0: boolean) {
  throw new Error("Function not implemented.");
}
