import { Card, Row, Space, Spin, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { ProfileContext } from ".";
import { Technology } from "./types";
import { getUserPosition } from "../../_core/hardcodedValues";
import { CardTitle } from "../../styles/StyledComponents";

export const Technologies = () => {
  const { profile, isLoading } = useContext(ProfileContext);

  return (
    <Spin spinning={isLoading}>
      <Card
        type="inner"
        style={{ marginBottom: 10 }}
        title={<CardTitle>Technologies</CardTitle>}
      >
        {profile?.technologies.map((technology: Technology) => (
          <Row key={technology.technologyId}>
            <Space size="middle">
              <Typography.Text type="secondary">
                Technology/Level
              </Typography.Text>
              <Typography.Text>
                {`${technology.technologyName}, 
              ${getUserPosition(technology.userPosition)}`}
              </Typography.Text>
            </Space>
          </Row>
        ))}
      </Card>
    </Spin>
  );
};
