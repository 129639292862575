import React, { useContext, useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import { TableHeader, TableText } from "../../../../styles/StyledComponents";
import { AllItemsContext } from ".";
import { Item } from "../../types";
import { OpenFieldIcon } from "../../../../assets/icons/OpenFieldIcon";
import { useHistory } from "react-router";
import { EditFieldIcon } from "../../../../assets/icons/EditFieldIcon";
import { DeleteFieldIcon } from "../../../../assets/icons/DeleteFieldIcon";
import { ItemDialog } from "./ItemDialog";

export const ItemsTable = () => {
  const { items, isLoading, deleteItem, searchInputValue } = useContext(AllItemsContext);
  const history = useHistory();
  const [editDialog, setEditDialog] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Item>();

  const filterItems = () => {
    return items.filter((item) => {
      return (
        item.inventoryNumber.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.description.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.ownedByFirstName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.ownedByLastName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.officeCity.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.modelName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        item.brandName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) 
      );
    });
  };

  const handleDelete = (item: Item) => {
    if (item.ownedByFirstName) {
      Modal.confirm({
        title: "You can not delete this model",
        icon: null,
        content: (
          <div>
            <span style={{ color: "#FF3E3E" }}>
              This item is assigned to {`${item.ownedByFirstName} ${item.ownedByLastName}`}
            </span>
            <br /> Before you delete this item make sure you change it’s status to Available
          </div>
        ),
        okButtonProps: { style: { display: "none" } },
        cancelText: "I understand",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#137AE9",
          },
        },
      });
    } else {
      Modal.confirm({
        title: "You are about to delete a item",
        icon: null,
        content: "This action can not be reversed. Are you sure you want to delete this item?",
        okText: "Yes, delete item",
        okButtonProps: {
          style: {
            backgroundColor: "#FF3E3E",
            color: "white",
            borderRadius: "5px",
            border: "none",
          },
        },
        onOk() {
          return deleteItem(item.id);
        },
        cancelText: "Cancel action",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#A8A8A8",
          },
        },
      });
    }
  };
  return (
    <div>
      <Table loading={isLoading} dataSource={filterItems()} rowKey="id" pagination={false}>
        <Table.Column<Item>
          title={<TableHeader>Item NR</TableHeader>}
          dataIndex="inventoryNumber"
          render={(itemNr) => <TableText>{itemNr}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Item Description</TableHeader>}
          dataIndex="description"
          render={(desc) => <TableText>{desc}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Model & Brand</TableHeader>}
          render={(val, row) => (
            <div>
              <div
                style={{ color: "#0F172A", fontSize: "14", lineHeight: "17px", fontWeight: 600 }}
              >
                {row.modelName}
              </div>
              <div
                style={{ color: "#64748B", fontSize: "12", lineHeight: "18px", fontWeight: 300 }}
              >
                {row.brandName}
              </div>
            </div>
          )}
        />
        <Table.Column<Item>
          title={<TableHeader>Office</TableHeader>}
          dataIndex="officeCity"
          render={(office) => <TableText>{office}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Owned by</TableHeader>}
          render={(val, row) => {
            if (row.brokenDate && row.brokenDate !== "0001-01-01T00:00:00") {
              return <TableText style={{ color: "#FF3E3E" }}>Broken</TableText>;
            } else {
              if (row.ownedByFirstName) {
                return <TableText>{`${row.ownedByFirstName} ${row.ownedByLastName} `}</TableText>;
              } else {
                return <TableText style={{ color: "#00AD0D" }}>Available</TableText>;
              }
            }
          }}
        />
        <Table.Column<Item>
          title={<TableHeader>Warranty</TableHeader>}
          dataIndex="warranty"
          render={(warranty) => <TableText>{warranty}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => (
            <div>
              <Tooltip title="Details">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/inventory/items/${row.id}`)}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <EditFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setItemToEdit(row);
                    setEditDialog(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteFieldIcon style={{ cursor: "pointer" }} onClick={() => handleDelete(row)} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      <ItemDialog isVisible={editDialog} setIsVisible={setEditDialog} item={itemToEdit} />
    </div>
  );
};
