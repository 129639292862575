export const formatMinToHoursString = (minutes?: number): string => {
  if (minutes) {
    const hours = minutes / 60;
    const rHours = Math.floor(hours);
    const min = (hours - rHours) * 60;
    const rMin = Math.round(min);
    return `${rHours}h ${rMin} min`;
  } else {
    return "-";
  }
};
