import { TriggerEventHandler } from "rc-table/lib/interface";

export interface ActiveSession {
  sessionId: string;
  taskId: string;
  projectId: string;
  technologyId: string;
  sessionType: number;
  startTime: string;
}

export interface APIProjects {
  projects: Array<Project>;
  activeSession: ActiveSession;
}

export interface Project {
  projectId: string;
  projectName: string;
  color: string;

  tasks: Array<Task>;
  technologies: Array<Technology>;
}

export interface Task {
  taskId: string;
  title: string;
  description: string;
  projectId: string;
  technologyId: string;
}

export interface Technology {
  id: string;
  name: string;
}

export type HistoryResponse = Array<TimeTrackingWeek>;

export const typesDict = {
  0: "Other",
  10: "Coding",
  20: "Client Call",
  25: "Internal Call",
  30: "Brainstorming",
  40: "Documentation",
  50: "Testing",
  60: "Code Review",
  70: "Configuring",
  80: "Management",
  90: "Internal Training",
  100: "External Training",
  110: "Design",
};

export const reasonDict = {
  0: "Unknown",
  100: "Maternal",
  200: "Holiday",
  300: "Sick time",
  400: "Study",
};

export interface TimeTrackingWeek {
  start: string;
  end: string;
  total: number;

  days: Array<TimeTrackingDay>;
}

export interface TimeTrackingDay {
  day: string;
  total: number;

  tasks: Array<TimeTrackingTask>;
}
export interface TimeTrackingTask {
  clientName: string;
  description: string;
  projectName: string;
  projectId: string;
  taskId: string;
  taskName: string;
  technology: string;
  technologyId: string;
  time: number;
  color: string;

  sessions: Array<TimeTrackingSession>;
}

export interface TimeTrackingSession {
  id: string;
  start: string;
  end: string;
  time: number;
  type: number;
}
