import { Button, Card, Form, Input, Modal, Row, Space, Typography, Select, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ProfileContext } from ".";
import { getInterestTechnologyReason, interestTechnologyReasons } from "../../_core/hardcodedValues";
import { DeleteFieldIcon } from "../../assets/icons/DeleteFieldIcon";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { CardTitle, FormLabel } from "../../styles/StyledComponents";

const OutlineButton = styled(Button)`
  color: #137ae9;
  border-color: #137ae9;
  border-radius: 5px;
`;
export const InterestTechnologies = () => {
  const { profile, isLoading, addInterestTechnology, deleteInterestTechnology } =
    useContext(ProfileContext);
  const [addInterestTechnologyDialog, setAddInterestTechnologyDialog] = useState(false);
  const [form] = Form.useForm();
  const handleCancelDialog = () => {
    setAddInterestTechnologyDialog(false);
    form.resetFields();
  };
  const handleFinish = async (values: { name: string; reason: number }) => {
    try {
      await addInterestTechnology(values);
      handleCancelDialog();
    } catch (err) {}
  };
  return (
    <div>
      {profile?.otherTechnologies.length ? (
        <Card type="inner" title={<CardTitle>Interest Technologies</CardTitle>}>
          {profile.otherTechnologies.map((technology) => (
            <Row key={technology.id} justify="space-between">
              <Space size="middle">
                <Typography.Text type="secondary">Technology/Reason</Typography.Text>
                <Typography.Text>{`${technology.name}, ${getInterestTechnologyReason(
                  technology.reason
                )}`}</Typography.Text>
              </Space>
              <Tooltip title="Delete">
                <DeleteFieldIcon onClick={() => deleteInterestTechnology(technology.id)} />
              </Tooltip>
            </Row>
          ))}
          <div style={{ display: "flex", justifyContent: "end", marginTop: 10 }}>
            <OutlineButton onClick={() => setAddInterestTechnologyDialog(true)}>
              Add interest technology
            </OutlineButton>
          </div>
        </Card>
      ) : (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <OutlineButton onClick={() => setAddInterestTechnologyDialog(true)}>
            Add interest technology
          </OutlineButton>
        </div>
      )}

      <Modal
        visible={addInterestTechnologyDialog}
        footer={null}
        onCancel={handleCancelDialog}
        closeIcon={<RoundCloseIcon />}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "25px",
            textAlign: "left",
            position: "relative",
            bottom: 10,
          }}
        >
          Add interest technology
        </div>
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="name"
            label={<FormLabel>Technology</FormLabel>}
            rules={[{ required: true, message: "Please input a technology" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="reason"
            label={<FormLabel>Reason</FormLabel>}
            rules={[{ required: true, message: "Please select a reason" }]}
          >
            <Select>
              {interestTechnologyReasons.map((reason) => (
                <Select.Option key={reason.name} value={reason.value}>
                  {reason.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              htmlType="submit"
              block
              type="primary"
              style={{ borderRadius: 5 }}
              size="large"
            >
              Add technology
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
