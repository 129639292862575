import {useContext} from "react";
import { message, Form } from "antd";
import { ApiClientContext } from "../../../../App";

export const useTimeOffActions = () => {
  const { apiClient } = useContext(ApiClientContext);

  const approveTimeOffRequest = async (requestId: string) => {
    try {
      await apiClient.put(`/api/time-off/approve/${requestId}`);
     // getTimeOffRequests(timeOffUserId);
      message.success("Request approved.");
    } catch (error: any) {
      console.log(error)
      message.error(error?.data?.message || 'Sorry, there was an error.');
    }
    return;
  };

  const declineTimeOffRequest = async (requestId: string) => {
    try {
      await apiClient.put(`/api/time-off/decline/${requestId}`);
      //getTimeOffRequests(timeOffUserId);
      message.success("Request declined.");
    } catch (error: any) {
      message.error(error?.data?.message || 'Sorry, there was an error.');
    }
    return;
  };

  const deleteTimeOffRequest = async (requestId: string) => {
    try {
      await apiClient.delete(`/api/time-off/delete/${requestId}`);
     // getTimeOffRequests(timeOffUserId);
      message.success("Request deleted.");
    } catch (error: any) {
      message.error(error?.data?.message || 'Sorry, there was an error.');
    }
    return;
  };


  return {
    approveTimeOffRequest,
    declineTimeOffRequest,
    deleteTimeOffRequest,
  };
};
