import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { AllItems } from "./AllItems";
import { ManageItem } from "./ManageItem";

export const Items = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={AllItems} />
      <Route exact path={`${path}/:itemId`} component={ManageItem} />
    </Switch>
  );
};
