import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React from "react";
import { useContext } from "react";
import { ProjectsContext } from "..";
import { SearchIcon } from "../../../assets/icons/SearchIcon";

export const SearchBar = () => {
  const { onSearch } = useContext(ProjectsContext);
  return (
    <Input
      style={{ borderRadius: 5 }}
      prefix={<SearchIcon />}
      placeholder="Search"
      allowClear
      onChange={(e) => onSearch(e.target.value)}
    />
  );
};
