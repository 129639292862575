import { DatePicker } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import TimeInput from "../../../common/TimeInput";
import { EditSessionModalContext } from "./types";

const formatWithLeadingZero = (num: number) =>
  num < 10 ? "0" + num : num.toString();

const CalendarTimeInput: React.FC = () => {
  const { startTime, setStartTime, endTime, setEndTime, day, setDay } =
    useContext(EditSessionModalContext);

  const [intervalDuration, setIntervalDuration] = useState<string>("0:30");
  const showPlusOneBadge = useMemo<boolean>(() => {
    return moment(endTime, "HH:mm").isBefore(moment(startTime, "HH:mm"));
  }, [startTime, endTime]);

  const recalculateIntervalDuration = (start: string, end: string) => {
    //if either one of them is undefined then use the internal state
    //if we use only the internal state on blur then we will have the precendence of the previous value
    const [startHour, startMinute] = start.split(":");
    const [endHour, endMinute] = end.split(":");
    let hoursDuration = parseInt(endHour) - parseInt(startHour);
    let minutesDuration = parseInt(endMinute) - parseInt(startMinute);

    if (isNaN(minutesDuration)) minutesDuration = 0;
    if (isNaN(hoursDuration)) hoursDuration = 0;
    if (hoursDuration < 0) hoursDuration = 24 + hoursDuration;
    if (minutesDuration < 0) {
      minutesDuration = 60 + minutesDuration;
      if (hoursDuration === 0) hoursDuration = 23;
      else hoursDuration--;
    }

    setIntervalDuration(
      formatWithLeadingZero(hoursDuration) +
        ":" +
        formatWithLeadingZero(minutesDuration)
    );
  };

  const handleDurationBlur = (duration?: string) => {
    const [hours, minutes] = duration?.split(":") || ["0", "0"];
    const newEndTime = moment(startTime, "HH:mm")
      .add(parseInt(hours), "hours")
      .add(parseInt(minutes), "minutes")
      .format("HH:mm");
    setEndTime(newEndTime);
  };
  const handleDurationChange = (value: string) => {
    setIntervalDuration(value);
  };

  const getDisabledDate = (currentDate: moment.Moment) => {
    const now = moment();
    return (
      currentDate &&
      (currentDate > now.endOf("day") || currentDate.month() !== now.month())
    );
  };

  const handleStartBlur = (time?: string) => {
    setStartTime(time || "");
  };

  const handleEndBlur = (time?: string) => {
    setEndTime(time || "");
  };

  useEffect(() => {
    recalculateIntervalDuration(startTime, endTime);
  }, [startTime, endTime]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          marginLeft: 6,
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: 10,
          }}
        >
          <div
            style={{
              position: "relative",
              width: "calc(50% - 3px)",
              marginRight: 6,
              height: "32px",
            }}
          >
            <TimeInput
              placeholder="Start"
              style={{ width: "100%", textAlign: "center" }}
              onBlur={handleStartBlur}
              valueProps={startTime}
            />
          </div>
          <div
            style={{
              position: "relative",
              width: "calc(50% - 3px)",
            }}
          >
            <TimeInput
              style={{ width: "100%", textAlign: "center" }}
              placeholder="End"
              onBlur={handleEndBlur}
              valueProps={endTime}
            />
            {showPlusOneBadge && (
              <span
                style={{
                  position: "absolute",
                  top: -10,
                  backgroundColor: "white",
                  right: 0,
                  color: "black",
                  borderRadius: "100%",
                }}
              >
                +1
              </span>
            )}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DatePicker
            style={{
              width: "calc(50% - 3px)",
              marginRight: 6,
              borderRadius: 5,
            }}
            defaultPickerValue={moment()}
            value={day ? moment(day) : null}
            defaultValue={moment()}
            onSelect={(value) => {
              setDay(value);
            }}
            disabledDate={getDisabledDate}
          />

          <div
            style={{
              flex: 1,
              border: "1px solid #ffffff",
              borderRadius: "5px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              backgroundColor: "#ececec",
              paddingLeft: 10,
            }}
          >
            <span>Total: </span>
            <TimeInput
              onBlur={handleDurationBlur}
              onChange={handleDurationChange}
              valueProps={intervalDuration}
              style={{ flex: 1 }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CalendarTimeInput;
