import { Button, Tooltip } from "antd";
import { useContext } from "react";
import {
  LiveTrackIcon,
  ManualTrackIcon,
  PlayIcon,
} from "../../../../../assets/icons/CustomIcons";
import { TrackerContext } from "./utils/types";
import { CalendarOutlined } from "@ant-design/icons";
const TrackerModeSwitch = () => {
  const { typeOfTracking, switchTracker } = useContext(TrackerContext);
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "40px",
          marginBottom: "12px",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Tooltip title="Track time live">
          <Button
            onClick={() => switchTracker("Auto")}
            style={{
              border: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <LiveTrackIcon
              color={typeOfTracking === "Auto" ? "#137AE9" : "gray"}
            />
          </Button>
        </Tooltip>
        <Tooltip title="Track time manually">
          <Button
            onClick={() => switchTracker("Manual")}
            style={{ border: "none" }}
          >
            <ManualTrackIcon
              color={typeOfTracking === "Manual" ? "#137AE9" : "gray"}
            />
          </Button>
        </Tooltip>
        <Button
          style={{ border: "none" }}
          onClick={() => switchTracker("Calendar")}
        >
          <CalendarOutlined
            color={typeOfTracking === "Calendar" ? "#137AE9" : "gray"}
            style={{
              //FONT SIZE TO CHANGE THE ACTUAL ICON SIZE
              fontSize: 20,
              color: typeOfTracking === "Calendar" ? "#137AE9" : "gray",
              strokeWidth: "40px",
              stroke: typeOfTracking === "Calendar" ? "#137AE9" : "gray",
            }}
          />
        </Button>
      </div>
    </>
  );
};
export default TrackerModeSwitch;
