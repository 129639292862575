import { Empty } from "antd";
import React from "react";

export const NoReport = () => {
  return (
    <div style={{ marginTop: 100 }}>
      <Empty
        description={
          <span>
            No report found <br />
            Please select an employee
          </span>
        }
      />
    </div>
  );
};
