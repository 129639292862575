import {TimeOffQueryParamsFilters} from "../interfaces/time-off-general.interface";
import moment, {Moment} from "moment/moment";
import {TimeOffView} from "../enums/time-off-view.enum";

export class UrlQueryParamsHelper {
    static initializeFilters(queryParams: any): TimeOffQueryParamsFilters {
        return {
            selectedStatus: queryParams['status'] || undefined,
            dateRange: queryParams['startDate'] && queryParams['endDate'] ? [moment(queryParams['startDate']), moment(queryParams['endDate'])] : [],
            showHistory: queryParams['showHistory'] || true,
            pageNumber: queryParams['page'] || 1,
            selectedUsers: typeof queryParams['user'] === 'string' ? [queryParams['user']] : queryParams['user'] || [],
            selectedProjects: typeof queryParams['project'] === 'string' ? [queryParams['project']] : queryParams['project'] || [],
        }
    }

    static setFiltersInQueryParams(
        dateRange: Moment[], selectedStatus: number | undefined, pageNumber: number, selectedUsers: string[], selectedProjects: string[], selectedUser: string, selectedView: TimeOffView
    ): void {
        let newUrl =  window.location.protocol + "//" + window.location.host + window.location.pathname;
        let params = '';

        if(selectedUser) {
            params += `&userId=${selectedUser}`
        }

        if(selectedView === TimeOffView.Table && dateRange?.length === 2) {
            params += `&startDate=${moment(dateRange[0]).format('MM-DD-YYYY')}&endDate=${moment(dateRange[1]).format('MM-DD-YYYY')}`
        }

        if(selectedStatus) {
            params += `&status=${selectedStatus}`
        }

        if(pageNumber && selectedView === TimeOffView.Table) {
            params += `&page=${pageNumber}`
        }

        if(selectedUsers?.length) {
            selectedUsers.forEach(user => {
                params += '&user=' + user;
            })
        }

        if(selectedProjects?.length) {
            selectedProjects.forEach(project => {
                params += '&project=' + project;
            })
        }

        if(params) {
            newUrl += '?' + params.replace('&', '');
        }

        window.history.pushState({path: newUrl},'', newUrl);
    }
}
