import { Button, Col, message, Row, Typography } from "antd";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { TransferItemDialog } from "../../TransferItemDialog";
import { Item } from "../../types";
import { ItemDetails } from "./ItemDetails";
import useManageMyItem from "./useManageMyItem";
interface ManageMyItemContextInterface {
  item?: Item;
  isLoading: boolean;
}
//@ts-ignore
export const ManageMyItemContext = React.createContext<ManageMyItemContextInterface>();
export const ManageItem = () => {
  const { itemId } = useParams<{ itemId: string }>();

  const ret = useManageMyItem(itemId);
  const history = useHistory();
  const [transferItemDialog, setTransferItemDialog] = useState(false);
  const transferCallback = () => {
    message.success("Item transferred");
    history.push("/inventory/my-items");
  };
  return (
    <ManageMyItemContext.Provider value={ret}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Title level={4}>Manage Item</Typography.Title>
        </Col>
        <Col>
          <Button
            type="primary"
            size="large"
            style={{ borderRadius: 5 }}
            onClick={() => setTransferItemDialog(true)}
          >
            Transfer Item
          </Button>
        </Col>
      </Row>
      <ItemDetails />
      <TransferItemDialog
        isVisible={transferItemDialog}
        setIsVisible={setTransferItemDialog}
        itemId={itemId}
        callback={transferCallback}
      />
    </ManageMyItemContext.Provider>
  );
};
