import { TimeTrackingSession, typesDict } from "../../../types";
import { SessionEditDTO, SessionUpdateLoading } from "./types";

export const mapToTimeTrackingSession = (
  dto: SessionEditDTO
): TimeTrackingSession => {
  return {
    id: dto.id,
    start: dto.start,
    end: dto.end,
    time: dto.timeSpan,
    technology: dto.technologyName,
    technologyId: dto.technologyId,
    type: dto.sessionType as keyof typeof typesDict,
  };
};

export const isLoadingAnUpdate = (
  loading: Partial<SessionUpdateLoading>,
  excludeKey?: keyof SessionUpdateLoading
): boolean => {
  return Object.entries(loading)
    .filter(([key]) => key !== excludeKey)
    .some(([, value]) => value === true);
};

export const timeStringToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + (minutes || 0);
};

export const minutesToTimeString = (minutes: number): string => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};

export const isValidTimeInput = (val: string) => {
  const regex24 = /^([01]?[0-9]|2[0-3])?(:[0-5]?[0-9]?)?$/;
  if (regex24.test(val) || val === "") {
    return false;
  }
  return true;
};
