import React from "react";
import { useTracker } from "./useTracker";
import { TrackerContext } from "./utils/types";
const TrackerProvider: React.FC = ({ children }) => {
  const tracker = useTracker();
  return (
    <TrackerContext.Provider value={tracker}>
      {children}
    </TrackerContext.Provider>
  );
};
export default TrackerProvider;
