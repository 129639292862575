import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Item } from "../../types";

const useAllItems = () => {
  const [items, setItems] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const { apiClient } = useContext(ApiClientContext);
  const fetchItems = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/items`);
      setItems(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      message.error("Could not fetch items");
    }
  };

  const addItem = async (formValues: any) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post("/api/items", formValues);
      fetchItems();
      message.success("Item added successfully");
    } catch (err : any) {
      setIsLoading(false);
      message.error(err.response.data.message);
    }
  };

  const editItem = async (formValues: any, itemId: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.put(`/api/items/update/${itemId}`, formValues);
      fetchItems();
      message.success("Item edited successfully");
    } catch (err : any) {
      setIsLoading(false);
      message.error(err.response.data.message);
    }
  };
  const deleteItem = async (itemId: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.delete(`/api/items/${itemId}`);
      fetchItems();
      message.success("Item deleted successfully");
    } catch (err : any) {
      setIsLoading(false);
      message.error(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return {
    items,
    isLoading,
    addItem,
    editItem,
    deleteItem,
    searchInputValue,
    setSearchInputValue,
  };
};

export default useAllItems;
