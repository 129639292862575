import { Badge, Button, Card, Col, Row, Spin, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React from "react";
import { useContext } from "react";
import { DashboardContext } from ".";
import { CardTitle } from "../../styles/StyledComponents";
import { Notification } from "./types";

export const Notifications = () => {
  const { notifications, loadingNotifications } = useContext(DashboardContext);
  return (
    <Card
      title={<CardTitle>Notifications</CardTitle>}
      type="inner"
      style={{ width: "100%" }}
      extra={
        notifications.length > 0 ? <Button style={{ padding: 0, margin: 0 }} type="link">
          Read all
        </Button> : null
      }
      bodyStyle={{
        height: "100%",
        maxHeight: "25vh",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Spin spinning={loadingNotifications}>
        {notifications.length > 0 ? (
          notifications.map((notification: Notification) => (
            <Row
              style={{ margin: 5 }}
              justify="space-between"
              wrap={false}
              //@ts-ignore
              key={notification.id}
            >
              <Col>
                <Row wrap={false}>
                  <Col>
                    <Badge dot={!notification.seen}>
                      <Avatar> {notification.firstName[0]}</Avatar>
                    </Badge>
                  </Col>
                  <Col style={{ textAlign: "left" }}>
                    <div
                      style={{
                        fontSize: "1.1em",
                        fontWeight: "bolder",
                        marginLeft: "3px",
                      }}
                    >
                      {`${notification.firstName} ${notification.lastName}`}
                    </div>
                    <Typography.Text type="secondary">{notification.text}</Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col>{moment(notification.seenAt).format("Do MMM")}</Col>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
              No notifications!
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
