import { DeleteOfficeModal } from "./DeleteOfficeModal";
import { SearchOutlined } from "@ant-design/icons";
import { AddOfficeModal } from "./AddOfficeModal";
import { OfficesTable } from "./OfficesTable";
import { useOffices } from "./hooks";
import { Button, Input, Skeleton } from "antd";
import React, { useContext } from "react";
import { useWindowSize } from "../../_core/hooks/useWindowSize";
import { OfficesTableMobile } from "./OfficesTableMobile";
import { UserContext } from "../../App";
import { EditOfficeModal } from "./EditOfficeModal";
import { createHook } from "async_hooks";
import { useUserRoles } from "../../_core/hooks/useUserRoles";

export const OfficesContext = React.createContext<any>(null);

export const Offices = () => {
  const offices = useOffices();
  const { setAddOfficeModal, loading, searchValue, setSearchValue, editOfficeModal } = offices;
  const { width } = useWindowSize();
  const { isAdmin } = useUserRoles();
  const { user } = useContext(UserContext);

  return (
    <div style={{ display: "flex", flexDirection: "column" }} data-testid="all-offices-01">
      <OfficesContext.Provider value={offices}>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <div
              style={{
                justifyContent: "space-between",
                flexFlow: "row wrap",
                minWidth: "200px",
                display: "flex",
              }}
            >
              {isAdmin() && (
                <Button
                  id="button__add"
                  type="primary"
                  onClick={() => {
                    setAddOfficeModal(true);
                  }}
                  style={{
                    borderColor: "white",
                    borderRadius: 4,
                    backgroundColor: "#00AD0D",
                    color: "#FFF",
                    paddingLeft: 40,
                    paddingRight: 40,
                  }}
                >
                  Add office
                </Button>
              )}
            </div>
            {/* {width > 600 ? <OfficesTable /> : <OfficesTableMobile />} */}
            <OfficesTable />
            <AddOfficeModal />
            {editOfficeModal ? <EditOfficeModal /> : null}
            {/* <DeleteOfficeModal /> */}
          </>
        )}
      </OfficesContext.Provider>
    </div>
  );
};
