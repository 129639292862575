import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Model } from "../../types";

const useManageModel = (modelId: string) => {
  const [model, setModel] = useState<Model>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);
  const fetchModel = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/items/get-model-by/${modelId}`);
      setModel(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      message.error("Could not fetch project");
    }
  };

  useEffect(() => {
    fetchModel();
  }, []);
  return {
    model,
    isLoading,
  };
};

export default useManageModel;
