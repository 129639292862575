import { Row, Table, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AllEmployeesContext } from ".";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { TableHeader, TableText } from "../../../styles/StyledComponents";
import { Employee, Technology, UserLegalPositionEnum } from "../types";
import "./styles.css";
import { getUserStatus } from "../../../_core/hardcodedValues";
import { TableEmployee } from "../../Utils/TableEmployee";

export const EmployeesTable: React.FC<{ employees: any[] }> = ({
  employees,
}) => {
  const { isLoading } = useContext(AllEmployeesContext);
  const { isAdmin, isOperationalUser } = useUserRoles();
  const history = useHistory();

  return (
    <Table
      style={{ marginTop: 32 }}
      rowKey="id"
      dataSource={employees}
      loading={isLoading}
      pagination={false}
    >
      <Table.Column<Employee>
        title={<TableHeader>Employee</TableHeader>}
        width={"30%"}
        render={(val, row) => (
          <TableEmployee employee={row} history={history} />
        )}
      />
      {isAdmin() || isOperationalUser() ? (
        <Table.Column<Employee>
          width={"10%"}
          title={<TableHeader>Status</TableHeader>}
          render={(row) => (
            <TableText
              style={{
                color:
                  row.userStatus === 10
                    ? "orange"
                    : row.userStatus === 20
                    ? "green"
                    : row.userStatus === 30
                    ? "red"
                    : "",
              }}
            >
              {getUserStatus(row.userStatus)}
            </TableText>
          )}
        />
      ) : null}
      <Table.Column
        width={"10%"}
        title={<TableHeader>Phone</TableHeader>}
        dataIndex="phoneNumber"
      />
      <Table.Column<Employee>
        width={"10%"}
        title={<TableHeader>Office</TableHeader>}
        render={(val, row) => row.office.city}
      />
      <Table.Column<Employee>
        width={"10%"}
        title={<TableHeader>Legal Position</TableHeader>}
        render={(val, row) => UserLegalPositionEnum[row.legalPosition || 0]}
      />
      <Table.Column<Employee>
        title={<TableHeader>Car</TableHeader>}
        width={"15%"}
        render={(val, row) => (
          <>
            {row.vehiclePlates ? (
              <div>
                {`${row.vehicleDescription ? row.vehicleDescription : ""} ${
                  row.vehiclePlates ? row.vehiclePlates : ""
                }`}
              </div>
            ) : (
              <div style={{ fontSize: 12, fontStyle: "italic", color: "grey" }}>
                N/A
              </div>
            )}
          </>
        )}
      />
      <Table.Column<Employee>
        title={<TableHeader>Technologies</TableHeader>}
        width={"25%"}
        render={(val, row) => {
          if (row.userTechnologies.length) {
            return row.userTechnologies.map((tech: Technology, index) => (
              <span
                style={{
                  fontSize: "1.1em",
                  color: "gray",
                }}
                key={tech.technologyId}
              >
                {tech.technologyName}
                {row.userTechnologies.length > index + 1 ? ", " : ""}
              </span>
            ));
          } else {
            return (
              <Row>
                <Typography.Text type="secondary">
                  No technologies
                </Typography.Text>
              </Row>
            );
          }
        }}
      />
    </Table>
  );
};
