import React from "react";
import { Select } from "antd";
import styled from "styled-components";
import { useReportsFilters } from "../ReportsFiltersContext";
import { ReportSortingCriteria, ReportSortingDirection } from "../types";

const { Option } = Select;

const SortSelectorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const SortingSection: React.FC = () => {
  const { sortOptions, handleSortChange, toggleOrder } = useReportsFilters(); // Using your custom hook

  const handleSortByChange = (value: ReportSortingCriteria) => {
    handleSortChange(value);
  };

  const handleOrderChange = (value: ReportSortingDirection) => {
    toggleOrder();
  };

  return (
    <SortSelectorContainer>
      <div>
        <label>Sort By: </label>
        <Select
          value={sortOptions.sortBy}
          onChange={handleSortByChange}
          style={{ width: 200 }}
        >
          <Option value={ReportSortingCriteria.EmployeeName}>
            Employee Name
          </Option>
          <Option value={ReportSortingCriteria.EmployeeTotalWorkedHours}>
            Total Worked Hours
          </Option>
          <Option value={ReportSortingCriteria.EmployeeHoursOff}>
            Hours Off
          </Option>
          <Option value={ReportSortingCriteria.ProjectTotalWorkedHours}>
            Project Total Worked Hours
          </Option>
        </Select>
      </div>
      <div>
        <label>Order: </label>
        <Select
          value={sortOptions.order}
          onChange={handleOrderChange}
          style={{ width: 120 }}
        >
          <Option value={ReportSortingDirection.Ascending}>Ascending</Option>
          <Option value={ReportSortingDirection.Descending}>Descending</Option>
        </Select>
      </div>
    </SortSelectorContainer>
  );
};

export default SortingSection;
