import {DatePicker, Radio, Select, Skeleton, Typography} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {TimeOffTable} from "../_components/TimeOffTable";
import {getTimeOffStatusesList} from "../_core/helpers/time-off-general.helper";
import {useTimeOffListingDatasource} from "../_core/hooks/time-off-listing-datasource.hook";
import {UrlQueryParamsHelper} from "../_core/helpers/url-query-params.helper";
import {KeyValue} from "../../../_core/types";
import {TimeOffCalendar} from "../_components/TimeOffCalendar";
import {FullCalendarHelper} from "../_core/helpers/full-calendar.helper";
import {TimeOffView} from "../_core/enums/time-off-view.enum";

const { RangePicker } = DatePicker;

export const TeamTimeOff: React.FC = () => {
  const didMount = useRef(false);

  const timeOffListingDatasource = useTimeOffListingDatasource();
  const {
    timeOffRequests, isLoading, pagination,
    getTeamTimeOffMetadata, getTeamTimeOffData, initializeFilters,
    projectsSeed, usersSeed,
    selectedProjects, setSelectedProjects,
    selectedUsers, setSelectedUsers,
    selectedStatus, setSelectedStatus,
    selectedDateRange, setSelectedDateRange,
    publicHolidaysSeed, currentCalendarMonth
  } = timeOffListingDatasource;

  const [selectedView, setSelectedView] = useState<TimeOffView>(TimeOffView.Calendar);

  useEffect(() => {
    getTeamTimeOffMetadata();
  }, []);

  useEffect(() => {
    if (didMount.current) {
      getTeamTimeOffData(pagination.pageNumber, selectedView);
      UrlQueryParamsHelper.setFiltersInQueryParams(
          selectedDateRange, selectedStatus,
          pagination.pageNumber, selectedUsers, selectedProjects, '', selectedView
      )
    } else {
      initializeFilters();
      didMount.current = true;
    }
  }, [selectedProjects, selectedUsers, selectedStatus, selectedDateRange, selectedView])

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
          <>
            <Typography.Title style={{ display: "flex", justifyContent: "flex-start", marginBottom: 25 }}>
              Team Time Off
            </Typography.Title>

            <div style={{ marginBottom: "25px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Radio.Group value={selectedView} onChange={(e) => setSelectedView(e.target.value)}>
                  <Radio.Button value={TimeOffView.Calendar} type="primary">Calendar</Radio.Button>
                  <Radio.Button value={TimeOffView.Table}>Table</Radio.Button>
                </Radio.Group>
                {
                  selectedView === TimeOffView.Table &&
                    <RangePicker
                        format="D MMM, YY"
                        style={{ width: "300px", borderRadius: 5, marginLeft: 10 }}
                        value={selectedDateRange as any}
                        onChange={(v) => setSelectedDateRange(v as any)}
                    />
                }
              </div>
              <div>
                <Select
                    placeholder="Select status"
                    onChange={(value: number) => setSelectedStatus(value)}
                    style={{ width: "200px", marginRight: 10 }}
                    showSearch={false}
                    defaultValue={selectedStatus}
                    allowClear={true}
                >
                  {getTimeOffStatusesList()?.map((status: KeyValue) => (
                      <Select.Option key={status.key} value={status.value}>
                        {status.value}
                      </Select.Option>
                  ))}
                </Select>

                <Select
                    placeholder="Select users"
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(value: string[]) => setSelectedUsers(value)}
                    style={{ width: "200px", marginRight: 10 }}
                    showSearch={true}
                    defaultValue={selectedUsers}
                    filterOption={(input, option) =>
                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                  {usersSeed?.map((user: KeyValue) => (
                      <Select.Option key={user.key} value={user.key} title={user.value}>
                        {user.value}
                      </Select.Option>
                  ))}
                </Select>

                <Select
                    placeholder="Select projects"
                    mode="multiple"
                    maxTagCount={1}
                    onChange={(value: string[]) => setSelectedProjects(value)}
                    style={{ width: "200px" }}
                    showSearch={true}
                    defaultValue={selectedProjects}
                    filterOption={(input, option) =>
                        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                  {projectsSeed?.map((project: KeyValue) => (
                      <Select.Option key={project.key} value={project.key} title={project.value}>
                        {project.value}
                      </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            {
              selectedView === TimeOffView.Table &&
                <>
                  {timeOffRequests ? (
                      <TimeOffTable
                          showEmployeeName={true}
                          totalCount={pagination.totalCount || 0}
                          currentPage={pagination.pageNumber}
                          changePage={(page: number) => getTeamTimeOffData(page, selectedView)}
                          requests={timeOffRequests}
                          refreshData={() => getTeamTimeOffData(pagination.pageNumber, selectedView)}
                      />
                  ) : (
                      <div style={{ color: "gray", fontStyle: "italic" }}>
                        There are no requests.
                      </div>
                  )}
                </>
            }
          </>
      )}

      {
          selectedView === TimeOffView.Calendar &&
          <div style={{paddingBottom: 50}}>
            <TimeOffCalendar
                isSelectable={true}
                publicHolidays={publicHolidaysSeed}
                currentDate={currentCalendarMonth}
                data={FullCalendarHelper.preprocessData(timeOffRequests, publicHolidaysSeed)}
                refreshData={(start: any, end: any) => getTeamTimeOffData(pagination.pageNumber, selectedView, [start,end])}
            />
          </div>
      }
    </>
  );
};
