import React from "react";
import { Task, Technology } from "../../../../types";

export interface ChangeTaskForm {
  projectId: string;
  taskId?: string;
  technologyId: string;
  sessionType: number;
  description?: string;
}

export interface IChangeSessionTaskModalProps {
  formData: ChangeTaskForm;
  technologies: Technology[];
  tasks: Task[];
  onSubmit: () => void;
  setFormData: React.Dispatch<React.SetStateAction<ChangeTaskForm>>;
  setIsNewTask: React.Dispatch<React.SetStateAction<boolean>>;
  isNewTask: boolean;
  taskInputValue: string;
  setTaskInputValue: (val: string) => void;
  isUpdating: boolean;
  isGettingData: boolean;
}
export const ChangeSessionTaskModalContext =
  React.createContext<IChangeSessionTaskModalProps>(
    {} as IChangeSessionTaskModalProps
  );
