import { CheckOutlined, CloseOutlined, InboxOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Select, Row, Upload, Switch, Button } from "antd";
``;
import TextArea from "antd/lib/input/TextArea";
import Dragger from "antd/lib/upload/Dragger";
import React, { useContext, useState } from "react";
import { ProjectContext } from ".";
import { ApiClientContext } from "../../../App";
import { projectDocumentType } from "../../../_core/hardcodedValues";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../styles/StyledComponents";

export const AddDocumentDialog = (props: {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
}) => {
  const { isVisible, setIsVisible } = props;
  const [form] = Form.useForm();
  const [isFile, setIsFile] = useState(false);
  const [filePath, setFilePath] = useState("");
  const { addDocument } = useContext(ProjectContext);
  const { apiClient } = useContext(ApiClientContext);

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },

      onUploadProgress: (event: any) => {
        onProgress({ percent: (event.loaded / event.total) * 100 }, file);
      },
    };
    try {
      const { data } = await apiClient.post("/api/projects/upload-document", formData, config);
      setFilePath(data.url);
      onSuccess(file);
    } catch (err) {
      const error = new Error("Some error");
      onError({ event: error });
    }
  };

  const handleSave = async (values: any) => {
    const formValues = { ...values, isFile };
    if (isFile) {
      formValues.url = filePath;
    }
    try {
      addDocument(formValues);
      handleCancel();
    } catch (err) {
      console.warn(err);
    }
  };
  return (
    <Modal visible={isVisible} onCancel={handleCancel} footer={null} closeIcon={<RoundCloseIcon />}>
      <Form layout="vertical" form={form} onFinish={handleSave}>
        <Row>
          <Form.Item
            name="docType"
            label={<FormLabel>Type</FormLabel>}
            style={{ flex: 2, marginRight: 5 }}
            rules={[{ required: true, message: "Select type!" }]}
          >
            <Select placeholder="Select type">
              {projectDocumentType.map((doctype) => (
                <Select.Option value={doctype.value} key={doctype.name}>
                  {doctype.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            label={<FormLabel>Name</FormLabel>}
            style={{ flex: 3 }}
            rules={[{ required: true, message: "Input name!" }]}
          >
            <Input />
          </Form.Item>
        </Row>

        <Form.Item name="description" label={<FormLabel>Description</FormLabel>}>
          <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
        </Form.Item>
        <Row justify="start" align="middle" style={{ marginBottom: 10 }}>
          <Switch
            checked={isFile}
            onChange={() => setIsFile(!isFile)}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
          />
          <FormLabel>Upload file</FormLabel>
        </Row>
        {isFile ? (
          <Form.Item name="" rules={[{ required: true, message: "Upload a document!" }]}>
            <Dragger customRequest={handleUpload} maxCount={1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </Dragger>
          </Form.Item>
        ) : (
          <Form.Item name="url" label={<FormLabel>URL</FormLabel>}>
            <Input />
          </Form.Item>
        )}

        <Form.Item style={{ marginTop: 10 }}>
          <Button type="primary" block style={{ borderRadius: 5 }} htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
