import { Layout } from "antd";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import useTimezones from "../../_core/hooks/useTimezones";
import { useUserRoles } from "../../_core/hooks/useUserRoles";
import { useWindowSize } from "../../_core/hooks/useWindowSize";
import { Timezone } from "../../_core/types";
import Clients from "../Clients";
import { ComingSoon } from "../ComingSoon";
import { Dashboard } from "../Dashboard";
import { Employees } from "../Employees";
import { Forecast } from "../Forecast";
import { Inventory } from "../Inventory";
import { Offices } from "../Offices";
import { OfficePage } from "../Offices/OfficePage";
import { Projects } from "../Projects";
import { ReportBug } from "../ReportBug";
import { Technologies } from "../Technologies/Index";
import { TeamTimeOff } from "../TimeOff/TeamTimeOff/Index";
import { TimeOffAdministration } from "../TimeOff/TimeOffAdministration";
import { TimeOffRequest } from "../TimeOff/TimeOffDetails/TimeOffRequest";
import { UserTimeOffProfile } from "../TimeOff/UserTimeOffProfile/Index";
import { TimeTrackingPage } from "../TimeTrackingv2";
import { UserProfile } from "../UserProfile";
import AdminRoute from "./AdminRoute";
import OperationalRoute from "./OperationalRoute";
import { SidebarMenu } from "./SidebarMenu/SidebarMenu";
import ReportsPage from "../Reports";

interface CommonResourcesContextInterface {
  timezones: Timezone[];
}

export const CommonResourcesContext =
  //@ts-ignore
  React.createContext<CommonResourcesContextInterface>();

export const AppRouting = () => {
  const { isAdmin } = useUserRoles();
  const { timezones } = useTimezones();
  const { Content } = Layout;
  const { width } = useWindowSize();

  if (
    window.location.pathname === "/register" &&
    localStorage.getItem("accessToken")
  ) {
    localStorage.removeItem("accessToken");
    window.location.reload();
  }

  return (
    <BrowserRouter>
      <CommonResourcesContext.Provider value={{ timezones }}>
        <Layout style={{ backgroundColor: "white" }}>
          <SidebarMenu />

          <Content
            style={{
              backgroundColor: "#fff",
              height: "100vh",
              overflowY: "auto",
              minWidth: 300,
            }}
          >
            <Layout
              style={{
                padding: width > 600 ? 32 : 8,
                backgroundColor: "transparent",
                minHeight: "80%",
                height: "100%",
              }}
            >
              <Switch>
                <Route path="/employees" component={Employees} />

                <Route path="/projects" component={Projects} />

                <Route path="/alloffices" component={Offices} />

                <Route path="/inventory" component={Inventory} />

                <Route path="/office/:id" component={OfficePage} />
                <Route
                  path="/dashboard"
                  component={!isAdmin() ? Dashboard : ComingSoon}
                />

                <Route path="/teamTimeOff">
                  {" "}
                  <TeamTimeOff />{" "}
                </Route>
                <Route path="/timeOffProfile" component={UserTimeOffProfile} />
                <Route
                  path="/time-off-request/:id"
                  component={TimeOffRequest}
                />
                <OperationalRoute
                  path="/time-off-administration"
                  component={TimeOffAdministration}
                />

                <Route path="/trackyourtime" component={TimeTrackingPage} />
                <Route path="/user-profile/" component={UserProfile} />
                <Route path="/report-bug" component={ReportBug} />
                <AdminRoute path="/technology" component={Technologies} />
                <AdminRoute path="/clients" component={Clients} />

                <Route path="/comingSoon" component={ComingSoon} />
                <Route path="/forecast" component={Forecast} />
                <Route
                  path="/reports/company-overview"
                  component={ReportsPage}
                />
                <Redirect to="/dashboard" />
              </Switch>
            </Layout>
          </Content>
        </Layout>
      </CommonResourcesContext.Provider>
    </BrowserRouter>
  );
};
