import { Row } from "antd";
import React, { useContext } from "react";
import { ForecastTableContext } from ".";

export const TotalHoursHeaderCell = () => {
  const { projects } = useContext(ForecastTableContext);
  const totalWorkedTime = () => {
    return projects.reduce((prev, cur) => {
      return prev + cur.totalWorkedTime;
    }, 0);
  };
  const totalForecastTime = () => {
    return projects.reduce((prev, cur) => {
      return prev + cur.totalForecastTime;
    }, 0);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center,",
        alignItems: "center",
        height: 127,
        width: 127,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Row justify="center">
        <div
          style={{
            textAlign: "center",
            color: "#137AE9",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          Total Hours
        </div>
        <div
          style={{
            textAlign: "center",
            color: "#475569",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          {totalWorkedTime()}/{totalForecastTime()}
        </div>
      </Row>
    </div>
  );
};
