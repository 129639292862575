import { Form, Input, Button, Spin, Alert, Checkbox } from "antd";
import { LoginForm } from "./StyledComponents";
import { useAuthFlow } from "./useAuthFlow";
import { Link } from "react-router-dom";

export const Login = () => {
  const { loading, handleLogin, alert } = useAuthFlow();

  return (
    <LoginForm>
      <Form
        onFinish={handleLogin}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h1
          style={{
            fontSize: 42,
            fontWeight: "bold",
            marginBottom: 4,
            color: "#06062D",
          }}
        >
          Login
        </h1>
        <span style={{ color: "#A8A8A8", fontSize: 20, marginBottom: 16, textAlign:'left' }}>
          Welcome back! Please login to your account.
        </span>
        <span style={{ color: "#A8A8A8", fontSize: 20, marginBottom: 10 }}>Email</span>
        <Form.Item
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input your Email!" }]}
          name="username"
        >
          <Input size="large" style={{ borderRadius: 8, borderWidth: 2 }} maxLength={100} />
        </Form.Item>
        <span style={{ color: "#A8A8A8", fontSize: 20, marginBottom: 16 }}>Password</span>
        <Form.Item
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input your Password!" }]}
          name="password"
        >
          <Input.Password
            size="large"
            style={{ borderRadius: 8, borderWidth: 2 }}
            maxLength={100}
          />
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div>
              <Form.Item name="check" valuePropName="checked" style={{marginBottom: 0}}>
                <Checkbox>
                  <span style={{ color: "#06062D", fontSize: 16 }}>Remember me</span>
                </Checkbox>
              </Form.Item>
            </div>
            <span style={{ color: "#A8A8A8" }}>
              <Link to={`/forgot-password`}>
                Forgot Password?
              </Link>
            </span>
          </div>
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: 64,
              borderRadius: 8,
              backgroundColor: "#137AE9",
              fontSize: 20,
            }}
            id="button__login"
          >
            Login
          </Button>
        </Form.Item>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spin tip="Loading..." className="spin"></Spin>{" "}
          </div>
        ) : null}

        {alert ? <Alert message={alert} type="error" style={{ width: "100%" }} /> : null}
      </Form>
    </LoginForm>
  );
};
