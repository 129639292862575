import { AutoComplete, Select } from "antd";
import { useContext, useState } from "react";
import { Task } from "../../../types";
import { TrackerContext, TrackerForm } from "../utils/types";
import React from "react";
import TextArea from "antd/lib/input/TextArea";
import { TimeTrackingUserDataContext } from "../../../TimeTrackingUserDataProvider";
import TaskOption from "./TaskOption";
const { Option } = Select;

export const TaskInput = () => {
  const {
    tasks,
    formData,
    setNewTaskTitle,
    isTracking,
    setFormData,
    setIsNewTask,
    isNewTask,
    markTaskAsComplete,
  } = useContext(TrackerContext);
  const { projects } = useContext(TimeTrackingUserDataContext);
  const [taskToComplete, setTaskToComplete] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>(
    tasks?.find((task: Task) => task.taskId === formData?.taskId)?.title || ""
  );
  const [lastValidTaskTitle, setLastValidTaskTitle] = useState<string>(
    tasks?.find((task: Task) => task.taskId === formData?.taskId)?.title || ""
  );
  const handleBlur = () => {
    if (!isNewTask) {
      setInputValue(lastValidTaskTitle);
    }
  };
  const handleChange = (value: string) => {
    setInputValue(value);
    const existingTask: Task | undefined = tasks?.find(
      (task: Task) => task.title?.toUpperCase() === value?.toUpperCase()
    );

    if (existingTask) {
      if (existingTask?.title) setLastValidTaskTitle(existingTask?.title);
      setIsNewTask(false);
      setFormData((prevState) => ({
        ...prevState,
        taskId: existingTask?.taskId,
        technologyId: (existingTask || prevState)?.technologyId,
        description: (existingTask || prevState)?.description,
      }));
    } else {
      setIsNewTask(true);
      setNewTaskTitle(value);
      // setFormData({ ...formData, taskId: undefined });
    }
  };

  const handleFocus = () => {
    setInputValue("");
  };
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleCompleteTask = async (taskId: string) => {
    await markTaskAsComplete(taskId);
    setTaskToComplete("");
  };

  const getFirstTaskDataForProject = (projectId: string) => {
    const projectTasks =
      projects.find((project) => project.projectId === projectId)?.tasks || [];
    const firstTask = projectTasks[0];

    if (firstTask) {
      return {
        taskId: firstTask.taskId,
        description: firstTask.description,
        technologyId: firstTask.technologyId,
      };
    }
    return {};
  };
  const handleSelectChange = (field: keyof TrackerForm, value: string) => {
    if (field === "projectId") {
      const firstTaskData = getFirstTaskDataForProject(value);
      setFormData((prevState) => ({
        ...prevState,
        ...firstTaskData,
        projectId: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };
  React.useEffect(() => {
    setInputValue(
      tasks?.find((t) => t.taskId === formData.taskId)?.title ||
        tasks?.[0]?.title ||
        ""
    );
    setLastValidTaskTitle(
      tasks?.find((t) => t.taskId === formData.taskId)?.title ||
        tasks?.[0]?.title ||
        ""
    );
  }, [tasks, formData?.taskId]);
  return (
    <div
      style={{
        width: "70%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          height: 44,
        }}
      >
        <Select
          style={{
            flex: 2,
            marginRight: 10,
          }}
          size={"large"}
          disabled={isTracking}
          value={formData?.projectId}
          placeholder="Select project.."
          onChange={(value) => handleSelectChange("projectId", value)}
        >
          {projects.map((project) => (
            <Option
              key={project.projectId + "key-form"}
              value={project.projectId}
            >
              {project.projectName}
            </Option>
          ))}
        </Select>

        <AutoComplete
          style={{ flex: 4, marginRight: 10 }}
          showSearch
          value={inputValue}
          placeholder="Select task"
          defaultValue={tasks?.[0]?.title}
          searchValue={inputValue}
          notFoundContent={<div>A new task will be added.</div>}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={isTracking}
          size={"large"}
        >
          {tasks
            ?.filter((task: Task) =>
              task?.title?.toUpperCase()?.includes(inputValue?.toUpperCase())
            )
            .map((task: Task) => (
              <Select.Option key={task.taskId} value={task.title}>
                <TaskOption
                  task={task}
                  markTaskAsComplete={handleCompleteTask}
                  taskToComplete={taskToComplete}
                  setTaskToComplete={setTaskToComplete}
                />
              </Select.Option>
            ))}
        </AutoComplete>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <TextArea
          style={{
            marginRight: 10,
            resize: "none",
          }}
          name="description"
          placeholder="Enter task description..."
          disabled={!isNewTask || isTracking}
          value={formData?.description}
          onChange={(e) => handleDescriptionChange(e)}
        />
      </div>
    </div>
  );
};
