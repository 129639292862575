import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { formatDate } from "../InviteModal/useInviteModal";
import { Employee, updateEmployeeDetailsFormValue } from "./types";

const useManageEmployee = (employeeId: string) => {
  const [employee, setEmployee] = useState<Employee>();
  const [isLoading, setIsLoading] = useState(false);

  const { apiClient } = useContext(ApiClientContext);

  const fetchEmployee = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/users/get-user-by/${employeeId}`);
      setEmployee(data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
    }
  };

  const updateEmployeeDetails = async (values: any) => {
    try {
      const putData: updateEmployeeDetailsFormValue = {
        ...values,
      };
      if (values.birthday) {
        putData.birthday = formatDate(values.birthday.toISOString(true));
      }
      if (values.hireDate) {
        putData.hireDate = formatDate(values.hireDate.toISOString(true));
      }
      setIsLoading(true);
      const { data } = await apiClient.put(`/api/users/update/${employeeId}`, putData);
      setIsLoading(false);
      fetchEmployee();
      return data;
    } catch (err) {
      setIsLoading(false);
    }
  };

  const deleteEmployee = async (employeeId: string) => {
    try {
      setIsLoading(true);
      await apiClient.delete(`/api/users/${employeeId}`);
      fetchEmployee();
    } catch (err : any) {
      message.error(err);
      throw err;
    }
  };

  const undoDeleteEmployee = async (employeeId: string) => {
    try {
      setIsLoading(true);
      await apiClient.put(`/api/users/undo-delete/${employeeId}`);
      fetchEmployee();
    } catch (err : any) {
      message.error(err);
      throw err;
    }
  };

  const addTechnology = async (values: any) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post(`/api/users/${employeeId}/technology`, values);
      fetchEmployee();
      return data;
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
      throw err;
    }
  };
  const deleteTechnology = async (technologyId: string) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.delete(
        `/api/users/${employeeId}/technology/${technologyId}`
      );
      fetchEmployee();
      return data;
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
      throw err;
    }
  };

  useEffect(() => {
    fetchEmployee();
  }, []);

  return {
    employee,
    isLoading,
    updateEmployeeDetails,
    deleteTechnology,
    addTechnology,
    deleteEmployee,
    undoDeleteEmployee
  };
};

export default useManageEmployee;
