import {message} from "antd";
import {useContext, useEffect, useState} from "react";
import {ApiClientContext} from "../../../App";
import {Request} from "../types";

const useItemRequests = () => {
  const [requests, setRequests] = useState<Request[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const {apiClient} = useContext(ApiClientContext);

  const fetchRequests = async () => {
    try {
      setIsLoading(true);
      const {data} = await apiClient.get(`/api/items/get-requested-items`);
      const sorted = data.sort((a: Request, b: Request) => a.state - b.state);
      setRequests(sorted);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      message.error("Could not fetch requests");
      console.warn(err);
      throw err;
    }
  };

  const updateRequest = async (state: number, requestId: string) => {
    try {
      setIsLoading(true);
      const data = await apiClient.put(`/api/items/handle-request/${requestId}`, {state});
      fetchRequests();
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  const acceptRequest = (requestId: string) => {
    return updateRequest(20, requestId);
  };

  const declineRequest = (requestId: string) => {
    return updateRequest(30, requestId);
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return {
    requests,
    isLoading,
    declineRequest,
    acceptRequest,
    searchInputValue,
    setSearchInputValue,
  };
};

export default useItemRequests;
