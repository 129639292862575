import { Checkbox, Drawer, Select, Tabs, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { useReportsFilters } from "../ReportsFiltersContext";
import ColumnFilter from "./ColumnFilter";
import RowFilter from "./RowFilter";
import SortingSection from "./SortingSection";
import { ProjectType } from "../types";
import { ReloadOutlined } from "@ant-design/icons";
import { useReportsData } from "../Table/ReportsDataContext";

const { TabPane } = Tabs;
const CheckboxContainer = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0; // Resetting the margin
  }
`;
// Styled component for full-width tabs
const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    flex: 1;
  }
`;

const FilterDrawer: React.FC = () => {
  const {
    isOpen,
    setIsOpen,
    hideProjectsWithNoHours,
    setHideProjectsWithNoHours,
    projectType,
    setProjectType,
  } = useReportsFilters();
  const onClose = () => {
    setIsOpen(false);
  };
  const { fetchData } = useReportsData();
  return (
    <Drawer
      placement="right"
      closable={true}
      onClose={onClose}
      visible={isOpen}
      width="25%"
    >
      <Button
        type="primary"
        icon={<ReloadOutlined />}
        onClick={() => fetchData()}
        style={{ marginBottom: "20px" }}
      >
        Refresh Data
      </Button>
      <CheckboxContainer>
        <Checkbox
          checked={hideProjectsWithNoHours}
          onChange={(e) => setHideProjectsWithNoHours(e.target.checked)}
        >
          Hide Projects with 0 hours
        </Checkbox>
        {/* <Checkbox
          checked={showZeroHourProjects}
          onChange={(e) => setShowZeroHourProjects(e.target.checked)}
        >
          Show Projects with 0 Hours
        </Checkbox> */}{" "}
        <Select
          value={projectType === null ? "none" : projectType}
          onChange={(value) =>
            setProjectType(value === "none" ? null : (value as ProjectType))
          }
          style={{ width: 200, marginTop: 16 }}
        >
          <Select.Option value="none">Select Project Type</Select.Option>
          <Select.Option value={ProjectType.Intern}>Intern</Select.Option>
          <Select.Option value={ProjectType.Client}>Client</Select.Option>
        </Select>
      </CheckboxContainer>
      <SortingSection />
      <StyledTabs defaultActiveKey="1">
        <TabPane tab="Row Filters" key="1">
          <RowFilter />
        </TabPane>
        <TabPane tab="Column Filters" key="2">
          <ColumnFilter />
        </TabPane>
      </StyledTabs>
    </Drawer>
  );
};

export default FilterDrawer;
