import { useState, useEffect, useContext } from "react";
import { LoginFormFields, RegisterFormFields } from "./types";
import { ApiClientContext, UserContext } from "../../App";
import { validatePassword } from "./formValidators";

export const useAuthFlow = () => {
  const [registerFields, setRegisterFields] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>("");
  const [email, setEmail] = useState<string | null>("");

  const { apiClient, setAuthorizationHeader } = useContext(ApiClientContext);
  const { setUser } = useContext(UserContext);

  const handleLogin = async (values: LoginFormFields) => {
    setLoading(true);
    setAlert("");

    try {
      const response = await apiClient.post("/api/Account/Login", values);

      if (response?.status === 200) {
        const { accessToken, refreshToken } = response.data.data;
        
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        setAuthorizationHeader(accessToken);
        const { data } = await apiClient.get(`/api/account`);
        setUser(data);
      }
    } catch (error) {
      console.warn(error);
      setAlert("Login failed");
    }
    
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    setUser(null);
  };

  const handleRegister = async (values: RegisterFormFields) => {
    if (!validatePassword(values.password)){
      setAlert("Password must contain at least 8 characters, upper + lower case letters, at least a number and a special character");
        return;
    }
    setLoading(true);

    try {
      const body = {
        email: new URLSearchParams(window.location.search).get("email"),
        validationToken: new URLSearchParams(window.location.search).get("token"),
        password: values.password,
      };
      const response = await apiClient.post("/api/account/register", body);
      if (response.status === 200) {
        setRegisterFields(false);
        setAlert("");
      }
    } catch (error) {
      console.warn(error);
      setAlert("Account setup failed");
    }

    setLoading(false);
  };

  useEffect(() => {
    setEmail(new URLSearchParams(window.location.search).get("email"));
  }, []);

  return {
    handleRegister,
    handleLogin,
    handleLogout,
    registerFields,
    loading,
    alert,
    email,
  };
};
