import { useState, useContext } from "react";
import { ApiClientContext } from "../../App";
import { validatePassword } from "./formValidators";

export const useAuthPasswordFlow = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<{status: ""|"error"|"success", statusText:string}>({status:"", statusText:""});

  const { apiClient } = useContext(ApiClientContext);

  const handleForgotPassword = async (formData: any) => {
    setLoading(true);
    setResponse({ status: "", statusText: "" });

    try {
      const response = await apiClient.get("/api/account/forgot-password?email=" + formData.email,);
      if (response?.status === 200) {
        setResponse({status:"success", statusText:"Recovery email was sent"});
      }
    } catch (error) {
      setResponse({status:"error", statusText:"Something wrong happened"});
    }

    setLoading(false);
  };

  const handleResetPassword = async (password: string, email: string, token: string) => {
    if (!validatePassword(password)) {
      setResponse({ status: "error", statusText: "Password must contain at least 8 characters, upper + lower case letters, at least a number and a special character" });
      return;
    }

    setLoading(true);
    setResponse({ status: "", statusText: "" });

    try {
      const body = {
        email: email,
        newPassword: password,
        resetPassToken: token,
      }; 
      const response = await apiClient.post("/api/account/reset-password", body);

      if (response?.status === 200) {
        setResponse({status:"success", statusText:"Password was changed successfully"});
      }
    } catch (error) {
      setResponse({status:"error", statusText:"Something wrong happened"});
    }
    setLoading(false);
  };

  return {
    handleForgotPassword,
    handleResetPassword,
    loading,
    response,
  };
};
