import { Select } from "antd";
import { useContext, useMemo } from "react";
import { TimeTrackingUserDataContext } from "../../../TimeTrackingUserDataProvider";
import SaveButton from "../SaveButton";
import {
  SessionEnum,
  SessionTypeLocalStorageKey,
  TechnologyLocalStorageKey,
  TrackerContext,
  TrackerForm,
} from "../utils/types";
const { Option } = Select;
export const SessionInputs = () => {
  const {
    technologies,
    formData,
    setFormData,
    isTracking,
    tasks,
    setIsNewTask,
  } = useContext(TrackerContext);

  const internalValue = useMemo(
    () => formData.technologyId || technologies[0]?.id,
    [formData.technologyId, technologies]
  );
  const setToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const handleTechnologyChange = (value: string) => {
    const matchingTask = tasks?.find((task) => task.taskId === formData.taskId);

    if (matchingTask?.technologyId !== value) {
      setIsNewTask(true);
      setFormData((prevState) => ({
        ...prevState,
        technologyId: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        taskId: matchingTask.taskId,
        technologyId: matchingTask.technologyId,
      }));
      setIsNewTask(false);
    }
    setToLocalStorage(TechnologyLocalStorageKey, value);
  };

  const handleSessionTypeChange = (value: string) => {
    setToLocalStorage(SessionTypeLocalStorageKey, value);
    setFormData((prevState) => ({
      ...prevState,
      sessionType: parseInt(value),
    }));
  };

  const handleSelectChange = (field: keyof TrackerForm, value: string) => {
    switch (field) {
      case "technologyId":
        handleTechnologyChange(value);
        break;
      case "sessionType":
        handleSessionTypeChange(value);
        break;
      default:
        setFormData((prevState) => ({
          ...prevState,
          [field]: value,
        }));
    }
  };

  return (
    <>
      <div
        style={{
          width: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <SaveButton />
        <Select
          disabled={isTracking}
          style={{ flex: 1, marginRight: 10, marginBottom: 2 }}
          value={internalValue}
          onChange={(value) => handleSelectChange("technologyId", value)}
        >
          {technologies.map((technology) => (
            <Option key={technology.id} value={technology.id}>
              {technology.name}
            </Option>
          ))}
        </Select>

        <Select
          style={{ flex: 1, marginRight: 10 }}
          disabled={isTracking}
          value={SessionEnum[formData.sessionType]}
          onChange={(value) =>
            handleSelectChange(
              "sessionType",
              SessionEnum[value as keyof typeof SessionEnum].toString()
            )
          }
        >
          {Object.keys(SessionEnum)
            .filter((key) => isNaN(Number(key)))
            .map((key) => {
              return (
                <Option key={key} value={key}>
                  {key}
                </Option>
              );
            })}
        </Select>
      </div>
    </>
  );
};
