import {Table, Tooltip, Tag, Modal} from "antd";
import React, {useContext, useState} from "react";
import {useHistory} from "react-router";
import {AllModelsContext} from ".";
import {getItemType} from "../../../../_core/hardcodedValues";
import {DeleteFieldIcon} from "../../../../assets/icons/DeleteFieldIcon";
import {EditFieldIcon} from "../../../../assets/icons/EditFieldIcon";
import {OpenFieldIcon} from "../../../../assets/icons/OpenFieldIcon";
import {StyledTag, TableHeader, TableText} from "../../../../styles/StyledComponents";
import {Model, Tag as TagType} from "../../types";
import {ModelDialog} from "./ModelDialog";

export const ModelsTable = () => {
  const {models, isLoading, deleteModel, searchInputValue} = useContext(AllModelsContext);
  const [modelDialog, setModelDialog] = useState(false);
  const [modelToEdit, setModelToEdit] = useState<Model>();
  const history = useHistory();

  const filterModels = () => {
    return models.filter((model) => {
      return (
        model.name.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        model.brandName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase())
      );
    });
  };

  const handleDelete = (model: Model) => {
    if (model.itemsAssigned > 0) {
      Modal.confirm({
        title: "You can not delete this model",
        icon: null,
        content: (
          <div>
            <span style={{color: "#FF3E3E"}}>
              This brand has {model.itemsAssigned} {model.itemsAssigned > 1 ? "items " : "item "}
              assigned to it.
            </span>
            <br /> Before you do this action, make sure that
            {model.itemsAssigned > 1 ? "all the models are" : "the model is"} assigned to another
            brand.
          </div>
        ),
        okButtonProps: {style: {display: "none"}},
        cancelText: "I understand",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#137AE9",
          },
        },
      });
    } else {
      Modal.confirm({
        title: "You are about to delete a model",
        icon: null,
        content: "This action can not be reversed. Are you sure you want to delete this model?",
        okText: "Yes, delete model",
        okButtonProps: {
          style: {
            backgroundColor: "#FF3E3E",
            color: "white",
            borderRadius: "5px",
            border: "none",
          },
        },
        onOk() {
          return deleteModel(model.id);
        },
        cancelText: "Cancel action",
        cancelButtonProps: {
          style: {
            border: "none",
            fontSize: "16px",
            fontWeight: 500,
            color: "#A8A8A8",
          },
        },
      });
    }
  };
  return (
    <div>
      <Table dataSource={filterModels()} rowKey="id" loading={isLoading} pagination={false}>
        <Table.Column<Model>
          title={<TableHeader>Model Name</TableHeader>}
          dataIndex="name"
          render={(name) => <TableText>{name}</TableText>}
        />
        <Table.Column<Model>
          title={<TableHeader>Brand</TableHeader>}
          dataIndex="brandName"
          render={(brandName) => <TableText>{brandName}</TableText>}
        />

        <Table.Column<Model>
          title={<TableHeader>Features</TableHeader>}
          dataIndex="modelTags"
          render={(tags: TagType[]) => (
            <div>
              {tags.map((tag: TagType) => (
                <StyledTag key={tag.id}>{tag.name}</StyledTag>
              ))}
            </div>
          )}
        />

        <Table.Column<Model>
          title={<TableHeader>Type</TableHeader>}
          dataIndex="itemType"
          render={(type) => <TableText>{getItemType(type)}</TableText>}
        />

        <Table.Column<Model>
          title={<TableHeader>Items assigned</TableHeader>}
          dataIndex="itemsAssigned"
          render={(items) => <TableText>{items}</TableText>}
        />
        <Table.Column<Model>
          title={<TableHeader>Actions</TableHeader>}
          render={(model: Model) => (
            <div>
              <Tooltip title="Open">
                <OpenFieldIcon
                  style={{cursor: "pointer"}}
                  onClick={() => history.push(`/inventory/models/${model.id}`)}
                />
              </Tooltip>
              <Tooltip title="Edit">
                <EditFieldIcon
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    setModelToEdit(model);
                    setModelDialog(true);
                  }}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteFieldIcon style={{cursor: "pointer"}} onClick={() => handleDelete(model)} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      <ModelDialog isVisible={modelDialog} setIsVisible={setModelDialog} model={modelToEdit} />
    </div>
  );
};
