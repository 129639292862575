import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import React, { useState } from "react";
import useApiClient from "../../_core/hooks/useApiClient";
import mime from "mime-db";
import { useReportsFilters } from "./ReportsFiltersContext";

export const DownloadReport: React.FC = () => {
  const { apiClient } = useApiClient();
  const [reportIsLoading, setReportIsLoading] = useState<boolean>(false);
  const { date } = useReportsFilters();
  const downloadReport = async () => {
    setReportIsLoading(true);
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    await apiClient
      .get(
        `/api/time-tracking/reports/download-report?month=${month}&year=${year}`,
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

        const link = document.createElement("a");
        link.href = fileUrl;
        const { extensions } = mime[res.data.type];
        const extension = extensions?.length ? extensions[0] : "";
        link.setAttribute("download", `${name}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode ? link.parentNode.removeChild(link) : null;
      })
      .catch((err) => {
        console.warn(err);
        message.error("Couldn't download file!");
      })
      .finally(() => setReportIsLoading(false));
  };

  return (
    <Button
      type="primary"
      icon={<FilterOutlined />}
      style={{ marginRight: "1rem", borderRadius: "0.5rem" }}
      onClick={downloadReport}
    >
      Export
    </Button>
  );
};
