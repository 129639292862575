import { BugOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { UserContext } from "../../App";
import { useUserRoles } from "../../_core/hooks/useUserRoles";
import { BugModal } from "./BugModal";
import { BugTable } from "./BugTable";
import { useBugs } from "./hooks";

export const BugContext = React.createContext<any>(null);

export const ReportBug = () => {
  const bugs = useBugs();
  const { user } = useContext(UserContext);
  const { isAdmin } = useUserRoles();

  return (
    <>
      {bugs.loading ? (
        <Skeleton active />
      ) : (
        <BugContext.Provider value={bugs}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h3 style={{ textAlign: "left" }}>
              <div>
                If you encounter bugs, poor performance, or unexpected behavior, let us know so we
                can improve your experience! (Please write the issue in as much detail as possible)
              </div>
            </h3>

            <Button type="primary" danger onClick={() => bugs.setBugModalVisible(true)}>
              <BugOutlined />
              Report a bug
            </Button>
          </div>

          {isAdmin() && <BugTable />}

          <BugModal />
        </BugContext.Provider>
      )}
    </>
  );
};
