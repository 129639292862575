import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../App";
import { Item } from "../Inventory/types";
import { DayOff, Notification, Project, Task } from "./types";

const useDashboard = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loadingProjects, setLoadingProjects] = useState(false);

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const [tasks, setTasks] = useState<Task[]>([]);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const [items, setItems] = useState<Item[]>([]);
  const [loadingItems, setLoadingItems] = useState(false);

  const [daysOff, setDaysOff] = useState<DayOff[]>([]);
  const [loadingDaysOff, setLoadingDaysOff] = useState(false);

  const { apiClient } = useContext(ApiClientContext);

  const fetchProjects = async () => {
    try {
      setLoadingProjects(true);
      const { data } = await apiClient("/api/projects/dashboard");
      setProjects(data.response);
      setLoadingProjects(false);
    } catch (err) {
      setLoadingProjects(false);
      console.warn(err);
    }
  };

  const fetchNotifications = async () => {
    try {
      setLoadingNotifications(true);
      const { data } = await apiClient.get("/api/notifications/dashboard");
      setNotifications(data);
      setLoadingNotifications(false);
    } catch (err) {
      setLoadingNotifications(false);
      console.warn(err);
    }
  };

  const fetchTasks = async () => {
    try {
      setLoadingTasks(true);
      const { data } = await apiClient.get("/api/tasks/dashboard");
      setTasks(data);
      setLoadingTasks(false);
    } catch (err) {
      setLoadingTasks(false);
      console.warn(err);
    }
  };

  const fetchItems = async () => {
    try {
      setLoadingItems(true);
      const { data } = await apiClient.get("/api/items/my-items");
      setItems(data);
      setLoadingItems(false);
    } catch (err) {
      setLoadingItems(false);
      console.warn(err);
    }
  };

  const fetchDaysOff = async () => {
    try {
      setLoadingDaysOff(true);
      const { data } = await apiClient.get("/api/time-off/dashboard");
      setDaysOff(data.response);
      setLoadingDaysOff(false);
    } catch (err) {
      setLoadingDaysOff(false);
      console.warn(err);
    }
  };

  const markTaskDone = async (taskId: string) => {
    try {
      setLoadingTasks(true);
      const { data } = await apiClient.post(`/api/tasks/done/${taskId}`);
      fetchTasks();
      setLoadingTasks(false);
    } catch (err) {
      setLoadingTasks(false);
      console.warn(err);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchNotifications();
    fetchTasks();
    fetchItems();
    fetchDaysOff();
  }, []);

  return {
    projects,
    loadingProjects,
    notifications,
    loadingNotifications,
    tasks,
    loadingTasks,
    items,
    loadingItems,
    daysOff,
    loadingDaysOff,
    markTaskDone,
  };
};

export default useDashboard;
