import { Button, Form, Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";
import { useContext } from "react";
import { ProfileContext } from ".";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../styles/StyledComponents";
import { ChangePasswordFormValues } from "./types";

interface ChangePasswordDialogProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
}

export const ChangePasswordDialog = (props: ChangePasswordDialogProps) => {
  const { isVisible, setIsVisible } = props;
  const handleCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };
  const [form] = Form.useForm();
  const { changePassword } = useContext(ProfileContext);
  const handleFinish = async (values: ChangePasswordFormValues) => {
    try {
      await changePassword(values);
      handleCancel();
    } catch (err) {
      //@ts-ignore
      message.error(err);
    }
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      closeIcon={<RoundCloseIcon />}
    >
      <Form form={form} onFinish={handleFinish}>
        <Form.Item
          label={
            <FormLabel style={{ textAlign: "left" }}>
              Current Password
            </FormLabel>
          }
          name="currentPassword"
          rules={[
            { required: true, message: "Please enter your current password" },
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          label={
            <FormLabel style={{ textAlign: "left" }}>New Password</FormLabel>
          }
          name="newPassword"
          rules={[{ required: true, message: "Please enter new password" }]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item
          label={
            <FormLabel style={{ textAlign: "left" }}>
              Confirm Password
            </FormLabel>
          }
          name="confirmPassword"
          hasFeedback
          rules={[
            { required: true, message: "Please confirm your password!" },
            ({ getFieldValue }) => ({
              validator(rules, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary" block>
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
