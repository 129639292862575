import React, { useContext, useState } from "react";
import { SessionsDayTask } from "../../types";
import { Row, Col, Collapse, Tooltip, Button } from "antd";
import { getTotal } from "../../../utils";
import { HOURS_COLOR } from "./SessionsHistory";
import { ReplayIcon } from "../../../../../assets/icons/CustomIcons";
import { Session } from "./Session";
import { TrackerContext } from "../Tracker/utils/types";
const { Panel } = Collapse;

interface TaskProps {
  task: SessionsDayTask;
  isFirst?: boolean;
  isLast?: boolean;
}

export const Task: React.FC<TaskProps> = ({ task, isFirst, isLast }) => {
  const [isHovered, setHovered] = useState(false);
  const [isReadMore, setIsReadMore] = useState(true);
  const maxChar = 60;
  const { startTracking } = useContext(TrackerContext);
  const toggleReadMore = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsReadMore(!isReadMore);
  };

  const handleStartTaskClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    startTracking(task.projectId, task.taskId);
  };

  return (
    <Collapse
      bordered={false}
      ghost
      style={{
        backgroundColor: isHovered ? "#fafafa" : "white",
        padding: "16px",
        borderRadius: `${isFirst ? "12" : "0"}px ${isFirst ? "12" : "0"}px ${
          isLast ? "12" : "0"
        }px ${isLast ? "12" : "0"}px `,
      }}
    >
      <Panel
        className="task"
        showArrow={false}
        key={task.taskId}
        style={{
          backgroundColor: isHovered ? "#fafafa" : "white",
        }}
        header={
          <Row
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
            align="middle"
          >
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#f1f1f1",
                    width: "fit-content",
                    height: "fit-content",
                    padding: "4px 9px",
                    fontSize: "14px",
                    borderRadius: "2px",
                    fontWeight: 600,
                    color: "#4a4a4a",
                  }}
                >
                  {task.sessions.length}
                </div>
                <div>
                  <div
                    style={{
                      color: task.color,
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    {task.projectName}
                  </div>
                  <div
                    style={{
                      textAlign: "start",
                    }}
                  >
                    {task.clientName}
                  </div>
                </div>
              </div>
            </Col>
            <Col span={4} style={{ textAlign: "start" }}>
              <strong>{task.taskName}</strong>
              <br />
              {task.sessions[0]?.technology}
            </Col>
            <Col
              span={8}
              style={{ textAlign: task.description ? "start" : "center" }}
            >
              {task.description ? (
                task.description.length > maxChar ? (
                  <>
                    <span>
                      {isReadMore
                        ? task.description.slice(0, maxChar) + "..."
                        : task.description}
                    </span>
                    <Button type="link" onClick={toggleReadMore}>
                      {isReadMore ? "Read more" : "Read less"}
                    </Button>
                  </>
                ) : (
                  task.description
                )
              ) : (
                <i>No description.</i>
              )}
            </Col>

            <Col span={3}>
              <b
                style={{
                  color: HOURS_COLOR,
                  textAlign: "end",
                  fontSize: "16px",
                }}
              >
                {getTotal(task.time)}
              </b>
            </Col>

            <Col
              style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip title="Start working on this task">
                <Button type="link" onClick={handleStartTaskClick}>
                  <ReplayIcon fillColor="#137AE9" />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        }
      >
        <div style={{ padding: "8px" }}>
          {task.sessions.map((session) => (
            <Session session={session} taskId={task.taskId} key={session.id} />
          ))}
        </div>
      </Panel>
    </Collapse>
  );
};
