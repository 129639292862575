import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../App";
import { Timezone } from "../types";

const useTimezones = () => {
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const { apiClient } = useContext(ApiClientContext);

  const fetchTimezones = async () => {
    try {
      const { data } = await apiClient.get("/api/common/timezones");
      setTimezones(data);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      setError(err.response.data.message);
    }
  };

  useEffect(() => {
    fetchTimezones();
  }, []);

  return {
    timezones,
    isLoading,
    error,
  };
};

export default useTimezones;
