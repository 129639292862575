import { DeleteOutlined } from "@ant-design/icons";
import { Table, Modal, Tooltip } from "antd";
import { useState } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ClientsContext } from ".";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { EditFieldIcon } from "../../../assets/icons/EditFieldIcon";
import { TableHeader, TableText } from "../../../styles/StyledComponents";
import { Client } from "../types";
import ClientDialog from "./ClientDialog";

export default function ClientsTable(props: any) {
  const { isLoading, deleteClient } = useContext(ClientsContext);
  const [editModal, setEditModal] = useState(false);
  const [clientToEdit, setClientToEdit] = useState<Client>();

  const history = useHistory();

  const onDelete = (client: Client) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${client.name} client?`,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      okType: "danger",
      okText: "Delete",

      onOk() {
       deleteClient(client.id);
      },
    });
  };

  const onEdit = (client: Client) => {
    setClientToEdit(client);
    setEditModal(true);
  };
  return (
    <div>
      <ClientDialog isVisible={editModal} setIsVisible={setEditModal} client={clientToEdit} />
      <Table dataSource={props.clients} loading={isLoading} rowKey="id" pagination={false}>
        <Table.Column<Client>
          title={<TableHeader>Client</TableHeader>}
          dataIndex="name"
          render={(name, row) => (
            <TableText
              style={{ cursor: "pointer", color: "#137AE9", width: "fit-content" }}
              onClick={() => history.push(`/clients/${row.id}`)}
            >
              {name}
            </TableText>
          )}
        />
        <Table.Column
          title={<TableHeader>City</TableHeader>}
          dataIndex="town"
          render={(val) => <TableText>{val}</TableText>}
        />
        <Table.Column
          title={<TableHeader>Country</TableHeader>}
          dataIndex="country"
          render={(val) => <TableText>{val}</TableText>}
        />
        <Table.Column<Client>
          title={<TableHeader>Timezone</TableHeader>}
          render={(val, row) => (
            <TableText>
              {row.timeZoneName} GMT{row.timeZone}
            </TableText>
          )}
        />
        <Table.Column<Client>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => (
            <div>
              <Tooltip title="Edit client">
                <EditFieldIcon style={{ cursor: "pointer" }} onClick={() => onEdit(row)} />
              </Tooltip>
              <Tooltip title="Delete client">
                <DeleteFieldIcon style={{ cursor: "pointer" }} onClick={() => onDelete(row)} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
    </div>
  );
}
