import { Col, Row, Image, Typography } from "antd";
import React from "react";
import logo from "../../assets/images/logo_black.png";

export const ComingSoon = () => {
  return (
    <div>
      <Col>
        <Row justify="center" style={{ margin: 20 }}>
          <Typography.Title level={1}>Coming soon</Typography.Title>
        </Row>
        <Row justify="center">
          <Image src={logo} preview={false} />
        </Row>
        <Row justify="center">
          <Typography.Title level={3} style={{ margin: 20 }}>
            We are working on bringing new features to the app
          </Typography.Title>
        </Row>
      </Col>
    </div>
  );
};
