import { useState } from "react";
import {
  FilterInterface,
  Filters,
  NewEmployee,
  ProjectReport,
  ProjectType,
  ReportSortingCriteria,
  ReportSortingDirection,
  ReportsFiltersContextInterface,
  SortOptions,
} from "./types";

const useFilters = (): ReportsFiltersContextInterface => {
  const [data, setData] = useState<NewEmployee[]>([]);
  const [cols, setCols] = useState<ProjectReport[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [projectType, setProjectType] = useState<ProjectType | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<Filters[]>([]);

  // in order to keep track of the initial rows and cols
  const [initialRows, setInitialRows] = useState<FilterInterface[]>([]);
  const [initialCols, setInitialCols] = useState<FilterInterface[]>([]);

  // in order to keep track of the selected rows and cols
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedCols, setSelectedCols] = useState<string[]>([]);

  const [sortOptions, setSortOptions] = useState<SortOptions>({
    sortBy: ReportSortingCriteria.EmployeeName,
    order: ReportSortingDirection.Ascending,
  });

  const [hideProjectsWithNoHours, setHideProjectsWithNoHours] =
    useState<boolean>(false);

  const toggleOrder = () => {
    setSortOptions((prevState) => {
      return {
        ...prevState,
        order:
          prevState.order === ReportSortingDirection.Ascending
            ? ReportSortingDirection.Descending
            : ReportSortingDirection.Ascending,
      };
    });
  };

  const findRowNameById = (id: string) => {
    const row = initialRows.find((row) => row.id === id);
    return row ? `${row.filterName}` : "";
  };

  const findColNameById = (id: string) => {
    const col = initialCols.find((col) => col.id === id);
    return col ? col.filterName : "";
  };

  const addFilter = (filterId: string, filterType: "row" | "col") => {
    const filterName =
      filterType === "row"
        ? findRowNameById(filterId)
        : findColNameById(filterId);
    setSelectedFilters([
      ...selectedFilters,
      { id: filterId, filterName, filterType },
    ]);
  };

  const removeFilter = (filterId: string, filterType: "row" | "col") => {
    setSelectedFilters(
      selectedFilters.filter(
        (f) => !(f.id === filterId && f.filterType === filterType)
      )
    );

    if (filterType === "row") {
      setSelectedRows(selectedRows.filter((row) => row !== filterId));
    } else if (filterType === "col") {
      setSelectedCols(selectedCols.filter((col) => col !== filterId));
    }
  };

  const handleRowSelection = (rowId: string) => {
    if (selectedRows.includes(rowId)) {
      removeFilter(rowId, "row");
    } else {
      addFilter(rowId, "row");
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const handleColSelection = (colId: string) => {
    if (selectedCols.includes(colId)) {
      removeFilter(colId, "col");
    } else {
      addFilter(colId, "col");
      setSelectedCols([...selectedCols, colId]);
    }
  };

  const handleSortChange = (newSortBy: ReportSortingCriteria) => {
    setSortOptions((prevOptions) => ({
      sortBy: newSortBy,
      order: ReportSortingDirection.Ascending,
    }));
  };
  return {
    data,
    setData,
    cols,
    setCols,
    date,
    setDate,
    isOpen,
    setIsOpen,
    sortOptions,
    toggleOrder,
    handleSortChange,
    selectedRows,
    setSelectedRows,
    selectedCols,
    setSelectedCols,
    hideProjectsWithNoHours,
    setHideProjectsWithNoHours,
    selectedFilters,
    setSelectedFilters,
    addFilter,
    removeFilter,
    handleRowSelection,
    handleColSelection,
    projectType,
    setProjectType,
    initialRows,
    setInitialRows,
    initialCols,
    setInitialCols,
  };
};

export default useFilters;
