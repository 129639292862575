import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext, UserContext } from "../../App";
import { useUserRoles } from "../../_core/hooks/useUserRoles";

export const useBugs = () => {
  const [bugModalVisible, setBugModalVisible] = useState<boolean>(false);
  const [bugText, setBugText] = useState<string>("");
  const [length, setLength] = useState<number>(0);
  const [bugs, setBugs] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { apiClient } = useContext(ApiClientContext);

  const { user } = useContext(UserContext);
  const { isAdmin } = useUserRoles();

  const sendBug = async () => {
    if (bugText === "") {
      message.info("please enter issue");
      return;
    }

    setLoading(true);
    try {
      const response = await apiClient.post("/api/bugs", {
        content: bugText,
      });

      if (response.status === 200) {
        setBugModalVisible(false);
        if (isAdmin()) getBugs();
        message.success("Bug report sent. Thank you for your help!");
      }
    } catch (error) {
      message.error("Could not send bug report");
    }
    setLoading(false);
  };

  const getBugs = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/bugs");
      if (response.status === 200) {
        setBugs(response.data);
      }
    } catch (error) {
      message.error("could not get bug list");
    }
    setLoading(false);
  };

  const deleteBug = async (bugId: string) => {
    setLoading(true);
    try {
      const response = await apiClient.delete(`/api/bugs/${bugId}`);

      if (response.status === 200) {
        getBugs();
        message.success("Bug deleted");
      }
    } catch (error) {
      message.error("Could not delete bug");
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isAdmin()) {
      getBugs();
    }
  }, []);

  return {
    deleteBug,
    sendBug,
    loading,
    setLoading,
    bugModalVisible,
    setBugModalVisible,
    bugs,
    setBugs,
    bugText,
    setBugText,
    length,
    setLength,
  };
};
