import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { SearchIcon } from "../../../../assets/icons/SearchIcon";
import { Brand } from "../../types";
import { BrandDialog } from "./BrandDialog";
import { BrandsTable } from "./BrandsTable";
import useAllBrands from "./useAllBrands";

interface AllBrandsContextInterface {
  brands: Brand[];
  isLoading: boolean;
  addBrand(brand: string): any;
  deleteBrand(brandId: string): any;
  editBrand(brandId: string, name: string): any;
  searchBrands(searchVal: string): any;
}
//@ts-ignore
export const AllBrandsContext = React.createContext<AllBrandsContextInterface>();
export const AllBrands = () => {
  const ret = useAllBrands();
  const [addBrandDialog, setAddBrandDialog] = useState(false);
  const searchWithDebounce = useDebouncedCallback((value) => {
    ret.searchBrands(value);
  }, 800);
  return (
    <AllBrandsContext.Provider value={ret}>
      <Row style={{ marginBottom: 10 }}>
        <Col flex="5">
          <Input
            onChange={(e) => searchWithDebounce(e.target.value)}
            placeholder="Search here"
            prefix={<SearchIcon />}
            style={{ borderRadius: 5 }}
          />
        </Col>
        <Col flex="1" style={{ marginLeft: 3 }}>
          <Button
            style={{ borderRadius: 5 }}
            block
            type="primary"
            onClick={() => setAddBrandDialog(true)}
          >
            Add Brand
          </Button>
        </Col>
      </Row>

      <BrandsTable />
      <BrandDialog isVisible={addBrandDialog} setIsVisible={setAddBrandDialog} />
    </AllBrandsContext.Provider>
  );
};
