import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface EventPopoverProps {
  position: { x: number; y: number } | null;
  onClose: () => void;
  children: React.ReactNode;
}

const EventPopover: React.FC<EventPopoverProps> = ({
  position,
  onClose,
  children,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!position) return null;

  return ReactDOM.createPortal(
    <div
      ref={popoverRef}
      style={{
        position: "fixed",
        top: position.y,
        left: position.x,
        zIndex: 2010,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default EventPopover;
