import { Card, Col, Divider, Row, Skeleton } from "antd";
import React, { useContext } from "react";
import { ManageBrandContext } from ".";
import { OpenFieldIcon } from "../../../../assets/icons/OpenFieldIcon";
import { CardTitle } from "../../../../styles/StyledComponents";

export const ModelsAssigned = () => {
  const { brand, isLoading } = useContext(ManageBrandContext);
  return (
    <Card
      type="inner"
      title={<CardTitle>Models Assigned</CardTitle>}
      bodyStyle={{ padding: 10, minHeight: 100 }}
    >
      <Skeleton loading={isLoading}>
        {brand?.models?.length ? (
          brand?.models?.map((model, index) => (
            <Row justify="space-between" key={model.id} align="middle">
              <Col>
                <div
                  style={{ fontWeight: 600, fontSize: 14, lineHeight: "17px", color: "#64748B" }}
                >
                  {model.name}
                </div>
              </Col>
              <Col>
                <OpenFieldIcon style={{ cursor: "pointer" }} />
              </Col>
              {brand?.models
                ? !!brand?.models[index + 1]
                : false && <Divider style={{ margin: 0 }} />}
            </Row>
          ))
        ) : (
          <div style={{ fontWeight: 600, fontSize: 14, lineHeight: "17px", color: "#64748B" }}>
            No models assigned
          </div>
        )}
      </Skeleton>
    </Card>
  );
};
