import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import React, { useContext } from "react";
import { EditSessionContext } from "../Session";
import { isLoadingAnUpdate } from "./utils";

export const Date: React.FC = () => {
  const { sessionState, setSessionState, loading } =
    useContext(EditSessionContext);
  const handleChange = (value: Moment | null, dateString: string) => {
    if (sessionState.start.split("T")[0] !== dateString) {
      const oldTime = moment.utc(sessionState.start).format("HH:mm:ss");

      const newDateTime = moment.utc(`${dateString}T${oldTime}`).toISOString();

      setSessionState({ ...sessionState, start: newDateTime });
    }
  };

  const isDateInFutureOrNotCurrentMonth = (currentDate: Moment) => {
    const today = moment();
    return (
      currentDate.isAfter(today) ||
      currentDate.month() !== today.month() ||
      currentDate.year() !== today.year()
    );
  };

  return (
    <>
      {loading.date ? (
        <div style={{ padding: "0px 12px 0px 12px" }}>
          <LoadingOutlined
            style={{
              fontSize: "16px",
              color: "black",
              width: "28px",
            }}
          />
        </div>
      ) : (
        <DatePicker
          className="icon-date-picker"
          bordered={false}
          suffixIcon={
            <CalendarOutlined style={{ color: "black", fontSize: "24px" }} />
          }
          picker="date"
          style={{
            height: "100%",
            cursor: "pointer",
          }}
          placeholder=""
          allowClear={false}
          disabled={isLoadingAnUpdate(loading)}
          value={moment.utc(sessionState.start)}
          onChange={handleChange}
          disabledDate={isDateInFutureOrNotCurrentMonth}
        />
      )}
    </>
  );
};
