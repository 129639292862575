import { Spin, Table } from "antd";
import { useEffect, useMemo } from "react";
import styled from "styled-components";
import { useReportsFilters } from "../ReportsFiltersContext";
import { TransformedReport } from "../types";
import { useReportsData } from "./ReportsDataContext";

const StyledTable = styled(Table)`
  max-height: 75vh;
  overflow-x: auto;

  .ant-table-cell {
    border-right: 1px solid #ddd;
  }

  .ant-table-row > .ant-table-cell:last-child {
    border-right: none;
  }
`;

const ReportsTable = () => {
  const { tableColumns, loading, allProjectsTotalHours } = useReportsData();
  const {
    setSelectedFilters,
    setSelectedRows,
    selectedRows,
    handleRowSelection,
    selectedFilters,
    data,
  } = useReportsFilters();
  const transformedReports: TransformedReport[] = useMemo(() => {
    return data.map((newReport) => {
      return {
        employee: {
          userId: newReport.userId,
          fullName: newReport.fullName,
          numberOfHoursContracted: newReport.numberOfHoursContracted,
          totalHoursWorked: newReport.totalHoursWorked,
          timeOffHours: newReport.timeOffHours,
          grandTotal: newReport.grandTotal,
          totalWorkingHours: newReport.totalWorkingHours,
          profilePicturePath: newReport.profilePicturePath,
        },
        ...newReport.projectsReport.reduce(
          (acc, projectReport) => ({
            ...acc,
            [projectReport.projectId]: {
              projectId: projectReport.projectId,
              projectName: projectReport.projectName,
              workedHours: projectReport.workedHours,
              color: projectReport.color,
            },
          }),
          {}
        ),
        totalHours: {
          totalHoursWorked: newReport.totalHoursWorked,
          timeOffHours: newReport.timeOffHours,
          // 'totalForecastTime', you can add it here
          // totalForecastTime: newReport.totalForecastTime,
        },
      };
    });
  }, [data]);

  return loading ? (
    <Spin
      size="large"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    ></Spin>
  ) : (
    <>
      <StyledTable
        dataSource={transformedReports}
        // it appears that antd does not support multiple typing for columns
        columns={tableColumns as any}
        scroll={{ x: "max-content" }}
        sticky
        pagination={false}
        onRow={(record) => {
          return {
            onClick: (e) => {
              const isCtrlPressed = e.ctrlKey;
              // @ts-ignore
              const rowId = record.employee.userId; // or whatever property uniquely identifies the row
              if (!isCtrlPressed) {
                if (rowId) {
                  handleRowSelection(rowId);
                  if (!selectedRows.includes(rowId)) {
                    setSelectedFilters([
                      ...selectedFilters,
                      {
                        id: rowId,
                        // @ts-ignore
                        filterName: `${record.employee.fullName}`,
                        filterType: "row",
                      },
                    ]);
                  }
                }
              } else {
                setSelectedRows([rowId]);
                setSelectedFilters([
                  ...selectedFilters.filter((sf) => sf.filterType === "col"),
                  {
                    id: rowId,
                    // @ts-ignore
                    filterName: `${record.employee.fullName}`,
                    filterType: "row",
                  },
                ]);
              }
            },
          };
        }}
        summary={() => {
          return (
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>
                <div style={{ fontSize: "32px", fontWeight: "bold" }}>
                  {" "}
                  Total
                </div>
              </Table.Summary.Cell>
              {tableColumns.slice(1, -1).map((col, index) => {
                return (
                  <Table.Summary.Cell index={index + 1} key={col.key}>
                    <div
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      {/* @ts-ignore */}
                      {col.workedHours}h
                    </div>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={tableColumns.length}>
                <div
                  style={{
                    fontSize: "32px",
                    fontWeight: "bold",
                    textAlign: "right",
                  }}
                >
                  {/* changed it with the total hours from JSON TODO */}
                  {allProjectsTotalHours}h
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </>
  );
};
export default ReportsTable;
