import { Form, Input, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuthFlow } from "./useAuthFlow";

const GreyText = styled.span`
  color: #a8a8a8;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
`;

export const Register = () => {
  const { handleRegister, registerFields, loading, alert } = useAuthFlow();

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 400 }}>
      <Form
        onFinish={handleRegister}
        scrollToFirstError
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {registerFields ? (
          <>
            <h1
              style={{
                fontSize: 40,
                fontWeight: "bold",
                marginBottom: 4,
                color: "#06062D",
              }}
            >
              Register
            </h1>
            <GreyText>Welcome to the team!<br/>Please activate your account</GreyText>

            <GreyText>Password</GreyText>

            <Form.Item
              name="password"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Please input your password" }]}
            >
              <Input.Password
                size="large"
                style={{ borderRadius: 8, borderWidth: 2 }}
                maxLength={100}
              />
            </Form.Item>

            <GreyText>Confirm Password</GreyText>

            <Form.Item
              name="confirm"
              style={{ width: "100%" }}
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please confirm your password" },
                ({ getFieldValue }) => ({
                  validator(rules, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("The passwords do not match");
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                style={{ borderRadius: 8, borderWidth: 2 }}
                maxLength={100}
              />
            </Form.Item>

            <Form.Item style={{ width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "100%",
                  height: 64,
                  borderRadius: 8,
                  backgroundColor: "#137AE9",
                  fontSize: 20,
                }}
              >
                Register
              </Button>
            </Form.Item>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <span style={{ color: "#06062D", fontSize: 16 }}>Already have an account?</span>

              <span style={{ color: "#A8A8A8" }}>
                <Link to="/login">Sign in</Link>
              </span>
            </div>

            <div style={{ width: "100%", justifyContent: "CENTER" }}>
              {loading && <Spin tip="Loading..." />}
            </div>
          </>
        ) : (
          <>
            <h1>Registration completed!</h1>
            <p>
              Please
              <Link to="/login"> Log In </Link> with your new account!
            </p>
          </>
        )}
      </Form>
      {alert && !loading ? (
        <p style={{ marginTop: 8, fontSize: "1.3em", color: "red" }}>{alert}</p>
      ) : null}
    </div>
  );
};
