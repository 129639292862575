import React, { useContext } from "react";
import { Table, Tooltip } from "antd";
import { TableHeader, TableText } from "../../../../styles/StyledComponents";
import { ManageModelContext } from ".";
import { Item } from "../../types";
import { OpenFieldIcon } from "../../../../assets/icons/OpenFieldIcon";
import { useHistory } from "react-router";

export const ItemsAssignedTable = () => {
  const { model, isLoading } = useContext(ManageModelContext);
  const history = useHistory();
  return (
    <Table dataSource={model?.items} rowKey="id" loading={isLoading} pagination={false}>
      <Table.Column<Item>
        title={<TableHeader>Item NR</TableHeader>}
        dataIndex="inventoryNumber"
        render={(itemNr) => <TableText>{itemNr}</TableText>}
      />
      <Table.Column<Item>
        title={<TableHeader>Item Description</TableHeader>}
        dataIndex="description"
        render={(desc) => <TableText>{desc}</TableText>}
      />
      <Table.Column<Item>
        title={<TableHeader>Office</TableHeader>}
        dataIndex="officeCity"
        render={(office) => <TableText>{office}</TableText>}
      />
      <Table.Column<Item>
        title={<TableHeader>Owned by</TableHeader>}
        render={(val, row) => (
          <TableText>
            {row.ownedByFirstName !== "CST"
              ? `${row.ownedByFirstName} ${row.ownedByLastName} `
              : row.ownedByFirstName}
          </TableText>
        )}
      />
      <Table.Column<Item>
        title={<TableHeader>Warranty</TableHeader>}
        dataIndex="warranty"
        render={(warranty) => <TableText>{warranty}</TableText>}
      />
      <Table.Column<Item>
        title={<TableHeader>Actions</TableHeader>}
        render={(val, row) => (
          <Tooltip title="Details">
            <OpenFieldIcon
              style={{ cursor: "pointer" }}
              onClick={() => history.push(`/inventory/items/${row.id}`)}
            />
          </Tooltip>
        )}
      />
    </Table>
  );
};
