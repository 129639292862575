import React from "react";
import { TrackerForm } from "../../utils/types";
import { Task, Technology } from "../../../../types";

export interface IEditSessionModalProps {
  formData: TrackerForm;
  technologies: Technology[];
  tasks: Task[];
  day: moment.Moment;
  startTime: string;
  endTime: string;
  onSubmit: () => void;
  setStartTime: React.Dispatch<React.SetStateAction<string>>;
  setEndTime: React.Dispatch<React.SetStateAction<string>>;
  setDay: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setFormData: React.Dispatch<React.SetStateAction<TrackerForm>>;
  setIsNewTask: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EditSessionModalContext =
  React.createContext<IEditSessionModalProps>({} as IEditSessionModalProps);
