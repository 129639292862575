export function validatePassword(pass: string){
  let hasUpper, hasLower, hasNumber, hasSpecialCharacter;
  hasUpper = hasLower = hasNumber = hasSpecialCharacter = false;
  let i = pass.length;

  while (i--) {
    if (pass.charAt(i) >= "a" && pass.charAt(i) <= "z") hasLower = true;
    else if (pass.charAt(i) >= "A" && pass.charAt(i) <= "Z") hasUpper = true;
    else if (pass.charAt(i) >= "0" && pass.charAt(i) <= "9") hasNumber = true;
    else if ('!"#$%&()*+,-./:;<=>?@[]^_`{|}~'.includes(pass.charAt(i)))
      hasSpecialCharacter = true;
  }

  return (
    hasSpecialCharacter && hasUpper && hasLower && hasNumber && pass.length >= 8
  );
};
