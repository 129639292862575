import { Card, Row, Typography, Spin, Col, Tooltip } from "antd";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { ManageEmployeeContext } from ".";
import { OpenFieldIcon } from "../../../assets/icons/OpenFieldIcon";
import { CardTitle } from "../../../styles/StyledComponents";

export const Items = () => {
  const { employee, isLoading } = useContext(ManageEmployeeContext);
  const history = useHistory();
  return (
    <Card type="inner" style={{ width: "100%" }} title={<CardTitle>Items Owned</CardTitle>}>
      <Spin spinning={isLoading}>
        {employee?.items.length ? (
          employee.items.map((item) => (
            <Row justify="space-between" align="middle" key={item.id}>
              <Col style={{ textAlign: "left" }}>
                <div style={{ fontSize: "1.1em", fontWeight: "bolder" }}>
                  {`${item.brandName} ${item.modelName}`}
                </div>

                <Typography.Text type="secondary">{item.inventoryNumber}</Typography.Text>
              </Col>
              <Tooltip title="Open">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/inventory/my-items/${item.id}`)}
                />
              </Tooltip>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
              No Items yet
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
