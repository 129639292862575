import { Button, Col, Input, Row } from "antd";
import React, {useState } from "react";
import { SearchIcon } from "../../../../assets/icons/SearchIcon";
import { Item } from "../../types";
import { ItemDialog } from "./ItemDialog";
import { ItemsTable } from "./ItemsTable";
import useAllItems from "./useAllItems";

interface AllItemsContextInterface {
  items: Item[];
  isLoading: boolean;
  searchInputValue: string;
  addItem(formValues: any): void;
  editItem(formValues: any, itemId: string): void;
  deleteItem(itemId: string): void;
}
//@ts-ignore
export const AllItemsContext = React.createContext<AllItemsContextInterface>();
export const AllItems = (props : any) => {
  const ret = useAllItems();
  const [addItemDialog, setAddItemDialog] = useState(false);
  const [addToModel, setAddToModel] = useState(props.location.state?.addToModel);
  const { modelId } = (props.location && props.location.state) || {};

  
  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ret.setSearchInputValue(ev.target.value);
  };
  
  return (
    <AllItemsContext.Provider value={ret}>
      <Row style={{ marginBottom: 10 }}>
        <Col flex="5">
          <Input placeholder="Search here" prefix={<SearchIcon />} style={{ borderRadius: 5 }} 
            value={ret.searchInputValue}
            onChange={handleSearchInputChange}/>
        </Col>
        <Col flex="1" style={{ marginLeft: 3 }}>
          <Button
            style={{ borderRadius: 5 }}
            block
            type="primary"
            onClick={() => setAddItemDialog(true)}
          >
            Add Item
          </Button>
        </Col>
      </Row>
      <ItemsTable />
      <ItemDialog isVisible={addItemDialog || addToModel} setAddToModel={setAddToModel} setIsVisible={setAddItemDialog} modelId = {modelId} />
    </AllItemsContext.Provider>
  );
};
