import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.25H5C2.5 1.25 1.25 2.5 1.25 5V13.125C1.25 13.4688 1.53125 13.75 1.875 13.75H10C12.5 13.75 13.75 12.5 13.75 10V5C13.75 2.5 12.5 1.25 10 1.25ZM9.6875 7.96875H7.96875V9.6875C7.96875 9.94375 7.75625 10.1562 7.5 10.1562C7.24375 10.1562 7.03125 9.94375 7.03125 9.6875V7.96875H5.3125C5.05625 7.96875 4.84375 7.75625 4.84375 7.5C4.84375 7.24375 5.05625 7.03125 5.3125 7.03125H7.03125V5.3125C7.03125 5.05625 7.24375 4.84375 7.5 4.84375C7.75625 4.84375 7.96875 5.05625 7.96875 5.3125V7.03125H9.6875C9.94375 7.03125 10.1562 7.24375 10.1562 7.5C10.1562 7.75625 9.94375 7.96875 9.6875 7.96875Z"
      fill="#C4C4C4"
    />
  </svg>
);

export const AddTechnologyIcon = (props: any) => (
  <Icon component={svg} {...props} />
);
