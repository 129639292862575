import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { Model } from "../../types";
import { ItemsAssignedTable } from "./ItemsAssignedTable";
import { ModelDetails } from "./ModelDetails";
import useManageModel from "./useManageModel";

interface ManageModelContextInterface {
  model?: Model;
  isLoading: boolean;
}

//@ts-ignore
export const ManageModelContext = React.createContext<ManageModelContextInterface>();

export const ManageModel = () => {
  const { modelId } = useParams<{ modelId: string }>();
  const ret = useManageModel(modelId);
  const history = useHistory();
  return (
    <ManageModelContext.Provider value={ret}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <Typography.Title level={3}>Manage model</Typography.Title>
        </Col>
        <Col>
          <Button type="primary" style={{ borderRadius: 5 }}
            onClick={() => history.push({pathname: `/inventory/items`, state: {addToModel: true, modelId : modelId}})}>
            Add new item to this model
          </Button>
        </Col>
      </Row>
      <ModelDetails />
      <Typography.Title level={3} style={{ textAlign: "left", marginTop: 10, marginBottom: 10 }}>
        Items assigned to this model
      </Typography.Title>
      <ItemsAssignedTable />
    </ManageModelContext.Provider>
  );
};
