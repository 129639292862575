import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Brand } from "../../types";

const useManageBrand = (brandId: string) => {
  const [brand, setBrand] = useState<Brand>();

  const [isLoading, setIsLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);

  const fetchBrand = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/items/get-brand-by/${brandId}`);

      setBrand(data);
      setIsLoading(false);
    } catch (err) {
      console.warn("err", err);
      setIsLoading(false);
      message.error("Could not fetch brand");
    }
  };

  useEffect(() => {
    fetchBrand();
  }, []);

  return {
    brand,
    isLoading,
  };
};

export default useManageBrand;
