import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useContext } from "react";
import { useState } from "react";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { TechnologiesContext } from "./Index";
import { TechnologyModel } from "./TechnologyModel";
import { typesDict } from "./useTechnologies";
const { Text } = Typography;

interface TableActionsProps {
  record: TechnologyModel;
}

const TableActions: React.FC<TableActionsProps> = ({ record }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const technologyId = record.id;
  const hook = useContext(TechnologiesContext);
  const [formInstance] = Form.useForm();

  const handleEditOk = async () => {
    try {
      await formInstance.validateFields();
      const name = formInstance.getFieldValue("name");
      const type = formInstance.getFieldValue("type");
      await hook.updateTechnology(technologyId, name, type);
      setEditModalVisible(false);
      formInstance.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditCancel = () => {
    formInstance.resetFields();
    setEditModalVisible(false);
  };

  const handleDeleteOk = async () => {
    try {
      await hook.deleteTechnology(technologyId, record.name);
      setDeleteModalVisible(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const showEditModal = () => {
    setEditModalVisible(true);
  };

  const showDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  return (
    <div>
      {/* <Button onClick={showEditModal} type="link" id="button__edit-technology">
        Edit
      </Button>
      <Button
        onClick={showDeleteModal}
        danger
        type="link"
        id="button__delete-technology"
      >
        Delete
      </Button> */}

      <Space size="small">
        <Tooltip title="Edit technology">
          <Button
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            style={{ backgroundColor: "#292D32", color: "white" }}
            onClick={showEditModal}
          />
        </Tooltip>

        <Tooltip title="Delete technology">
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            size="small"
            type="primary"
            danger
            onClick={showDeleteModal}
          />
        </Tooltip>
      </Space>

      {/* EDIT MODAL */}

      <Modal
        visible={editModalVisible}
        closeIcon={<RoundCloseIcon />}
        onOk={handleEditOk}
        onCancel={handleEditCancel}
        okButtonProps={{
          id: "button__save-edit-technology",
          loading: hook.editLoading,
        }}
        okText="Save"
      >
        <h1>Modify {record.name} technology:</h1>
        <Form form={formInstance}>
          <Form.Item
            label="technology name"
            name="name"
            rules={[
              { required: true, message: "The name is required!" },
              {
                max: 20,
                message: "The name should not be longer than 20 characters!",
              },
            ]}
            initialValue={record.name}
          >
            <Input id="input__edit-technology" />
          </Form.Item>

          <Form.Item
            label="type"
            name="type"
            rules={[{ required: true, message: "The type is required!" }]}
            initialValue={record.type.name}
          >
            <Select id="dropdown__edit-technology-type" style={{ width: "200px" }}>
              {Object.keys(typesDict).map((key) => {
                return (
                  <Select.Option value={key}>
                    {
                      //@ts-ignore
                      typesDict[key]
                    }
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {/* DELETE MODAL */}

      <Modal
        visible={deleteModalVisible}
        closeIcon={<RoundCloseIcon />}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        okButtonProps={{
          id: "button__save-delete-technology",
          loading: hook.deleteLoading,
          danger: true,
        }}
        okText="Delete"
      >
        <h1>Delete {record.name} technology?</h1>
      </Modal>
    </div>
  );
};

export const TechnologiesTable: React.FC<{ technologies: TechnologyModel[] }> = ({
  technologies,
}) => {
  const availableColor = (employeesNumber: number) => (employeesNumber > 0 ? "red" : "black");

  const columns = [
    {
      title: "Technology",
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical">
            <Text>{record.name}</Text>
            <Text type="secondary" style={{ fontSize: "14px" }}>
              {record.type.name}
            </Text>
          </Space>
        );
      },
      sorter: (a: TechnologyModel, b: TechnologyModel) =>
        a.name.toLocaleUpperCase() >= b.name.toLocaleUpperCase() ? 1 : -1,
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      sorter: (a: TechnologyModel, b: TechnologyModel) =>
        a.totalEmployees >= b.totalEmployees ? 1 : -1,
    },
    {
      title: "Projects",
      dataIndex: "totalProjects",
    },
    {
      title: "Seniors",
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>W: {record.workingPositions.Senior ?? 0}</Text>
            <Text style={{ color: availableColor(record.available.Senior) }}>
              A: {record.available.Senior ?? 0}
            </Text>
          </Space>
        );
      },
    },
    {
      title: "Mid",
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text style={{ whiteSpace: "nowrap" }}>W: {record.workingPositions.Mid ?? 0}</Text>
            <Text style={{ color: availableColor(record.available.Mid) }}>
              A: {record.available.Mid ?? 0}
            </Text>
          </Space>
        );
      },
    },
    {
      title: "Juniors",
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>W: {record.workingPositions.Junior ?? 0}</Text>
            <Text style={{ color: availableColor(record.available.Junior) }}>
              A: {record.available.Junior ?? 0}
            </Text>
          </Space>
        );
      },
    },
    {
      title: "Interns",
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>W: {record.workingPositions.Intern ?? 0}</Text>
            <Text style={{ color: availableColor(record.available.Intern) }}>
              A: {record.available.Intern ?? 0}
            </Text>
          </Space>
        );
      },
    },
    {
      title: () => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>Hours</Text>
            <Text type="secondary" style={{ fontSize: "14px" }}>
              {moment().format("MMMM")}
            </Text>
          </Space>
        );
      },
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>W: {record.workedThisMonth ?? 0}</Text>
            <Text>F: {record.forecastThisMonth ?? 0}</Text>
          </Space>
        );
      },
    },
    {
      title: () => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>Hours</Text>
            <Text type="secondary" style={{ fontSize: "14px" }}>
              {moment().subtract(1, "months").format("MMMM")}
            </Text>
          </Space>
        );
      },
      render: (record: TechnologyModel) => {
        return (
          <Space direction="vertical" style={{ whiteSpace: "nowrap" }}>
            <Text>W: {record.workedLastMonth ?? 0}</Text>
            <Text>F: {record.forecastLastMonth ?? 0}</Text>
          </Space>
        );
      },
    },
    {
      title: "Actions",
      render: (record: TechnologyModel) => <TableActions record={record} />,
    },
  ];

  return (
    <div>
      <Table
        dataSource={technologies?.map((val: any) => ({
          ...val,
          key: val.id,
        }))}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
