import { useContext } from "react";
import { ApiClientContext } from "../../App";
import mime from "mime-db";
import { message } from "antd";
const useDownloadDocument = () => {
  const { apiClient } = useContext(ApiClientContext);

  const downloadDocument = async (url: string, name: string) => {
    try {
      const res = await apiClient.get(url, {
        responseType: "blob",
      });

      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement("a");
      link.href = fileUrl;
      const { extensions } = mime[res.data.type];
      const extension = extensions?.length ? extensions[0] : "";
      link.setAttribute("download", `${name}.${extension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode ? link.parentNode.removeChild(link) : null;
    } catch (err) {
      console.warn(err);
      message.error("Couldn't download file!");
    }
  };
  return downloadDocument;
};

export default useDownloadDocument;
