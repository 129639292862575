import { Row, Typography, Select, Col } from "antd";
import React, { useContext } from "react";
import { ForecastReportsContext } from ".";
import useEmployees from "../../Employees/AllEmployees/useEmployees";

export const Header = () => {
  const monthsData = Array.from({ length: 12 }, (item, i) => {
    return {
      label: new Date(0, i).toLocaleString("en-US", { month: "long" }),
      value: i + 1,
    };
  });
  const yearsData = Array.from({ length: 2100 - 2021 }, (item, i) => {
    return 2021 + i;
  });
  const {
    year,
    month,
    setEmployeeId,
    setMonth,
    setYear,
    employeeId,
    users: employees,
  } = useContext(ForecastReportsContext);
  return (
    <div>
      <Row justify="space-between" align="middle">
        <Typography.Title level={4}>Forecast reports</Typography.Title>
        <Col>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            style={{ marginRight: 5, width: 150 }}
            placeholder="Select month"
            value={month}
            onSelect={(value: number) => setMonth(value)}
          >
            {monthsData.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toString()
                .toLowerCase()
                .localeCompare(optionB.children.toString().toLowerCase())
            }
            style={{ marginRight: 5, width: 150 }}
            placeholder="Select year"
            value={year}
            onSelect={(value: number) => setYear(value)}
          >
            {yearsData.map((x) => (
              <Select.Option key={x} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
          <Select
            allowClear
            showSearch
            style={{ width: 300 }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            placeholder="Select employee"
            value={employeeId ? employeeId : undefined}
            onSelect={(value: string) => setEmployeeId(value)}
            onClear={() => setEmployeeId("")}
          >
            {employees.map((employee) => (
              <Select.Option key={employee.id} value={employee.id}>
                {`${employee.firstName} ${employee.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};
