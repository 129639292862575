import { Button, Card, Col, Row, Typography } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CardTitle } from "../../../../styles/StyledComponents";
import { Brand } from "../../types";
import { BrandDetails } from "./BrandDetails";
import { ItemsAssigned } from "./ItemsAssigned";
import { ModelsAssigned } from "./ModelsAssigned";
import useManageBrand from "./useManageBrand";

interface ManageBrandContextInterface {
  brand?: Brand;
  isLoading: boolean;
}

//@ts-ignore
export const ManageBrandContext = React.createContext<ManageBrandContextInterface>();

export const ManageBrand = () => {
  const { brandId } = useParams<{ brandId: string }>();
  const ret = useManageBrand(brandId);
  const history = useHistory();

  return (
    <ManageBrandContext.Provider value={ret}>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={3}>Manage Brand</Typography.Title>
        </Col>
        <Col>
          <Button type="primary" style={{ borderRadius: 5 }}
            onClick={() => history.push({pathname: `/inventory/models`, state: {addToBrand: true, brandId : brandId}})}>
            Add new model to this brand
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 10, marginBottom: 10 }}>
        <Col flex="1">
          <BrandDetails />
        </Col>
      </Row>
      <Row>
        <Col flex="1" style={{ paddingRight: 10 }}>
          <ModelsAssigned />
        </Col>
        <Col flex="1">
          <ItemsAssigned />
        </Col>
      </Row>
    </ManageBrandContext.Provider>
  );
};
