import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../App";
import { Technology, User } from "./types";

const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [isUsersLoading, setIsLoading] = useState(false);

  const { apiClient } = useContext(ApiClientContext);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get("/api/users/get-all-with-technologies");
      setUsers(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      message.error("Could not get users");
      setIsLoading(false);
    }
  };

  const getUsersTechnologies = (userId?: string): Technology[] | null => {
    if (userId) {
      const user = users.find((x) => x.id === userId);
      return user ? user.userTechnologies : null;
    }
    return null;
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return { users, isUsersLoading, getUsersTechnologies };
};

export default useUsers;
