import { Row, Typography, Table } from "antd";
import React from "react";
import { TableHeader } from "../../../../styles/StyledComponents";

export const ItemHistory = () => {
  return (
    <div style={{ marginTop: 20 }}>
      <Row>
        <Typography.Title level={4}>Item history</Typography.Title>
      </Row>
      <Table>
        <Table.Column title={<TableHeader>Item Description</TableHeader>} />
        <Table.Column title={<TableHeader>From State</TableHeader>} />
        <Table.Column title={<TableHeader>To State</TableHeader>} />
        <Table.Column title={<TableHeader>Changed by</TableHeader>} />
        <Table.Column title={<TableHeader>Date</TableHeader>} />
      </Table>
    </div>
  );
};
