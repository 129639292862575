import { Col, Row } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

interface ColorCircleProps {
  color: string;
  selected: boolean;
}
export const ColorCircle = styled.div<ColorCircleProps>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 10px;
  border: ${(props) => (props.selected ? "3px solid black" : "")};
  cursor: pointer;
  :hover {
    transform: scale(1.1, 1.1);
  }
`;
export const ColorPicker = ({
  value,
  onChange,
}: {
  value?: string;
  onChange?(value: string): void;
}) => {
  const colors = [
    "#3A6FF5",
    "#31B729",
    "#FA714B",
    "#CA49B7",
    "#00AFB6",
    "#DEA641",
    "#00e5ff",
    "#FF0000",
    "#8d6e63",
    "#ffeb3b",
    "#90caf9",
    "#ef9a9a",
    "#d1c4e9",
    "#311b92",
    "#aed581",
    "#006c67",
    "#FBACBE",
    "#643173",
    "#284B63",
    "#570000",
    "#7CB518",
    "#FB6107",
    "#F3DE2C",
    "#0A1045",
  ];
  const [color, setColor] = useState("");

  const triggerChange = (changedValue: string) => {
    onChange?.(changedValue);
  };

  const onColorChanged = (newColor: string) => {
    setColor(newColor);
    triggerChange(newColor);
  };

  useEffect(() => {
    if (value) {
      setColor(value);
    }
  }, [value]);
  return (
    <Row justify="center">
      {colors.map((_color, index) => (
        <Col key={index}>
          <ColorCircle
            color={_color}
            selected={color === _color}
            onClick={() => onColorChanged(_color)}
          />
        </Col>
      ))}
    </Row>
  );
};
