import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Row, Space, Typography } from "antd";
import React, { createContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { useProjects } from "../AllProjects/useProjects";
import { ProjectDialog } from "../ProjectDialog";
import {
  AddMemberFormValues,
  Project as ProjectInterface,
  ProjectDocument,
} from "../types";
import { AddContactDialog } from "./AddContactDialog";
import { AddDocumentDialog } from "./AddDocumentDialog";
import { AddMemberDialog } from "./AddMemberDialog";
import { ContactsTable } from "./ContactsTable";
import { DocumentsTable } from "./DocumentsTable";
import { ProjectDetails } from "./ProjectDetails";
import { TeamTable } from "./TeamTable";
import useProject from "./useProject";

interface ProjectPageRouteParam {
  projectId: string;
}
interface ProjectContextInterface {
  project?: ProjectInterface;
  isLoading: boolean;
  error: string;
  fetchProject(): void;
  addMember(values: AddMemberFormValues): void;
  removeMember(memberId: string): void;
  addClientContact(contactId: string): void;
  removeClientContact(contactId: string): void;
  documents: ProjectDocument[];
  documentsLoading: boolean;
  addDocument(formValues: any): void;
  deleteDocument(documentId: string): void;
  projectId: string;
}
//@ts-ignore
export const ProjectContext = createContext<ProjectContextInterface>();
export const Project = () => {
  const { projectId } = useParams<ProjectPageRouteParam>();
  const ret = useProject(projectId);
  const [editProjectDialog, setEditProjectDialog] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [addContactDialog, setAddContactDialog] = useState(false);
  const [addDocumentDialog, setAddDocumentDialog] = useState(false);
  const { isAdmin, isTeamLead } = useUserRoles();
  const navigate = useHistory();
  return (
    <ProjectContext.Provider value={{ ...ret, projectId }}>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <ArrowLeftOutlined
          style={{
            fontSize: "24px",
            cursor: "pointer",
            display: "flex",
            justifyItems: "center",
            paddingRight: 8,
            paddingTop: 2,
          }}
          onClick={() => {
            navigate.push("/projects");
          }}
        />
        <Typography.Title level={4}>Manage Project</Typography.Title>
        {(isAdmin() || isTeamLead(projectId)) && (
          <Button
            type="primary"
            style={{ borderRadius: 5 }}
            onClick={() => setEditProjectDialog(true)}
          >
            Edit Project
          </Button>
        )}
      </Row>
      <ProjectDetails />

      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        <Typography.Title level={4} style={{ marginLeft: 5 }}>
          Documents
        </Typography.Title>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setAddDocumentDialog(true)}
        >
          Add document
        </Button>
      </Row>
      <DocumentsTable />

      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        <Typography.Title level={4} style={{ marginLeft: 5 }}>
          Contact People
        </Typography.Title>
        {(isAdmin() || isTeamLead(projectId)) && (
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setAddContactDialog(true)}
          >
            Add Contact
          </Button>
        )}
      </Row>
      <ContactsTable />
      <Row justify="space-between" align="middle" style={{ marginTop: 20 }}>
        <Typography.Title level={4} style={{ marginLeft: 5 }}>
          Team Members
        </Typography.Title>
        {(isAdmin() || isTeamLead(projectId)) && (
          <Button
            type="link"
            icon={<PlusOutlined />}
            onClick={() => setAddMemberDialog(true)}
          >
            Add Member
          </Button>
        )}
      </Row>

      <TeamTable />
      <ProjectDialog
        isVisible={editProjectDialog}
        setIsVisible={setEditProjectDialog}
        project={ret.project}
        updateCallback={ret.fetchProject}
      />
      <AddMemberDialog
        isVisible={addMemberDialog}
        setIsVisible={setAddMemberDialog}
      />
      <AddContactDialog
        isVisible={addContactDialog}
        setIsVisible={setAddContactDialog}
      />
      <AddDocumentDialog
        isVisible={addDocumentDialog}
        setIsVisible={setAddDocumentDialog}
      />
    </ProjectContext.Provider>
  );
};
