import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33333 3.33301H13.3333"
      stroke="#A8A8A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33333 5.33301H11.3333"
      stroke="#A8A8A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7.66634C14 11.1663 11.1667 13.9997 7.66667 13.9997C4.16667 13.9997 1.33333 11.1663 1.33333 7.66634C1.33333 4.16634 4.16667 1.33301 7.66667 1.33301"
      stroke="#A8A8A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 14.6663L13.3333 13.333"
      stroke="#A8A8A8"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = (props: any) => <Icon component={svg} {...props} />;
