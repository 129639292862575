import { DeleteOutlined } from "@ant-design/icons";
import { Col, Row, Table, Tooltip, Typography, Modal } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProjectsContext } from "..";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { EditFieldIcon } from "../../../assets/icons/EditFieldIcon";
import { OpenFieldIcon } from "../../../assets/icons/OpenFieldIcon";
import { ProjectTag, TableHeader } from "../../../styles/StyledComponents";
import { ProjectDialog } from "../ProjectDialog";
import { ClientContact, Project, Technology } from "../types";

export const ProjectsTable = () => {
  const { filteredProjects, isLoading, deleteProject } = useContext(ProjectsContext);
  const [editDialog, setEditDialog] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<Project>();
  const history = useHistory();

  const { isAdmin, isTeamLead } = useUserRoles();

  const handleEdit = (project: Project) => {
    setProjectToEdit(project);
    setEditDialog(true);
  };

  const handleDelete = (project: Project) => {
    Modal.confirm({
      title: `Sure you want to delete project ${project.name}?`,
      icon: <DeleteOutlined />,
      onOk() {
        return deleteProject(project.id);
      },
      okText: "Delete",
    });
  };
  return (
    <div>
      <Table
        dataSource={filteredProjects}
        rowKey="id"
        loading={isLoading}
        pagination={false}
        className="table-striped-rows"
      >
        <Table.Column<Project>
          title={<TableHeader>Project / Client</TableHeader>}
          render={(val, row) => (
            <Col>
              <ProjectTag
                style={{ fontSize: 17, marginBottom: 5, width: "fit-content" }}
                color={row.color}
              >
                {row.name}
              </ProjectTag>
              <Typography.Text type="secondary">{row.client.name}</Typography.Text>
            </Col>
          )}
        />
        <Table.Column<Project>
          title={<TableHeader>Team Lead</TableHeader>}
          render={(val, row) => {
            if (row.teamLeader) {
              return (
                <Row>
                  <Col>
                    <Typography.Title level={5} style={{ margin: 0 }}>
                      {`${row.teamLeader.firstName} ${row.teamLeader.lastName}`}
                    </Typography.Title>
                    <Typography.Text type="secondary">{row.teamLeader.email}</Typography.Text>
                  </Col>
                </Row>
              );
            } else {
              return (
                <Row>
                  <Typography.Text type="secondary">No team lead</Typography.Text>
                </Row>
              );
            }
          }}
        />
        <Table.Column<Project>
          title={<TableHeader>Contact Person</TableHeader>}
          render={(val, row) => {
            if (row.client.contacts.length) {
              return row.client.contacts.map((contact: ClientContact) => {
                if (contact) {
                  return (
                    <Row key={contact.id}>
                      <Col>
                        <Typography.Title level={5} style={{ margin: 0 }}>
                          {contact.name}
                        </Typography.Title>
                        <Typography.Text type="secondary">{contact.email}</Typography.Text>
                        <br />
                        <Typography.Text type="secondary">
                          {`${contact.timeZoneName} ${contact.timeZone}`}
                        </Typography.Text>
                      </Col>
                    </Row>
                  );
                }
              });
            } else {
              return (
                <Row>
                  <Typography.Text type="secondary">No contact persons</Typography.Text>
                </Row>
              );
            }
          }}
        />
        <Table.Column<Project>
          title={<TableHeader>Technology</TableHeader>}
          render={(val, row) => {
            if (row.technologies.length) {
              return row.technologies.map((tech: Technology, index) => (
                <div key={tech.id}>
                  <div
                    style={{
                      fontSize: "1.1em",
                      color: "gray",
                    }}
                  >
                    {tech.name} {row.technologies[index + 1] ? "," : ""}
                  </div>
                </div>
              ));
            } else {
              return (
                <Row>
                  <Typography.Text type="secondary">No technologies</Typography.Text>
                </Row>
              );
            }
          }}
        />
        {(isAdmin() || filteredProjects.some((project) => isTeamLead(project.id))) && (
          <Table.Column<Project>
            title={
              <TableHeader>
                <Col>
                  <div>Hours</div>
                  <Typography.Text type="secondary" style={{ fontSize: "0.9em" }}>
                    {moment().subtract(1, "M").format("MMMM")}
                  </Typography.Text>
                </Col>
              </TableHeader>
            }
            render={(val, row) => {
              if (isAdmin() || isTeamLead(row.id)) {
                return (
                  <div>
                    <Col>
                      <div>
                        <span style={{ fontWeight: "bold" }}>W: </span>
                        {row.lastMonthWorked}
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>F: </span>
                        {row.lastMonthForecast}
                      </div>
                    </Col>
                  </div>
                );
              }
            }}
          />
        )}
        {(isAdmin() || filteredProjects.some((project) => isTeamLead(project.id))) && (
          <Table.Column<Project>
            title={
              <TableHeader>
                <Col>
                  <div>Hours</div>
                  <Typography.Text type="secondary" style={{ fontSize: "0.9em" }}>
                    {moment().format("MMMM")}
                  </Typography.Text>
                </Col>
              </TableHeader>
            }
            render={(val, row) => {
              if (isAdmin() || isTeamLead(row.id)) {
                return (
                  <Col>
                    <div>
                      <span style={{ fontWeight: "bold" }}>W: </span>
                      {row.currentMonthWorked}
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>F: </span>
                      {row.currentMonthForecast}
                    </div>
                  </Col>
                );
              }
            }}
          />
        )}
        <Table.Column<Project>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => (
            <div>
              <Tooltip title="View details">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/projects/${row.id}`)}
                />
              </Tooltip>

              {(isAdmin() || isTeamLead(row.id)) && (
                <>
                  <Tooltip title="Edit project">
                    <EditFieldIcon style={{ cursor: "pointer" }} onClick={() => handleEdit(row)} />
                  </Tooltip>
                  <Tooltip title="Delete project">
                    <DeleteFieldIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(row)}
                    />
                  </Tooltip>
                </>
              )}
            </div>
          )}
        />
      </Table>
      <ProjectDialog isVisible={editDialog} setIsVisible={setEditDialog} project={projectToEdit} />
    </div>
  );
};
