import React, { useContext } from "react";
import styled from "styled-components";
import { PlayIcon } from "../../../../../assets/icons/CustomIcons";
import { TrackerContext } from "./utils/types";
const StyledButton = styled.button`
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-right: 10px;
  transition: all 0.3s ease;
  background-color: ${(props) => props.color || "#237804"};
  color: white;

  &:hover {
    background-color: ${(props) => `${props.color}cc` || "#237804cc"};
    transform: scale(1.01);
  }
`;
const SaveButton: React.FC = () => {
  const {
    trackerLoading,
    startTracking,
    typeOfTracking,
    createSession,
    stopTracking,
    isTracking,
  } = useContext(TrackerContext);

  return (
    <>
      {typeOfTracking === "Manual" ? (
        <StyledButton
          onClick={() => createSession()}
          disabled={isTracking}
          color="#237804"
        >
          {trackerLoading ? "Loading..." : "Save progress"}
        </StyledButton>
      ) : (
        <>
          {!isTracking ? (
            <StyledButton
              onClick={() => startTracking()}
              disabled={isTracking}
              color="#1890ff" // Ant Design blue color
            >
              {trackerLoading ? (
                "Loading..."
              ) : (
                <>
                  <PlayIcon color="white" />
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    Start Tracking
                  </div>
                </>
              )}
            </StyledButton>
          ) : (
            <StyledButton
              onClick={stopTracking}
              disabled={!isTracking}
              color="#f5222d"
            >
              Stop Tracking
            </StyledButton>
          )}
        </>
      )}
    </>
  );
};
export default SaveButton;
