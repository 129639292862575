import {
  Card,
  Col,
  Image,
  Row,
  Avatar,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Skeleton,
  Modal,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ManageEmployeeContext } from ".";
import { functions, legalPositions } from "../../../_core/hardcodedValues";
import { CardTitle, FormLabel } from "../../../styles/StyledComponents";
import { useOffices } from "../../Offices/hooks";
import { Office, Timezone } from "../../../_core/types";
import { CommonResourcesContext } from "../../Routing/AppRouting";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";

export const EmployeeDetails = () => {
  const {
    employee,
    isLoading,
    updateEmployeeDetails,
    deleteEmployee,
    undoDeleteEmployee,
  } = useContext(ManageEmployeeContext);
  const inputStyle: React.CSSProperties = {
    borderRadius: 5,
    textAlign: "left",
  };
  const { offices } = useOffices();
  const { timezones } = useContext(CommonResourcesContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const { isAdmin } = useUserRoles();

  useEffect(() => {
    if (employee) {
      form.setFieldsValue({ firstName: employee.firstName });
      form.setFieldsValue({ lastName: employee.lastName });
      form.setFieldsValue({ email: employee.email });
      form.setFieldsValue({ function: employee.function });
      form.setFieldsValue({ legalPosition: employee.legalPosition });
      form.setFieldsValue({ workHours: employee.workHours });
      form.setFieldsValue({ phoneNumber: employee.phoneNumber });
      form.setFieldsValue({ phoneNumber: employee.phoneNumber });
      form.setFieldsValue({ officeId: employee.office.id });
      form.setFieldsValue({ timezone: employee.timezone.name });
      form.setFieldsValue({ startHour: employee.startHour });
      form.setFieldsValue({ birthday: moment(employee.birthday) });
      form.setFieldsValue({ hireDate: moment(employee.hireDate) });
      form.setFieldsValue({ vehiclePlates: employee.vehiclePlates });
      form.setFieldsValue({ vehicleDescription: employee.vehicleDescription });
    }
  }, [employee]);

  const handleUpdate = async (values: any) => {
    await updateEmployeeDetails(values);
    setIsEditMode(false);
  };

  const handleDelete = (employeeId: string) => {
    Modal.confirm({
      title: "You are about to delete an employee",
      icon: null,
      content: "Are you sure you want to delete this employee?",
      okText: "Yes, delete employee",
      okButtonProps: {
        style: {
          backgroundColor: "#FF3E3E",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return deleteEmployee(employeeId);
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };

  const handleUndoDelete = (employeeId: string) => {
    Modal.confirm({
      title: "You are about to reactivate an employee",
      icon: null,
      content: "Are you sure you want to reactivate this employee?",
      okText: "Yes, reactivate employee",
      okButtonProps: {
        style: {
          backgroundColor: "#FF3E3E",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return undoDeleteEmployee(employeeId);
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };

  return (
    <Card
      type="inner"
      title={<CardTitle>Employee Details</CardTitle>}
      extra={
        isAdmin() &&
        employee && (
          <>
            {isEditMode ? (
              <Button type="link" onClick={() => setIsEditMode(false)}>
                Cancel
              </Button>
            ) : (
              <>
                {employee?.userStatus === 20 ? (
                  <Button type="link" onClick={() => handleDelete(employee.id)}>
                    Delete user
                  </Button>
                ) : employee?.userStatus === 30 ? (
                  <Button
                    type="link"
                    onClick={() => handleUndoDelete(employee.id)}
                  >
                    Reactivate user
                  </Button>
                ) : null}
                <Button type="link" onClick={() => setIsEditMode(true)}>
                  Edit Profile
                </Button>
              </>
            )}
          </>
        )
      }
    >
      <Skeleton loading={isLoading}>
        <Row justify="center">
          <Col>
            <Avatar
              src={
                <Image
                  preview={{ mask: <div /> }}
                  src={employee?.profilePicture}
                />
              }
              size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            />
          </Col>
        </Row>

        <Form
          style={{ marginTop: 20 }}
          form={form}
          onFinish={handleUpdate}
          layout="horizontal"
          labelCol={{ span: 12, style: { textAlign: "left" } }}
          wrapperCol={{ span: 12 }}
        >
          <Form.Item
            name="firstName"
            label={<FormLabel>First Name</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input first name",
              },
            ]}
          >
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          <Form.Item
            name="lastName"
            label={<FormLabel>Last Name</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input last name",
              },
            ]}
          >
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          <Form.Item
            name="email"
            label={<FormLabel>Email</FormLabel>}
            rules={[
              {
                type: "email",
                message: "This E-mail is not valid!",
              },
              {
                required: true,
                message: "Please input E-mail!",
              },
            ]}
          >
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          <Form.Item name="phoneNumber" label={<FormLabel>Phone</FormLabel>}>
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          <Form.Item name="function" label={<FormLabel>Function</FormLabel>}>
            <Select style={inputStyle} disabled={!isEditMode}>
              {functions.map((_function) => (
                <Select.Option key={_function.name} value={_function.value}>
                  {_function.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="legalPosition"
            label={<FormLabel>Legal Position</FormLabel>}
          >
            <Select style={inputStyle} disabled={!isEditMode}>
              {legalPositions.map((_legalPosition) => (
                <Select.Option
                  key={_legalPosition.name}
                  value={_legalPosition.value}
                >
                  {_legalPosition.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="workHours" label={<FormLabel>Work Hours</FormLabel>}>
            <Select style={inputStyle} disabled={!isEditMode}>
              <Select.Option value={4}>Half Time(4h)</Select.Option>
              <Select.Option value={6}>Part Time(6h)</Select.Option>
              <Select.Option value={8}>Full Time(8h)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="officeId" label={<FormLabel>Office</FormLabel>}>
            <Select style={inputStyle} disabled={!isEditMode}>
              {offices.map((office: Office) => (
                <Select.Option key={office.id} value={office.id}>
                  {office.city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="timezone" label={<FormLabel>Timezone</FormLabel>}>
            <Select style={inputStyle} disabled={!isEditMode}>
              {timezones.map((timezone: Timezone) => (
                <Select.Option key={timezone.name} value={timezone.name}>
                  {timezone.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="startHour" label={<FormLabel>Start Hour</FormLabel>}>
            <Select style={inputStyle} disabled={!isEditMode}>
              {[...Array(24)].map((x, i) => (
                <Select.Option key={i} value={i * 60}>
                  {`${i < 10 ? "0" + i : i}:00`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="hireDate"
            label={<FormLabel>Hire Date</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input hire date!",
              },
            ]}
          >
            <DatePicker
              style={{ ...inputStyle, width: "100%" }}
              disabled={!isEditMode}
            />
          </Form.Item>

          <Form.Item
            name="birthday"
            label={<FormLabel>Birth Date</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input birth date!",
              },
            ]}
          >
            <DatePicker
              style={{ ...inputStyle, width: "100%" }}
              disabled={!isEditMode}
            />
          </Form.Item>

          <Form.Item
            name="vehiclePlates"
            label={<FormLabel>Car Number</FormLabel>}
          >
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          <Form.Item
            name="vehicleDescription"
            label={<FormLabel>Car Description</FormLabel>}
          >
            <Input style={inputStyle} disabled={!isEditMode} />
          </Form.Item>

          {isEditMode && (
            <Row style={{ marginTop: "40px" }} justify="center">
              <Form.Item noStyle>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  style={{ borderRadius: 5 }}
                  type="primary"
                  size="large"
                >
                  Update Profile
                </Button>
              </Form.Item>
            </Row>
          )}
        </Form>
      </Skeleton>
    </Card>
  );
};
