import { AxiosError } from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import ClientDialog from "./ClientDialog";
import ClientsTable from "./ClientsTable";
import { Client } from "../types";
import useClients from "./useClients";
import { Button, Divider, Input } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { SearchIcon } from "../../../assets/icons/SearchIcon";

interface ClientContextInterface {
  clients: Client[];
  isLoading: boolean;
  error: string;
  addClient(formData: any): any;
  deleteClient(clientId: string): any;
  editClient(client: Client): any;
}

// @ts-ignore
export const ClientsContext = createContext<ClientContextInterface>();

export default function index() {
  const [addDialog, setAddDialog] = useState<boolean>(false);
  const ret = useClients();
  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    setSearchInputValue(ev.target.value);

  return (
    <ClientsContext.Provider value={ret}>
      <ClientDialog isVisible={addDialog} setIsVisible={setAddDialog} />
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px" }}>
        <Input
          onChange={handleSearchInputChange}
          placeholder="Search here"
          prefix={<SearchIcon />}
          value={searchInputValue}
          style={{ borderRadius: 5, flexGrow: 1, marginBottom: 8 }}
        />

        <Button
          type="primary"
          style={{
            borderRadius: 5,
            marginLeft: 5,
            paddingTop: 3,
            paddingBottom: 3,
          }}
          icon={<PlusOutlined />}
          onClick={() => setAddDialog(true)}
        >
          Add Client
        </Button>
      </div>

      <ClientsTable
        clients={
          searchInputValue
            ? ret.clients.filter((c: any) =>
                c.name.toLowerCase().includes(searchInputValue.toLowerCase())
              )
            : ret.clients
        }
      />
    </ClientsContext.Provider>
  );
}
