import { FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Typography, message } from "antd";
import React from "react";
import styled from "styled-components";
import FilterTags from "./FilterTags";
import { useReportsFilters } from "./ReportsFiltersContext";
import { useReportsData } from "./Table/ReportsDataContext";
import { DownloadReport } from "./DownloadReport";

const Wrapper = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const DatePicker = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
`;

const ReportsWrapper: React.FC = ({ children }) => {
  const { date, setDate, setIsOpen } = useReportsFilters();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleMonthClick = (monthIndex: number) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setMonth(monthIndex);
      setDate(newDate);
    }
  };

  const handleYearClick = (year: number) => {
    if (date) {
      const newDate = new Date(date);
      newDate.setFullYear(year);
      setDate(newDate);
    }
  };

  const monthMenu = (
    <Menu>
      {monthNames.map((month, index) => (
        <Menu.Item key={index} onClick={() => handleMonthClick(index)}>
          {month}
        </Menu.Item>
      ))}
    </Menu>
  );

  const yearMenu = (
    <Menu>
      {[...Array(5)].map((_, i) => {
        const year = new Date().getFullYear() - i;
        return (
          <Menu.Item key={year} onClick={() => handleYearClick(year)}>
            {year}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const openDrawer = () => {
    setIsOpen(true);
  };
  const { fetchData } = useReportsData();
  return (
    <Wrapper className="reports-wrapper">
      <Header>
        <Typography.Title level={2}>Reports</Typography.Title>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          onClick={() => fetchData()}
          style={{ marginBottom: "20px", marginLeft: "1rem" }}
        >
          Refresh Data
        </Button>
        <div style={{ flexGrow: 1 }} />
        <DatePicker className="date-picker">
          <Dropdown overlay={monthMenu}>
            <span className="month" style={{ cursor: "pointer" }}>
              {date ? monthNames[date.getMonth()] : "Select Month"}
            </span>
          </Dropdown>
          <Dropdown overlay={yearMenu}>
            <span className="year" style={{ cursor: "pointer" }}>
              {date ? date.getFullYear() : "Select Year"}
            </span>
          </Dropdown>
        </DatePicker>
        <Button
          type="primary"
          icon={<FilterOutlined />}
          style={{ marginRight: "1rem", borderRadius: "0.5rem" }}
          onClick={openDrawer}
        >
          Filters
        </Button>
        <DownloadReport />
      </Header>
      <FilterTags />

      {children}
    </Wrapper>
  );
};

export default ReportsWrapper;
