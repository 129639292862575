import { useContext, useState } from "react";
import { message } from "antd";
import { ApiClientContext } from "../../../App";
export const formatDate = (isoString: string): string => {
  return isoString.replace(isoString.substring(11), "00:00:00Z");
};
export const useInviteModal = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { apiClient } = useContext(ApiClientContext);

  const handleInvite = async (values: any) => {
    setLoading(true);
    const postBody = {
      ...values,
      hireDate: formatDate(values.hireDate.toISOString(true)),
    };

    if (values.birthday) {
      postBody.birthday = formatDate(values.birthday.toISOString(true));
    }
    if (!values.technologies) {
      postBody.technologies = [];
    }

    try {
      const res = await apiClient.post("/api/Account/Invite", postBody);

      if (res.status === 200) {
        if (res.data.message === null) message.error("Invite Already Sent!");
        else {
          message.info("Invitation Sent!");
          setLoading(false);
          return res;
        }
      }
    } catch (error: any) {
      if (error.response.data.message) {
        message.error(error.response.data.message);
      } else {
        message.error("Could Not Invite Employee!");
      }

      setLoading(false);
      throw error;
    }
  };

  return { handleInvite, loading };
};
