import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";

import { Client } from "../types";
import { ContactFormData } from "./types";

const useContacts = (clientId: string) => {
  const [client, setClient] = useState<Client>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const { apiClient } = useContext(ApiClientContext);
  const fetchClient = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/clients/${clientId}`);
      setClient(data);
      setIsLoading(false);
    } catch (err) {
      setError("Could not get client");
    }
  };

  const addContact = async (formData: ContactFormData): Promise<any> => {
    try {
      setIsLoading(true);
      const postBody = { ...formData, clientId };
      for (let key in postBody) {
        //@ts-ignore
        if (!postBody[key]) {
          //@ts-ignore
          delete postBody[key];
        }
      }
      const { data } = await apiClient.post("/api/clients/contact", postBody);
      fetchClient();
      setIsLoading(false);
      return "Contact added successfully!";
    } catch (err) {
      setIsLoading(false);
      throw "An error occurred";
    }
  };

  const removeContact = async (contactId: string): Promise<any> => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.delete(`/api/clients/contact/${contactId}`);
      fetchClient();
      return "Contact removed";
    } catch (err) {
      setIsLoading(false);
      throw "An error occurred";
    }
  };

  const editContact = async (formData: ContactFormData, contactId: string): Promise<any> => {
    try {
      const putData = { ...formData };
      for (let key in putData) {
        //@ts-ignore
        if (!putData[key]) {
          //@ts-ignore
          delete putData[key];
        }
      }
      setIsLoading(true);
      const { data } = await apiClient.put(`/api/clients/contact/${contactId}`, putData);
      fetchClient();
    } catch (err) {
      setIsLoading(false);
      throw "An error occurred";
    }
  };

  useEffect(() => {
    fetchClient();
  }, []);

  return {
    client,
    isLoading,
    error,
    addContact,
    removeContact,
    editContact,
  };
};

export default useContacts;
