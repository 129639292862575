import { Modal, Form, Select, Button } from "antd";
import React from "react";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../styles/StyledComponents";
import useTransferItem from "./useTransferItem";
interface TransferItemDialogProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
  itemId: string;
  isAssignItem?: boolean;
  callback: any;
}
export const TransferItemDialog = (props: TransferItemDialogProps) => {
  const { isVisible, setIsVisible, itemId, isAssignItem, callback } = props;
  const { transferItem, assignItem, employees, employeesLoading, actionLoading } =
    useTransferItem(itemId);
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields;
  };

  const handleFinish = async (values: { userId: string }) => {
    if (isAssignItem) {
      await assignItem(values.userId);
      callback();
      handleCancel();
    } else {
      await transferItem(values.userId);
      callback();
      handleCancel();
    }
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      closeIcon={<RoundCloseIcon />}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        {isAssignItem ? "Assign" : "Transfer"} item
      </div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="userId"
          label={<FormLabel>Search employee</FormLabel>}
          rules={[{ required: true, message: "Please select an employee!" }]}
        >
          <Select
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            showSearch
            loading={employeesLoading}
          >
            {employees.map((employee) => (
              <Select.Option
                key={employee.id}
                value={employee.id}
              >{`${employee.firstName} ${employee.lastName}`}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            loading={actionLoading}
            htmlType="submit"
            block
            type="primary"
            style={{
              borderRadius: 5,
              border: "none",
              backgroundColor: isAssignItem ? "#00AD0D" : "",
            }}
            size="large"
          >
            {isAssignItem ? "Assign" : "Transfer"} item
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
