import React, {useContext, useEffect, useState} from "react";
import {Button, DatePicker, Input, InputNumber, message, Modal, Radio, Tooltip} from "antd";
import {
    CloseOutlined,
    CloudDownloadOutlined,
    HourglassTwoTone,
    InfoCircleOutlined,
    InfoCircleTwoTone,
    LinkOutlined
} from "@ant-design/icons";
import {TimeOffRequestType} from "../_core/enums/time-off-request-type.enum";
import moment from "moment";
import Dropzone from "react-dropzone";
import {getTimeOffRequestType} from "../../../_core/hardcodedValues";
import {useRequestTimeOff} from "../_core/hooks/request-time-off.hook";
import {ApiClientContext, UserContext} from "../../../App";
import {TimeOffFile, TimeOffRecord} from "../_core/interfaces/time-off-record.interface";
import {PublicHoliday} from "../_core/interfaces/public-holiday.interface";
import {useTimeOffActions} from "../_core/hooks/time-off-actions.hook";
import {useTimeOffRequest} from "../TimeOffDetails/hooks";
import {DownloadFileIcon} from "../../../assets/icons/CustomIcons";
import {Link} from "react-router-dom";
import {UserInfoShort} from "../../../_core/types";
import {CountryTypes} from "../../../_core/enums";
const { RangePicker } = DatePicker;

export const RequestTimeOffModal: React.FC<
    {isVisible: boolean, publicHolidays: PublicHoliday[], countryType: CountryTypes, onClose: () => void, onSuccess: () => void, timeOffRequest?: TimeOffRecord | null, defaultDateRange?: any, userInfo?: UserInfoShort}
> =
    ({isVisible, publicHolidays,countryType, onClose, onSuccess, timeOffRequest = null, defaultDateRange = null, userInfo = null}) =>
{
    const { user } = useContext(UserContext);
    const {apiClient} = useContext(ApiClientContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const requestTimeOff = useRequestTimeOff(publicHolidays, countryType);
    const {
        selectedType, setSelectedType,
        selectedDateRange, setSelectedDateRange,
        workingDaysCount, setWorkingDaysCount,
        description, setDescription,
        documents,
        displayConfirmPage, setDisplayConfirmPage,
        displayExtraSteps, daysWithPublicHolidays,
        computeTypeAltText, onDateRangeSelected,
        generateDocument,
        handleDrop, removeDocument,
    } = requestTimeOff;

    const timeOffActions = useTimeOffActions();
    const { approveTimeOffRequest, declineTimeOffRequest, deleteTimeOffRequest } = timeOffActions;

    const timeOffHook = useTimeOffRequest('');
    const { getTimeOffDoc } = timeOffHook;

    useEffect(() => {
        setError('');
        setIsLoading(false);
    }, [isVisible])

    useEffect(() => {
        if(!timeOffRequest) {
            return
        }
        setSelectedType(timeOffRequest.type);
        setSelectedDateRange([moment(timeOffRequest.startDate), moment(timeOffRequest.endDate)]);
        setWorkingDaysCount(timeOffRequest.workingDaysCount);
        setDescription(timeOffRequest.description || 'No Description');
        setDisplayConfirmPage(true);
    }, [timeOffRequest])

    useEffect(() => {
        if(!defaultDateRange) {
            return
        }
        setSelectedDateRange(defaultDateRange);
        onDateRangeSelected(defaultDateRange);
    }, [defaultDateRange])

    const submit = async () => {
        setIsLoading(true);
        setError('');
        const requestBody = {
            typeOfTimeOff: selectedType,
            startDate: moment(selectedDateRange[0]).format('MM/DD/YYYY'),
            endDate: moment(selectedDateRange[1]).format('MM/DD/YYYY'),
            description: description,
            workingDaysCount: workingDaysCount,
            files: [],
        };

        try {

            let res = null;
            if(userInfo === null) {
                res = await apiClient.post("/api/time-off/create-request", requestBody);
            } else {
                res = await apiClient.post("/api/time-off/admin-create-request?userId=" + userInfo?.id, requestBody);
            }

            await uploadTimeOffDocs(res.data.response.id);
            message.success("Request created. Please wait for admin approval.");
            setIsLoading(false);
            onSuccess();
        } catch(error: any) {
            if(error?.data?.details.indexOf('Wrong number of working days') > -1) {
                setError('Wrong number of working days detected. Please re-check your request or contact a CST manager.');
                setIsLoading(false);
                return;
            }
            message.error("Sorry, there was an error. Please notify someone.");
        }
    }

    const uploadTimeOffDocs = async(timeOffId: string) => {
        try {
            const formData = new FormData();
            documents.forEach((doc, i) => {
                formData.append(`files`, doc);
            })
            await apiClient.post(`/api/time-off-docs/${timeOffId}/upload-docs/`, formData,);
        } catch(error) {
            message.error("Failed to upload associated docs to time off request.");
        }
    }

    const handleDecline = () => {
        if(!timeOffRequest) {
            return;
        }
        Modal.confirm({
            title: <>Are you sure you want to <b>decline</b> this <br/>Time Off?</>,
            icon: null,
            okType: "danger",
            okText: "Decline",
            async onOk() {
                await declineTimeOffRequest(timeOffRequest?.id);
                onSuccess();
            },
        });
    };

    const handleApprove = async () => {
        if(!timeOffRequest) {
            return;
        }
        await approveTimeOffRequest(timeOffRequest.id);
        onSuccess();
    };

    const handleDelete = () => {
        if(!timeOffRequest) {
            return;
        }

        Modal.confirm({
            title: <>Are you sure you want to <b>remove</b> this <br/>Time Off?</>,
            icon: null,
            okType: "danger",
            okText: "Delete",
            onOk: async () => {
                await deleteTimeOffRequest(timeOffRequest.id);
                onSuccess();
            },
        });
    };

    const downloadTimeOffDoc = (document: TimeOffFile) => {
        if (timeOffRequest)
            getTimeOffDoc(timeOffRequest?.id || '', document.id, document.fileName)
    };

    return (
        <Modal
            visible={isVisible}
            onCancel={onClose}
            footer={null}
        >
            <div>
                <h4 style={headerStyle}>
                    {timeOffRequest === null
                        ? <>
                            <HourglassTwoTone style={{marginRight: 7}}/> Request Time Off
                            {
                                userInfo !== null && <p style={{fontSize: 15}}>for {userInfo?.userName}</p>
                            }
                        </>
                        : <>
                            <Link to={`time-off-request/${timeOffRequest.id}`} style={{textDecoration: 'underline'}}>
                                <LinkOutlined style={{marginRight: 7}}/>
                                {timeOffRequest?.user.userName + '\'s Time Off'}
                            </Link>

                            {window.location.href.indexOf(`timeOffProfile?userId=${timeOffRequest?.user.id}`) === -1 &&
                                <p style={{marginTop: 10, fontSize: 14, textDecoration: 'underline'}}>
                                    <Link to={`timeOffProfile?userId=${timeOffRequest?.user.id}`}>
                                        Time Off Profile
                                    </Link>
                                </p>
                            }
                        </>
                    }
                </h4>
            </div>

            {
                !displayConfirmPage &&
                <>
                    <div style={stepStyleOdd}>
                        <div>
                            <p style={stepTitleStyle}>
                                <span style={{...stepNumberStyle, backgroundColor: 'white'}}>1</span> Choose Type... <span style={typeAltTextStyle}>{computeTypeAltText()}</span>
                            </p>
                            <Radio.Group value={selectedType} onChange={(e) => setSelectedType(e.target.value)} style={typeBtnContainerStyle}>
                                <Radio.Button style={selectedType === TimeOffRequestType.Holiday ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRequestType.Holiday}>Holiday</Radio.Button>
                                <Radio.Button style={selectedType === TimeOffRequestType.Medical ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRequestType.Medical}>Medical</Radio.Button>
                                <Radio.Button style={selectedType === TimeOffRequestType.Study ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRequestType.Study}>Study</Radio.Button>
                                <Radio.Button style={selectedType === TimeOffRequestType.Maternal ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRequestType.Maternal}>Maternal</Radio.Button>
                                <Radio.Button style={selectedType === TimeOffRequestType.Other ? btnTypeSelectedStyle : btnTypeStyle} value={TimeOffRequestType.Other}>Other</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div style={stepStyle}>
                        <p style={stepTitleStyle}>
                            <span style={stepNumberStyle}>2</span> When?
                        </p>
                        <RangePicker format="DD MMM, YY" value={selectedDateRange as any} onChange={(value: any) => {setSelectedDateRange(value); onDateRangeSelected(value)}} style={rangePickerSelect}/>

                        {
                            daysWithPublicHolidays?.length > 0 && selectedDateRange &&
                            <div style={{marginTop: 10}}>
                                <p><b>Public Holidays in range: </b>
                                    {
                                        daysWithPublicHolidays.map((d, index) => (
                                            <Tooltip title={d.name}>
                                                <span>{moment(d.date).format('D MMM')}{index !== daysWithPublicHolidays.length-1 ? ', ' : ''}</span>
                                            </Tooltip>
                                        ))
                                    }

                                </p>
                            </div>
                        }

                        <div style={{alignItems: 'center', display: 'flex'}}>
                            <div>
                                <p style={{...stepTitleStyle, marginLeft: 30, marginTop: 20, marginBottom: 0}}>
                                    ...and for how long?
                                </p>
                                <p style={{marginLeft: 30, maxWidth: '80%'}}>just so you know, we don't count weekends and public holidays</p>
                            </div>
                            <div style={{textAlign: 'center'}}>
                                <p style={{marginBottom: 0, fontSize: 35, fontWeight: 900, color: '#1a8bcd', lineHeight: 1}}>{workingDaysCount}</p>
                                <p style={{fontSize: 13, lineHeight: 1, marginBottom: 0}}>working {workingDaysCount === 1 ? 'day' : 'days'}</p>
                            </div>
                        </div>
                    </div>

                    {
                        displayExtraSteps &&
                        <div style={stepStyleOdd}>
                            <p style={stepTitleStyle}>
                                <span style={{...stepNumberStyle, backgroundColor: 'white'}}>3</span> Files
                            </p>
                            <p style={{marginBottom: 0, fontWeight: 500}}>
                                <InfoCircleTwoTone /> We can generate the file for you, just add your signature and upload it.
                                {
                                    selectedDateRange &&
                                    <a onClick={() => generateDocument()} style={{display: 'flex', fontSize: 17, marginBottom: 7, alignItems: 'center', justifyContent: 'center'}}>
                                        <CloudDownloadOutlined style={{fontSize: 21, marginRight: 10}} />
                                        Click here to generate
                                    </a>
                                }
                                {
                                    !selectedDateRange && <span style={{fontStyle: 'italic'}}> (select a timeframe first)</span>
                                }
                            </p>
                            <p style={{fontWeight: 500}}><InfoCircleTwoTone /> If this is not a holiday, you might need to upload some extra ones. </p>

                            <Dropzone onDrop={handleDrop}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <Button type="dashed" block>
                                            + Upload File
                                            <input {...getInputProps()} />
                                        </Button>
                                    </div>
                                )}
                            </Dropzone>

                            <div style={{marginTop: 20}}>
                                {documents?.map((document: File, index: number) => (
                                    <li key={document.name} style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                                        {document.name}
                                        <CloseOutlined style={{marginLeft: 10, cursor: "pointer"}} onClick={() => removeDocument(index)}/>
                                    </li>
                                ))}
                            </div>

                        </div>
                    }

                    {
                        displayExtraSteps &&
                        <div style={stepStyle}>
                            <p style={stepTitleStyle}>
                                <span style={{...stepNumberStyle}}>4</span> Words
                            </p>
                            <Input.TextArea value={description} onChange={(e: any) => setDescription(e.target.value)} autoSize={{ minRows: 3, maxRows: 3 }} maxLength={1000} style={{borderRadius: 0, fontSize: 15}} placeholder={'This is optional, just so you know'}/>
                        </div>
                    }

                    {
                        displayExtraSteps &&
                        <div style={{textAlign: 'center', marginTop: 30}}>
                            <Button type="primary" style={{borderRadius: 4}} disabled={!selectedDateRange || workingDaysCount === 0} onClick={() => setDisplayConfirmPage(true)}>Continue</Button>
                        </div>
                    }

                </>
            }

            {
                displayConfirmPage &&
                <div style={{marginTop: 30}}>
                    {timeOffRequest === null &&
                        <p style={{fontWeight: 500, fontSize: 16}}>Please confirm and you are good to go </p>
                    }

                    <div style={confirmBoxStyle}>
                        <p><label style={confirmBoxLabelStyle}>Type: </label><span style={confirmBoxValueStyle}>{getTimeOffRequestType(selectedType)}</span></p>
                        <p><label style={confirmBoxLabelStyle}>When: </label><span style={confirmBoxValueStyle}>
                            {
                                moment(selectedDateRange[0]).format('DD MMM, YYYY') === moment(selectedDateRange[1]).format('DD MMM, YYYY')
                                    ? <>{moment(selectedDateRange[0]).format('DD MMMM, YYYY')}</>
                                    : <>{moment(selectedDateRange[0]).format('DD MMMM, YYYY')}  -  {moment(selectedDateRange[1]).format('DD MMMM, YYYY')}</>
                            }
                        </span></p>
                        <p><label style={confirmBoxLabelStyle}>Days Count: </label><span style={confirmBoxValueStyle}>{workingDaysCount}</span></p>
                        <p><label style={confirmBoxLabelStyle}>Description: </label><span style={confirmBoxValueStyle}>{description}</span></p>
                        <p style={{marginBottom: 0}}><label style={confirmBoxLabelStyle}>Files: {documents.length === 0 && timeOffRequest?.files.length === 0 ? '-': ''} </label></p>
                        {documents?.map((document: File, index: number) => (
                            <li key={document.name} style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
                                {document.name}
                                <CloseOutlined style={{marginLeft: 10, cursor: "pointer"}} onClick={() => removeDocument(index)}/>
                            </li>
                        ))}
                        {timeOffRequest?.files?.map((document: TimeOffFile) => (
                            <a
                                onClick={()=>downloadTimeOffDoc(document)}
                                style={{ display: "flex", alignItems: "center", marginTop:10 }}
                            >
                                <li key={document.id} style={{display: 'flex', alignItems: 'flex-start', marginBottom: 5}}>
                                    <span style={{marginRight: 5}}><DownloadFileIcon /></span>
                                    {document.fileName}
                                </li>
                            </a>
                        ))}
                    </div>

                    {
                        error &&
                        <div>
                            <p style={{color:'#e60000', fontWeight: 700}}>{error}</p>
                        </div>
                    }

                    {
                        (user.id === timeOffRequest?.user.id || !timeOffRequest) &&
                        <p style={{fontWeight: 500, fontSize: 16, marginBottom: 20}}>Please don't forget to notify your colleagues</p>
                    }

                    {timeOffRequest === null &&
                       <>
                           <Button style={{borderRadius: 4}} onClick={() => setDisplayConfirmPage(false)} disabled={isLoading}>Mno, go back</Button>
                           <Button type="primary" style={{borderRadius: 4, marginLeft: 20}} onClick={() => submit()} loading={isLoading}>All Good</Button>
                       </>
                    }

                    {timeOffRequest !== null && timeOffRequest.availableOperations.find((role) => role === "Approve") &&
                        <>
                            <Button type="primary" style={{borderRadius: 4, marginLeft: 20, backgroundColor: '#00AD0D', borderColor: '#00AD0D'}} onClick={() => handleApprove()} loading={isLoading}>Approve</Button>
                        </>
                    }

                    {timeOffRequest !== null && timeOffRequest.availableOperations.find((role) => role === "Delete") &&
                        <>
                            <Button type="primary" danger={true} style={{borderRadius: 4, marginLeft: 20}} onClick={() => handleDelete()} loading={isLoading}>Remove</Button>
                        </>
                    }

                    {timeOffRequest !== null && timeOffRequest.availableOperations.find((role) => role === "Decline") &&
                        <>
                            <Button type="primary" danger={true} style={{borderRadius: 4, marginLeft: 20}} onClick={() => handleDecline()} loading={isLoading}>Decline</Button>
                        </>
                    }
                </div>
            }

        </Modal>
    )
}

const headerStyle = {
    color: '#1a8bcd',
    fontSize: 19,
    fontWeight: 600
}

const stepStyle = {
    marginTop: 30,
    textAlign: 'left' as 'left'
}

const stepStyleOdd = {
    backgroundColor: '#d2e0e9',
    marginLeft: -24,
    marginRight: -24,
    marginBottom: 24,
    padding: 24,
    marginTop: 30,
    textAlign: 'left' as 'left'
}

const stepTitleStyle = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 500
}

const stepNumberStyle = {
    fontSize: 21,
    fontWeight: 700,
    backgroundColor: '#cee6f4',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    border: '2px solid #1a8bcd',
    color: '#1a8bcd',
    marginRight: 10
}

const typeBtnContainerStyle = {
    width: '100%',
}

const btnTypeStyle = {
    width: '20%',
    textAlign: 'center' as 'center',
}

const btnTypeSelectedStyle = {
    fontWeight: 500,
    width: '20%',
    textAlign: 'center' as 'center',
    backgroundColor: '#cee6f4',
}

const typeAltTextStyle = {
    marginLeft: 10,
    fontStyle: 'italic',
    fontSize: 15
}

const rangePickerSelect = {
    width: "100%",
    borderRadius: 4
}

const confirmBoxStyle = {
    backgroundColor: '#cee6f4',
    padding: 20,
    borderRadius: 4,
    textAlign: 'left' as 'left',
    marginBottom: 30,
    border:'1px solid #b7c6d5'
}

const confirmBoxLabelStyle = {
    fontSize: 16,
    fontWeight: 600,
}

const confirmBoxValueStyle = {
    fontSize: 16
}
