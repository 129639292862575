import { Button, Input, Modal, Spin } from "antd";
import React, { useContext } from "react";
import { BugContext } from ".";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";

export const BugModal = () => {
  const { bugModalVisible, setBugModalVisible, length, setLength, sendBug, setBugText, loading } =
    useContext(BugContext);

  return (
    <Modal
      visible={bugModalVisible}
      footer={null}
      onCancel={() => setBugModalVisible(false)}
      closeIcon={<RoundCloseIcon />}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2 style={{ textAlign: "left" }}> Please write your issue here:</h2>
        <Input.TextArea
          rows={6}
          maxLength={500}
          onChange={(e: any) => {
            setBugText(e.target.value);
            setLength(e.target.value.length);
          }}
        />
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          {length}/500
        </div>
        <Button type="primary" style={{ width: 128, marginTop: 16 }} onClick={sendBug}>
          Submit
        </Button>
        {loading && <Spin />}
      </div>
    </Modal>
  );
};
