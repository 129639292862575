import React from "react";
import { TaskInput } from "./TaskInput";
import { SessionInputs } from "./SessionInputs";
export const TrackerForm = () => {
  const onSubmitt = (e: React.FormEvent) => {
    e.preventDefault();
  };
  return (
    <>
      <form
        onSubmit={onSubmitt}
        style={{
          width: "75%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TaskInput />
        <SessionInputs />
      </form>
    </>
  );
};
