export interface Task {
  taskId?: string;
  title: string;
  description: string;
  projectId: string;
  technologyId: string;
}

export interface Technology {
  id: string;
  name: string;
}

export interface Project {
  projectId: string;
  projectName: string;
  color: string;
  tasks: Array<Task>;
  technologies: Array<Technology>;
}

export interface SessionsWeek {
  start: string;
  end: string;
  total: number;
  days: SessionsDay[];
}

export interface SessionsDay {
  day: string;
  total: number;
  tasks: SessionsDayTask[];
}
export interface SessionsDayTask {
  clientName: string;
  description: string;
  projectName: string;
  projectId: string;
  taskId: string;
  taskName: string;
  time: number;
  color: string;
  sessions: Array<TimeTrackingSession>;
}

export interface TimeTrackingSession {
  id: string;
  start: string;
  end: string;
  time: number;
  technology: string;
  technologyId: string;
  type: keyof typeof typesDict;
}

export const typesDict = {
  0: "Other",
  10: "Coding",
  20: "Client Call",
  25: "Internal Call",
  30: "Brainstorming",
  40: "Documentation",
  50: "Testing",
  60: "Code Review",
  70: "Configuring",
  80: "Management",
  90: "Internal Training",
  100: "External Training",
  110: "Design",
};

export interface ITimeTrackingUserData {
  projects: Project[];
  sessionWeeks: SessionsWeek[];
  isGettingInitialData: boolean;
  weeksToFetch: number;
  setWeeksToFetch: (val: number) => void;
  updateSessionWeeks: () => Promise<void>;
  updateProjectTasks: (projectId: string, newTasks: Task[]) => void;
  cloneSession: (session: TimeTrackingSession, taskId: string) => void;
  deleteSession: (sessionId: string) => void;
  sessionToDelete: string;
  sessionToClone: string;
  sessionToChangeTask: TimeTrackingSession | null;
  setSessionToChangeTask: (val: TimeTrackingSession | null) => void;
}

export type UseTimeTrackingUserData = () => ITimeTrackingUserData;
export interface ActiveSession {
  sessionId: string;
  taskId: string;
  projectId: string;
  technologyId: string;
  sessionType: number;
  start: string;
}
