import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8899 3.45H9.10989C8.70989 3.45 8.38989 3.13 8.38989 2.73C8.38989 2.33 8.70989 2 9.10989 2H14.8899C15.2899 2 15.6099 2.32 15.6099 2.72C15.6099 3.12 15.2899 3.45 14.8899 3.45Z"
      fill="white"
    />
    <path
      d="M19.97 15H17.03C15.76 15 15 15.76 15 17.03V19.97C15 21.24 15.76 22 17.03 22H19.97C21.24 22 22 21.24 22 19.97V17.03C22 15.76 21.24 15 19.97 15ZM19.69 19.46L18.51 20.14C18.27 20.28 18.03 20.35 17.81 20.35C17.64 20.35 17.49 20.31 17.35 20.23C17.03 20.04 16.85 19.67 16.85 19.18V17.82C16.85 17.33 17.03 16.96 17.35 16.77C17.67 16.58 18.08 16.62 18.51 16.86L19.69 17.54C20.11 17.79 20.35 18.13 20.35 18.5C20.35 18.87 20.12 19.21 19.69 19.46Z"
      fill="white"
    />
    <path
      d="M14.0001 19.9699V17.0299C14.0001 15.2199 15.2201 13.9999 17.0301 13.9999H19.9701C20.2001 13.9999 20.4201 14.0199 20.6301 14.0599C20.6501 13.8199 20.6701 13.5799 20.6701 13.3299C20.6701 8.5399 16.7801 4.6499 12.0001 4.6499C7.22008 4.6499 3.33008 8.5399 3.33008 13.3299C3.33008 18.1099 7.22008 21.9999 12.0001 21.9999C12.8501 21.9999 13.6601 21.8599 14.4401 21.6399C14.1601 21.1699 14.0001 20.6099 14.0001 19.9699ZM12.7501 12.9999C12.7501 13.4099 12.4101 13.7499 12.0001 13.7499C11.5901 13.7499 11.2501 13.4099 11.2501 12.9999V7.9999C11.2501 7.5899 11.5901 7.2499 12.0001 7.2499C12.4101 7.2499 12.7501 7.5899 12.7501 7.9999V12.9999Z"
      fill="white"
    />
  </svg>
);

export const TimeTrackerIcon = (props: any) => (
  <Icon component={svg} {...props} />
);
