import { Skeleton, TimePicker } from "antd";
import moment from "moment";
import { useContext, useRef, useState } from "react";

import { convertToLocal, convertToUTC } from "../utils/helpers";
import TimeInput from "../../common/TimeInput";
import { TrackerContext } from "../utils/types";
import TrackerModeSwitch from "../TrackerModeSwitch";

export const AutomaticInsert = () => {
  const {
    trackerLoading,
    isTracking,
    startTime,
    liveTime,
    editCurrentSession,
  } = useContext(TrackerContext);
  const [isHovered, setHovered] = useState(false);
  const timeTrackingRef = useRef(null);

  const handleTimeChange = (value?: string) => {
    let parsedTime = moment(value, "HH:mm");
    const existingStartTime = moment(startTime);
    //if time is 00:30 and I did choose 23:30 then it means that the start time is yesterday
    //parsed time is the chose hour parsed in todays date
    const isPreviousDay = moment().isBefore(parsedTime);
    if (isPreviousDay) {
      parsedTime = parsedTime.subtract(1, "day");
    }

    if (
      parsedTime.isValid() &&
      !(parsedTime.format("HH:mm") === existingStartTime.format("HH:mm"))
    ) {
      const isoString = parsedTime.toISOString();
      editCurrentSession(isoString);
    }
  };

  return (
    <>
      {trackerLoading ? (
        <Skeleton active />
      ) : (
        <div
          onMouseEnter={() => setHovered(true)} // Handle mouse enter
          onMouseLeave={() => setHovered(false)} // Handle mouse leave
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <TrackerModeSwitch />
          </div>

          <div
            style={{
              border: "0.5px solid #CBD5E1",
              height: "100%",
              borderRadius: "5px",
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flex: 1,
              }}
            >
              {isTracking && (
                <div
                  style={{
                    flex: 1,
                    height: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    display: "flex",
                    color: "#64748B",
                    fontWeight: "bold",
                    fontSize: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  Started at:
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      height: "32px",
                    }}
                  >
                    {isHovered ? (
                      <TimeInput
                        ref={timeTrackingRef}
                        style={{
                          textAlign: "center",
                          width: "100%",
                          margin: 0,
                          padding: 0,
                          color: "#64748B",
                          fontWeight: "bold",
                          fontSize: "20px",
                          height: "100%",
                          lineHeight: "32px",
                        }}
                        valueProps={moment(startTime).format("HH:mm")}
                        placeholder="End"
                        onBlur={(value) => handleTimeChange(value)}
                      />
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                          margin: 0,
                          height: "100%",
                          padding: 0,
                          color: "#64748B",
                          fontWeight: "bold",
                          fontSize: "20px",
                          lineHeight: "32px", // Add line height if necessary
                        }}
                      >
                        {convertToLocal(startTime).format("HH:mm")}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div
                style={{
                  flex: 1,
                  color: "#137AE9",
                  fontSize: "30px",
                  fontWeight: 600,
                }}
              >
                {moment().hour(0).minute(0).second(liveTime).format("HH:mm:ss")}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
