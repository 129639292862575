import { Modal, Form, Select, Input, DatePicker, InputNumber, Switch, Button } from "antd";
import { useContext, useEffect } from "react";
import { RoundCloseIcon } from "../../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../../styles/StyledComponents";
import { Office } from "../../../../_core/types";
import { useOffices } from "../../../Offices/hooks";
import useAllModels from "../../Models/AllModels/useAllModels";
import { Item } from "../../types";
import { formatDate } from "../../../Employees/InviteModal/useInviteModal";
import { AllItemsContext } from ".";
import moment from "moment";
import { useHistory } from "react-router-dom";

interface ItemDialogProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
  setAddToModel?(value: boolean): void;
  item?: Item;
  modelId? : number;
}
export const ItemDialog = (props: ItemDialogProps) => {
  const { isVisible, setIsVisible, setAddToModel, item, modelId } = props;
  const { addItem, isLoading, editItem } = useContext(AllItemsContext);
  const [form] = Form.useForm();
  const { models } = useAllModels();
  const { offices } = useOffices();
  const history = useHistory();
  
  useEffect(() => {
    if (item) {
      form.setFieldsValue({ modelId: item.modelId });
      form.setFieldsValue({ serialNumber: item.serialNumber });
      form.setFieldsValue({ description: item.description });
      form.setFieldsValue({ acquisitionDate: moment(item.acquisitionDate) });
      form.setFieldsValue({ invoice: item.invoice });
      form.setFieldsValue({ warranty: item.warranty });
      form.setFieldsValue({ officeId: item.officeId });
      form.setFieldsValue({ assignable: item.assignable });
    }

    if(modelId)
    {
      form.setFieldsValue({ modelId: modelId });
    }
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    setAddToModel ? setAddToModel(false) : null;
    history.replace({pathname: `/inventory/items`, state: {}});
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    values.acquisitionDate = formatDate(values.acquisitionDate.toISOString(true));
    if (item) {
      await editItem(values, item.id);
      handleCancel();
    } else {
      await addItem(values);
      handleCancel();
    }
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      closeIcon={<RoundCloseIcon />}
    >
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        {item ? "Edit item" : "Add a new item"}
      </div>

      <Form form={form} onFinish={handleFinish} layout="vertical">
        <Form.Item
          name="modelId"
          label={<FormLabel>Assign Model</FormLabel>}
          rules={[{ required: true, message: "Please select a model!" }]}
        >
          <Select disabled= {modelId ? true : false}>
            {models.map((model) => (
              <Select.Option key={model.id} value={model.id}>
                {model.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="serialNumber"
          label={<FormLabel>Serial Number</FormLabel>}
          rules={[{ required: true, pattern: /.*[^ ].*/, message: "Please insert a serial number" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="description"
          label={<FormLabel>Description</FormLabel>}
          rules={[{ required: true, pattern: /.*[^ ].*/, message: "Please insert a description"}]}
        >
          <Input.TextArea maxLength={200} />
        </Form.Item>

        <Form.Item name="acquisitionDate" label={<FormLabel>Acquisition date</FormLabel>}
          rules={[{ required: true, message: "Please insert the acquisition date" }]}>
          <DatePicker style={{ borderRadius: 5, width: "100%" }} showHour={false} />
        </Form.Item>

        <Form.Item
          name="invoice"
          label={<FormLabel>Invoice number</FormLabel>}
          rules={[{ required: true, pattern: /.*[^ ].*/, message: "Please insert an invoice number" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="warranty"
          label={<FormLabel>Warranty (months)</FormLabel>}
          rules={[{ required: true, pattern: /.*[^ ].*/, message: "Please insert the item's warranty" }]}
        >
          <InputNumber style={{ borderRadius: 5, width: "100%" }} min={0} />
        </Form.Item>

        <Form.Item
          name="officeId"
          label={<FormLabel>Office</FormLabel>}
          rules={[{ required: true, message: "Please insert the ofice" }]}
        >
          <Select>
            {offices.map((office: Office) => (
              <Select.Option key={office.id} value={office.id}>
                {office.city}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name="assignable"
          label={<FormLabel>Assignable item</FormLabel>}
        >
          <Switch
            style={{ float: "left" }}
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            htmlType="submit"
            block
            type="primary"
            style={{ borderRadius: 5, border: "none", backgroundColor: item ? "#00AD0D" : "" }}
            size="large"
          >
            {item ? "Save Changes" : "Add Item"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
