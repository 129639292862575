import { Button, Card, Skeleton } from "antd";
import React, { useContext } from "react";
import styled from "styled-components";
import { OfficePageContext } from ".";
import { UserContext } from "../../../App";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";

const GreyText = styled.div`
  color: #a8a8a8;
`;

const BlackText = styled.div`
  color: #000000;
  font-weight: bold;
`;

const UserInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const ManagerCard = () => {
  const officeHooks = useContext(OfficePageContext);
  const { loading, setManagerModal, officeData } = officeHooks;
  const officeManager = officeData?.manager;
  const { isAdmin } = useUserRoles();
  const { user } = useContext(UserContext);

  return (
    <Card
      title="Office Manager"
      headStyle={{ backgroundColor: "#fafafa" }}
      style={{ flexGrow: 1, marginBottom: 16 }}
    >
      {loading ? (
        <Skeleton />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "space-between",
          }}
        >
          <div>
            <UserInfoRow>
              <GreyText style={{ marginRight: 16 }}>Name </GreyText>
              <BlackText>{officeManager?.firstName}</BlackText>
            </UserInfoRow>
            <UserInfoRow>
              <GreyText style={{ marginRight: 16 }}>Email</GreyText>
              <BlackText>{officeManager?.email}</BlackText>
            </UserInfoRow>
            <UserInfoRow>
              <GreyText style={{ marginRight: 16 }}>Phone </GreyText>
              <BlackText>{officeManager?.phoneNumber}</BlackText>
            </UserInfoRow>
          </div>
          {isAdmin() && (
            <div
              style={{
                display: "flex",
                alignSelf: "flex-end",
                justifyContent: "flex-end",
                marginTop: 16,
              }}
            >
              <Button
                type="primary"
                onClick={() => setManagerModal({ visible: true, officeId: officeData?.id })}
              >
                Set Manager
              </Button>
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
