import { Modal, Table, Tooltip } from "antd";
import React, { useContext } from "react";
import { ItemsRequestsContext } from ".";
import { getItemRequestStatus } from "../../../_core/hardcodedValues";
import { ApproveIcon } from "../../../assets/icons/Inventory/ApproveIcon";
import { DeclineIcon } from "../../../assets/icons/Inventory/DeclineIcon";
import { TableHeader, TableText } from "../../../styles/StyledComponents";
import { Request } from "../types";

export const RequestsTable = () => {
  const { requests, isLoading, acceptRequest, declineRequest, searchInputValue } = useContext(ItemsRequestsContext);

  const filterRequests = () => {
    return requests.filter((request) => {
      return (
        request.firstName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        request.lastName.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) ||
        request.description.toLocaleLowerCase().includes(searchInputValue.toLocaleLowerCase()) 
      );
    });
  };

  const handleAccept = (request: Request) => {
    Modal.confirm({
      title: "You are about to accept a request",
      icon: null,
      content: (
        <p>
          This action can not be reversed. Are you sure you want to accept{" "}
          <b>{` ${request.firstName} ${request.lastName} `}</b>
          request for a/an <b>{request.description}</b>?
        </p>
      ),
      okText: "Yes, accept request",
      okButtonProps: {
        style: {
          backgroundColor: "#00AD0D",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return acceptRequest(request.requestId);
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };

  const handleDecline = (request: Request) => {
    Modal.confirm({
      title: "You are about to decline a request",
      icon: null,
      content: (
        <p>
          This action can not be reversed. Are you sure you want to decline
          <b>{` ${request.firstName} ${request.lastName} `}</b>
          request for a/an <b>{request.description}</b>?
        </p>
      ),
      okText: "Yes, decline request",
      okButtonProps: {
        style: {
          backgroundColor: "#FF3E3E",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return declineRequest(request.requestId);
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };

  return (
    <Table dataSource={filterRequests()} rowKey="requestId" loading={isLoading} pagination={false}>
      <Table.Column<Request>
        title={<TableHeader>Employee</TableHeader>}
        render={(value, record) => (
          <TableText>{`${record.firstName} ${record.lastName}`}</TableText>
        )}
      />
      <Table.Column<Request>
        title={<TableHeader>Item requested description</TableHeader>}
        dataIndex="description"
        render={(description) => <TableText>{description}</TableText>}
      />
      <Table.Column<Request>
        title={<TableHeader>Status</TableHeader>}
        dataIndex="state"
        render={(state) => (
          <TableText
            style={{
              color:
                state === 10 ? "#F1C757" : state === 20 ? "#00AD0D" : state === 30 ? "#FF3E3E" : "",
            }}
          >
            {getItemRequestStatus(state)}
          </TableText>
        )}
      />
      <Table.Column<Request>
        title={<TableHeader>Actions</TableHeader>}
        render={(request: Request) => {
          if (request.state === 10) {
            return (
              <div>
                <Tooltip title="Approve">
                  <ApproveIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => handleAccept(request)}
                  />
                </Tooltip>
                <Tooltip title="Decline">
                  <DeclineIcon onClick={() => handleDecline(request)} />
                </Tooltip>
              </div>
            );
          }
        }}
      />
    </Table>
  );
};
