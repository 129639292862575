import { Modal, Skeleton } from "antd";
import TechnologiesSelect from "./TechnologiesSelect";
import TaskInputs from "./TaskInputs";
import { useChangeTaskModal } from "./useChangeTaskModal";
import { ChangeSessionTaskModalContext } from "./types";
import { TimeTrackingSession } from "../../../../types";

const ChangeTaskModal = ({
  session,
  onUpdate,
  isModalVisible,
  onClose,
}: {
  session: TimeTrackingSession | null;
  onUpdate: () => void;
  isModalVisible: boolean;
  onClose: () => void;
}) => {
  const handleCancel = () => {
    onClose();
  };
  const hook = useChangeTaskModal(session, onUpdate);
  return (
    <ChangeSessionTaskModalContext.Provider value={hook}>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        confirmLoading={hook.isUpdating}
        okText="Save Changes"
        onOk={hook.onSubmit}
        destroyOnClose
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Skeleton loading={hook.isGettingData}>
            <TaskInputs />
            <TechnologiesSelect />
          </Skeleton>
        </div>
      </Modal>
    </ChangeSessionTaskModalContext.Provider>
  );
};

export default ChangeTaskModal;
