import {message} from "antd";
import {useContext, useEffect, useState} from "react";
import {ApiClientContext} from "../../../../App";
import {AddModelFormValues, Model, Tag} from "../../types";

const useAllModels = () => {
  const [models, setModels] = useState<Model[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");

  const {apiClient} = useContext(ApiClientContext);

  const fetchModels = async () => {
    try {
      setIsLoading(true);
      const {data} = await apiClient.get(`/api/items/get-all-models`);
      setModels(data);
      setIsLoading(false);
      return data;
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
      message.error("Could not fetch models");
      throw err;
    }
  };

  const addModel = async (formValues: AddModelFormValues) => {
    try {
      setIsLoading(true);
      const {data} = await apiClient.post("/api/items/add-model", formValues);
      fetchModels();
      message.success("Model added successfully");
    } catch (err : any) {
      console.warn(err);
      setIsLoading(false);
      message.error(err.response.data.message);
    }
  };

  const deleteModel = async (modelId: string) => {
    try {
      const {data} = await apiClient.delete(`/api/items/delete-model/${modelId}`);
      fetchModels();
      message.success("Model deleted successfully");
    } catch (err : any) {
      console.warn(err);
      message.error(err.response.data.message);
    }
  };

  const updateModel = async (formValues: AddModelFormValues, modelId: string) => {
    try {
      setIsLoading(true);
      const {data} = await apiClient.put(`/api/items/update-model/${modelId}`, formValues);
      fetchModels();
      message.success("Model updated successfully");
    } catch (err : any) {
      console.warn(err);
      setIsLoading(false);
      message.error(err.response.data.message);
    }
  };

  const getTags = async () => {
    try {
      const {data} = await apiClient.get("/api/items/tags");
      setTags(data);
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchModels();
    getTags();
  }, []);

  return {
    models,
    isLoading,
    addModel,
    deleteModel,
    updateModel,
    tags,
    searchInputValue,
    setSearchInputValue,
  };
};

export default useAllModels;
