import { useContext } from "react";
import CalendarTimePicker from "../Calendar/CalendarTimePicker";
import { TrackerContext } from "../utils/types";

const CalendarTracking = () => {
  const { typeOfTracking, handleClose } = useContext(TrackerContext);
  return (
    <CalendarTimePicker />
    // <Modal
    //   title="Select a day"
    //   onCancel={handleClose}
    //   visible={typeOfTracking === "Calendar"}
    //   okButtonProps={{ style: { display: "none" } }}
    //   cancelButtonProps={{ style: { display: "none" } }}
    // >
    // {/* <CalendarTimePickerComponent />
    //  */}

    // </Modal>
  );
};

export default CalendarTracking;
