import { SelectOutlined, TeamOutlined } from "@ant-design/icons";
import { Card, Skeleton, Space, Table, Tooltip } from "antd";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { OfficePageContext } from ".";
import { UserContext } from "../../../App";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { useWindowSize } from "../../../_core/hooks/useWindowSize";
import "../../Employees/AllEmployees/styles.css";

export const EmployeesTable = () => {
  const width = useWindowSize();
  const officeHooks = useContext(OfficePageContext);
  const { officeData, loading } = officeHooks;
  const { isAdmin } = useUserRoles();
  const { user } = useContext(UserContext);
  const columns = [
    {
      key: "1",
      render: (record: any) => {
        return (
          <>
            {record.firstName} {record.lastName}
          </>
        );
      },
    },
    isAdmin()
      ? {
          key: "actions",
          render: (record: any) => {
            return (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip title="View profile">
                  {/* The route change to /employees/{userId}  */}
                  <Link to={`/employees/${record.id}`}>
                    <SelectOutlined style={{ color: "#0F59B3" }} />
                  </Link>
                </Tooltip>
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <Card
      title="Employees"
      headStyle={{ backgroundColor: "#fafafa" }}
      style={{
        flexGrow: 7,
        marginBottom: 16,
      }}
      bodyStyle={{ padding: 0 }}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          showHeader={false}
          pagination={false}
          dataSource={officeData?.members}
          columns={columns}
          rowKey="email"
          rowClassName={(record) =>
            record.emailConfirmed == false ? "inactive-user" : ""
          }
        />
      )}
    </Card>
  );
};
