import { Card, Col, Row } from "antd";

import React, { createContext } from "react";
import { AccountInfo } from "./AccountInfo";
import { InterestTechnologies } from "./InterestTechnologies";
import { Technologies } from "./Technologies";
import { ChangePasswordFormValues, Profile, UpdateProfileFormValues } from "./types";
import useProfile from "./useProfile";

interface ProfileContextInterface {
  profile?: Profile;
  isLoading: boolean;
  error: string;
  updateProfile(values: UpdateProfileFormValues): void;
  changePassword(values: ChangePasswordFormValues): void;
  changeProfilePicture(photo: File): void;
  isLoadingProfilePicture: boolean;
  addInterestTechnology(formValues: any): void;
  deleteInterestTechnology(otherTechnologyId: string): void;
}

//@ts-ignore
export const ProfileContext = createContext<ProfileContextInterface>();

export const UserProfile = () => {
  const ret = useProfile();
  return (
    <ProfileContext.Provider value={ret}>
      <div>
        <Row>
          <Col md={24} lg={12} style={{ padding: 5 }}>
            <AccountInfo />
          </Col>
          <Col md={24} lg={12} style={{ padding: 5, width: "100%" }}>
            <Technologies />
            <InterestTechnologies />
          </Col>
        </Row>
      </div>
    </ProfileContext.Provider>
  );
};
