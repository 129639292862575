import { Button, Skeleton } from "antd";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useWindowSize } from "../../../_core/hooks/useWindowSize";
import { EmployeesTable } from "./EmployeesTable";
import { useOfficePageHooks } from "./hooks";
import { LocationCard } from "./LocationCard";
import { ManagerCard } from "./ManagerCard";
import { ManagerModal } from "./ManagerModal";

export const OfficePageContext = React.createContext<any>(null);

const BlackText = styled.div`
  color: #000000;
  font-weight: bold;
  font-size: larger;
`;

export const OfficePage = () => {
  const params: any = useParams();
  const { id } = params;
  const officePage = useOfficePageHooks();
  const { getOfficeId, loading, officeData, getManagers, officeCity } = officePage;
  const { width } = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    getOfficeId(id);
    getManagers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OfficePageContext.Provider value={officePage}>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100%",
              alignItems: "flex-start",
            }}
          >
            <Button
              style={{
                marginBottom: width > 426 ? 32 : 16,
                marginTop: width > 426 ? 0 : 8,
              }}
              onClick={() => history.goBack()}
            >
              {`< back`}
            </Button>

            <BlackText style={{ marginBottom: 4 }}>{officeCity} Office</BlackText>

            <div
              style={{
                display: "flex",
                minHeight: "100%",
                width: "100%",
                flexWrap: "wrap",
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  marginRight: width > 425 ? 16 : 0,
                }}
              >
                <LocationCard />
                <ManagerCard />
              </div>

              <div style={{ display: "flex", flexGrow: 1 }}>
                <EmployeesTable />
              </div>
            </div>
          </div>
          <ManagerModal />
        </>
      )}
    </OfficePageContext.Provider>
  );
};
