import { useContext, useEffect, useState } from "react";
import { TimeTrackingUserDataContext } from "../../../../TimeTrackingUserDataProvider";
import { TrackerForm, defaultTrackerForm } from "../../utils/types";
import { IEditSessionModalProps } from "./types";
import { ApiClientContext } from "../../../../../../../App";
import { Task, Technology } from "../../../../types";
import moment from "moment";
import { message } from "antd";
import {
  getDates,
  prepareSessionForTracking,
  validateTrackerForm,
} from "../../utils/helpers";
export const useEditSessionModal = (
  session: any,
  onUpdate: () => void
): IEditSessionModalProps => {
  const { apiClient } = useContext(ApiClientContext);
  const [formData, setFormData] = useState<TrackerForm>(defaultTrackerForm);
  const [day, setDay] = useState<moment.Moment>(moment(session.start));
  const [startTime, setStartTime] = useState<string>(
    moment(session.start).format("HH:mm")
  );
  const [endTime, setEndTime] = useState<string>(
    moment(session.end).format("HH:mm")
  );
  const [isNewTask, setIsNewTask] = useState<boolean>(false);
  const { projects, updateSessionWeeks } = useContext(
    TimeTrackingUserDataContext
  );
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const validateSessionCreation = () => {
    const validationResult = validateTrackerForm(
      formData,
      isNewTask,
      "Manual",
      endTime
    );
    if (!validationResult.isValid) {
      return validationResult.errorMessage;
    }
    return null;
  };
  const initializeFormData = async () => {
    setStartTime(moment(session.start).format("HH:mm"));
    setEndTime(moment(session.end).format("HH:mm"));
    await apiClient
      .get(`/api/sessions/${session.id}`)
      .then((res) => {
        setFormData({
          ...formData,
          taskId: res.data.taskId,
          technologyId: res.data.technologyId,
          projectId: res.data.projectId,
          sessionType: res.data.sessionType,
          description: res.data.description,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = async () => {
    const validationError = validateSessionCreation();
    if (validationError) {
      console.log(validationError);
      message.error(validationError);
      return;
    }

    const dates = getDates(startTime, endTime, day);
    const basicSession = prepareSessionForTracking(
      formData,
      isNewTask,
      "",
      tasks
    );

    const preparedSession = {
      ...basicSession,
      ...dates,
      task: isNewTask ? basicSession.task : undefined,
      taskId: !isNewTask ? formData.taskId : undefined,
    };

    try {
      await apiClient
        .put(`/api/sessions/update/${session.id}`, preparedSession)
        .then((res) => {
          message.success("Session recorded.");
        })
        .catch((err) => {
          message.error("Could not record session. " + err);
        });

      await updateSessionWeeks();
      onUpdate();
    } catch (error) {
      message.error("Could not record session. " + error);
    }
  };
  useEffect(() => {
    initializeFormData();
  }, [session]);
  useEffect(() => {
    setTechnologies(
      projects.find((project) => project.projectId === formData.projectId)
        ?.technologies || []
    );
    setTasks(
      projects.find((project) => project.projectId === formData.projectId)
        ?.tasks || []
    );
  }, [formData.projectId]);
  return {
    formData,
    technologies,
    tasks,
    setIsNewTask,
    setFormData,
    day,
    setDay,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    onSubmit,
  };
};
