import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { Client } from "../types";
import { AddClientFormData } from "./types";

const useClients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const { apiClient } = useContext(ApiClientContext);
  const fetchClients = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get("/api/clients");
      setClients(data);
      setIsLoading(false);
    } catch (err) {
      setError("err");
    }
    setIsLoading(false);
  };

  const addClient = async (formData: AddClientFormData) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post("/api/clients", formData);
      setIsLoading(false);
      setClients([...clients, data.response]);
      return "Client Added Successfully";
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  const deleteClient = async (clientId: string) => {
    try {
      const { data } = await apiClient.delete(`/api/clients/${clientId}`);
      fetchClients();
      message.success('Client Deleted Successfully');
    } catch (err: any) {
      message.error(err.response.data.message);
    }
  };

  const editClient = async (client: Client) => {
    try {
      setIsLoading(true);
      const putBody = {
        name: client.name,
        town: client.town,
        country: client.country,
        timezone: client.timeZoneName,
      };
      const { data } = await apiClient.put(`/api/clients/${client.id}`, putBody);
      if (data.succeed) {
        fetchClients();
        setIsLoading(false);
        return `${client.name} client updated successfully!`;
      } else {
        setIsLoading(false);
        throw "An error occurred!";
      }
    } catch (err: any) {
      setIsLoading(false);
      throw err.response.data.message;
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return {
    clients,
    isLoading,
    error,
    addClient,
    deleteClient,
    editClient,
  };
};

export default useClients;
