import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../App";
import { TechnologyModel } from "./TechnologyModel";

export const typesDict = {
  0: "Unknown",
  10: "Backend",
  20: "Frontend",
  30: "Mobile",
  40: "QA",
  50: "Design",
  60: "Research",
};

export const useTechnologies = () => {
  const [listLoading, setListLoading] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [error, setError] = useState("");
  const [technologies, setTechnologies] = useState([]);

  const [actionSuccess, setActionSuccess] = useState<boolean | undefined>(undefined);

  const { apiClient } = useContext(ApiClientContext);

  const mapTechnology = (technology: any) => {
    const prepared: TechnologyModel = {
      ...technology,
      //@ts-ignore
      type: { key: technology.type, name: typesDict[technology.type] },
    };
    return prepared;
  };

  const getAllTechnologies = async () => {
    setListLoading(true);
    try {
      const res = await apiClient.get("/api/technologies/stats");
      const techs = res.data.map((t: any) => mapTechnology(t));
      setTechnologies(techs);
      setListLoading(false);
    } catch (error) {
      message.error("The technologies could not been retrieved!");
      setListLoading(false);
    }
  };

  // const getTechnology = async (id: string) => {
  //   try {
  //     setLoadingTechnology(true);
  //     const res = await apiClient.get(`/api/technologies/${id}`);
  //     setLoadingTechnology(false);
  //   } catch (err) {
  //     setLoadingTechnology(false);
  //     setError(err);
  //   }
  // };

  const addTechnology = async (name: string, techType: number) => {
    setAddLoading(true);
    try {
      const res = await apiClient.post(`/api/technologies`, { name, techType });
      getAllTechnologies();
      setAddLoading(false);
      message.success(`${name} was created succesfully!`);
      return Promise.resolve();
    } catch (err: any) {
      setAddLoading(false);
      message.error(`${name} could not been created!`);
      setActionSuccess(false);
      setError(err);
      throw "";
    }
  };

  const updateTechnology = async (id: string, name: string, techType: number) => {
    setEditLoading(true);
    try {
      await apiClient.put(`/api/technologies/${id}`, { name, techType });
      message.success(`${name} was updated succesfully!`);
      setActionSuccess(true);
      getAllTechnologies();
      setEditLoading(false);
      return Promise.resolve();
    } catch (err: any) {
      message.error(`${name} could not been updated!`);
      setActionSuccess(false);
      setError(err);
      setEditLoading(false);
      setError(err);
      throw "";
    }
  };

  const deleteTechnology = async (id: string, name: string) => {
    setDeleteLoading(true);
    try {
      const res = await apiClient.delete(`/api/technologies/${id}`);
      message.success(`${name} was deleted succesfully!`);
      setActionSuccess(true);
      getAllTechnologies();
      setDeleteLoading(false);
      return Promise.resolve();
    } catch (err: any) {
      message.error(`${name} could not been deleted!`);
      setActionSuccess(false);
      setError(err);
      setDeleteLoading(false);
      setError(err);
      throw "";
    }
  };

  useEffect(() => {
    getAllTechnologies();
  }, []);

  return {
    getAllTechnologies,
    // getTechnology,
    updateTechnology,
    deleteTechnology,
    addTechnology,
    setCreateModalVisible,
    // types,
    createModalVisible,
    actionSuccess,
    listLoading,
    addLoading,
    deleteLoading,
    editLoading,
    error,
    technologies,
  };
};
