import { Col, Divider, Row } from "antd";
import React from "react";
import { getUserPosition } from "../../../../_core/hardcodedValues";
import { ProjectTag } from "../../../../styles/StyledComponents";
import { formatMinToHoursString } from "../../formatMinToHoursString";
import { Project } from "../../types";

export const ProjectRow = ({ project }: { project: Project }) => {
  return (
    <>
      <Row style={{ marginTop: 5 }}>
        <Col flex="1">
          <div style={{ width: "content-fit" }}>
            <ProjectTag color={project.color}>{project.name}</ProjectTag>
          </div>
          <div style={{ textAlign: "left", marginTop: 10, fontSize: 14, color: "grey" }}>
            {project.teamLeader}
          </div>
        </Col>
        <Col flex="2">
          {project.technologies.map((technology) => (
            <Row key={technology.id} align="middle">
              <Col flex="1">
                {technology.name}/{getUserPosition(technology.userPosition)}
              </Col>
              <Col flex="1" style={{ textAlign: "left" }}>
                <Row justify="space-between">
                  <Col flex="1">Worked:</Col>
                  <Col style={{ fontWeight: 700 }} flex="1">
                    {formatMinToHoursString(technology.workedTime)}
                  </Col>
                </Row>

                <Row justify="space-between">
                  <Col flex="1">Forecast:</Col>
                  <Col style={{ fontWeight: 700 }} flex="1">
                    {formatMinToHoursString(technology.forecastTime)}
                  </Col>
                </Row>
              </Col>
            </Row>
          ))}
        </Col>
        <Col flex="1">
          <Row style={{ textAlign: "left" }}>Total</Row>
          <Row style={{ textAlign: "left" }}>
            <Col flex="1">Worked:</Col>
            <Col flex="1" style={{ fontWeight: 700 }}>
              {formatMinToHoursString(project.totalWorkedTime)}
            </Col>
          </Row>
          <Row style={{ textAlign: "left" }}>
            <Col flex="1">Forecast:</Col>
            <Col flex="1" style={{ fontWeight: 700 }}>
              {formatMinToHoursString(project.totalForecastTime)}
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </>
  );
};
