import { Button, Card, Col, Modal, Row, Spin, Tooltip, Typography, Form, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState } from "react";
import { useContext } from "react";
import { ManageEmployeeContext } from ".";
import { UserContext } from "../../../App";
import { getUserPosition, positions } from "../../../_core/hardcodedValues";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";
import { CardTitle, FormLabel } from "../../../styles/StyledComponents";
import { useTechnologies } from "../../Technologies/useTechnologies";

export const Technologies = () => {
  const { employee, isLoading, addTechnology, deleteTechnology } =
    useContext(ManageEmployeeContext);
  const { user } = useContext(UserContext);
  const { isAdmin } = useUserRoles();
  const [addTechnologyDialog, setAddTechnologyDialog] = useState(false);
  const [form] = useForm();
  const { technologies, listLoading } = useTechnologies();
  const handleCancelDialog = () => {
    setAddTechnologyDialog(false);
    form.resetFields();
  };
  const handleFinish = async (values: any) => {
    try {
      await addTechnology(values);
      handleCancelDialog();
    } catch (err) {}
  };

  const handleDelete = (techId: string) => {
    Modal.confirm({
      title: "You are about to delete a technology",
      icon: null,
      content: "This action can not be reversed. Are you sure you want to delete this technology?",
      okText: "Yes, delete technology",
      okButtonProps: {
        style: {
          backgroundColor: "#FF3E3E",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return deleteTechnology(techId);
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <Card
        type="inner"
        style={{ width: "100%" }}
        title={<CardTitle>Technologies</CardTitle>}
        extra={
          isAdmin() && (
            <Button type="link" onClick={() => setAddTechnologyDialog(true)}>
              Add technology
            </Button>
          )
        }
      >
        <Spin spinning={isLoading}>
          {employee?.userTechnologies.length ? (
            employee.userTechnologies.map((technology) => (
              <Row justify="space-between" align="middle" key={technology.technologyId}>
                <Col style={{ textAlign: "left" }}>
                  <Typography.Text type="secondary" style={{ fontSize: "1.1em" }}>
                    Tech/Level
                  </Typography.Text>
                </Col>
                <Col>
                  <Row>
                    <div style={{ fontSize: "1.1em", fontWeight: "bolder", marginRight: 5 }}>
                      {`${technology.technologyName}/ ${getUserPosition(technology.userPosition)}`}
                    </div>
                    {isAdmin() && (
                      <Tooltip title="Delete">
                        <DeleteFieldIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(technology.technologyId)}
                        />
                      </Tooltip>
                    )}
                  </Row>
                </Col>
              </Row>
            ))
          ) : (
            <Row justify="center">
              <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
                No items yet!
              </Typography.Text>
            </Row>
          )}
        </Spin>
      </Card>
      <Modal
        visible={addTechnologyDialog}
        onCancel={handleCancelDialog}
        footer={null}
        closeIcon={<RoundCloseIcon />}
      >
        <div
          style={{
            fontWeight: 700,
            fontSize: "20px",
            lineHeight: "25px",
            textAlign: "left",
            position: "relative",
            bottom: 10,
          }}
        >
          Add technology
        </div>

        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item
            name="id"
            label={<FormLabel>Technology</FormLabel>}
            rules={[{ required: true, message: "Please select a technology" }]}
          >
            <Select>
              {technologies.map((technology: any) => (
                <Select.Option key={technology.id} value={technology.id}>
                  {technology.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="userPosition"
            label={<FormLabel>Position</FormLabel>}
            rules={[{ required: true, message: "Please select a position" }]}
          >
            <Select loading={listLoading}>
              {positions.map((position) => (
                <Select.Option key={position.value} value={position.value}>
                  {position.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              loading={isLoading}
              htmlType="submit"
              block
              type="primary"
              style={{ borderRadius: 5 }}
              size="large"
            >
              Add technology
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
