import { DeleteOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useContext } from "react";
import { BugContext } from ".";
import { Typography, Space } from "antd";

const { Text, Link } = Typography;

export const BugTable = () => {
  const { bugs, deleteBug } = useContext(BugContext);

  const columns = [
    {
      render: (record: any) => {
        return (
          <Space direction="vertical">
            <Text>
              <b>Bug: </b> {record.content}
            </Text>
            <Text>
              <b>Issued by: </b> {record.userName}
            </Text>
          </Space>
        );
      },
    },
    {
      render: (record: any) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "1.2em",
            }}
          >
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={() => deleteBug(record.id)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      pagination={{ pageSize: 25 }}
      dataSource={bugs.map((bug: any) => ({
        ...bug,
        key: bug.id,
      }))}
      columns={columns}
      style={{ marginTop: 16 }}
    />
  );
};
