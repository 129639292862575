import { Card, Col, Row, Skeleton, Typography } from "antd";
import React, { useContext } from "react";
import { ManageModelContext } from ".";
import { getItemType } from "../../../../_core/hardcodedValues";
import { CardTitle, StyledTag } from "../../../../styles/StyledComponents";

export const ModelDetails = () => {
  const { model, isLoading } = useContext(ManageModelContext);
  return (
    <Card type="inner" title={<CardTitle>Model Details</CardTitle>}>
      <Skeleton loading={isLoading}>
        <Row>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Model name</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{model?.name}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Brand</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{model?.brandName}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Item type</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{getItemType(model?.itemType || 0)}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Items assigned</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{model?.itemsAssigned}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 120 }}>
            <Row>
              <Typography.Text type="secondary">Features</Typography.Text>
            </Row>
          </Col>
          <Col flex="1">
            <Row>
              {model?.modelTags.map((tag) => (
                <StyledTag key={tag.id}>{tag.name}</StyledTag>
              ))}
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};
