import { Skeleton } from "antd";
import React, { useContext } from "react";
import { TimeTrackingUserDataContext } from "../../TimeTrackingUserDataProvider";
import { SessionsHistory } from "../History/SessionsHistory";
import { TrackerForm } from "./Inputs";
import { AutomaticInsert } from "./Inserts/AutomaticInsert";
import CalendarTracking from "./Inserts/CalendarTracking";
import { ManualInsert } from "./Inserts/ManualInsert";
import { TrackerContext } from "./utils/types";

interface TrackerProps {
  defaultProjectId?: string;
}
export const Tracker: React.FC<TrackerProps> = () => {
  const { typeOfTracking } = useContext(TrackerContext);
  const { isGettingInitialData } = React.useContext(
    TimeTrackingUserDataContext
  );
  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: 30,
          width: "100%",
          maxHeight: "120px",
        }}
      >
        {isGettingInitialData ? (
          <Skeleton active />
        ) : (
          <>
            <TrackerForm />
            <div
              style={{
                display: "flex",
                width: "25%",
              }}
            >
              {typeOfTracking === "Manual" ? (
                <ManualInsert />
              ) : (
                <AutomaticInsert />
              )}
            </div>
          </>
        )}
      </div>
      {typeOfTracking !== "Calendar" ? (
        <SessionsHistory />
      ) : (
        <CalendarTracking />
      )}
    </>
  );
};
