import { Card, Col, Row, Skeleton, Typography } from "antd";
import React, { useContext } from "react";
import { ManageBrandContext } from ".";
import { CardTitle } from "../../../../styles/StyledComponents";

export const BrandDetails = () => {
  const { brand, isLoading } = useContext(ManageBrandContext);
  return (
    <Card type="inner" title={<CardTitle>Brand Details</CardTitle>}>
      <Skeleton loading={isLoading}>
        <Row>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Brand name</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{brand?.name}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Models assigned</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{brand?.modelsAssigned}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Items assigned</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{brand?.itemsAssigned}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span="8">
            <Row justify="space-around" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Items available</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{brand?.itemsAvailable}</Typography.Text>
                </Row>
              </Col>
            </Row>

            <Row justify="space-around" align="middle" style={{ marginTop: 10, marginBottom: 10 }}>
              <Col flex="1" style={{ minWidth: 120 }}>
                <Row>
                  <Typography.Text type="secondary">Items broken</Typography.Text>
                </Row>
              </Col>
              <Col flex="2">
                <Row>
                  <Typography.Text>{brand?.itemsBroken}</Typography.Text>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};
