import { Modal, Form, Select, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useContext } from "react";
import { ProjectContext } from ".";
import { FormLabel } from "../../../styles/StyledComponents";
import useClients from "../../Clients/Clients/useClients";
import { Contact } from "../../Clients/types";

interface AddContactDialogProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
}
export const AddContactDialog = (props: AddContactDialogProps) => {
  const { isVisible, setIsVisible } = props;

  const { clients } = useClients();
  const { project, addClientContact } = useContext(ProjectContext);
  const getContacts = () => {
    let client = clients.find((x) => x.id === project?.client.id);
    return client?.contacts;
  };
  const [form] = useForm();

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };
  const handleFinish = async (values: any) => {
    try {
      await addClientContact(values.contactId);
      handleCancel();
    } catch (err) {}
  };
  return (
    <Modal visible={isVisible} footer={null} onCancel={handleCancel}>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item name="contactId" label={<FormLabel>Contacts</FormLabel>}>
          <Select>
            {getContacts()?.map((contact: Contact) => (
              <Select.Option key={contact.id} value={contact.id}>
                {contact.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Contact
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
