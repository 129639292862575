import { Table, Tooltip } from "antd";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { AllMyItemsContext } from ".";
import { TransferIcon } from "../../../../assets/icons/Inventory/TransferIcon";
import { OpenFieldIcon } from "../../../../assets/icons/OpenFieldIcon";
import { TableHeader, TableText } from "../../../../styles/StyledComponents";
import { TransferItemDialog } from "../../TransferItemDialog";
import { Item } from "../../types";

export const MyItemsTable = () => {
  const { items, isLoading, fetchMyItems } = useContext(AllMyItemsContext);
  const history = useHistory();
  const [transferDialog, setTransferDialog] = useState(false);
  const [itemToTransfer, setItemToTransfer] = useState<Item>();
  return (
    <div>
      <Table loading={isLoading} dataSource={items} rowKey="id" pagination={false}>
        <Table.Column<Item>
          title={<TableHeader>Item NR</TableHeader>}
          dataIndex="inventoryNumber"
          render={(itemNr) => <TableText>{itemNr}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Item Description</TableHeader>}
          dataIndex="description"
          render={(desc) => <TableText>{desc}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Model & Brand</TableHeader>}
          render={(val, row) => (
            <div>
              <div
                style={{ color: "#0F172A", fontSize: "14", lineHeight: "17px", fontWeight: 600 }}
              >
                {row.modelName}
              </div>
              <div
                style={{ color: "#64748B", fontSize: "12", lineHeight: "18px", fontWeight: 300 }}
              >
                {row.brandName}
              </div>
            </div>
          )}
        />
        <Table.Column<Item>
          title={<TableHeader>Office</TableHeader>}
          dataIndex="officeCity"
          render={(office) => <TableText>{office}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Warranty</TableHeader>}
          dataIndex="warranty"
          render={(warranty) => <TableText>{warranty}</TableText>}
        />
        <Table.Column<Item>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => (
            <div>
              <Tooltip title="Details">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/inventory/my-items/${row.id}`)}
                />
              </Tooltip>

              <Tooltip title="Transfer">
                <TransferIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setItemToTransfer(row);
                    setTransferDialog(true);
                  }}
                />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      <TransferItemDialog
        isVisible={transferDialog}
        setIsVisible={setTransferDialog}
        itemId={itemToTransfer?.id || ""}
        callback={fetchMyItems}
      />
    </div>
  );
};
