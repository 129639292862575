import { Button, Col, Row, Typography } from "antd";
import React from "react";
import { useParams } from "react-router";
import { ManageModelContext } from "../../Models/ManageModel";
import { Item } from "../../types";
import { ItemActionsButtons } from "./ItemActionsButtons";
import { ItemDetails } from "./ItemDetails";
import { ItemHistory } from "./ItemHistory";
import useManageItem from "./useManageItem";

interface ManageItemContextInterface {
  item?: Item;
  isLoading: boolean;
  breakItem(): void;
  makeItemAvailable(): void;
  fetchItem(): void;
}

//@ts-ignore
export const ManageItemContext = React.createContext<ManageItemContextInterface>();
export const ManageItem = () => {
  const { itemId } = useParams<{ itemId: string }>();

  const ret = useManageItem(itemId);
  return (
    <ManageItemContext.Provider value={ret}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 20 }}>
        <Col>
          <Typography.Title level={4}>Manage Item</Typography.Title>
        </Col>
        <Col>
          <ItemActionsButtons />
        </Col>
      </Row>
      <ItemDetails />
      <ItemHistory />
    </ManageItemContext.Provider>
  );
};
