import { Button, Modal, Skeleton } from "antd";
import React, { useContext, useState } from "react";
import { ManageItemContext } from ".";
import { TransferItemDialog } from "../../TransferItemDialog";

export const ItemActionsButtons = () => {
  const { item, fetchItem, makeItemAvailable, breakItem, isLoading } =
    useContext(ManageItemContext);
  const [transferDialog, setTransferDialog] = useState(false);
  const handleMarkBroken = () => {
    Modal.confirm({
      title: "Are you sure you want to mark the item as broken?",
      icon: null,
      okText: "Yes, make item broken",
      okButtonProps: {
        style: {
          backgroundColor: "#FF3E3E",
          color: "white",
          borderRadius: "5px",
          border: "none",
        },
      },
      onOk() {
        return breakItem();
      },
      cancelText: "Cancel action",
      cancelButtonProps: {
        style: {
          border: "none",
          fontSize: "16px",
          fontWeight: 500,
          color: "#A8A8A8",
        },
      },
    });
  };
  return (
    <Skeleton loading={isLoading}>
      {item?.brokenDate && item?.brokenDate !== "0001-01-01T00:00:00" ? (
        <Button
          type="primary"
          style={{ borderRadius: 5, border: "none", backgroundColor: "#00AD0D" }}
          onClick={makeItemAvailable}
        >
          Mark as available
        </Button>
      ) : (
        <div>
          <Button
            type="primary"
            danger
            style={{ borderRadius: 5, marginRight: 5 }}
            onClick={handleMarkBroken}
          >
            Mark as broken
          </Button>
          <Button
            type="primary"
            style={{
              borderRadius: 5,
              width: 139,
              backgroundColor: item?.ownedByFirstName ? "" : "#00AD0D",
              border: "none",
            }}
            onClick={() => setTransferDialog(true)}
          >
            {item?.ownedByFirstName ? "Transfer item" : "Assign Item"}
          </Button>
        </div>
      )}

      <TransferItemDialog
        isVisible={transferDialog}
        setIsVisible={setTransferDialog}
        itemId={item?.id || ""}
        isAssignItem={!item?.ownedByFirstName}
        callback={fetchItem}
      />
    </Skeleton>
  );
};
