import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.524 2C7.025 2 2.54395 6.49 2.54395 12C2.54395 17.51 7.025 22 12.524 22C18.0231 22 22.5041 17.51 22.5041 12C22.5041 6.49 18.0231 2 12.524 2ZM15.8773 14.3C16.1668 14.59 16.1668 15.07 15.8773 15.36C15.7276 15.51 15.538 15.58 15.3484 15.58C15.1588 15.58 14.9692 15.51 14.8195 15.36L12.524 13.06L10.2286 15.36C10.0789 15.51 9.88929 15.58 9.69967 15.58C9.51005 15.58 9.32043 15.51 9.17072 15.36C8.8813 15.07 8.8813 14.59 9.17072 14.3L11.4661 12L9.17072 9.7C8.8813 9.41 8.8813 8.93 9.17072 8.64C9.46015 8.35 9.93919 8.35 10.2286 8.64L12.524 10.94L14.8195 8.64C15.1089 8.35 15.5879 8.35 15.8773 8.64C16.1668 8.93 16.1668 9.41 15.8773 9.7L13.5819 12L15.8773 14.3Z"
      fill="#137AE9"
    />
  </svg>
);

export const RoundCloseIcon = (props: any) => <Icon component={svg} {...props} />;
