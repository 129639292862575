import React, { useState } from "react";
import { Input } from "antd";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

const SearchWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  width: 100%;
`;

const ClearButton = styled(CloseOutlined)`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

const FilterSearch: React.FC<{ onSearch: (value: string) => void }> = ({
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchValue("");
    onSearch("");
  };

  return (
    <SearchWrapper>
      <Input
        placeholder="Search a filter value..."
        value={searchValue}
        onChange={handleChange}
      />
      {searchValue && <ClearButton onClick={handleClear} />}
    </SearchWrapper>
  );
};

export default FilterSearch;
