import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Brands } from "./Brands";
import { Items } from "./Items";
import { ItemsRequests } from "./ItemsRequests";
import { Models } from "./Models";
import { MyItems } from "./MyItems";

export const Inventory = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/requests`} component={ItemsRequests} />
      <Route path={`${path}/brands`} component={Brands} />
      <Route path={`${path}/models`} component={Models} />
      <Route path={`${path}/items`} component={Items} />
      <Route path={`${path}/my-items`} component={MyItems} />
    </Switch>
  );
};
