import { AuthenticationRouting } from "./features/Routing/AuthenticationRouting";
import React, { useState, createContext, useEffect } from "react";
import { AppRouting } from "./features/Routing/AppRouting";
import { LoadingOutlined } from "@ant-design/icons";
import useApiClient from "./_core/hooks/useApiClient";
import { Spin } from "antd";
import "./styles/App.less";
import "./styles/App.css";
import { User } from "./_core/types";
import { AxiosInstance } from "axios";
import styled from "styled-components";

const EmptySpace = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
`;

interface UserContextInterface {
  user: User;
  setUser(value: User | null): void;
  loading: boolean;
}
//@ts-ignore
export const UserContext = createContext<UserContextInterface>();

interface ApiClientContextInterface {
  apiClient: AxiosInstance;
  BASE_URL: string;
  RECRUITMENT_APP_LINK: string;
  setAuthorizationHeader(token: string): void;
}
//@ts-ignore
export const ApiClientContext = createContext<ApiClientContextInterface>();

export default function App() {
  const antIcon = <LoadingOutlined style={{ fontSize: "5em" }} spin />;

  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>(null);
  const { apiClient, BASE_URL, RECRUITMENT_APP_LINK, setAuthorizationHeader } = useApiClient();
  const getUserData = async () => {
    try {
      const { data } = await apiClient.get(`/api/account`);
      setUser(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      <ApiClientContext.Provider
        value={{
          apiClient,
          BASE_URL,
          RECRUITMENT_APP_LINK,
          setAuthorizationHeader,
        }}
      >
        <Spin indicator={antIcon} spinning={loading}>
          {loading ? (
            <EmptySpace />
          ) : user ? (
            <div className="fade-in">
              <AppRouting />
            </div>
          ) : (
            <div className="fade-in">
              <AuthenticationRouting />
            </div>
          )}
        </Spin>
      </ApiClientContext.Provider>
    </UserContext.Provider>
  );
}
