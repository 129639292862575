import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ClientsPage from "./Clients";
import ClientPage from "./ClientContacts";

export default function index() {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={ClientsPage} />
      <Route exact path={`${path}/:clientId`} component={ClientPage} />
    </Switch>
  );
}
