import { AxiosPromise } from "axios";
import React from "react";
import { Request } from "../types";
import { FilterBar } from "./FilterBar";
import { RequestsTable } from "./RequestsTable";
import useItemRequests from "./useItemRequests";

interface ItemsRequestsContextInterface {
  requests: Request[];
  isLoading: boolean;
  searchInputValue: string;
  acceptRequest(requestId: string): any;
  declineRequest(requestId: string): any;
  setSearchInputValue:any;
}
//@ts-ignore
export const ItemsRequestsContext = React.createContext<ItemsRequestsContextInterface>();
export const ItemsRequests = () => {
  const ret = useItemRequests();
  return (
    <ItemsRequestsContext.Provider value={ret}>
      <FilterBar />
      <RequestsTable />
    </ItemsRequestsContext.Provider>
  );
};
