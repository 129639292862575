import { Table } from "antd";
import React from "react";
import styled from "styled-components";
import { Employee, Project, UpdateForecastValues } from "../types";
import { EmployeeCell } from "./EmployeeCell";
import { EmployeeTechnologyCell } from "./EmployeeTechnologyCell";
import { ProjectHeaderCell } from "./ProjectHeaderCell";
import { TimePicker } from "./TimePicker";
import { TotalHoursHeaderCell } from "./TotalHoursHeaderCell";
import useForecastTable from "./useForecastTable";

interface ForecastTableContext {
  projects: Project[];
  employees: Employee[];
  isLoading: boolean;

  idProjectSelected: string;
  setIdProjectSelected(id: string): void;
  month: number;
  setMonth(val: number): void;
  year: number;
  setYear(val: number): void;
  updateForecast(values: UpdateForecastValues): void;
  workedTime: boolean;

  idSelectedEmployee: string;
  setIdSelectedEmployee(id: string): void;
}

//@ts-ignore
export const ForecastTableContext = React.createContext<ForecastTableContext>();

const StyledTable = styled(Table)`
  tbody {
    tr {
      td {
        padding: 5px;
      }
    }
  }
`;
interface ForecastTableProps {
  workedTime?: boolean;
}
export const ForecastTable = ({ workedTime }: ForecastTableProps) => {
  const ret = useForecastTable(workedTime ? workedTime : false);

  const { projects, isLoading, employees, idProjectSelected, idSelectedEmployee } = ret;
  return (
    <ForecastTableContext.Provider value={{ ...ret, workedTime: workedTime ? workedTime : false }}>
      <TimePicker />
      <StyledTable
        bordered
        dataSource={employees}
        rowKey="id"
        loading={isLoading}
        scroll={{ x: projects.length * 380, y: "72vh" }}
        pagination={false}
      >
        <Table.Column<Employee>
          title={<TotalHoursHeaderCell />}
          fixed="left"
          width="250px"
          render={(val, record) => <EmployeeCell employee={record} />}
        />
        {projects.map((project) => (
          <Table.Column<Employee>
            key={project.id}
            title={<ProjectHeaderCell project={project} />}
            render={(val, record) => {
              const userProject = record.projects.find((x) => x.id === project.id);
              if (userProject) {
                return (
                  <EmployeeTechnologyCell
                    employee={record}
                    project={userProject}
                    selected={
                      (idProjectSelected ? idProjectSelected === project.id : true) &&
                      (idSelectedEmployee ? idSelectedEmployee === record.id : true)
                    }
                  />
                );
              }
            }}
          />
        ))}
      </StyledTable>
    </ForecastTableContext.Provider>
  );
};
