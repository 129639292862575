import { Col, Row, Select } from "antd";
import React, { useContext } from "react";
import { ForecastTableContext } from ".";

export const TimePicker = () => {
  const { month, year, setMonth, setYear } = useContext(ForecastTableContext);

  const monthsData = Array.from({ length: 12 }, (item, i) => {
    return {
      label: new Date(0, i).toLocaleString("en-US", { month: "long" }),
      value: i + 1,
    };
  });
  const yearsData = Array.from({ length: 2100 - 2021 }, (item, i) => {
    return 2021 + i;
  });
  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
      <Col>
        <div style={{ fontSize: "20px", lineHeight: "25px", fontWeight: "bold" }}>
          Manage users and projects hours
        </div>
      </Col>
      <Col>
        <Row>
          <Select
            style={{ marginRight: 5 }}
            placeholder="Select month"
            value={month}
            onChange={(val) => setMonth(val)}
          >
            {monthsData.map((x) => (
              <Select.Option key={x.value} value={x.value}>
                {x.label}
              </Select.Option>
            ))}
          </Select>
          <Select placeholder="Select year" value={year} onChange={(val) => setYear(val)}>
            {yearsData.map((x) => (
              <Select.Option key={x} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </Row>
      </Col>
    </Row>
  );
};
