import { Button, Col, Row, Space } from "antd";
import React, { createContext, useContext, useState } from "react";
import { UserContext } from "../../../App";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { AddMemberDialog } from "../Project/AddMemberDialog";
import { ProjectDialog } from "../ProjectDialog";
import { Project, ProjectFormInputData } from "../types";
import { ProjectsTable } from "./ProjectsTable";
import { SearchBar } from "./SearchBar";
import { useProjects } from "./useProjects";

export const AllProjects = () => {
  const [addProjectDialog, setAddProjectDialog] = useState(false);
  const { user } = useContext(UserContext);
  const { isAdmin } = useUserRoles();

  return (
    <div>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col flex="1" style={{ marginRight: 5 }}>
          <SearchBar />
        </Col>
        {isAdmin() && (
          <Col>
            <Button
              type="primary"
              style={{ borderRadius: 5 }}
              onClick={() => setAddProjectDialog(true)}
            >
              Add new project
            </Button>
          </Col>
        )}
      </Row>
      <ProjectsTable />
      <ProjectDialog isVisible={addProjectDialog} setIsVisible={setAddProjectDialog} />
    </div>
  );
};
