import { message } from "antd";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import useApiClient from "../../../../../../_core/hooks/useApiClient";
import { TimeTrackingUserDataContext } from "../../../TimeTrackingUserDataProvider";
import { TimeTrackingSession } from "../../../types";
import { SessionUpdateLoading, UseEditSession } from "./types";

export const useEditSession: UseEditSession = (initialSession) => {
  const [sessionState, setSessionState] =
    useState<TimeTrackingSession>(initialSession);
  const [loading, setLoading] = useState<Partial<SessionUpdateLoading>>({});
  const { apiClient } = useApiClient();
  const { updateSessionWeeks } = useContext(TimeTrackingUserDataContext);

  //this ref and the logic with it prevents infinite render loops
  //The idea of isMountedRef  is primarily to prevent asynchronous operations from setting the state on an unmounted component, so use it accordingly
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  //this function handles calling apis to the backend in order to edit the fields of a session granularly
  const applyChange = async (
    type: keyof typeof loading, //field to edit and reflect in loading state
    apiUrlSuffix: string, // endpoint to call
    data: any, //endpoint payload
    successMessage: string,
    errorMessage: string
  ) => {
    if (isMountedRef.current) {
      setLoading((prevLoading) => ({ ...prevLoading, [type]: true }));
    }

    await apiClient
      .put(
        `/api/time-tracking/sessions/${apiUrlSuffix}/${sessionState.id}`,
        data
      )
      .then(async () => {
        if (isMountedRef.current) {
          await updateSessionWeeks();
          message.success(successMessage);
        }
      })
      .catch((err) => {
        console.error(`Error while updating session ${type}`, err);
        if (isMountedRef.current) {
          message.error(errorMessage);
        }
      })
      .finally(() => {
        if (isMountedRef.current) {
          setLoading((prevLoading) => ({ ...prevLoading, [type]: false }));
        }
      });
  };

  //this useEffect detects what changes need to be relayed to the backend and calls the applyChange function to do that
  useEffect(() => {
    const oldStartMoment = moment.utc(initialSession.start);
    const newStartMoment = moment.utc(sessionState.start);

    const oldEndMoment = moment.utc(initialSession.end);
    const newEndMoment = moment.utc(sessionState.end);

    // Date changed
    if (
      moment(sessionState.start).format("DD-MM-YYYY") !==
      moment(initialSession.start).format("DD-MM-YYYY")
    ) {
      applyChange(
        "date",
        "update-start-date",
        { startDate: sessionState.start },
        "Session date changed.",
        "Could not update session date."
      );

      // Total time changed
    } else if (sessionState.time !== initialSession.time) {
      applyChange(
        "total",
        "update-total-time",
        { totalTime: sessionState.time },
        "Session total time changed.",
        "Could not update session total time."
      );

      // Start hour changed
    } else if (
      oldStartMoment.format("HH:mm") !== newStartMoment.format("HH:mm")
    ) {
      applyChange(
        "start",
        "update-start-moment",
        { startMoment: newStartMoment.format("HH:mm") },
        "Session start time changed.",
        "Could not update session start time."
      );

      // End hour changed
    } else if (oldEndMoment.format("HH:mm") !== newEndMoment.format("HH:mm")) {
      applyChange(
        "end",
        "update-end-moment",
        { endMoment: newEndMoment.format("HH:mm") },
        "Session end time changed.",
        "Could not update session end time."
      );
    } // Type changed
    else if (sessionState.type !== initialSession.type) {
      applyChange(
        "type",
        "update-type",
        { sessionType: sessionState.type },
        "Session type changed.",
        "Could not update session type."
      );
    }
  }, [sessionState]);

  useEffect(() => {
    setSessionState(initialSession);
  }, [initialSession]);

  return { sessionState, setSessionState, loading };
};
