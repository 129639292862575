import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2H8C4 2 2 4 2 8V21C2 21.55 2.45 22 3 22H16C20 22 22 20 22 16V8C22 4 20 2 16 2ZM11.11 16.66C10.86 16.91 10.4 17.14 10.06 17.19L7.98 17.48C7.9 17.49 7.82 17.5 7.75 17.5C7.4 17.5 7.08 17.38 6.85 17.15C6.57 16.87 6.45 16.46 6.52 16.02L6.81 13.94C6.86 13.6 7.09 13.13 7.34 12.89L11.11 9.12C11.17 9.3 11.25 9.48 11.34 9.68C11.43 9.86 11.52 10.04 11.62 10.21C11.7 10.35 11.79 10.49 11.87 10.59C11.97 10.74 12.07 10.87 12.14 10.94C12.18 11 12.22 11.04 12.23 11.06C12.45 11.31 12.68 11.55 12.9 11.73C12.96 11.79 13 11.82 13.01 11.83C13.14 11.93 13.26 12.04 13.38 12.11C13.51 12.21 13.65 12.3 13.79 12.38C13.96 12.48 14.14 12.58 14.33 12.67C14.52 12.76 14.7 12.83 14.88 12.89L11.11 16.66ZM16.55 11.23L15.77 12.01C15.72 12.06 15.65 12.09 15.58 12.09C15.56 12.09 15.52 12.09 15.5 12.08C13.78 11.59 12.41 10.22 11.92 8.5C11.89 8.41 11.92 8.31 11.99 8.24L12.78 7.45C14.07 6.16 15.29 6.19 16.55 7.45C17.19 8.09 17.51 8.71 17.5 9.35C17.5 9.98 17.19 10.59 16.55 11.23Z"
      fill="#292D32"
    />
  </svg>
);

export const EditFieldIcon = (props: any) => <Icon component={svg} {...props} />;
