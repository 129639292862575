import { DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Card, Skeleton, Typography, Image, Tooltip, Modal } from "antd";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { DownloadFileIcon } from "../../../assets/icons/CustomIcons";
import { reasonDict } from "../../TimeTracking/Util/types";
import {getTimeOffStatusColor, getTimeOffStatusText, prepareDates} from "../_core/helpers/time-off-general.helper";
import { useTimeOffRequest } from "./hooks";
import {TimeOffFile} from "../_core/interfaces/time-off-record.interface";

export const TimeOffRequestContext = React.createContext<any>(null);

export const TimeOffRequest = (requestId: string) => {
  const params: any = useParams();
  const { id, type } = params;
  const timeOffHook = useTimeOffRequest(id);
  const { timeOffRequest, requestLoading, getTimeOffDoc } = timeOffHook;

  const handleDelete = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this?`,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      okType: "danger",
      okText: "Delete",

      onOk() {
        timeOffHook.deleteRequest(id);
      },
    });
  };

  const handleApprove = () => {
    timeOffHook.approveRequest(id);
  };

  const handleDecline = () => {
    Modal.confirm({
      title: `Are you sure you want to decline this?`,
      icon: <StopOutlined />,
      okType: "danger",
      okText: "Decline",

      onOk() {
        timeOffHook.declineRequest(id);
      },
    });
  };

  const downloadTimeOffDoc = (document: TimeOffFile) => {
    if (timeOffRequest)
      getTimeOffDoc(timeOffRequest.id, document.id, document.fileName)
  };

  const getButtons = () => (
    <>
      {timeOffRequest ? (
        <div>

          {timeOffRequest.availableOperations.find((role) => role === "Approve") ? (
            <Button onClick={() => handleApprove()} type="primary" style={{ marginLeft: "8px", backgroundColor: "green", borderColor: 'green' }} >
              Approve request
            </Button>
          ) : null}
          {timeOffRequest.availableOperations.find((role) => role === "Decline") ? (
            <Button onClick={() => handleDecline()} type="primary" style={{ marginLeft: "8px", backgroundColor: 'orange', borderColor: 'orange' }} >
              Decline request
            </Button>
          ) : null}
          {timeOffRequest.availableOperations.find((role) => role === "Delete") ? (
            <Button onClick={() => handleDelete()} type="primary" danger style={{ marginLeft: "8px" }} >
              Delete
            </Button>
          ) : null}
        </div>
      ) : null}
    </>
  );

  return (
    <>
      {requestLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <TimeOffRequestContext.Provider value={timeOffHook}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
              <Typography.Title>Manage time off request</Typography.Title>

              {getButtons()}
            </div>

            {timeOffRequest ? (
              <div style={{ display: "flex " }}>
                <Card title="Request information" type="inner" style={{ flex: 1, margin: "4px" }} >
                  <table
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <tr style={{ padding: "16px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        User
                      </td>
                      <td>
                        <Tooltip title="See user" placement="top">
                          <Link to={`/employees/${timeOffRequest.userId}`}>
                            {timeOffRequest.user.userName}
                          </Link>
                        </Tooltip>
                      </td>
                    </tr>
                    <tr style={{ padding: "16px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Email
                      </td>
                      <td>{timeOffRequest.user.email}</td>
                    </tr>
                    <tr style={{ padding: "4px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Time off period
                      </td>
                      <td style={{ fontWeight: 550 }}>
                        {prepareDates(
                          timeOffRequest.startDate,
                          timeOffRequest.endDate
                        )}
                      </td>
                    </tr>
                    <tr style={{ padding: "4px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Working days
                      </td>
                      <td>{timeOffRequest.workingDaysCount}</td>
                    </tr>
                    <tr style={{ padding: "4px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Reason
                      </td>
                      <td>
                        {reasonDict[timeOffRequest.type as keyof typeof reasonDict]}
                      </td>
                    </tr>
                    <tr style={{ padding: "4px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Description
                      </td>
                      <td>
                        {timeOffRequest.description ? timeOffRequest.description : (
                          <span style={{ color: "gray", fontStyle: "italic" }}>
                            No description
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr style={{ padding: "4px" }}>
                      <td style={{ color: "#A8A8A8", paddingRight: "32px" }}>
                        Status
                      </td>
                      <td>
                        <span
                          style={{
                            color: getTimeOffStatusColor(timeOffRequest.approvalStatus),
                          }}
                        >
                          {
                            //@ts-ignore
                            getTimeOffStatusText(timeOffRequest.approvalStatus)
                          }
                        </span>
                      </td>
                    </tr>
                  </table>
                </Card>

                <Card title="Documents" type="inner" style={{ flex: 1, margin: "4px" }} >
                  {timeOffRequest.files.length ? (
                    timeOffRequest.files?.map((file: TimeOffFile) => (
                      <a
                        onClick={()=>downloadTimeOffDoc(file)}
                        style={{ display: "flex", alignItems: "center", marginTop:10 }}
                      >
                        <DownloadFileIcon />
                        <span style={{marginLeft:6}}>{file.fileName}</span>
                      </a>
                    ))
                  ) : (
                    <span style={{ color: "gray", fontStyle: "italic" }}>
                      No files attached
                    </span>
                  )}
                </Card>
              </div>
            ) : null}
          </TimeOffRequestContext.Provider>
        </div>
      )}
    </>
  );
};
