import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.69 14.05C17.65 14.14 17.6 14.22 17.53 14.29L14.58 17.24C14.43 17.39 14.24 17.46 14.05 17.46C13.86 17.46 13.67 17.39 13.52 17.24C13.23 16.95 13.23 16.47 13.52 16.18L15.19 14.51H7C6.59 14.51 6.25 14.17 6.25 13.76C6.25 13.35 6.59 13.01 7 13.01H17C17.1 13.01 17.19 13.03 17.29 13.07C17.47 13.15 17.62 13.29 17.7 13.48C17.77 13.66 17.77 13.86 17.69 14.05ZM17 10.99H7C6.9 10.99 6.81 10.97 6.71 10.93C6.53 10.85 6.38 10.71 6.3 10.52C6.22 10.34 6.22 10.13 6.3 9.95C6.35 9.86 6.4 9.78 6.47 9.71L9.42 6.76C9.71 6.47 10.19 6.47 10.48 6.76C10.77 7.05 10.77 7.53 10.48 7.82L8.81 9.49H17C17.41 9.49 17.75 9.83 17.75 10.24C17.75 10.65 17.41 10.99 17 10.99Z"
      fill="#292D32"
    />
  </svg>
);

export const TransferIcon = (props: any) => <Icon component={svg} {...props} />;
