import styled from "styled-components";

export const LogoImg = styled.img`
  height: 100px;
  width: 100px;
`;

export const LoginForm = styled.div`
  width: 400px;
`;

export const ForgotPassForm = styled.div`
  width: 400px;
`;

export const ResetPassForm = styled.div`
  width: 400px;
`;

export const RegisterForm = styled.div`
  box-shadow: 1px 1px 2px #032745;
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  width: 400px;
`;

export const ForgotPasswordForm = styled.div`
  box-shadow: 1px 1px 2px #032745;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  width: 430px;
`;
