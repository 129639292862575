import { Select } from "antd";
import React, { useContext, useEffect } from "react";
import { ChangeSessionTaskModalContext } from "./types";
import { TechnologyLocalStorageKey } from "../../../Tracker/utils/types";
const { Option } = Select;
const TechnologiesSelect: React.FC = () => {
  const { formData, setIsNewTask, setFormData, technologies, tasks } =
    useContext(ChangeSessionTaskModalContext);
  const setToLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
  };

  const handleTechnologyChange = (value: string) => {
    const matchingTask = tasks?.find((task) => task.taskId === formData.taskId);
    if (matchingTask?.technologyId !== value) {
      setIsNewTask(true);
      setFormData((prevState) => ({
        ...prevState,
        technologyId: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        taskId: matchingTask.taskId,
        technologyId: matchingTask.technologyId,
      }));
      setIsNewTask(false);
    }
    setToLocalStorage(TechnologyLocalStorageKey, value);
  };

  return (
    <Select
      style={{ flex: 1, marginBottom: 10 }}
      value={formData.technologyId}
      onChange={handleTechnologyChange}
      placeholder="Technology"
    >
      {technologies.map((technology) => (
        <Option key={technology.id} value={technology.id}>
          {technology.name}
        </Option>
      ))}
    </Select>
  );
};
export default TechnologiesSelect;
