export interface ReportsFiltersContextInterface {
  projectType: ProjectType | null;
  setProjectType: React.Dispatch<React.SetStateAction<ProjectType | null>>;
  data: NewEmployee[];
  setData: React.Dispatch<React.SetStateAction<NewEmployee[]>>;
  cols: ProjectReport[];
  setCols: React.Dispatch<React.SetStateAction<ProjectReport[]>>;
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCols: string[];
  setSelectedCols: React.Dispatch<React.SetStateAction<string[]>>;
  sortOptions: SortOptions;
  toggleOrder: () => void;
  handleSortChange: (newSortBy: ReportSortingCriteria) => void;
  hideProjectsWithNoHours: boolean;
  setHideProjectsWithNoHours: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFilters: Filters[];
  setSelectedFilters: React.Dispatch<React.SetStateAction<Filters[]>>;
  addFilter: (filterName: string, filterType: "row" | "col") => void;
  removeFilter: (filterName: string, filterType: "row" | "col") => void;
  handleRowSelection: (rowId: string) => void;
  handleColSelection: (colId: string) => void;
  initialRows: FilterInterface[];
  setInitialRows: React.Dispatch<React.SetStateAction<FilterInterface[]>>;
  initialCols: FilterInterface[];
  setInitialCols: React.Dispatch<React.SetStateAction<FilterInterface[]>>;
}
export enum ReportSortingCriteria {
  EmployeeName = 10,
  EmployeeTotalWorkedHours = 20,
  EmployeeHoursOff = 30,
  ProjectTotalWorkedHours = 40,
}
export enum ReportSortingDirection {
  Ascending = 10,
  Descending = 20,
}
export interface FilterInterface {
  id: string;
  filterName: string;
}
export interface SortOptions {
  sortBy: ReportSortingCriteria;
  order: ReportSortingDirection;
}

export interface Employee {
  userId: string;
  fullName: string;
  numberOfHoursContracted: number;
  totalHoursWorked: number;
  timeOffHours: TimeOffHours;
  grandTotal: number;
  totalWorkingHours: number;
  profilePicturePath: string;
}
export interface Filters {
  id: string;
  filterName: string;
  filterType: "row" | "col";
}
export interface TimeOffHours {
  totalHours: number;
  holidayHours: number;
  maternityHours: number;
  otherHours: number;
}

export interface ProjectReport {
  projectId: string;
  projectName: string;
  workedHours: number;
  color: string;
}

export interface NewEmployee {
  profilePicturePath: string;
  userId: string;
  fullName: string;
  numberOfHoursContracted: number;
  totalHoursWorked: number;
  timeOffHours: TimeOffHours;
  grandTotal: number;
  totalWorkingHours: number;
  projectsReport: ProjectReport[];
}
export enum ProjectType {
  Intern = 100,
  Client = 200,
}
export interface TableColumn {
  title: string;
  dataIndex: string;
  key: string;
  fixed?: "left" | "right";
  render?: (data: any, cell: any) => React.ReactNode;
  onHeaderCell?: (column: TableColumn) => any;
  color?: string;
  workedHours?: number;
}

interface TotalHours {
  totalHoursWorked: number;
  timeOffHours: TimeOffHours;
}

export interface TransformedReport {
  [key: string]: ProjectReport | any;
  employee: Employee;
  totalHours: TotalHours;
}
