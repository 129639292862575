import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Image, message, Select, Spin, Tooltip, Upload } from "antd";
import { Card, Col, Input, Row, Typography, Form, Button } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { ProfileContext } from ".";
import { UserContext } from "../../App";
import { functions } from "../../_core/hardcodedValues";
import { CardTitle, FormLabel } from "../../styles/StyledComponents";
import { ChangePasswordDialog } from "./ChangePasswordDialog";
import { Timezone } from "../../_core/types";
import { CommonResourcesContext } from "../Routing/AppRouting";

const colStyle = {
  width: 201,
  textAlign: "left",
};
const rowStyle = {
  marginBottom: 20,
};

export const AccountInfo = () => {
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [photo, setPhoto] = useState<any>();
  const [photoFile, setPhotoFile] = useState<any>();
  const { user } = useContext(UserContext);
  const { timezones } = useContext(CommonResourcesContext);

  useEffect(() => {
    if (user) {
      //setPhotoBase64(`data:image/png;base64, ${user.profilePicture}`);
      setPhoto(user.profilePicturePath);
    }
  }, [user]);

  const { profile, isLoading, updateProfile, changeProfilePicture, isLoadingProfilePicture } =
    useContext(ProfileContext);

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);

  const [form] = Form.useForm();

  useEffect(() => {
    if (profile) {
      form.setFieldsValue(profile);
    }
  }, [profile]);

  const handleUpdate = (values: any) => {
    updateProfile(values);
    setUpdateButtonDisabled(true);
  };

  const handleInputOnChange = (e: any) => {
    if (profile) {
      if (
        //@ts-ignore
        e.target.value === profile[e.target.name]
      ) {
        setUpdateButtonDisabled(true);
      } else {
        setUpdateButtonDisabled(false);
      }
    }
  };

  const getFunction = (val: number | undefined): string | undefined => {
    const _function = functions.find((_function) => _function.value === val);
    return _function?.name;
  };

  const beforeUpload = (file: any) => {
    if (file.type.split("/")[0] === "image") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setPhoto(reader.result);
        setPhotoFile(file);
      };
    } else {
      message.error(`${file.name} is not an image`);
    }

    return false;
  };

  const handleCancelChangePicture = () => {
    //setPhotoBase64(`data:image/png;base64, ${user.profilePicture}`);
    setPhoto(user.profilePicturePath);
    setUpdateButtonDisabled(true);
    setPhotoFile(null);
  };

  const handleSaveProfilePicture = async () => {
    try {
      await changeProfilePicture(photoFile);
      setPhotoFile(null);
      message.success("Photo uploaded");
    } catch (err) {
      message.error("Could not update picture");
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Card type="inner" title={<CardTitle>Account Information</CardTitle>}>
        <Row align="middle">
          <Col>
            <Spin spinning={isLoadingProfilePicture}>
              <div>
                <Avatar
                  src={<Image preview={{ mask: <div/>}} src={photo} />}
                  size={{ xs: 64, sm: 64, md: 64, lg: 64, xl: 80, xxl: 100 }}
                />
                {!photoFile ? (
                  <Upload showUploadList={false} beforeUpload={beforeUpload}>
                    <Tooltip title="Change profile picture" placement="bottomLeft">
                      <EditOutlined
                        style={{
                          fontSize: 17,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          backgroundColor: "#1378e4",
                          borderRadius: "50%",
                          padding: 5,
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Upload>
                ) : (
                  <div>
                    <Tooltip title="Cancel" placement="bottomLeft">
                      <CloseOutlined
                        onClick={handleCancelChangePicture}
                        style={{
                          fontSize: 17,
                          position: "absolute",
                          bottom: 0,
                          right: 15,
                          backgroundColor: "tomato",
                          borderRadius: "50%",
                          padding: 5,
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Save Picture" placement="bottomLeft">
                      <CheckOutlined
                        onClick={handleSaveProfilePicture}
                        style={{
                          fontSize: 17,
                          position: "absolute",
                          bottom: 0,
                          right: -15,
                          backgroundColor: "green",
                          borderRadius: "50%",
                          padding: 5,
                          color: "white",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Spin>
          </Col>
          <Col style={{ textAlign: "left", marginLeft: 15 }}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {profile?.firstName} {profile?.lastName}
            </Typography.Title>
            <Typography.Text type="secondary" style={{ fontSize: "1.2em" }}>
              {getFunction(profile?.function)}
            </Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between" style={{ ...rowStyle, marginTop: 20 }}>
          <Col>
            <FormLabel style={{ fontSize: 14 }}>Email</FormLabel>
          </Col>
          <Col
            //@ts-ignore
            style={colStyle}
          >
            <Typography.Text>{profile?.email}</Typography.Text>
          </Col>
        </Row>

        <Form form={form} onFinish={handleUpdate}>
          <Row justify="space-between" style={rowStyle}>
            <Col>
              <FormLabel>Car Plate</FormLabel>
            </Col>
            <Col>
              <Form.Item name="vehiclePlates" noStyle>
                <Input
                  style={{ borderRadius: 5 }}
                  onChange={handleInputOnChange}
                  name="vehiclePlates"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between" style={rowStyle}>
            <Col>
              <FormLabel>Car description</FormLabel>
            </Col>
            <Col>
              <Form.Item name="vehicleDescription" noStyle>
                <Input
                  style={{ borderRadius: 5 }}
                  onChange={handleInputOnChange}
                  name="vehicleDescription"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <FormLabel>Phone Number</FormLabel>
            </Col>
            <Col>
              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: "Input your phone number" }]}
              >
                <Input
                  style={{ borderRadius: 5 }}
                  onChange={handleInputOnChange}
                  name="phoneNumber"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <FormLabel>Timezone</FormLabel>
            </Col>
            <Col style={{ width: 200 }}>
              <Form.Item
                style={{ flex: 1 }}
                name="timezone"
                rules={[{ required: true, message: "Select your timezone" }]}
              >
                <Select
                  showSearch
                  onChange={(val) =>
                    val !== profile?.timezone
                      ? setUpdateButtonDisabled(false)
                      : setUpdateButtonDisabled(true)
                  }
                  style={{ textAlign: "left" }}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
                  }
                >
                  {timezones.map((timezone: Timezone) => (
                    <Select.Option
                      key={timezone.name}
                      style={{ textAlign: "left" }}
                      value={timezone.name}
                    >
                      {`${timezone.name} `}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify="space-between">
            <Col>
              <FormLabel>Start working hour</FormLabel>
            </Col>
            <Col style={{ width: 200 }}>
              <Form.Item
                style={{ flex: 1 }}
                name="startWorkingHour"
                rules={[{ required: true, message: "Input your phone number" }]}
              >
                <Select
                  style={{ textAlign: "left" }}
                  onChange={(val) =>
                    val !== profile?.startWorkingHour
                      ? setUpdateButtonDisabled(false)
                      : setUpdateButtonDisabled(true)
                  }
                >
                  {[...Array(24)].map((x, i) => (
                    <Select.Option key={i} value={i * 60}>
                      {`${i < 10 ? "0" + i : i}:00`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row justify="space-between" style={rowStyle}>
          <Col>
            <FormLabel style={{ fontSize: 14 }}>Birthdate</FormLabel>
          </Col>
          <Col
            //@ts-ignore
            style={colStyle}
          >
            <Typography.Text>{moment(profile?.birthday).format("Do MMM YYYY")}</Typography.Text>
          </Col>
        </Row>
        <Row justify="space-between" style={rowStyle}>
          <Col>
            <FormLabel style={{ fontSize: 14 }}>Office</FormLabel>
          </Col>
          <Col
            //@ts-ignore
            style={colStyle}
          >
            <Typography.Text>{profile?.office}</Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between" style={rowStyle}>
          <Col>
            <FormLabel style={{ fontSize: 14 }}>Hire Date</FormLabel>
          </Col>
          <Col
            //@ts-ignore
            style={colStyle}
          >
            <Typography.Text>{moment(profile?.hireDate).format("Do MMM YYYY")}</Typography.Text>
          </Col>
        </Row>

        <Row justify="space-between" style={rowStyle}>
          <Col>
            <FormLabel style={{ fontSize: 14 }}>Work Hours</FormLabel>
          </Col>
          <Col
            //@ts-ignore
            style={colStyle}
          >
            <Typography.Text>{`${profile?.workHours} hours/day`}</Typography.Text>
          </Col>
        </Row>

        <Row style={{ marginTop: "40px" }} justify="space-around">
          <Button type="text" size="large" onClick={() => setChangePasswordDialog(true)}>
            Change Password
          </Button>
          <Button
            style={{ borderRadius: 5 }}
            type="primary"
            size="large"
            onClick={form.submit}
            disabled={updateButtonDisabled}
          >
            Update Profile
          </Button>
        </Row>

        <Row justify="center" style={{ margin: 20 }}>
          <Typography.Text style={{ fontSize: "1.2em" }} type="secondary">
            If you want to do any other changes of your account please get in touch with an admin!
          </Typography.Text>
        </Row>
      </Card>
      <ChangePasswordDialog
        isVisible={changePasswordDialog}
        setIsVisible={setChangePasswordDialog}
      />
    </Spin>
  );
};
