import { message } from "antd";
import { useEffect, useState } from "react";
import useApiClient from "../../../_core/hooks/useApiClient";
import {
  Project,
  SessionsWeek,
  Task,
  TimeTrackingSession,
  UseTimeTrackingUserData,
} from "./types";

//this hook is used for handling of user sessions, projects, tasks, etc
export const useTimeTrackingUserData: UseTimeTrackingUserData = () => {
  const [isGettingInitialData, setIsGettingInitialData] =
    useState<boolean>(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [sessionWeeks, setSessionWeeks] = useState<SessionsWeek[]>([]);
  const [weeksToFetch, setWeeksToFetch] = useState<number>(4);
  const [sessionToDelete, setSessionToDelete] = useState<string>("");
  const [sessionToClone, setSessionToClone] = useState<string>("");
  const [sessionToChangeTask, setSessionToChangeTask] =
    useState<TimeTrackingSession | null>(null);

  const { apiClient } = useApiClient();

  const getInitialData = async () => {
    try {
      setIsGettingInitialData(true);
      const getProjects = apiClient.get("/api/projects/user");
      const getSessions = apiClient.get(
        `/api/time-tracking/tasks/history/${weeksToFetch}`
      );

      const [projectsRes, sessionsRes] = await Promise.all([
        getProjects,
        getSessions,
      ]);
      setProjects(projectsRes.data.response.projects);
      setSessionWeeks(sessionsRes.data);

      setIsGettingInitialData(false);
    } catch (err: any) {
      message.error(
        "Could not get user data for tracker. " + err?.response?.data?.message
      );
    }
  };
  const updateProjectTasks = async (projectId: string, newTasks: Task[]) => {
    setProjects(
      projects.map((project) => {
        if (project.projectId === projectId) {
          return {
            ...project,
            tasks: newTasks,
          };
        }
        return project;
      })
    );
  };

  const updateSessionWeeks = async () => {
    try {
      const res = await apiClient.get(
        `/api/time-tracking/tasks/history/${weeksToFetch}`
      );

      setSessionWeeks(res.data as SessionsWeek[]);
    } catch (err) {
      message.error("Could not fetch sessions.");
    }
  };

  const cloneSession = async (session: TimeTrackingSession, taskId: string) => {
    setSessionToClone(session.id);
    await apiClient
      .post(`/api/time-tracking/sessions/create`, {
        ...session,
        sessionType: session.type,
        taskId,
      })
      .then(async () => {
        await updateSessionWeeks();
        message.success("Session cloned successfully.");
      })
      .catch((error: any) => {
        message.error("Could not clone session.");
      })
      .finally(() => {
        setSessionToClone("");
      });
  };
  const deleteSession = async (sessionId: string) => {
    setSessionToDelete(sessionId);
    await apiClient
      .put(`/api/time-tracking/sessions/delete/${sessionId}`)
      .then(async () => {
        await updateSessionWeeks();
        message.success("Session deleted successfully.");
      })
      .catch((error: any) => {
        message.error("Could not delete session.");
      })
      .finally(() => {
        setSessionToDelete("");
      });
  };

  useEffect(() => {
    getInitialData();
  }, [weeksToFetch]);

  return {
    projects,
    sessionWeeks,
    isGettingInitialData,
    weeksToFetch,
    setWeeksToFetch,
    updateProjectTasks,
    updateSessionWeeks,
    cloneSession,
    deleteSession,
    sessionToDelete,
    sessionToClone,
    sessionToChangeTask,
    setSessionToChangeTask,
  };
};
