import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { AllModels } from "./AllModels";
import { ManageModel } from "./ManageModel";

export const Models = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={AllModels} />
      <Route exact path={`${path}/:modelId`} component={ManageModel} />
    </Switch>
  );
};
