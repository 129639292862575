import { message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { Employee } from "../../Employees/types";
import { Report } from "../types";

const useForecastReports = () => {
  const [month, setMonth] = useState<number>(moment().get("M") + 1);
  const [year, setYear] = useState<number>(moment().get("y"));
  const [employeeId, setEmployeeId] = useState<string>("");
  const [report, setReport] = useState<Report>();
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState<Employee[]>([]);

  const { apiClient } = useContext(ApiClientContext);
  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`/api/time-tracking/users`);
      setUsers(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      message.error("Could not get users");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchReport = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(
        `/api/forecast/report?userId=${employeeId}&month=${month}&year=${year}`
      );
      setReport(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      message.error("Could not get report");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (month && year && employeeId) {
      fetchReport();
    }
  }, [month, year, employeeId]);

  return {
    report,
    year,
    month,
    employeeId,
    setYear,
    setMonth,
    setEmployeeId,
    isLoading,
    users,
  };
};

export default useForecastReports;
