import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, message, Select, Typography } from "antd";
import { Form } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import React, { useContext } from "react";
import { useEffect } from "react";
import { ClientContactsContext } from ".";
import { FormLabel } from "../../../styles/StyledComponents";
import { Contact } from "../types";
import { Timezone } from "../../../_core/types";
import { CommonResourcesContext } from "../../Routing/AppRouting";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";

export interface ContactDialogProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
  contact?: Contact;
}
export default function ContactDialog(props: ContactDialogProps) {
  const { isVisible, setIsVisible, contact } = props;
  const [form] = useForm();

  const { isLoading, addContact, editContact } = useContext(
    ClientContactsContext
  );

  const { timezones } = useContext(CommonResourcesContext);

  useEffect(() => {
    if (contact) {
      form.setFieldsValue({ name: contact.name });
      form.setFieldsValue({ email: contact.email });
      form.setFieldsValue({ phoneNumber: contact.phoneNumber });
      form.setFieldsValue({ timezone: contact.timeZoneName });
      form.setFieldsValue({ position: contact.position });
      form.setFieldsValue({ socialMedia: contact.socialMedia });
    }
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    try {
      let apiMsg;
      if (contact) {
        apiMsg = await editContact(values, contact.id);
      } else {
        apiMsg = await addContact(values);
      }

      handleCancel();
      message.success(apiMsg);
    } catch (err) {
      //@ts-ignore
      message.error(err);
    }
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      closeIcon={<RoundCloseIcon />}
    >
      <Typography.Title level={3}>
        {contact ? "Update Contact" : "Add a new contact"}
      </Typography.Title>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          label={<FormLabel>Name</FormLabel>}
          name="name"
          rules={[
            { required: true, message: "Please insert the contact's name" },
            { max: 50, message: "Maximum 50 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormLabel>Email</FormLabel>}
          name="email"
          rules={[
            { required: true, message: "Please insert the contact's email" },
            { type: "email", message: "Please insert a valid email" },
            { max: 50, message: "Maximum 50 characters" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormLabel>Phone</FormLabel>}
          name="phoneNumber"
          rules={[{ max: 50, message: "Maximum 50 characters" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Function"
          name="position"
          rules={[{ max: 50, message: "Maximum 50 characters" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormLabel>Timezone</FormLabel>}
          name="timezone"
          rules={[
            { required: true, message: "Please insert the contact's timezone" },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
            }
          >
            {timezones.map((timezone: Timezone, index) => (
              <Select.Option key={index} value={timezone.name}>
                <div style={{ float: "left" }}>{timezone.name}</div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={<FormLabel>Social Media</FormLabel>}
          name="socialMedia"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            loading={isLoading}
            type="primary"
            block
            icon={contact ? <SaveOutlined /> : <PlusOutlined />}
          >
            {contact ? "Save" : "Add"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
