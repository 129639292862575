import {useContext, useEffect, useState} from "react";
import {message} from "antd";
import {ApiClientContext} from "../../../../App";
import {TimeOffPayloadHelper} from "../helpers/payload.helper";
import moment, {Moment} from "moment";
import {Pagination} from "../interfaces/payload.interface";
import {UrlQueryParamsHelper} from "../helpers/url-query-params.helper";
import queryString from "qs";
import {TimeOffRecord} from "../interfaces/time-off-record.interface";
import {KeyValue} from "../../../../_core/types";
import {TIME_OFF_PAGE_SIZE} from "../constants";
import {TimeOffHistoryLog} from "../interfaces/history-log.interface";
import {UserTimeOffProfileMetadata} from "../types";
import {useLocation} from "react-router-dom";
import {PublicHoliday} from "../interfaces/public-holiday.interface";
import {TimeOffView} from "../enums/time-off-view.enum";

export const useTimeOffListingDatasource = () => {
    const location = useLocation();
    const queryParams: any = queryString.parse(location.search.slice(1));

    const {apiClient} = useContext(ApiClientContext);

    const [historyLogs, setHistoryLogs] = useState<TimeOffHistoryLog[]>([]);
    const [userTimeOffProfileMetadata, setUserTimeOffProfileMetadata] = useState<UserTimeOffProfileMetadata | null>(null);
    const [timeOffRequests, setTimeOffRequests] = useState<Array<TimeOffRecord>>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState<Pagination>({pageSize: TIME_OFF_PAGE_SIZE, pageNumber: 1, totalCount: 0})

    const [projectsSeed, setProjectsSeed] = useState<Array<KeyValue> | []>([]);
    const [usersSeed, setUsersSeed] = useState<Array<KeyValue> | []>([]);
    const [publicHolidaysSeed, setPublicHolidaysSeed] = useState<PublicHoliday[]>([]);

    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<number|undefined>(undefined);
    const [selectedDateRange, setSelectedDateRange] = useState<Moment[]>([]);
    const [showHistory, setShowHistory] = useState<boolean>(true);

    const [currentCalendarMonth, setCurrentCalendarMonth] = useState(moment().format('MM/DD/YYYY'));

    const getTeamTimeOffMetadata = async () => {
        try {
            const response = await apiClient.post("/api/time-off/get-team-time-off-metadata");
            setProjectsSeed(response.data.projects);
            setUsersSeed(response.data.users);
        } catch (error: any) {
            message.error("Could not fetch projects and users.");
        }
    }

    const getTeamTimeOffData = async (pageNumber: number = 1, selectedView: TimeOffView, calendarInterval?: any) => {
        setIsLoading(true);
        const payload = TimeOffPayloadHelper.computeTeamTimeOffPayload(
            selectedUsers,
            selectedProjects,
            selectedStatus,
            selectedDateRange,
            {...pagination, pageNumber},
            selectedView,
            calendarInterval
        );
        try {
            const response = await apiClient.post('/api/time-off/get-team-time-off', payload);
            setTimeOffRequests(response.data.results);
            if (selectedView === TimeOffView.Table) {
                setPagination({...pagination, pageNumber, totalCount: response.data.metadata.paginationResultsCount});
            } else {
                setCurrentCalendarMonth(calendarInterval ? moment(calendarInterval[0]).format('MM/DD/YYYY') : moment(currentCalendarMonth).format('MM/DD/YYYY'));
            }
            setIsLoading(false);
        } catch (error: any) {
            message.error(error?.data?.message || 'Sorry, there was an error.');
            setIsLoading(false);
            setTimeOffRequests([]);
            setPagination({...pagination, pageNumber: 1, totalCount: 0});
        }

    }

    const getUserTimeOffProfileData = async (pageNumber: number = 1, userId = '', selectedView: TimeOffView, calendarInterval?: any) => {
        setIsLoading(true);
        const payload = TimeOffPayloadHelper.computeUserTimeOffProfilePayload(
            selectedStatus,
            selectedDateRange,
            {...pagination, pageNumber},
            selectedView,
            calendarInterval
        );

        try {
            const response = await apiClient.post("/api/time-off/get-time-off-dashboard" + (userId ? '?userId=' + userId : ''), payload);
            setTimeOffRequests(response.data.results);
            if (selectedView === TimeOffView.Table) {
                setPagination({...pagination, pageNumber, totalCount: response.data.metadata.paginationResultsCount});
            } else {
                setCurrentCalendarMonth(calendarInterval ? moment(calendarInterval[0]).format('MM/DD/YYYY') : moment(currentCalendarMonth).format('MM/DD/YYYY'));
            }
            setIsLoading(false);
        }  catch (error: any) {
            message.error(error?.data?.message || 'Sorry, there was an error.');
            setIsLoading(false);
            setTimeOffRequests([]);
            setHistoryLogs([]);
            setUserTimeOffProfileMetadata(null);
            setPagination({...pagination, pageNumber: 1, totalCount: 0});
        }
    }

    const getUserProfileMetadata = async (userId = '') => {
        try {
            const response = await apiClient.post("/api/time-off/get-time-off-dashboard-metadata" + (userId ? '?userId=' + userId : ''));
            setUserTimeOffProfileMetadata(response.data.metadataHeader);
            setHistoryLogs(response.data.historyLogs);
        } catch (error: any) {
            message.error("Could not fetch projects and users.");
        }
    }

    const initializeFilters = () => {
        const filters = UrlQueryParamsHelper.initializeFilters(queryParams);
        setSelectedProjects(filters.selectedProjects);
        setSelectedUsers(filters.selectedUsers);
        setSelectedStatus(filters.selectedStatus);
        setSelectedDateRange(filters.dateRange);
        setShowHistory(filters.showHistory);
    }

    const getPublicHolidays = async () => {
        try {
            const response = await apiClient.get('/api/time-off-public-holidays');
            setPublicHolidaysSeed(response.data);
        } catch (error: any) {
            console.error(error)
        }
    }

    useEffect(() => {
        getPublicHolidays();
    }, [])

    return {
        timeOffRequests,
        isLoading,
        pagination,
        userTimeOffProfileMetadata,
        historyLogs,
        projectsSeed,
        usersSeed,
        selectedProjects,
        selectedUsers,
        selectedStatus,
        selectedDateRange,
        showHistory,
        publicHolidaysSeed,
        currentCalendarMonth,

        setProjectsSeed,
        setUsersSeed,
        setSelectedProjects,
        setSelectedUsers,
        setSelectedStatus,
        setSelectedDateRange,
        setShowHistory,

        getTeamTimeOffMetadata,
        getTeamTimeOffData,
        getUserTimeOffProfileData,
        getUserProfileMetadata,
        setPagination,
        initializeFilters,
    }
}
