import {TimeOffRequestType} from "../features/TimeOff/_core/enums/time-off-request-type.enum";
import {CountryTypes} from "./enums";

export const functions = [
  {
    name: "Administrator",
    value: 10,
  },
  {
    name: "Human Resources",
    value: 20,
  },
  {
    name: "Junior Developer",
    value: 30,
  },
  {
    name: "Developer",
    value: 40,
  },
  {
    name: "Manager",
    value: 50,
  },
  {
    name: "Director of Engineering",
    value: 60,
  },
  {
    name: "ChiefExecutiveOfficer",
    value: 100,
  },
];
export const legalPositions = [
  {
    name: "Angajat",
    value: 0,
  },
  {
    name: "Programator",
    value: 10,
  },
  {
    name: "Programator ajutor",
    value: 20,
  },
  {
    name: "Analist",
    value: 30,
  },
  {
    name: "Analist ajutor",
    value: 40,
  },
  {
    name: "Resurse umane",
    value: 50,
  },
  {
    name: "Manager de proiect informatic",
    value: 60,
  },
];
export const positions = [
  {
    name: "Unknown",
    value: 0,
  },
  {
    name: "Intern",
    value: 5,
  },
  {
    name: "Junior",
    value: 10,
  },
  {
    name: "Mid",
    value: 20,
  },
  {
    name: "Senior",
    value: 30,
  },
  {
    name: "Architect",
    value: 40,
  },
];

export const projectDocumentType = [
  {
    name: "Unknown",
    value: 0,
  },
  {
    name: "Technical Document",
    value: 10,
  },
  {
    name: "Roadmap",
    value: 20,
  },
  {
    name: "Database ",
    value: 30,
  },
  {
    name: "Design",
    value: 40,
  },
  {
    name: "Other",
    value: 50,
  },
];

export const itemRequestStatus = [
  {
    value: 0,
    name: "Unknown",
  },
  {
    value: 10,
    name: "Pending",
  },
  {
    value: 20,
    name: "Accepted",
  },
  {
    value: 30,
    name: "Declined",
  },
];

export const itemTypes = [
  {
    value: 0,
    name: "Unknown ",
  },
  {
    value: 100,
    name: "Laptop",
  },
  {
    value: 101,
    name: "Smart Phone ",
  },
  {
    value: 102,
    name: "Tablet",
  },
  {
    value: 300,
    name: "Monitor",
  },
  {
    value: 301,
    name: "Mouse",
  },
  {
    value: 302,
    name: "Keyboard",
  },
  {
    value: 303,
    name: "Printer",
  },
  {
    value: 304,
    name: "Xerox",
  },
  {
    value: 305,
    name: "TV",
  },
];

export const timeOffRequestType = [
  {
    name: "Other",
    value: TimeOffRequestType.Other,
  },
  {
    name: "Maternal",
    value: TimeOffRequestType.Maternal,
  },
  {
    name: "Holiday",
    value: TimeOffRequestType.Holiday,
  },
  {
    name: "Medical",
    value: TimeOffRequestType.Medical,
  },
  {
    name: "Study",
    value: TimeOffRequestType.Study,
  },
];

export const interestTechnologyReasons = [
  {
    name: "Unknown/Other Reason",
    value: 0,
  },
  {
    name: "I want to learn this technology",
    value: 10,
  },
  {
    name: "I already have knowledge about this technology",
    value: 20,
  },
];

export const userStatuses = [
  {
    name: "Active",
    value: 20,
  },
  {
    name: "Invited",
    value: 10,
  },
  {
    name: "Deleted",
    value: 30,
  },
  {
    name: "All",
    value: 0,
  },
];

export enum userStatusesEnum {
  All = 0,
  Invited = 10,
  Active = 20,
  Deleted = 30,
}

export const countryTypesOptions = [{text: 'Romania', value: CountryTypes.Romania}, {text: 'Georgia', value: CountryTypes.Georgia}];

const getVal = (arr: Array<{ name: string; value: number }>, value: number): string => {
  const obj = arr.find((x) => x.value === value);

  return obj !== undefined ? obj.name : "";
};

export const getUserPosition = (value?: number): string => {
  if (value) {
    return getVal(positions, value);
  }
  return "";
};

export const getProjectDocType = (value: number): string => {
  return getVal(projectDocumentType, value);
};

export const getItemRequestStatus = (value: number): string => {
  return getVal(itemRequestStatus, value);
};

export const getItemType = (value: number): string => {
  return getVal(itemTypes, value);
};

export const getInterestTechnologyReason = (value: number): string => {
  return getVal(interestTechnologyReasons, value);
};

export const getTimeOffRequestType = (value: number): string => {
  return getVal(timeOffRequestType, value);
};

export const getUserStatus = (value: number): string => {
  return getVal(userStatuses, value);
};
