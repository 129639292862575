import {Pagination, TeamTimeOffPayload, UserTimeOffProfilePayload} from "../interfaces/payload.interface";
import moment, {Moment} from "moment";
import {TimeOffView} from "../enums/time-off-view.enum";

export class TimeOffPayloadHelper {
    static computeTeamTimeOffPayload(
        userIds: string[],
        projectIds: string[],
        approvalStatus: number | undefined,
        dateRange: Moment[],
        pagination: Pagination,
        selectedView: TimeOffView,
        calendarInterval: any
    ): TeamTimeOffPayload {

        let startDate: string | null = null;
        if(selectedView === TimeOffView.Table) {
            startDate = dateRange?.length === 2 ? moment(dateRange[0]).format('MM/DD/YYYY') : null;
        } else {
            startDate = calendarInterval ? moment(calendarInterval[0]).format('MM/DD/YYYY') : moment().startOf('month').startOf('isoWeek').format('MM/DD/YYYY');
        }
        let endDate: string | null = null;
        if(selectedView === TimeOffView.Table) {
            endDate = dateRange?.length === 2 ? moment(dateRange[1]).format('MM/DD/YYYY') : null;
        } else {
            endDate = calendarInterval ? moment(calendarInterval[1]).format('MM/DD/YYYY') : moment().endOf('month').endOf('isoWeek').format('MM/DD/YYYY');
        }

        return {
            userIds, projectIds, approvalStatus,
            startDate, endDate,
            pagination: selectedView === TimeOffView.Calendar ? null : pagination
        }
    }

    static computeUserTimeOffProfilePayload(
        approvalStatus: number | undefined,
        dateRange: Moment[],
        pagination: Pagination,
        selectedView: TimeOffView,
        calendarInterval: any
    ): UserTimeOffProfilePayload {

        let startDate: string | null = null;
        if(selectedView === TimeOffView.Table) {
            startDate = dateRange?.length === 2 ? moment(dateRange[0]).format('MM/DD/YYYY') : null;
        } else {
            startDate = calendarInterval ? moment(calendarInterval[0]).format('MM/DD/YYYY') : moment().startOf('month').startOf('isoWeek').format('MM/DD/YYYY');
        }
        let endDate: string | null = null;
        if(selectedView === TimeOffView.Table) {
            endDate = dateRange?.length === 2 ? moment(dateRange[1]).format('MM/DD/YYYY') : null;
        } else {
            endDate = calendarInterval ? moment(calendarInterval[1]).format('MM/DD/YYYY') : moment().endOf('month').endOf('isoWeek').format('MM/DD/YYYY');
        }

        return {
            approvalStatus,
            startDate,
            endDate,
            pagination: selectedView === TimeOffView.Calendar ? null : pagination
        }
    }
}
