import { Col, Image, Row, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ForecastTableContext } from ".";
import { Employee } from "../types";

const EmployeeCellContainer = styled.div<{ selected: boolean }>`
  opacity: ${(props: any) => (!props.selected ? "0.2!important" : "")};
  cursor: pointer;
`;
export const EmployeeCell = (props: { employee: Employee }) => {
  const { employee } = props;
  const { idSelectedEmployee, setIdSelectedEmployee } = useContext(ForecastTableContext);
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (employee.id === idSelectedEmployee) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [idSelectedEmployee]);
  return (
    <EmployeeCellContainer
      selected={idSelectedEmployee === "" ? true : isSelected}
      onClick={() => {
        isSelected ? setIdSelectedEmployee("") : setIdSelectedEmployee(employee.id);
      }}
    >
      <Row align="middle" wrap={false}>
        <Col>
          <Avatar
            size={isSelected ? "large" : "default"}
            style={{ border: isSelected ? "3px solid #137AE9" : "none" }}
            src={<Image preview={{ mask: <div/>}} src={employee.profilePicture} />}
          />
        </Col>
        <Col
          style={{
            marginLeft: 20,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <Typography.Text
            style={{
              width: 190,
              color: isSelected ? "#137AE9" : "#0F172A",
              lineHeight: "17px",
              fontSize: 14,
              fontWeight: "bold",
            }}
            ellipsis={{ tooltip: `${employee.firstName} ${employee.lastName}` }}
          >
            {`${employee.firstName} ${employee.lastName}`}
          </Typography.Text>
          <div
            style={{
              color: "#64748B",
              lineHeight: "18px",
              fontSize: 12,
              fontWeight: "lighter",
            }}
          >
            {`${employee.totalWorkedTime}/${employee.totalForecastTime}`}
          </div>
        </Col>
      </Row>
    </EmployeeCellContainer>
  );
};
