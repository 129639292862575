import {
  // DeleteOutlined,
  EditOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import React, { useContext } from "react";
import { OfficesContext } from "./index";
import { Button, Space, Table, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import { useUserRoles } from "../../_core/hooks/useUserRoles";
import {CountryTypes, getCountryTypeValue} from "../../_core/enums";
const { Text } = Typography;

export const OfficesTable = () => {
  const {
    offices,
    // setDeleteOfficeModal,
    setEditOfficeModal,
    setOfficeId,
    searchValue,
    setCurrentOffice,
  } = useContext(OfficesContext);
  const { isAdmin } = useUserRoles();

  const prepareEditModal = (record: any) => {
    setOfficeId(record.id);
    setCurrentOffice({
      city: record.city,
      address: record.address,
      countryTypes: record.countryTypes,
      managerId: record.managerId,
      timezone: record.timezone,
      startWorkingHour: record.startWorkingHour,
      latitude: record.latitude,
      longitude: record.longitude,
    });
    setEditOfficeModal(true);
  };

  let filteredOffices = offices?.filter((el: any) => {
    if (searchValue !== "") {
      if (el.name.toLowerCase().includes(searchValue.toLowerCase())) return true;
      return false;
    }
    return true;
  });

  const columns = [
    {
      title: () => {
        return <b>City</b>;
      },
      dataIndex: "city",
    },
    {
      title: () => {
        return <b>Country</b>;
      },
      render: (record: any) => {
        return (
            <Text>
              {getCountryTypeValue(record.countryTypes)}
            </Text>
        );
      },
    },
    {
      title: () => {
        return <b>Manager</b>;
      },
      render: (record: any) => {
        return (
          <Space direction="vertical">
            <Text>
              {record.managerFirstName} {record.managerLastName}
            </Text>
            <Text type="secondary" style={{ fontSize: "12px" }}>
              {record.managerEmail ?? "email not available"}
            </Text>
          </Space>
        );
      },
    },
    {
      title: () => {
        return <b>Address</b>;
      },
      dataIndex: "address",
    },
    {
      title: () => {
        return <b>Employees</b>;
      },
      dataIndex: "employees",
    },
    {
      render: (record: any) => (
        <Space size="small">
          <Tooltip title="View office">
            <Link to={`/office/${record.id}`}>
              {/* <SelectOutlined
                id="button_details"
                style={{ color: "#0F59B3", marginRight: 8 }}
              /> */}
              <Button shape="circle" icon={<SelectOutlined />} size="small" type="primary" />
            </Link>
          </Tooltip>

          {isAdmin() && (
            <Tooltip title="Edit office">
              <Button
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                style={{ backgroundColor: "#292D32", color: "white" }}
                onClick={() => prepareEditModal(record)}
              />
            </Tooltip>
          )}

          {/* {isAdmin(user) && (
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={() => {
                setOfficeId(record.id);
                setDeleteOfficeModal(true);
              }}
            />
          )} */}
        </Space>
      ),
    },
  ];

  return (
    <Table
      pagination={false}
      dataSource={filteredOffices?.map((val: any, key: number) => ({
        ...val,
        key,
      }))}
      columns={columns}
      style={{ marginTop: 32 }}
    />
  );
};
