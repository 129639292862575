import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../../../../App";
import { TimeTrackingUserDataContext } from "../../../../TimeTrackingUserDataProvider";
import { Task, Technology, TimeTrackingSession } from "../../../../types";
import { TrackerForm, defaultTrackerForm } from "../../../Tracker/utils/types";
import { IChangeSessionTaskModalProps } from "./types";

export const useChangeTaskModal = (
  session: TimeTrackingSession | null,
  onUpdate: () => void
): IChangeSessionTaskModalProps => {
  const { apiClient } = useContext(ApiClientContext);
  const [formData, setFormData] = useState<TrackerForm>(defaultTrackerForm);
  const [isNewTask, setIsNewTask] = useState<boolean>(false);
  const { projects, updateSessionWeeks } = useContext(
    TimeTrackingUserDataContext
  );
  const [technologies, setTechnologies] = useState<Technology[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskInputValue, setTaskInputValue] = useState<string>(
    tasks?.find((task: Task) => task.taskId === formData.taskId)?.title || ""
  );
  const [isGettingData, setIsGettingData] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const validateSessionCreation = () => {
    if (!formData.projectId) {
      return "Project is required.";
    }
    if (!formData.taskId) {
      if (!taskInputValue) {
        return "Task name is required.";
      }
      if (!formData.technologyId) {
        return "Technology is required";
      }
    }
    return null;
  };
  const initializeFormData = async () => {
    if (session) {
      setIsGettingData(true);
      await apiClient
        .get(`/api/sessions/${session.id}`)
        .then((res) => {
          setFormData({
            ...formData,
            taskId: res.data.taskId,
            technologyId: res.data.technologyId,
            projectId: res.data.projectId,
            description: res.data.description,
          });
        })
        .catch((err) => {
          console.log(err);
        });
      setIsGettingData(false);
    }
  };
  const onSubmit = async () => {
    if (session) {
      const validationError = validateSessionCreation();
      if (validationError) {
        message.error(validationError);
        return;
      }
      const payload = formData.taskId
        ? {
            taskId: formData.taskId,
          }
        : {
            task: {
              projectId: formData.projectId,
              title: taskInputValue,
              description: formData.description,
              technologyId: formData.technologyId,
            },
          };
      setIsUpdating(true);
      try {
        await apiClient
          .put(`/api/time-tracking/sessions/update-task/${session.id}`, payload)
          .then(async (res) => {
            await updateSessionWeeks();
            message.success("Session task changed.");
          })
          .catch((err) => {
            message.error("Could not change session task. " + err);
          });
        onUpdate();
      } catch (error) {
        message.error("Could not record session. " + error);
      }
      setIsUpdating(false);
    }
  };
  useEffect(() => {
    initializeFormData();
  }, [session]);
  useEffect(() => {
    setTechnologies(
      projects.find((project) => project.projectId === formData.projectId)
        ?.technologies || []
    );
    setTasks(
      projects.find((project) => project.projectId === formData.projectId)
        ?.tasks || []
    );
  }, [formData.projectId]);

  return {
    formData,
    technologies,
    tasks,
    setIsNewTask,
    setFormData,
    onSubmit,
    isNewTask,
    taskInputValue,
    setTaskInputValue,
    isUpdating,
    isGettingData,
  };
};
