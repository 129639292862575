import { DeleteOutlined, SelectOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip, Typography, Modal } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { ProjectContext } from ".";
import { getUserPosition } from "../../../_core/hardcodedValues";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { EditFieldIcon } from "../../../assets/icons/EditFieldIcon";
import { OpenFieldIcon } from "../../../assets/icons/OpenFieldIcon";
import { TableHeader } from "../../../styles/StyledComponents";
import { Employee, Technology } from "../types";
import { EditMemberTechnologies } from "./EditMemberTechnologies";

export const TeamTable = () => {
  const { project, isLoading, removeMember, projectId } = useContext(ProjectContext);
  const history = useHistory();
  const [editDialog, setEditDialog] = useState(false);
  const [memberToEdit, setMemberToEdit] = useState<Employee>();
  const handleRemoveMember = (employee: Employee) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${employee.firstName} ${employee.lastName} from the team?`,
      icon: <DeleteOutlined />,
      okText: "Remove",
      onOk() {
        return removeMember(employee.userId);
      },
    });
  };

  const { isAdmin, isTeamLead } = useUserRoles();
  return (
    <div>
      <Table dataSource={project?.members} rowKey="userId" loading={isLoading}>
        <Table.Column<Employee>
          title={<TableHeader>Employee</TableHeader>}
          render={(val, row) => (
            <Col>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {`${row.firstName} ${row.lastName}`}
              </Typography.Title>
              <Typography.Text type="secondary">{row.email}</Typography.Text>
            </Col>
          )}
        />

        <Table.Column<Employee>
          title={<TableHeader>Technology</TableHeader>}
          render={(val, row) =>
            row.technologies.map((technology, index) => (
              <div key={technology.technologyId}>
                {technology.technologyName}
                {row.technologies[index + 1] ? ", " : ""}
              </div>
            ))
          }
        />

        <Table.Column<Employee>
          title={<TableHeader>Level</TableHeader>}
          render={(val, row) =>
            row.technologies.map((technology, index) => (
              <div key={technology.technologyId}>
                {getUserPosition(technology.userPosition)}
                {row.technologies[index + 1] ? ", " : ""}
              </div>
            ))
          }
        />
        {(isAdmin() || isTeamLead(projectId)) && (
          <Table.Column<Employee>
            title={
              <TableHeader>
                <Col>
                  <div>Hours</div>
                  <Typography.Text type="secondary" style={{ fontSize: "0.9em" }}>
                    {moment().subtract(1, "M").format("MMMM")}
                  </Typography.Text>
                </Col>
              </TableHeader>
            }
            render={(val, row) => (
              <Col>
                <div>
                  <span style={{ fontWeight: "bold" }}>W: </span>
                  {row.lastMonthWorked}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>F: </span>
                  {row.lastMonthForecast}
                </div>
              </Col>
            )}
          />
        )}
        {(isAdmin() || isTeamLead(projectId)) && (
          <Table.Column<Employee>
            title={
              <TableHeader>
                <Col>
                  <div>Hours</div>
                  <Typography.Text type="secondary" style={{ fontSize: "0.9em" }}>
                    {moment().format("MMMM")}
                  </Typography.Text>
                </Col>
              </TableHeader>
            }
            render={(val, row) => (
              <Col>
                <div>
                  <span style={{ fontWeight: "bold" }}>W: </span>
                  {row.currentMonthWorked}
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>F: </span>
                  {row.currentMonthForecast}
                </div>
              </Col>
            )}
          />
        )}

        <Table.Column<Employee>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => (
            <>
              <Tooltip title="View details">
                <OpenFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/employees/${row.userId}`)}
                />
              </Tooltip>
              {(isAdmin() || isTeamLead(projectId)) && (
                <>
                  <Tooltip title="Edit Technologies">
                    <EditFieldIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setMemberToEdit(row);
                        setEditDialog(true);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title="Delete">
                    <DeleteFieldIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemoveMember(row)}
                    />
                  </Tooltip>
                </>
              )}
            </>
          )}
        />
      </Table>
      <EditMemberTechnologies
        isVisible={editDialog}
        setIsVisible={setEditDialog}
        member={memberToEdit}
      />
    </div>
  );
};
