import {Col, Input, Row} from "antd";
import React, { useContext } from "react";
import { ItemsRequestsContext } from ".";
import {SearchIcon} from "../../../assets/icons/SearchIcon";

export const FilterBar = () => {
  const {searchInputValue,setSearchInputValue } = useContext(ItemsRequestsContext);

  const handleSearchInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(ev.target.value);
  };

  return (
    <Row style={{marginBottom: 20}}>
      <Col span="24">
        <Input
          placeholder="Search here"
          prefix={<SearchIcon />}
          style={{borderRadius: 5}}
          value={searchInputValue}
          onChange={handleSearchInputChange}
        />
      </Col>
    </Row>
  );
};
