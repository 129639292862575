import { Checkbox, Spin } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useReportsFilters } from "../ReportsFiltersContext";
import { useReportsData } from "../Table/ReportsDataContext";
import { FilterInterface } from "../types";
import FilterSearch from "./FilterSearch";

const CheckboxWrapper = styled.div`
  padding-left: 16px;
`;

const ColumnFilter: React.FC = () => {
  const { selectedCols, setSelectedCols, handleColSelection, initialCols } =
    useReportsFilters();

  const [loading, setLoading] = useState<boolean>(true);
  const [filteredCols, setFilteredCols] =
    useState<FilterInterface[]>(initialCols);

  const handleCheckboxChange = (colId: string) => {
    handleColSelection(colId);
    if (selectedCols.includes(colId)) {
      setSelectedCols(selectedCols.filter((id) => id !== colId));
    } else {
      setSelectedCols([...selectedCols, colId]);
    }
  };

  const handleSearch = (value: string) => {
    setFilteredCols(
      initialCols.filter((col) =>
        col.filterName.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (initialCols.length > 0) {
      setFilteredCols(initialCols);
      setLoading(false);
    }
  }, [initialCols]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {loading ? (
        <Spin />
      ) : (
        <>
          <FilterSearch onSearch={handleSearch} />
          {filteredCols.map((col) => (
            <CheckboxWrapper key={col.id}>
              <Checkbox
                checked={selectedCols.includes(col.id)}
                onChange={() => handleCheckboxChange(col.id)}
              >
                {col.filterName}
              </Checkbox>
            </CheckboxWrapper>
          ))}
        </>
      )}
    </div>
  );
};

export default ColumnFilter;
