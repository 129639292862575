import {useContext, useEffect, useState} from "react";
import {TimeOffRequestType} from "../enums/time-off-request-type.enum";
import moment from "moment";
import {message} from "antd";
import {saveFile} from "../helpers/time-off-general.helper";
import {ApiClientContext, UserContext} from "../../../../App";
import {PublicHoliday} from "../interfaces/public-holiday.interface";
import {CountryTypes} from "../../../../_core/enums";

export const useRequestTimeOff = (publicHolidays: PublicHoliday[], countryType: CountryTypes) => {
    const {apiClient} = useContext(ApiClientContext);
    const { user } = useContext(UserContext);

    const [selectedType, setSelectedType] = useState<TimeOffRequestType>(TimeOffRequestType.Holiday);
    const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
    const [workingDaysCount, setWorkingDaysCount] = useState(1);
    const [description, setDescription] = useState('');
    const [documents, setDocuments] = useState<File[]>([]);

    const [displayExtraSteps, setDisplayExtraSteps] = useState(false);
    const [displayConfirmPage, setDisplayConfirmPage] = useState(false);

    const [daysWithPublicHolidays, setDaysWithPublicHolidays] = useState<PublicHoliday[]>([]);

    const generateDocument = async () => {
        if(selectedDateRange?.length !== 2) {
            return;
        }
        try {
            const response = await apiClient.post('/api/time-off-docs/print-pdf-file', {
                createdAt: moment().format(),
                startDate: moment(selectedDateRange[0]).format('MM/DD/YYYY'),
                endDate: moment(selectedDateRange[1]).format('MM/DD/YYYY'),
                workingDaysCount,
                timeOffType: selectedType
            },{responseType: 'blob'} )

            const fileName = 
                user.firstName + '_' +
                user.lastName + '-' +
                moment(selectedDateRange[0]).format('MM/DD/YYYY') + '-' +
                moment(selectedDateRange[1]).format('MM/DD/YYYY') + '.pdf';

            saveFile(fileName, response.data);
        }
        catch (error: any) {
            console.log(error)
            message.error(error?.data?.message || 'Sorry, there was an error.');
        }
    }

    const handleDrop = (acceptedFiles: File[]) => {
        setDocuments([...documents, ...acceptedFiles]);
    };

    const removeDocument = (index: number) => {
        const docs = documents;
        docs.splice(index,1);
        setDocuments(docs.slice());
    }

    const computeTypeAltText = () => {
        switch (selectedType) {
            case TimeOffRequestType.Holiday:
                return 'have fun!'
            case TimeOffRequestType.Medical:
                return 'sorry :('
            case TimeOffRequestType.Study:
                return 'at least you\'ll get that diploma'
            case TimeOffRequestType.Maternal:
                return 'congrats!'
            case TimeOffRequestType.Other:
                return 'hmm, what could that be?'
        }
    }

    const onDateRangeSelected = (value: any) => {
        if(value?.length !== 2) {
            setWorkingDaysCount(0);
            return;
        }

        let start = moment(value[0].format('MM/DD/YYYY'));
        const end = moment(value[1].format('MM/DD/YYYY'))

        const holidaysInRange: PublicHoliday[] = [];
        const availablePublicHolidays = publicHolidays.filter(holiday => holiday.countries.findIndex(c => c === countryType) > -1);

        let result = 0;
        do {
            if(start.weekday() !== 6 && start.weekday() !== 0) {
                const ph = availablePublicHolidays.find(el => moment(el.date).format('MM/DD/YYYY') === start.format('MM/DD/YYYY'));
                if(!ph) {
                    result += 1;
                } else {
                    holidaysInRange.push(ph)
                }
            }
            start.add(1, 'days');
        } while(start.isBefore(end, 'date') || start.isSame(end, 'date'))

        setWorkingDaysCount(result);
        setDaysWithPublicHolidays(holidaysInRange);
    }

    useEffect(() => {
        if(selectedDateRange?.length > 0) {
            setDisplayExtraSteps(true);
        }
    }, [selectedDateRange]);

    return {
        selectedType,
        selectedDateRange,
        workingDaysCount,
        description,
        documents,
        displayConfirmPage,
        displayExtraSteps,
        daysWithPublicHolidays,

        setSelectedType,
        setSelectedDateRange,
        setWorkingDaysCount,
        setDescription,
        setDocuments,
        setDisplayConfirmPage,
        computeTypeAltText,
        onDateRangeSelected,
        generateDocument,
        handleDrop,
        removeDocument,
    }
}
