import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, message, Form, Typography, Select } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { ClientsContext } from ".";
import { FormLabel } from "../../../styles/StyledComponents";
import { Client } from "../types";
import { Timezone } from "../../../_core/types";
import { CommonResourcesContext } from "../../Routing/AppRouting";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";

type ClientDialogProps = {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
  client?: Client;
};

export default function ClientDialog(props: ClientDialogProps) {
  const { isVisible, setIsVisible, client } = props;

  const [form] = Form.useForm();

  const { addClient, isLoading, editClient } = useContext(ClientsContext);

  const { timezones } = useContext(CommonResourcesContext);

  useEffect(() => {
    if (client) {
      form.setFieldsValue({ name: client.name });
      form.setFieldsValue({ town: client.town });
      form.setFieldsValue({ country: client.country });
      form.setFieldsValue({ timezone: client.timeZoneName });
    }
  }, [isVisible]);

  const handleCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const onSubmit = async (values: any) => {
    try {
      let apiMsg;
      if (client) {
        const putBody: Client = {
          ...values,
          id: client.id,
          timeZoneName: values.timezone,
        };
        apiMsg = await editClient(putBody);
      } else {
        apiMsg = await addClient(values);
      }

      message.success(apiMsg);
      setIsVisible(false);
      form.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Modal
        visible={isVisible}
        footer={null}
        onCancel={handleCancel}
        closeIcon={<RoundCloseIcon />}
      >
        <Typography.Title level={3}>
          {client ? "Update Client" : "Add a new client"}
        </Typography.Title>
        <Form onFinish={onSubmit} form={form} layout="vertical">
          <Form.Item
            label={<FormLabel>Name</FormLabel>}
            name="name"
            rules={[
              { required: true, message: "Please insert the client's name" },
              { max: 50, message: "Maximum 50 characters" },
            ]}
          >
            <Input placeholder="John Doe" />
          </Form.Item>
          <Form.Item
            label={<FormLabel>City</FormLabel>}
            name="town"
            rules={[
              { required: true, message: "Please insert the client's city" },
              { max: 50, message: "Maximum 50 characters" },
            ]}
          >
            <Input placeholder="Bucharest" />
          </Form.Item>
          <Form.Item
            label={<FormLabel>Country</FormLabel>}
            name="country"
            rules={[
              { required: true, message: "Please insert the client's country" },
              { max: 50, message: "Maximum 50 characters" },
            ]}
          >
            <Input placeholder="Romania" />
          </Form.Item>
          <Form.Item
            label={<FormLabel>Timezone</FormLabel>}
            name="timezone"
            rules={[
              {
                required: true,
                message: "Please insert the client's timezone",
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
              }
            >
              {timezones.map((timezone: Timezone, index) => (
                <Select.Option key={index} value={timezone.name}>
                  <div style={{ float: "left" }}>{timezone.name}</div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              loading={isLoading}
              type="primary"
              block
              icon={client ? <SaveOutlined /> : <PlusOutlined />}
            >
              {client ? "Save" : "Add"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
