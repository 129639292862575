import { Col, Input, InputNumber, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { getUserPosition } from "../../../_core/hardcodedValues";
import { AddTechnologyIcon } from "../../../assets/icons/Forecast/AddTechnologyIcon";
import { Employee, EmployeeProject, Technology } from "../types";
import { EmployeeTechnologyCellInputField } from "./EmployeeTechnologyCellInputField";

const CellContainer = styled.div<{ selected: boolean }>`
  opacity: ${(props: any) => (!props.selected ? "0.3!important" : "")};
`;

export const EmployeeTechnologyCell = (props: {
  employee: Employee;
  project: EmployeeProject;
  selected: boolean;
}) => {
  const { employee, project, selected } = props;
  const { employeeTechnologies, id: projectId } = project;
  return (
    <CellContainer selected={selected}>
      <Row justify="end" align="stretch" style={{ marginBottom: 5 }}>
        <AddTechnologyIcon />
      </Row>
      <Row>
        <Col flex="1">
          {employeeTechnologies.map((technology) => (
            <Row
              key={technology.id}
              style={{
                fontSize: 12,
                lineHeight: "18px",
                fontWeight: 500,
                color: "grey",
              }}
              align="middle"
            >
              <Col flex="1">
                <span
                  style={{
                    marginRight: 3,
                  }}
                >
                  {technology.name}
                </span>
                <span style={{ fontSize: 10 }}>{getUserPosition(technology.userPosition)}</span>
              </Col>
              <Col flex="1" style={{ textAlign: "center" }}>
                {`${technology.lastMonthWorked}/${technology.lastMonthForecast}`}
              </Col>
              <Col>
                <EmployeeTechnologyCellInputField
                  technology={technology}
                  employeeId={employee.id}
                  projectId={project.id}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </CellContainer>
  );
};
