import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../../App";
import { Item } from "../../types";

const useManageItem = (itemId: string) => {
  const [item, setItem] = useState<Item>();
  const [isLoading, setIsLoading] = useState(false);
  const { apiClient } = useContext(ApiClientContext);
  const fetchItem = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get(`api/items/${itemId}`);
      setItem(data);
      setIsLoading(false);
    } catch (err) {
      console.warn(err);
      setIsLoading(false);
      message.error("Could not get item");
    }
  };

  const breakItem = async () => {
    try {
      const { data } = await apiClient.put(`/api/items/${itemId}/break`);
      fetchItem();
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  const makeItemAvailable = async () => {
    try {
      const { data } = await apiClient.put(`/api/items/${itemId}/available`);
      fetchItem();
      return data;
    } catch (err) {
      console.warn(err);
      throw err;
    }
  };

  useEffect(() => {
    fetchItem();
  }, []);

  return {
    item,
    isLoading,
    breakItem,
    makeItemAvailable,
    fetchItem,
  };
};

export default useManageItem;
