import { SelectOutlined } from "@ant-design/icons";
import { Button, Card, Row, Spin, Tooltip, Typography } from "antd";
import moment from "moment";
import { useContext } from "react";
import { DashboardContext } from ".";
import { ApproveIcon } from "../../assets/icons/Inventory/ApproveIcon";
import { OpenFieldIcon } from "../../assets/icons/OpenFieldIcon";
import { CardTitle, ProjectTag } from "../../styles/StyledComponents";
import { Task } from "./types";

export const Tasks = () => {
  const { tasks, loadingTasks, markTaskDone } = useContext(DashboardContext);
  return (
    <Card
      title={<CardTitle>Tasks</CardTitle>}
      type="inner"
      style={{ height: "100%" }}
      bodyStyle={{ height: "100%", padding: 5 }}
    >
      <div style={{ maxHeight: 410, overflow: "auto", overflowX: "hidden" }}>
        <Spin spinning={loadingTasks}>
          {tasks.length ? (
            tasks.map((task: Task) => (
              <Card.Grid
                style={{ width: "32%", borderRadius: "5px", margin: 3, padding: 10 }}
                hoverable={false}
                key={task.id}
              >
                <Row justify="space-between">
                  <Typography.Text type="secondary">
                    {task.jiraCode ? task.jiraCode : "No jira code"}
                  </Typography.Text>
                  <ProjectTag color={task.color}>
                    <Typography.Text
                      style={{ maxWidth: 80, color: "white" }}
                      ellipsis={{ tooltip: task.projectName }}
                    >
                      {task.projectName}
                    </Typography.Text>
                  </ProjectTag>
                </Row>
                <Row>
                  <Typography.Title level={4}>{task.title}</Typography.Title>
                </Row>
                <Row align="middle" justify="space-between">
                  <Typography.Text type="secondary">
                    Created At: {moment(task.createdDate).format("D/M/YYYY")}
                  </Typography.Text>
                  <div>
                    {task.jiraLink && (
                      <Tooltip title="Open task">
                        <OpenFieldIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => window.open(task.jiraLink, "_blank")}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Mark task complete">
                      <ApproveIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => markTaskDone(task.id)}
                      />
                    </Tooltip>
                  </div>
                </Row>
              </Card.Grid>
            ))
          ) : (
            <Row justify="center">
              <Typography.Text type="secondary" style={{ fontSize: "1.3em", padding: 30 }}>
                No tasks yet!
              </Typography.Text>
            </Row>
          )}
        </Spin>
      </div>
    </Card>
  );
};
