import { Form, Input, Button, Spin, Alert } from "antd";
import { Link } from "react-router-dom";
import { ForgotPassForm } from "./StyledComponents";
import { useAuthPasswordFlow } from "./useAuthPasswordFlow";

export const ForgotPassword = () => {
  const { loading, response, handleForgotPassword } = useAuthPasswordFlow();

  return (
    <ForgotPassForm>
      <Form
        onFinish={handleForgotPassword}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <h1
          style={{
            fontSize: 42,
            fontWeight: "bold",
            marginBottom: 4,
            color: "#06062D"
          }}
        >
          Forgot Password
        </h1>
        <span style={{ color: "#A8A8A8", fontSize: 20, marginBottom: 16, textAlign:'left' }}>
          Have you forgotten your password? Input your email address below
        </span>
        <span style={{ color: "#A8A8A8", fontSize: 20, marginBottom: 10 }}>Email</span>
        <Form.Item
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please input your Email!" }]}
          name="email"
        >
          <Input size="large" style={{ borderRadius: 8, borderWidth: 2 }} maxLength={100} />
        </Form.Item>
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              height: 64,
              borderRadius: 8,
              backgroundColor: "#137AE9",
              fontSize: 20,
            }}
            id="button__forgot"
          >
            Send Recovery Email
          </Button>
        </Form.Item>

        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spin tip="Loading..." className="spin"></Spin>{" "}
          </div>
        ) : null}

        {response.status ? <Alert message={response.statusText} type={response.status} style={{ width: "100%" }} /> : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span style={{ color: "#06062D", fontSize: 16 }}>Got here by mistake?</span>

          <span style={{ color: "#A8A8A8" }}>
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </Form>
    </ForgotPassForm>
  );
};
