import { Button, Modal, Form, Input, DatePicker, Select, Typography, Row, Tooltip } from "antd";
import { useInviteModal } from "./useInviteModal";
import React, { useContext, useEffect } from "react";
import { functions, positions } from "../../../_core/hardcodedValues";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTechnologies } from "../../Technologies/useTechnologies";
import { TechnologyModel } from "../../Technologies/TechnologyModel";
import { FormLabel } from "../../../styles/StyledComponents";
import { AllEmployeesContext } from "../AllEmployees";
import { Office, Timezone } from "../../../_core/types";
import { CommonResourcesContext } from "../../Routing/AppRouting";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";

interface InviteModalProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
}
export const InviteModal = (props: InviteModalProps) => {
  const { isVisible, setIsVisible } = props;
  const { offices } = useContext(AllEmployeesContext);
  const { timezones } = useContext(CommonResourcesContext);

  const [form] = Form.useForm();

  useEffect(() => {
      form.setFieldsValue({ timezone: "Europe/Bucharest" });
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    try {
      await handleInvite(values);
      handleCancel();
    } catch (err) {}
  };

  const { loading, handleInvite } = useInviteModal();
  const { technologies, listLoading: technologiesLoading } = useTechnologies();
  return (
    <Modal
      onCancel={handleCancel}
      visible={isVisible}
      centered
      footer={null}
      width="50%"
      closeIcon={<RoundCloseIcon />}
    >
      <Typography.Title level={4}>Invite employee!</Typography.Title>
      <Form layout="vertical" name="form" onFinish={handleFinish} form={form}>
        <Form.Item
          style={{ flex: 1 }}
          label={<FormLabel>Email</FormLabel>}
          name="email"
          rules={[
            {
              type: "email",
              message: "This E-mail is not valid!",
            },
            {
              required: true,
              message: "Please input E-mail!",
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>
        <Row>
          <Form.Item
            style={{ flex: 1, marginRight: "5px" }}
            name="firstName"
            label={<FormLabel>First Name</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input first name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            name="lastName"
            label={<FormLabel>Last Name</FormLabel>}
            rules={[
              {
                required: true,
                message: "Please input last name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            style={{ flex: 1, marginRight: "5px" }}
            label={<FormLabel>Role</FormLabel>}
            name="role"
            rules={[
              {
                required: true,
                message: "Please select a Function!",
              },
            ]}
          >
            <Select>
              <Select.Option value={"NormalUser"} key={"NormalUser"}>
                Normal user
              </Select.Option>
              <Select.Option value={"Admin"} key={"Admin"}>
                Admin
              </Select.Option>
              <Select.Option value={"Operational"} key={"Operational"}>
                Operational
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            label={<FormLabel>Function</FormLabel>}
            name="function"
            rules={[
              {
                required: true,
                message: "Please select a Function!",
              },
            ]}
          >
            <Select>
              {functions.map((_function) => {
                return (
                  <Select.Option value={_function.value} key={_function.name}>
                    {_function.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            style={{ flex: 1, marginRight: "5px" }}
            name="phone"
            label={<FormLabel>Phone</FormLabel>}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            label={<FormLabel>Office</FormLabel>}
            name="officeId"
            rules={[
              {
                required: true,
                message: "Please select an Office!",
              },
            ]}
          >
            <Select>
              {offices.map((office: Office) => {
                return (
                  <Select.Option value={office.id} key={office.id}>
                    {office.city} {office.country}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            name="timezone"
            label={<FormLabel>Timezone</FormLabel>}
            rules={[{ required: true, message: "Please select employee timezone" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.value.toLowerCase().indexOf(input.toLowerCase()) !== -1
              }
            >
              {timezones.map((timezone: Timezone) => (
                <Select.Option key={timezone.name} value={timezone.name}>
                  {`${timezone.name} GMT${timezone.offset}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            name="startWorkingHour"
            label={<FormLabel>Start Hour</FormLabel>}
            rules={[{ required: true, message: "Please select employee start hour" }]}
          >
            <Select>
              {[...Array(24)].map((x, i) => (
                <Select.Option key={i} value={i * 60}>
                  {`${i < 10 ? "0" + i : i}:00`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            style={{ flex: 1, marginRight: "5px" }}
            label={<FormLabel>Hire Date</FormLabel>}
            name="hireDate"
            rules={[
              {
                required: true,
                message: "Please input hire date!",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            style={{ flex: 1, marginLeft: "5px", marginRight: "5px" }}
            label={<FormLabel>Work Hours</FormLabel>}
            name="workHours"
            rules={[
              {
                required: true,
                message: "Please input the work hours!",
              },
            ]}
          >
            <Select>
              <Select.Option value={4}>Half Time(4h)</Select.Option>
              <Select.Option value={6}>Part Time(6h)</Select.Option>
              <Select.Option value={8}>Full Time(8h)</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            style={{ flex: 1, marginLeft: "5px" }}
            label={<FormLabel>Birthday</FormLabel>}
            name="birthday"
            rules={[
              {
                required: true,
                message: "Please input birthday!",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Row>

        <Form.List name="technologies">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item key={field.key}>
                  <Row style={{ alignItems: "flex-end" }}>
                    <Form.Item
                      label={<FormLabel>Technology</FormLabel>}
                      name={[field.name, "id"]}
                      fieldKey={[field.fieldKey, "technology"]}
                      style={{ flex: 1, margin: "2px" }}
                    >
                      <Select loading={technologiesLoading}>
                        {technologies.map((technology: TechnologyModel) => {
                          return (
                            <Select.Option value={technology.id} key={technology.id}>
                              {technology.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label={<FormLabel>Level</FormLabel>}
                      name={[field.name, "userPosition"]}
                      fieldKey={[field.fieldKey, "position"]}
                      style={{ flex: 1, margin: "2px" }}
                    >
                      <Select>
                        {positions.map((position) => {
                          return (
                            <Select.Option value={position.value} key={position.name}>
                              {position.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Tooltip title="Remove technology">
                      <MinusCircleOutlined
                        style={{
                          color: "red",
                          margin: "2px",
                          fontSize: "1.5em",
                          marginBottom: "10px",
                        }}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </Row>
                </Form.Item>
              ))}

              <Form.Item>
                <Button
                  size="small"
                  shape="round"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Add technology
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Row justify="space-between">
          <Form.Item
            name="vehiclePlates"
            label={<FormLabel>Car Number</FormLabel>}
            style={{ flex: 1, marginRight: "5px" }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="vehicleDescription"
            label={<FormLabel>Car Description</FormLabel>}
            style={{ flex: 1, marginLeft: "5px" }}
          >
            <Input />
          </Form.Item>
        </Row>

        <Button type="primary" htmlType="submit" block loading={loading}>
          Send invitation
        </Button>
      </Form>
    </Modal>
  );
};
