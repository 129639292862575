import {Pagination, Space, Table, Tag, Tooltip, Typography} from "antd";
import moment from "moment";
import React from "react";
import {TableActions} from "./TableActions";
import {getTimeOffStatusColor, getTimeOffStatusText, prepareDates} from "../_core/helpers/time-off-general.helper";
import {TableEmployee} from "../../Utils/TableEmployee";
import {useHistory} from "react-router-dom";
import {getTimeOffRequestType} from "../../../_core/hardcodedValues";
import {SnippetsOutlined} from "@ant-design/icons";
import {TimeOffRecord} from "../_core/interfaces/time-off-record.interface";
import {TIME_OFF_PAGE_SIZE} from "../_core/constants";
import {TimeOffStatus} from "../_core/enums/time-off-status.enum";

interface TimeOffTableProps {
  requests: Array<TimeOffRecord>;
  showEmployeeName: boolean;
  totalCount: number;
  changePage: (page: number) => void;
  currentPage: number;
  refreshData: () => void;
}

export const TimeOffTable: React.FC<TimeOffTableProps> = ({
  requests, showEmployeeName, changePage, currentPage, totalCount, refreshData
}) => {
  const history = useHistory();

  const employeeColumn = [
    {
      title: "Employee",
      width: "20%",
      render: (record: TimeOffRecord) => {
        return (
          <TableEmployee employee={record.user} history={history} reverse={true} />
        )
      }
    },
  ]

  const tableColumns = [
    {
      title: "Time off",
      width: "25%",
      render: (record: TimeOffRecord) => {
        return (
          <Space direction="vertical">
            <a href={`/time-off-request/${record.id}`}>
                <div>
                  <Typography.Text strong>
                    {prepareDates(record.startDate, record.endDate)}
                  </Typography.Text>
                  {
                    record.description &&
                      <Tooltip title={record.description} placement="top">
                        <SnippetsOutlined style={{marginLeft: 5}} />
                      </Tooltip>
                  }
                </div>
                <div>
                  <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
                    {record.workingDaysCount} Working day{record.workingDaysCount === 1 ? ' ' : 's '}
                    (<span style={getTimeOffRequestType(record.type) === 'Holiday' ? {fontWeight: 600, color: 'orange'} : {}}>
                      {getTimeOffRequestType(record.type)}
                    </span>)
                  </Typography.Text>
                </div>
            </a>
          </Space>
        );
      },
    },
    {
      title: "Requested On",
      width: "15%",
      render: (record: TimeOffRecord) => {
        return (
          <Typography.Text>
            {moment(record.createdAt).format("DD MMM, YYYY")}
          </Typography.Text>
        )
      }
    },
    {
      title: "Status",
      width: "30%",
      render: (record: TimeOffRecord) => {
        return (
          <div>
            <Tag color={getTimeOffStatusColor(record.approvalStatus)} style={{fontSize: 15}}>
              {getTimeOffStatusText(record.approvalStatus)}
            </Tag>
              {
                  record.statusLog && record.approvalStatus !== TimeOffStatus.Pending &&
                  <span style={{fontSize: 12, color: "gray"}}>
                        by {record.statusLog?.reviewedBy?.lastName || '?'}. {record.statusLog?.reviewedBy?.firstName[0]} on {moment(record.statusLog?.reviewedAt).format('D MMM, YY')}
                  </span>
              }
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "10%",
      render(record: TimeOffRecord) {
        return {
          props: {
            style: { padding: 0 },
          },
          children: <TableActions record={record} refreshData={refreshData}/>,
        };
      },
    },
  ];

  return (
      <>
        <Table
            dataSource={requests?.map((val: any) => ({
              ...val,
              key: val.id,
            }))}
            pagination={false}
            columns={showEmployeeName ? [...employeeColumn, ...tableColumns] : [...tableColumns]}
        />
        <Pagination style={{marginTop: 20, paddingBottom: 20}} total={totalCount} pageSize={TIME_OFF_PAGE_SIZE} onChange={(page: number) => changePage(page)} current={currentPage} />
      </>

  );
};
