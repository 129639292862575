import { Button, Col, Row, Typography } from "antd";
import React, { createContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EmployeeDetails } from "./EmployeeDetails";
import { Items } from "./Items";
import { Projects } from "./Projects";
import { Technologies } from "./Technologies";
import { Employee, updateEmployeeDetailsFormValue } from "./types";
import useManageEmployee from "./useManageEmployee";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface ManageEmployeeContextInterface {
  employee?: Employee;
  isLoading: boolean;
  updateEmployeeDetails(values: updateEmployeeDetailsFormValue): void;
  deleteEmployee(employeeId: string): void;
  undoDeleteEmployee(employeeId: string): void;
  addTechnology(values: any): void;
  deleteTechnology(technologyId: string): void;
}
export const ManageEmployeeContext =
  //@ts-ignore
  createContext<ManageEmployeeContextInterface>();
export const ManageEmployee = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const navigate = useHistory();
  const ret = useManageEmployee(employeeId);
  return (
    <ManageEmployeeContext.Provider value={ret}>
      <div>
        <Row style={{ marginLeft: 5 }}>
          <ArrowLeftOutlined
            style={{
              fontSize: "24px",
              cursor: "pointer",
              display: "flex",
              justifyItems: "center",
              paddingRight: 8,
              paddingTop: 2,
            }}
            onClick={() => {
              navigate.push("/employees");
            }}
          />
          <Typography.Title level={3}>Manage Employee</Typography.Title>
        </Row>
        <Row>
          <Col xs={24} lg={12} style={{ padding: 5 }}>
            <EmployeeDetails />
          </Col>
          <Col xs={24} lg={12}>
            <Row style={{ padding: 5 }}>
              <Technologies />
            </Row>
            <Row style={{ padding: 5 }}>
              <Projects />
            </Row>
            <Row style={{ padding: 5 }}>
              <Items />
            </Row>
          </Col>
        </Row>
      </div>
    </ManageEmployeeContext.Provider>
  );
};
