import { message } from "antd";
import { useContext, useState } from "react";
import { ApiClientContext } from "../../../App";
import { OfficeData } from "./types";

export const useOfficePageHooks = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [officeData, setOfficeData] = useState<OfficeData | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [managers, setManagers] = useState({});
  const [officeCity, setOfficeCity] = useState("");
  const [managerModal, setManagerModal] = useState({
    visible: false,
    officeId: "",
  });

  const { apiClient } = useContext(ApiClientContext);
  const getOfficeId = async (officeId: string) => {
    setLoading(true);
    try {
      const response = await apiClient.get(`/api/offices/${officeId}`);
      setOfficeData(response.data);
      setOfficeCity(response.data.city);
    } catch (error) {
      message.error("Error");
    }
    setLoading(false);
  };

  const getManagers = async () => {
    setLoading(true);
    try {
      const response = await apiClient.get("/api/users/office-manager");

      if (response.status === 200) {
        setManagers(response.data);
      }
    } catch (error) {
      message.error("Could not get managers!");
    }
    setLoading(false);
  };

  const handleSetManager = async (values: any) => {
    setLoading(true);
    try {
      const response = await apiClient.post("/api/offices/manager", {
        officeId: values.office,
        managerId: values.manager,
      });
      setManagerModal({ visible: false, officeId: "" });
      getOfficeId(values.office);
    } catch (error) {
      message.info("error");
    }
    setLoading(false);
  };

  return {
    getOfficeId,
    managerModal,
    getManagers,
    managers,
    setManagerModal,
    officeData,
    handleSetManager,
    searchValue,
    setSearchValue,
    loading,
    officeCity,
  };
};
