import { Input, Tooltip } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";

interface TimeInputProps {
  valueProps: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  style?: React.CSSProperties;
  onEnter?: (value?: string) => void;
  isDisabled?: boolean;
  disableHoursConstraint?: boolean;
}

const isValidInput = (value: string): boolean =>
  /^[0-9:]*$/.test(value) || value === "";

const formatTime = (time: string): string => {
  if (!time.includes(":")) {
    if (time.length === 3) {
      time = "0" + time[0] + ":" + time.slice(1);
    } else if (time.length === 4) {
      time = time.slice(0, 2) + ":" + time.slice(2);
    }
  }
  const [hours, minutes] = time.split(":");
  return `${hours.padStart(2, "0")}:${
    minutes ? minutes.padStart(2, "0") : "00"
  }`;
};

const isValid24HourFormat = (
  time: string,
  restrictTo24Hours: boolean = false
): boolean => {
  if (restrictTo24Hours) {
    return /^([01]?[0-9]|2[0-3])?(:[0-5]?[0-9]?)?$/.test(time);
  }
  return /^([0-9]{1,2})?(:[0-5]?[0-9]?)?$/.test(time); // Allowing hours beyond 23
};

// Check if the time string can be transformed into a valid time by adding a colon
const isValidTimeWithoutColon = (
  time: string,
  restrictTo24Hours: boolean = false
): boolean => {
  if (restrictTo24Hours) {
    return /^([01]?[0-9]|2[0-3])[0-5][0-9]$/.test(time);
  }
  return /^[0-9]{3,4}$/.test(time); // This would match 3-4 digits without colon
};

const TimeInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  TimeInputProps
> = (
  {
    valueProps,
    placeholder,
    onChange,
    onBlur,
    style,
    onEnter,
    isDisabled,
    disableHoursConstraint,
  },
  ref
) => {
  const [internalValue, setInternalValue] = useState(valueProps);
  const [error, setError] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (isValidInput(inputValue)) {
      onChange?.(inputValue);
      setInternalValue(inputValue);
    }
  };

  const handleBlur = () => {
    let validFormat = isValid24HourFormat(
      internalValue,
      !disableHoursConstraint
    );

    if (
      !validFormat &&
      isValidTimeWithoutColon(internalValue, !disableHoursConstraint)
    ) {
      const formattedTime = formatTime(internalValue);
      onBlur?.(formattedTime);
      setInternalValue(formattedTime);
      setError(false);
      return;
    }

    if (validFormat) {
      const formattedTime = formatTime(internalValue);
      onBlur?.(formattedTime);
      setInternalValue(formattedTime);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      let validFormat = isValid24HourFormat(
        internalValue,
        !disableHoursConstraint
      );

      if (
        !validFormat &&
        isValidTimeWithoutColon(internalValue, !disableHoursConstraint)
      ) {
        const formattedTime = formatTime(internalValue);
        onEnter?.(formattedTime);
        setError(false);
        return;
      }

      if (validFormat) {
        onEnter?.(internalValue);
      } else {
        setError(true);
      }
    }
  };

  const inputStyle = error ? { borderColor: "red" } : {};

  useEffect(() => {
    setInternalValue(valueProps);
    const isValid =
      isValid24HourFormat(valueProps, !disableHoursConstraint) ||
      isValidTimeWithoutColon(valueProps, !disableHoursConstraint);

    if (isValid) {
      setError(false);
    }
  }, [valueProps]);

  return (
    <Tooltip
      title={error ? "Please enter a valid time in 24-hour format (HH:mm)" : ""}
      visible={error}
    >
      <Input
        style={{ ...style, ...inputStyle }}
        value={internalValue}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        disabled={isDisabled} //@ts-ignore
        ref={ref}
      />
    </Tooltip>
  );
};

export default React.forwardRef(TimeInput);
