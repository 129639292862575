import React, { FC, useContext } from "react";
import useReports, { IReportsContextInterface } from "./useReports";

export const ReportsDataContext = React.createContext<IReportsContextInterface>(
  {} as IReportsContextInterface
);

export const useReportsData = () => {
  const context = useContext(ReportsDataContext);
  if (!context) {
    throw new Error(
      "useReportsData must be used within a ReportsDataContextProvider"
    );
  }
  return context;
};

export const ReportsDataContextProvider: FC = ({ children }) => {
  const dataHook = useReports();
  return (
    <ReportsDataContext.Provider value={dataHook}>
      {children}
    </ReportsDataContext.Provider>
  );
};
