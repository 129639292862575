import React from "react";
import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.499999 7.7625L0.5 18.2375C0.5 22.7875 3.2125 25.5 7.7625 25.5L18.225 25.5C22.7875 25.5 25.5 22.7875 25.5 18.2375L25.5 7.775C25.5 3.225 22.7875 0.512498 18.2375 0.512498L7.7625 0.512499C3.2125 0.499998 0.499999 3.2125 0.499999 7.7625ZM11.725 17.4125C11.3625 17.05 11.3625 16.45 11.725 16.0875L13.875 13.9375L6.1375 13.9375C5.625 13.9375 5.2 13.5125 5.2 13C5.2 12.4875 5.625 12.0625 6.1375 12.0625L13.875 12.0625L11.725 9.9125C11.3625 9.55 11.3625 8.95 11.725 8.5875C12.0875 8.225 12.6875 8.225 13.05 8.5875L16.8 12.3375C16.8875 12.425 16.95 12.525 17 12.6375C17.05 12.75 17.075 12.875 17.075 13C17.075 13.125 17.05 13.2375 17 13.3625C16.95 13.475 16.8875 13.575 16.8 13.6625L13.05 17.4125C12.6875 17.775 12.1 17.775 11.725 17.4125ZM19.525 5.2C20.3625 7.7125 20.7875 10.35 20.7875 13C20.7875 15.65 20.3625 18.2875 19.525 20.8C19.3625 21.2875 18.825 21.55 18.3375 21.3875C17.85 21.225 17.575 20.7 17.75 20.2C19.3 15.55 19.3 10.4375 17.75 5.7875C17.5875 5.3 17.85 4.7625 18.3375 4.6C18.8375 4.45 19.3625 4.7125 19.525 5.2Z"
      fill="white"
    />
  </svg>
);
export const SidebarToggleIcon = (props: any) => {
  return <Icon component={svg} {...props} />;
};
