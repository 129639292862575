import Icon from "@ant-design/icons";

const svg = (props: any) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.25 5.75C7.84 5.75 7.5 5.41 7.5 5V2C7.5 1.59 7.84 1.25 8.25 1.25C8.66 1.25 9 1.59 9 2V5C9 5.41 8.66 5.75 8.25 5.75Z"
      fill="white"
    />
    <path
      d="M15.75 5.75C15.34 5.75 15 5.41 15 5V2C15 1.59 15.34 1.25 15.75 1.25C16.16 1.25 16.5 1.59 16.5 2V5C16.5 5.41 16.16 5.75 15.75 5.75Z"
      fill="white"
    />
    <path
      d="M12.0001 14.09C12.5201 14.09 12.9001 13.78 12.9001 13.29C12.9001 12.79 12.5201 12.5 12.0001 12.5C11.4801 12.5 11.1001 12.79 11.1001 13.29C11.1001 13.78 11.4801 14.09 12.0001 14.09Z"
      fill="white"
    />
    <path
      d="M12.0001 17.0001C12.6297 17.0001 13.1401 16.5838 13.1401 16.0701C13.1401 15.5565 12.6297 15.1401 12.0001 15.1401C11.3705 15.1401 10.8601 15.5565 10.8601 16.0701C10.8601 16.5838 11.3705 17.0001 12.0001 17.0001Z"
      fill="white"
    />
    <path
      d="M19.57 4.5C18.91 4.01 17.96 4.48 17.96 5.31V5.41C17.96 6.58 17.12 7.66 15.95 7.78C14.6 7.92 13.46 6.86 13.46 5.54V4.5C13.46 3.95 13.01 3.5 12.46 3.5H11.54C10.99 3.5 10.54 3.95 10.54 4.5V5.54C10.54 6.33 10.13 7.03 9.51 7.42C9.42 7.48 9.32 7.53 9.22 7.58C9.13 7.63 9.03 7.67 8.92 7.7C8.8 7.74 8.67 7.77 8.53 7.78C8.37 7.8 8.21 7.8 8.05 7.78C7.91 7.77 7.78 7.74 7.66 7.7C7.56 7.67 7.46 7.63 7.36 7.58C7.26 7.53 7.16 7.48 7.07 7.42C6.44 6.98 6.04 6.22 6.04 5.41V5.31C6.04 4.54 5.22 4.08 4.57 4.41C4.56 4.42 4.55 4.42 4.54 4.43C4.5 4.45 4.47 4.47 4.43 4.5C4.4 4.53 4.36 4.55 4.33 4.58C4.05 4.8 3.8 5.05 3.59 5.32C3.48 5.44 3.39 5.57 3.31 5.7C3.3 5.71 3.29 5.72 3.28 5.74C3.19 5.87 3.11 6.02 3.04 6.16C3.02 6.18 3.01 6.19 3.01 6.21C2.95 6.33 2.89 6.45 2.85 6.58C2.82 6.63 2.81 6.67 2.79 6.72C2.73 6.87 2.69 7.02 2.65 7.17C2.61 7.31 2.58 7.46 2.56 7.61C2.54 7.72 2.53 7.83 2.52 7.95C2.51 8.09 2.5 8.23 2.5 8.37V17.13C2.5 19.82 4.68 22 7.37 22H16.63C19.32 22 21.5 19.82 21.5 17.13V8.37C21.5 6.78 20.74 5.39 19.57 4.5ZM12 18.25C10.45 18.25 9.5 17.48 9.5 16.24C9.5 15.56 9.85 14.97 10.46 14.62C10.02 14.31 9.73 13.85 9.73 13.22C9.73 11.92 10.77 11.25 12 11.25C13.23 11.25 14.26 11.92 14.26 13.22C14.26 13.85 13.98 14.31 13.53 14.62C14.15 14.97 14.5 15.56 14.5 16.24C14.5 17.48 13.54 18.25 12 18.25Z"
      fill="white"
    />
  </svg>
);

export const TimeOffIcon = (props: any) => <Icon component={svg} {...props} />;
