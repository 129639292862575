import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Space, Table, Tooltip, Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { ProjectContext } from ".";
import { useUserRoles } from "../../../_core/hooks/useUserRoles";
import { DeleteFieldIcon } from "../../../assets/icons/DeleteFieldIcon";
import { TableHeader } from "../../../styles/StyledComponents";
import { Contact } from "../../Clients/types";
import { ClientContact } from "../types";

export const ContactsTable = () => {
  const { project, isLoading, removeClientContact, projectId } = useContext(ProjectContext);
  const { isAdmin, isTeamLead } = useUserRoles();

  const handleRemoveContact = (contact: Contact) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${contact.name} contact`,
      icon: <DeleteOutlined />,
      okText: "Remove",
      onOk() {
        return removeClientContact(contact.id);
      },
    });
  };
  return (
    <Table dataSource={project?.client.contacts} loading={isLoading} pagination={false} rowKey="id">
      <Table.Column<ClientContact>
        title={<TableHeader>Contact Person</TableHeader>}
        dataIndex="name"
        render={(val, row) => (
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {row.name}
            </Typography.Title>
            <Typography.Text type="secondary">{row.email}</Typography.Text>
          </Col>
        )}
      />
      <Table.Column<ClientContact>
        title={<TableHeader>Timezone</TableHeader>}
        render={(val, row) => `${row.timeZoneName} ${row.timeZone}`}
      />
      <Table.Column title={<TableHeader>Role</TableHeader>} dataIndex="position" />
      <Table.Column title={<TableHeader>Phone Number</TableHeader>} dataIndex="phoneNumber" />
      <Table.Column
        title={<TableHeader>Social Media</TableHeader>}
        dataIndex="socialMedia"
        render={(val) => (
          <Button type="link" href={val} target="_blank">
            Open Link
          </Button>
        )}
      />
      {(isAdmin() || isTeamLead(projectId)) && (
        <Table.Column<ClientContact>
          title={<TableHeader>Actions</TableHeader>}
          render={(val, row) => {
            return (
              <Tooltip title="Delete">
                <DeleteFieldIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRemoveContact(row)}
                />
              </Tooltip>
            );
          }}
        />
      )}
    </Table>
  );
};
