import { message } from "antd";
import { useContext, useEffect, useState } from "react";
import { ApiClientContext } from "../../../App";
import { Project, ProjectFormInputData } from "../types";
import { useLocation } from "react-router-dom";

export const useProjects = () => {
  const location = useLocation();
  const [projects, setProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { apiClient } = useContext(ApiClientContext);
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.get("/api/projects");
      setProjects(data);

      setFilteredProjects(data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.warn(err);
      message.error("Couldn't get projects");
    }
  };

  const onSearch = (input: string) => {
    let filtered = projects.filter((project) =>
      project.name.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  const createProject = async (values: ProjectFormInputData) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.post("/api/projects", values);
      fetchProjects();
      return data;
    } catch (err: any) {
      setIsLoading(false);
      if (err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Could not create project");
      }

      throw err;
    }
  };

  const updateProject = async (
    projectId: string,
    values: ProjectFormInputData
  ) => {
    try {
      setIsLoading(true);
      const { data } = await apiClient.put(
        `/api/projects/${projectId}`,
        values
      );
      fetchProjects();
      return data;
    } catch (err: any) {
      setIsLoading(false);
      if (err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Could not update project");
      }
      throw err;
    }
  };

  const deleteProject = async (projectId: string) => {
    try {
      const { data } = await apiClient.delete(`/api/projects/${projectId}`);
      fetchProjects();
      return data;
    } catch (err: any) {
      if (err.response.data.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Could not delete project");
      }
      throw err;
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [location.pathname]);

  return {
    projects,
    isLoading,
    filteredProjects,
    onSearch,
    createProject,
    updateProject,
    deleteProject,
  };
};
