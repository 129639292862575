import {Button, Modal, Tooltip} from "antd";
import React from "react";
import { AcceptRequestIcon, DeclineRequestIcon, DeleteRequestIcon} from "../../../assets/icons/CustomIcons";
import {TimeOffRecord} from "../_core/interfaces/time-off-record.interface";
import {useTimeOffActions} from "../_core/hooks/time-off-actions.hook";

interface TableActionsProps {
  record: TimeOffRecord;
  refreshData: () => void;
}

export const TableActions: React.FC<TableActionsProps> = ({ record: timeOffRequest, refreshData }) => {
  const timeOffActions = useTimeOffActions();

  const { approveTimeOffRequest, declineTimeOffRequest, deleteTimeOffRequest } = timeOffActions;

  const handleApprove = () => {
    approveTimeOffRequest(timeOffRequest.id);
    refreshData();
  };

  const handleDelete = () => {
    Modal.confirm({
      title: <>Are you sure you want to <b>remove</b> this <br/>Time Off?</>,
      icon: null,
      okType: "danger",
      okText: "Delete",
      onOk: async () => {
        await deleteTimeOffRequest(timeOffRequest.id);
        refreshData();
      },
    });
  };

  const handleDecline = () => {
    Modal.confirm({
      title: <>Are you sure you want to <b>decline</b> this <br/>Time Off?</>,
      icon: null,
      okType: "danger",
      okText: "Decline",
      onOk() {
        declineTimeOffRequest(timeOffRequest.id);
        refreshData();
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: '0 15px',
      }}
    >
      {timeOffRequest.availableOperations.find((role) => role === "Approve") ? (
          <Tooltip title="Approve" placement="top">
            <Button type="link" style={{ padding: 0 }} onClick={handleApprove}>
              <AcceptRequestIcon />
            </Button>
          </Tooltip>
      ) : null}
      {timeOffRequest.availableOperations.find((role) => role === "Decline") ? (
          <Tooltip title="Decline" placement="top">
            <Button type="link" style={{ padding: 0 }} onClick={handleDecline}>
              <DeclineRequestIcon />
            </Button>
          </Tooltip>
      ) : null}
      {timeOffRequest.availableOperations.find((role) => role === "Delete") ? (
          <Tooltip title="Remove" placement="top">
            <Button type="link" onClick={handleDelete} style={{ padding: 0 }}>
              <DeleteRequestIcon />
            </Button>
          </Tooltip>
      ) : null}
      {timeOffRequest.availableOperations?.length === 0 ? (
          <span>-</span>
      ) : null}

    </div>
  );
};
