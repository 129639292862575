import moment from "moment";

export const getWeekName = (start: string, end: string) => {
  const weekStart = moment(start).startOf("week").format("YYYY-MM-DD");

  const currentWeekFirstDay = moment().startOf("week").format("YYYY-MM-DD");
  const lastWeekFirstDay = moment()
    .subtract(7, "d")
    .startOf("week")
    .format("YYYY-MM-DD");

  if (currentWeekFirstDay === weekStart) return "This week";

  if (lastWeekFirstDay === weekStart) return "Last week";

  return moment(start).format("D MMMM") + "-" + moment(end).format("D MMMM");
};

export const getDayName = (day: string) => {
  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment().subtract(1, "d").format("YYYY-MM-DD");

  if (day === today) return "Today";
  if (day === yesterday) return "Yesterday";

  return (
    moment(day, "YYYY-MM-D").format("dddd") +
    ", " +
    moment(day).format("D MMMM")
  );
};

export const getTotal = (total: number) => {
  let hours: number | string = Math.floor(total / 60);
  if (hours < 10) hours = "0" + hours;
  let minutes: number | string = total % 60;
  if (minutes < 10) minutes = "0" + minutes;

  return hours + ":" + minutes;
};

export const convertToLocal = (date: string) => {
  if (date == null) return "";

  // const utcDate = moment.utc(date);
  const localDate = moment.utc(date).local();
  return localDate.format("HH:mm");
};

export const getLimitByWidth = () => {
  const width = window.innerWidth;
  if (width > 1670) return 160;

  if (width < 1670 && width > 1310) return 110;

  if (width < 1310 && width > 1010) return 75;

  return 50;
};

export const getDates = (initialStart: any, initialEnd: any, day: any) => {
  const dayDifference = day
    .endOf("day")
    .diff(moment(initialStart).endOf("day"), "day");

  const startDate = moment(initialStart).add(dayDifference, "days").format();
  const endDate = moment(initialEnd).add(dayDifference, "days").format();

  return { startTime: startDate, endTime: endDate };
};
