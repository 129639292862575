import { Col, Radio, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ForecastTableContext } from ".";
import { getUserPosition } from "../../../_core/hardcodedValues";
import { UserCircleAdd } from "../../../assets/icons/Forecast/UserCircleAdd";
import { Project } from "../types";
import { ProjectNameTitle } from "./styledComponents";

const CellContainer = styled.div<{ selected: boolean }>`
  min-width: 250px;
  opacity: ${(props: any) => (!props.selected ? "0.3!important" : "")};
`;

interface ProjectHeaderCellProps {
  project: Project;
}

export const ProjectHeaderCell = (props: ProjectHeaderCellProps) => {
  const { project } = props;
  const { idProjectSelected, setIdProjectSelected } = useContext(ForecastTableContext);
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (idProjectSelected === project.id) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [idProjectSelected]);
  return (
    <CellContainer selected={idProjectSelected === "" ? true : isSelected}>
      <Row>
        <Col flex="1">
          <Row justify="space-between">
            <Col>
              <ProjectNameTitle color={project.color}>{project.name}</ProjectNameTitle>
            </Col>
            <Col>
              <Row align="middle">
                <UserCircleAdd />
                <Checkbox
                  checked={isSelected}
                  onChange={() => {
                    isSelected ? setIdProjectSelected("") : setIdProjectSelected(project.id);
                  }}
                  style={{ transform: "scale(1.2)", marginLeft: 5 }}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col
              flex="1"
              style={{
                height: 80,
                maxHeight: 80,
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              {project.technologies.map((technology) => (
                <Row
                  key={technology.id + technology.userPosition}
                  style={{
                    fontSize: 12,
                    lineHeight: "18px",
                    fontWeight: 500,
                    color: "grey",
                  }}
                >
                  <Col flex="1">
                    <span
                      style={{
                        marginRight: 3,
                      }}
                    >
                      {technology.name}
                    </span>
                    <span style={{ fontSize: 10 }}>{getUserPosition(technology.userPosition)}</span>
                  </Col>
                  <Col flex="1" style={{ textAlign: "center" }}>
                    {`${technology.lastMonthWorked}/${technology.lastMonthForecast}`}
                  </Col>
                  <Col
                    flex="1"
                    style={{
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingRight: 5,
                    }}
                  >
                    {`${technology.currentMonthWorked}/${technology.currentMonthForecast}`}
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        justify="space-between"
        align="middle"
        style={{ borderTop: "1px solid #E2E8F0", color: "#137AE9" }}
      >
        <Col>Total</Col>
        <Col>{`${project.totalWorkedTime}/${project.totalForecastTime}`}</Col>
      </Row>
    </CellContainer>
  );
};
