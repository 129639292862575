import { Modal, Form, Input, Button, message } from "antd";
import React, { useContext } from "react";
import { AllMyItemsContext } from ".";
import { RoundCloseIcon } from "../../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../../styles/StyledComponents";
interface RequestItemDialogProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
}
export const RequestItemDialog = (props: RequestItemDialogProps) => {
  const { isVisible, setIsVisible } = props;
  const { isLoading, requestItem } = useContext(AllMyItemsContext);
  const [form] = Form.useForm();
  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };
  const handleFinish = async (values: { description: string }) => {
    try {
      await requestItem(values.description);
      handleCancel();
      message.success("Request sent");
    } catch (err) {}
  };
  return (
    <Modal visible={isVisible} onCancel={handleCancel} footer={null} closeIcon={<RoundCloseIcon />}>
      <div
        style={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "25px",
          textAlign: "left",
          position: "relative",
          bottom: 10,
        }}
      >
        Request Item
      </div>
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="description"
          label={<FormLabel>Item description</FormLabel>}
          rules={[{ required: true, message: "Please input a item description!" }]}
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button loading={isLoading} htmlType="submit" block type="primary" size="large">
            Request item
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
