import {
  DeleteOutlined,
  EditOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, Modal } from "antd";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { ClientContactsContext } from ".";
import { Client, Contact } from "../types";
import ContactDialog from "./ContactDialog";

export default function ContactsTable() {
  const { client, removeContact, isLoading } = useContext(
    ClientContactsContext
  );
  const [editDialog, setEditDialog] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<Contact>();

  const onEdit = (contact: Contact) => {
    setContactToEdit(contact);
    setEditDialog(true);
  };
  const onDelete = (contact: Contact) => {
    Modal.confirm({
      title: `Are you sure you want to remove ${contact.name} contact`,
      icon: <DeleteOutlined style={{ color: "red" }} />,
      okType: "danger",
      okText: "Delete",
      onOk() {
        return removeContact(contact.id);
      },
    });
  };
  return (
    <div>
      <Table dataSource={client?.contacts} rowKey="id" loading={isLoading}>
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Email" dataIndex="email" />
        <Table.Column title="Function" dataIndex="position" />
        <Table.Column title="Phone" dataIndex="phoneNumber" />
        <Table.Column<Contact>
          title="Social Media"
          dataIndex="socialMedia"
          render={(text) => {
            if (text) {
              return (
                <Tooltip title="Visit social media">
                  <Button type="link" href={text} target="_blank">
                    <GlobalOutlined style={{ fontSize: "1.5em" }} />
                  </Button>
                </Tooltip>
              );
            } else {
              return "-";
            }
          }}
        />
        <Table.Column title="Timezone" dataIndex="timeZone" />
        <Table.Column<Contact>
          title="Actions"
          render={(val, row) => (
            <>
              <Space size="small">
                <Tooltip title="Edit Contact">
                  <Button shape="circle" onClick={() => onEdit(row)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Delete Contact">
                  <Button shape="circle" danger onClick={() => onDelete(row)}>
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </>
          )}
        />
      </Table>
      <ContactDialog
        isVisible={editDialog}
        setIsVisible={setEditDialog}
        contact={contactToEdit}
      />
    </div>
  );
}
