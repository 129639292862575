import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Months } from "./Months";
import { Reports } from "./Reports";
import { Worked } from "./Worked";

export const Forecast = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/`} component={Months} />
      <Route exact path={`${path}/reports`} component={Reports} />
      <Route exact path={`${path}/worked`} component={Worked} />
    </Switch>
  );
};
