import { Col, Image, Row, Typography } from "antd";
import React from "react";
import styled from "styled-components";
import { NewEmployee } from "../types";

const { Text } = Typography;

const StyledEmployeeCell = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
`;

const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
`;

const StyledText = styled(Text)`
  font-size: 14px;
  line-height: 1.5;
`;

interface EmployeeCellProps {
  employee: NewEmployee;
}

const EmployeeCell: React.FC<EmployeeCellProps> = ({ employee }) => {
  return (
    <StyledEmployeeCell>
      <StyledImage src={employee?.profilePicturePath} />
      <Col>
        <Row>
          <StyledText strong>{`${employee?.fullName}`}</StyledText>
        </Row>
        <Row>
          <StyledText type="secondary">
            Works on: {employee?.numberOfHoursContracted}h | Total:{" "}
            {employee?.totalHoursWorked}h
          </StyledText>
        </Row>
        <Row>
          <StyledText type="secondary">
            Grand Total: {employee?.grandTotal}h
          </StyledText>
        </Row>
        <Row>
          <StyledText type="secondary">
            Total Working Hours: {employee?.totalWorkingHours}h
          </StyledText>
        </Row>
      </Col>
    </StyledEmployeeCell>
  );
};

export default EmployeeCell;
