import { Card, Col, Row, Spin, Typography } from "antd";
import moment from "moment";
import { useContext } from "react";
import { DashboardContext } from ".";
import { getTimeOffRequestType } from "../../_core/hardcodedValues";
import { CardTitle } from "../../styles/StyledComponents";
import { DayOff } from "./types";

export const DaysOff = () => {
  const { daysOff, loadingDaysOff } = useContext(DashboardContext);

  return (
    <Card
      title={<CardTitle>Days Off</CardTitle>}
      type="inner"
      style={{ width: "100%" }}
      bodyStyle={{
        height: "100%",
        minHeight: 298,
        maxHeight: 298,
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Spin spinning={loadingDaysOff}>
        {daysOff.length ? (
          daysOff.map((dayOff: DayOff, index) => (
            <Row justify="space-between" align="middle" wrap={false} key={index}>
              <Col style={{ textAlign: "left" }}>
                <a href={`/time-off-request/${dayOff.id}/my`}>
                  <div style={{ fontSize: "1.1em", fontWeight: "bolder" }}>
                    {getTimeOffRequestType(dayOff.typeOfTimeOff)}
                    {" - "}{dayOff.daysOff}{" "}
                    {dayOff.daysOff > 1 ? "days" : "day"}
                  </div>
                  <Typography.Text type="secondary" style={{ color: "#64748B" }}>
                    {moment(dayOff.startDate).format("D MMM")}
                    {" - "}
                    {moment(dayOff.endDate).format("D MMM")}
                  </Typography.Text>
                </a>
              </Col>
            </Row>
          ))
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
              No days off!
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
