import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import { useContext, useEffect } from "react";
import { ProjectsContext } from ".";
import { RoundCloseIcon } from "../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../styles/StyledComponents";
import useClients from "../Clients/Clients/useClients";
import { Client } from "../Clients/types";
import { ColorPicker } from "./ColorPicker";
import { Employee, Project, ProjectFormInputData, User } from "./types";

interface ProjectDialogProps {
  isVisible: boolean;
  setIsVisible(val: boolean): void;
  project?: Project;
  updateCallback?(): void;
}
export const ProjectDialog = (props: ProjectDialogProps) => {
  const { isVisible, setIsVisible, project, updateCallback } = props;
  const { createProject, updateProject, isLoading, users } = useContext(ProjectsContext);

  const [form] = useForm();
  const { clients } = useClients();
  useEffect(() => {
    if (project) {
      for (let key in project) {
        //@ts-ignore
        form.setFieldsValue({ [key]: project[key] });
      }
      form.setFieldsValue({ clientId: project.client.id });

      form.setFieldsValue({ teamLeaderId: project.teamLeader?.userId });
    }
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const onFinish = async (values: ProjectFormInputData) => {
    try {
      if (!project) {
        await createProject(values);
      } else {
        await updateProject(project.id, values);
        updateCallback ? updateCallback() : null;
      }
      handleCancel();
    } catch (err) {}
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={handleCancel}
      footer={null}
      centered
      width="60%"
      closeIcon={<RoundCloseIcon />}
    >
      <h2>{project ? "Edit Project" : "Create a new Project"}</h2>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Row>
          <Col flex="1" style={{ marginRight: 5 }}>
            <Form.Item
              label={<FormLabel>Project Name</FormLabel>}
              name="name"
              rules={[{ required: true, message: "Please input a Project Name!" }]}
            >
              <Input maxLength={100} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item
              label={<FormLabel> Type</FormLabel>}
              name="projectType"
              rules={[{ required: true, message: "Please select a project type!" }]}
            >
              <Select>
                <Select.Option value={100}>Internal Project</Select.Option>
                <Select.Option value={200}>Client Project</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1" style={{ marginRight: 5 }}>
            <Form.Item
              label={<FormLabel> Client</FormLabel>}
              name="clientId"
              rules={[{ required: true, message: "Please select a client!" }]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {clients.map((client: Client) => (
                  <Select.Option value={client.id} key={client.id}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col flex="1" style={{ marginRight: 5 }}>
            <Form.Item name="teamLeaderId" label={<FormLabel>Team Leader</FormLabel>}>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                 {!project
                  ? users.map((user: User) => (
                      <Select.Option value={user.id} key={user.id}>
                        {`${user.firstName} ${user.lastName}`}
                      </Select.Option>
                    ))
                  : project.members.map((user: Employee) => (
                      <Select.Option value={user.userId} key={user.userId}>
                        {`${user.firstName} ${user.lastName}`}
                      </Select.Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex="1" style={{ marginRight: 5 }}>
            <Form.Item label={<FormLabel>Daily meeting URL</FormLabel>} name="dailyUrl">
              <Input maxLength={100} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={<FormLabel>Repository URL</FormLabel>} name="repoUrl">
              <Input maxLength={100} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col flex="1" style={{ marginRight: 5 }}>
            <Form.Item label={<FormLabel>Task Management URL</FormLabel>} name="taskManagementUrl">
              <Input maxLength={100} />
            </Form.Item>
          </Col>
          <Col flex="1">
            <Form.Item label={<FormLabel>Jira Project Key</FormLabel>} name="jiraProjectKey">
              <Input maxLength={5} />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col flex="1">
            <Form.Item label={<FormLabel>Forecast Time</FormLabel>} name="forecastTime">
              <InputNumber style={{ width: "100%" }} min={1} max={2147483647} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={<FormLabel>Project Color</FormLabel>}
          name="color"
          rules={[{ required: true, message: "Please select a project color" }]}
        >
          <ColorPicker />
        </Form.Item>

        <Form.Item>
          <Button
            block
            type="primary"
            style={{ borderRadius: 5 }}
            htmlType="submit"
            loading={isLoading}
          >
            {project ? "Save Project" : "Create a project"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
