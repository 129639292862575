import { Tag } from "antd";
import styled from "styled-components";

export const ModalStyle = styled.div`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const TableBorder = styled.div`
  padding: 1px;
  margin-top: 24px;
  background: linear-gradient(0deg, rgb(255, 255, 255) 20%, rgb(238, 238, 238) 90%);
`;

export const FormLabel = styled.label`
  font-size: 20px;
  color: #a8a8a8;
  font-weight: 500;
`;

export const FormButtons = styled.div`
  justify-content: flex-end;
  margin-bottom: -27px;
  display: flex;
`;

export const TableHeader = styled.div`
  background-color: #f8fafc;
  color: #475569;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
`;

export const CardTitle = styled.div`
  font-size: 1.2em;
  text-align: left;
  margin-left: 20px;
  line-height: 24px;
  font-weight: 500;
`;

export const TableText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #64748b;
  font-weight: 600;
`;

export const StyledTag = styled(Tag)`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: #64748b;
  background-color: #e2e8f0;
  border-radius: 5px;
  padding: 5px 8px;
  margin-bottom: 4px;
  margin-right: 4px;
`;

const getContrast = function (hexColor: string) {
  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === "#") {
    hexColor = hexColor.slice(1);
  }

  // Convert to RGB value
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);

  // Get YIQ ratio
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Check contrast
  return yiq >= 128 ? "black" : "white";
};
export const ProjectTag = styled.div`
  background-color: ${(props) => props.color || "blue"};
  border-radius: 5px;
  color: ${(props) => getContrast(props.color || "blue")};
  padding: 3px 20px;
  font-size: 1em;
`;
