import React from "react";
import { TimeTrackingUserDataProvider } from "./TimeTrackingUserData/TimeTrackingUserDataProvider";
import { Tracker } from "./TimeTrackingUserData/components/Tracker";
import TrackerProvider from "./TimeTrackingUserData/components/Tracker/TrackerProvider";

export const TimeTrackingPage: React.FC = (props: any) => {
  return (
    <TimeTrackingUserDataProvider>
      <TrackerProvider>
        <Tracker defaultProjectId={props.location.state?.defaultProjectId} />
      </TrackerProvider>
    </TimeTrackingUserDataProvider>
  );
};
