import { Avatar, Card, Col, Divider, Empty, Image, Row, Skeleton, Typography } from "antd";
import React, { useContext } from "react";
import { ForecastReportsContext } from "..";
import { getUserPosition } from "../../../../_core/hardcodedValues";
import { ProjectTag } from "../../../../styles/StyledComponents";
import { formatMinToHoursString } from "../../formatMinToHoursString";
import { ProjectRow } from "./ProjectRow";

export const ReportCard = () => {
  const { report, isLoading, month, year } = useContext(ForecastReportsContext);
  return (
    <Card>
      <Skeleton loading={isLoading}>
        <Row align="middle">
          <Col>
            <div>
              <Avatar
                src={<Image preview={{ mask: <div/>}} src={report?.profilePicture} />}
                size={{ xs: 64, sm: 64, md: 64, lg: 64, xl: 80, xxl: 100 }}
              />
            </div>
          </Col>
          <Col style={{ textAlign: "left", marginLeft: 15 }}>
            <Typography.Title level={3} style={{ marginBottom: 0 }}>
              {report?.firstName} {report?.lastName}
            </Typography.Title>
            <Typography.Text type="secondary" style={{ fontSize: "1.2em" }}>
              {report?.email}
            </Typography.Text>
          </Col>
        </Row>
        <Divider />
        {report?.projects.length ? (
          report?.projects.map((project) => <ProjectRow key={project.id} project={project} />)
        ) : (
          <Empty description="This user has no projects working on!" />
        )}

        <Row style={{ textAlign: "left", fontSize: 17 }}>
          <Col flex="3"></Col>
          <Col flex="1">
            <div>
              Total hours in {new Date(year, month - 1).toLocaleString("en-us", { month: "long" })}{" "}
              {year}
            </div>
            <Row justify="space-between">
              <Col flex="1">Worked:</Col>
              <Col flex="1" style={{ fontWeight: 700 }}>
                {formatMinToHoursString(report?.totalWorkedTime)}
              </Col>
            </Row>
            <Row justify="space-between">
              <Col flex="1">Forecast:</Col>
              <Col flex="1" style={{ fontWeight: 700 }}>
                {formatMinToHoursString(report?.totalForecastTime)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Skeleton>
    </Card>
  );
};
