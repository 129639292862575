import React from "react";
import { Employee } from "../../Employees/types";
import { Report as ReportType } from "../types";
import { Header } from "./Header";
import { Report } from "./Report";
import useForecastReports from "./useForecastReports";
interface ForecastReportsContextInterface {
  year: number;
  setYear(value: number): void;
  month: number;
  setMonth(value: number): void;
  employeeId: string;
  setEmployeeId(value: string): void;
  report?: ReportType;
  isLoading: boolean;
  users: Employee[];
}
//@ts-ignore
export const ForecastReportsContext = React.createContext<ForecastReportsContextInterface>();
export const Reports = () => {
  const ret = useForecastReports();
  return (
    <ForecastReportsContext.Provider value={ret}>
      <div>
        <Header />
        <Report />
      </div>
    </ForecastReportsContext.Provider>
  );
};
