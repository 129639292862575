import { Card, Col, Row, Space, Spin, Typography } from "antd";
import moment from "moment";
import { useContext } from "react";
import { ManageEmployeeContext } from ".";
import { CardTitle } from "../../../styles/StyledComponents";

export const Projects = () => {
  const { employee, isLoading } = useContext(ManageEmployeeContext);
  return (
    <Card type="inner" style={{ width: "100%" }} title={<CardTitle>Projects</CardTitle>}>
      <Spin spinning={isLoading}>
        {employee?.projects.length ? (
          employee.projects.map((project) => (
            <div key={project.id} style={{  maxHeight: '100' }}>
              <Row style={{ fontSize: "1.2em", marginTop: 10 }}>
                <Space size="large">
                  <Typography.Text type="secondary">Project</Typography.Text>
                  <Typography.Text>{project.name}</Typography.Text>
                </Space>
              </Row>
              <Row justify="space-between">
                <Col>
                  <div>
                    <Typography.Text type="secondary" style={{ paddingRight: 5 }}>
                      Hours clocked 
                    </Typography.Text>
                    {moment().subtract(1, "months").format("MMM")} {' '} 
                    {`${project.prevMonthWorked}/${project.prevMonthForecast}`}
                    {' | '} {moment().format("MMM")} {' '}
                    {`${project.currentMonthWorked}/${project.currentMonthForecast}`}
                  </div>
                </Col>
              </Row>
            </div>
          ))
        ) : (
          <Row justify="center">
            <Typography.Text type="secondary" style={{ textAlign: "center", fontSize: "1.2em" }}>
              No projects
            </Typography.Text>
          </Row>
        )}
      </Spin>
    </Card>
  );
};
