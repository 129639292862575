import moment from "moment";
import { PreparedSession, TrackerForm } from "./types";
import { Task } from "../../../types";

// 1. Time-Related Functions

export const getPassedSeconds = (startTime: string) => {
  const time = moment.utc(startTime).local();
  return moment().diff(time, "second");
};

export const getDates = (
  initialStart: string,
  initialEnd: string,
  day: any
) => {
  const startMoment = moment(initialStart, "HH:mm").set({
    year: moment(day).year(),
    month: moment(day).month(),
    date: moment(day).date(),
  });
  const endMoment = moment(initialEnd, "HH:mm").set({
    year: moment(day).year(),
    month: moment(day).month(),
    date: moment(day).date(),
  });
  if (endMoment.isBefore(startMoment)) {
    endMoment.add(1, "day");
  }
  return {
    start: startMoment.utc().format(),
    end: endMoment.utc().format(),
  };
};

// 2. Data Parsers and Converters

export const convertToUTC = (date?: string): string => {
  return moment.utc(date).toISOString();
};

export const convertToLocal = (date: string): moment.Moment => {
  if (date == null) return moment();
  return moment.utc(date).local();
};

// 3. Validators

type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
};

export const validateTrackerForm = (
  formData: TrackerForm,
  isNewTask: boolean,
  typeOfTracking: string,
  endTime?: string
): ValidationResult => {
  const fieldsToCheck = ["projectId", "sessionType"];
  for (let field of fieldsToCheck) {
    if (!formData[field as keyof TrackerForm]) {
      return {
        isValid: false,
        errorMessage: `Validation error: ${field} is required.`,
      };
    }
  }
  if (!formData.taskId && !isNewTask) {
    return {
      isValid: false,
      errorMessage: "Validation error: Task is required.",
    };
  }
  if (typeOfTracking === "Manual" && !endTime) {
    return {
      isValid: false,
      errorMessage: "Validation error: End time is required.",
    };
  }
  return { isValid: true };
};

// 4. Form Handling

export const prepareSessionForTracking = (
  formData: TrackerForm,
  isNewTask: boolean,
  newTaskTitle: string | undefined,
  tasks: Task[] | undefined
): PreparedSession => {
  const preparedSession: PreparedSession = {
    sessionType: formData.sessionType,
    start: convertToUTC(undefined),
    technologyId: formData.technologyId,
    taskId: !isNewTask ? formData.taskId : undefined,
    task: isNewTask
      ? {
          projectId: formData.projectId,
          title:
            newTaskTitle ||
            tasks?.find((task) => task.taskId === formData.taskId)?.title ||
            "",
          description: formData.description,
        }
      : null,
  };
  return preparedSession;
};
