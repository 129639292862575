import { AutoComplete, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useContext, useEffect, useState } from "react";
import { TimeTrackingUserDataContext } from "../../../../TimeTrackingUserDataProvider";
import { Task } from "../../../../types";
import { ChangeSessionTaskModalContext, ChangeTaskForm } from "./types";

const { Option } = Select;
const TaskInputs = () => {
  const {
    formData,
    setFormData,
    tasks,
    isNewTask,
    setIsNewTask,
    taskInputValue,
    setTaskInputValue,
  } = useContext(ChangeSessionTaskModalContext);
  const { projects } = useContext(TimeTrackingUserDataContext);

  const [lastValidTaskTitle, setLastValidTaskTitle] = useState<string>(
    tasks?.find((task: Task) => task.taskId === formData.taskId)?.title || ""
  );
  const handleBlur = () => {
    const selectedTask = tasks?.find(
      (task: Task) => task.title === taskInputValue
    );
    if (!selectedTask) {
      setFormData({
        ...formData,
        taskId: undefined,
      });
    }
  };
  const handleChange = (value: string) => {
    setTaskInputValue(value);
    const existingTask = tasks?.find(
      (task: Task) => task.title?.toUpperCase() === value?.toUpperCase()
    );

    if (existingTask) {
      if (existingTask?.title) setLastValidTaskTitle(existingTask?.title);
      setFormData((prevState) => ({
        ...prevState,
        taskId: existingTask?.taskId,
        technologyId: (existingTask || prevState)?.technologyId,
        description: (existingTask || prevState)?.description,
      }));
      setIsNewTask(false);
    } else {
      setFormData((prevState) => ({
        ...prevState,
        taskId: undefined,
        description: "",
      }));
      setIsNewTask(true);
    }
  };

  const handleFocus = () => {
    setTaskInputValue("");
  };
  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getFirstTaskDataForProject = (projectId: string) => {
    const projectTasks =
      projects.find((project) => project.projectId === projectId)?.tasks || [];
    const firstTask = projectTasks[0];

    if (firstTask) {
      return {
        taskId: firstTask.taskId,
        description: firstTask.description,
        technologyId: firstTask.technologyId,
      };
    }
    return {};
  };
  const handleSelectChangeTask = (
    field: keyof ChangeTaskForm,
    value: string
  ) => {
    if (field === "projectId") {
      const firstTaskData = getFirstTaskDataForProject(value);
      setFormData((prevState) => ({
        ...prevState,
        ...firstTaskData,
        projectId: value,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
  };
  useEffect(() => {
    setTaskInputValue(
      tasks?.find((task: Task) => task.taskId === formData.taskId)?.title || ""
    );
  }, [tasks, formData.taskId]);

  return (
    <>
      <label
        style={{
          display: "block",
          marginBottom: 2,
          textAlign: "left",
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Project
      </label>
      <Select
        style={{
          flex: 2,
          marginBottom: 10,
        }}
        size={"large"}
        value={formData.projectId}
        onChange={(value) => handleSelectChangeTask("projectId", value)}
      >
        {projects.map((project) => (
          <Option
            key={project.projectId + "key-form"}
            value={project.projectId}
          >
            {project.projectName}
          </Option>
        ))}
      </Select>
      <label
        style={{
          display: "block",
          marginBottom: 2,
          textAlign: "left",
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Task
      </label>
      <AutoComplete
        style={{ flex: 4, marginBottom: 10 }}
        showSearch
        value={taskInputValue}
        placeholder="Select task"
        defaultValue={tasks?.[0]?.title}
        searchValue={taskInputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        size={"large"}
      >
        {tasks
          ?.filter((task: Task) =>
            task?.title?.toUpperCase()?.includes(taskInputValue?.toUpperCase())
          )
          .map((task: Task) => (
            <Select.Option key={task.taskId} value={task.title}>
              {task.title}
            </Select.Option>
          ))}
      </AutoComplete>
      <label
        style={{
          display: "block",
          marginBottom: 2,
          textAlign: "left",
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Description
      </label>
      <TextArea
        style={{
          marginBottom: 10,
          minHeight: "120px",
        }}
        name="description"
        placeholder="Enter task description..."
        disabled={!isNewTask}
        value={formData.description}
        onChange={(e) => handleDescriptionChange(e)}
      />
    </>
  );
};
export default TaskInputs;
