import { Modal, Form, Select, Button, message } from "antd";

import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { ProjectContext } from ".";
import { getUserPosition } from "../../../_core/hardcodedValues";
import { RoundCloseIcon } from "../../../assets/icons/RoundCloseIcon";
import { FormLabel } from "../../../styles/StyledComponents";
import { AddMemberFormValues, Technology } from "../types";
import useUsers from "../useUsers";

interface AddMemberDialogProps {
  isVisible: boolean;
  setIsVisible(value: boolean): void;
}
export const AddMemberDialog = (props: AddMemberDialogProps) => {
  const { isVisible, setIsVisible } = props;
  const { users, getUsersTechnologies, isUsersLoading } = useUsers();
  const { addMember } = useContext(ProjectContext);

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleFinish = async (values: AddMemberFormValues) => {
    try {
      await addMember(values);
      handleCancel();
    } catch (err: any) {
      message.error(err);
    }
  };

  return (
    <Modal visible={isVisible} footer={null} onCancel={handleCancel} closeIcon={<RoundCloseIcon />}>
      <h2>Add a new member</h2>
      <Form style={{ textAlign: "left" }} form={form} onFinish={handleFinish}>
        <FormLabel>Select Employee</FormLabel>
        <Form.Item name="memberId" shouldUpdate>
          <Select
            showSearch
            optionFilterProp="children"
            loading={isUsersLoading}
            onChange={() => {
              form.setFieldsValue({ technologies: [] });
            }}
          >
            {users.map((user) => (
              <Select.Option style={{ textAlign: "left" }} key={user.id} value={user.id}>
                {`${user.firstName} ${user.lastName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <FormLabel>Select Technology</FormLabel>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <Form.Item name="technologies">
              <Select
                mode="multiple"
                allowClear
                notFoundContent={<div>The employee selected has no technology assigned!</div>}
              >
                {getUsersTechnologies(getFieldValue("memberId"))?.map((technology: Technology) => (
                  <Select.Option
                    style={{ textAlign: "left" }}
                    key={technology.technologyId}
                    //@ts-ignore
                    value={technology.technologyId}
                  >
                    {`${technology.technologyName} / ${getUserPosition(technology.userPosition)}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            Add Team Member
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
