import {useContext, useState} from "react";
import {ApiClientContext} from "../../../../App";
import {message} from "antd";
import {TimeOffUserAdministration} from "../interfaces/time-off-user-administration.interface";

export const useTimeOffAdministration = () => {
    const {apiClient} = useContext(ApiClientContext);

    const [data,setData] = useState<TimeOffUserAdministration[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const getEmployeesData = async () => {
        setIsLoading(data.length === 0);
        try {
            const response = await apiClient.post("/api/time-off-management/get-management-dashboard", {});
            setData(response.data.users);
            setIsLoading(false);
        } catch(error: any) {
            message.error("Could not fetch data.");
            setIsLoading(false)
        }
    }

    return {
        getEmployeesData,

        data,
        isLoading,
    }
}
